const DEFAULT_ALLOWED_EXTENSIONS = ['jpeg', 'jpg', 'png']

export default class ImageUploader {
  constructor (element) {
    this.opts = JSON.parse(element.dataset.imageUploader)
    this.uploader = $(element).fineUploaderS3({
      debug: false,
      multiple: false,
      validation: this.validation,
      request: {
        endpoint: this.s3Endpoint,
        accessKey: this.accessKey
      },
      signature: {
        customHeaders: () => {
          return { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
        },
        endpoint: this.signatureEndpoint,
        version: 4
      },
      objectProperties: {
        acl: this.acl,
        key: this.objectProperties.bind(this),
        region: this.region,
        bucket: this.bucket
      },
      iframeSupport: {
        localBlankPagePath: '/500.html'
      }
    }).on({
      complete: this.onUploadComplete.bind(this),
      upload: this.disableSendButton.bind(this),
      cancel: this.enableSendButton.bind(this)
    })
  }

  get target () { return this.opts.target || null }
  get previewTarget () { return this.opts.previewTarget || null }
  get region () {
    // eslint-disable-next-line no-throw-literal
    if (!this.opts.region) { throw 'region must be provided to ImageUploader' }
    return this.opts.region || ''
  }

  get fillPageFields () {
    return this.opts.fillPageFields
  }

  get bucket () {
    // eslint-disable-next-line no-throw-literal
    if (!this.opts.bucket) { throw 'bucket must be provided to ImageUploader' }
    return this.opts.bucket || ''
  }

  get acl () { return this.opts.acl || 'public-read' }
  get keyPrefix () {
    // eslint-disable-next-line no-throw-literal
    if (!this.opts.keyPrefix) { throw 'keyPrefix must be provided to ImageUploader' }
    return this.opts.keyPrefix
  }

  get accessKey () {
    // eslint-disable-next-line no-throw-literal
    if (!this.opts.accessKey) { throw 'accessKey must be provided to ImageUploader' }
    return this.opts.accessKey
  }

  get s3Endpoint () {
    // eslint-disable-next-line no-throw-literal
    if (!this.opts.endpoint) { throw 'endpoint must be provided to ImageUploader' }
    return this.opts.endpoint + '/' + this.bucket
  }

  get signatureEndpoint () {
    return `/s3_objects/signature?bucket=${this.bucket}&acl=${this.acl}&key_start=${this.keyPrefix}`
  }

  get validation () {
    const validation = this.opts.validation || {}

    return {
      sizeLimit: validation.sizeLimit,
      allowedExtensions: validation.allowedExtensions || DEFAULT_ALLOWED_EXTENSIONS,
      image: {
        minWidth: validation.min_width,
        minHeight: validation.min_height,
        maxWidth: validation.max_width,
        maxHeight: validation.max_height
      }
    }
  }

  onUploadComplete (event, fileId, name, response, request) {
    if (response.success) {
      const key = this.uploader.fineUploader('getKey', fileId)
      const url = this.s3Endpoint + '/' + encodeURIComponent(key).replace(/%2F/g, '/')
      const filepath = this.objectProperties(fileId)

      $('[data-toggle=' + this.previewTarget + ']').attr('src', url)
      $('[data-toggle=' + this.target + ']').val(key)

      if (this.fillPageFields) {
        this.updatePageFields(filepath)
      }
    }
    this.enableSendButton()
  }

  updatePageFields (filepath) {
    this.fillPageFields.forEach((element) => {
      $(element.field).val(element.prepend + filepath + element.append)
    })
  }

  enableSendButton () {
    $('[data-toggle=upload-button]').attr('enabled', 'true')
  }

  disableSendButton () {
    $('[data-toggle=upload-button]').removeAttr('enabled')
  }

  objectProperties (fileId) {
    const uuid = this.uploader.fineUploader('getUuid', fileId)
    const filename = this.uploader.fineUploader('getName', fileId)
    return `${this.keyPrefix}/${uuid}-${filename}`
  }
}
