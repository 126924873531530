import MediumEditor from 'medium-editor'

export default function setupPorts (ports) {
  ports.create.subscribe(editorClass => {
    // eslint-disable-next-line no-new
    new MediumEditor(editorClass, {
      buttons: ['bold', 'italic', 'underline', 'anchor']
    })

    $(editorClass).on('input', event => {
      const target = event.target
      const field = $(target).data('field')
      const value = $(target).html()
      const modelSlug = $(target).data('model-slug')
      const data = JSON.stringify({ field, value, modelSlug })
      ports.input.send(data)
    })
  })
}
