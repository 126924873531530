const title = document.title
function checkNotifications () {
  $.ajax({ url: '/notifications/unread_notifications' }).done(function (data) {
    if (data.count > 9) {
      $('#notification-count').text('9+')
      $('title').text('(9+) ' + title)
    } else if (data.count > 0) {
      $('#notification-count').text(data.count)
      $('title').text('(' + data.count + ') ' + title)
    } else {
      $('#notification-count').text('')
      $('title').text(title)
    }
    setTimeout(checkNotifications, 120000)
  })
};
checkNotifications()
