function handleMissingImage (event) {
  const image = event.target
  if (image.dataset.replaceOnError !== 'true') return

  image.style.display = 'none'

  const fallback = image.parentNode.querySelector('[data-replace-on-error-fallback]')
  if (!fallback) return

  fallback.classList.remove(fallback.dataset.replaceOnErrorFallback)
}

// Use capture mode, since this event does not bubble
document.addEventListener('error', handleMissingImage, true)
