Handlebars.registerHelper('asCurrency', function (money) {
  if (money === null) return '-'

  return (new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(money))
})

Handlebars.registerHelper('asTick', function (booleanValue) {
  if (booleanValue === null) return '-'
  const html = booleanValue ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>'
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('asPercentage', function (percentage) {
  if (percentage === null) return '-'
  return percentage.toString() + '%'
})

Handlebars.registerHelper('simpleCheckBox', function (cssClass, checked) {
  let checkedAttribute = ''
  if (checked === true || checked === '1') checkedAttribute = "checked='checked'"
  const html = "<input class='{0}' type='checkbox' class='form-control' {1}>".format(cssClass, checkedAttribute)
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('selectTag', function (name, options, selectedOption) {
  let optionsHtml = '<option></option>'
  for (const i in options) {
    const val = options[i][0]
    const key = options[i][1]
    const selected = (key === selectedOption) ? 'selected' : ''
    optionsHtml += '<option value="{0}" {1}>{2}</option>'.format(key, selected, val)
  }
  const html = "<select name='{0}' class='form_control'>{1}</select>".format(name, optionsHtml)
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('checkBox', function (name, checked, label, other) {
  if ((typeof label).toLowerCase() !== 'string') {
    label = null
  }

  let checkedAttribute = ''
  if (checked === true || checked === '1') checkedAttribute = "checked='checked'"
  let html = "<input name='" + name + "' type='hidden' value='0'/>\n"

  if (label) {
    html += "<label for='" + name + "'>"
  }
  html += "<input name='" + name + "' type='checkbox' class='" + (label ? '' : 'form-control') + "' " +
          "value='1' " + checkedAttribute + '/>'
  if (label) {
    html += ' ' + label + '</label>'
  }
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('autocompleteTextField', function (name, value, displayValue, defaultValue) {
  value = value || defaultValue
  displayValue = displayValue || value
  let html = "<input type='hidden' name='{0}' value=''/>\n".format(name)
  html += "<input type='text' id='{0}' data-attribute='{0}' class='form-control' value='{1}' size='3' autocomplete='off'>".format(name, displayValue)
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('textField', function (name, value, defaultValue) {
  value = value || defaultValue
  const html = "<input name='{0}' type='text' class='form-control' value='{1}' size='3' autocomplete='off'>".format(name, value)
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('dateField', function (name, value) {
  const html = "<input name='{0}' type='date' class='form-control' value='{1}' size='3' autocomplete='off'>".format(name, value)
  return new Handlebars.SafeString(html)
})

Handlebars.registerHelper('userUrl', function (userId) {
  return '/users/' + userId
})

Handlebars.registerHelper('offerUrl', function (offerId) {
  return '/offers/' + offerId
})

Handlebars.registerHelper('reviewUrl', function (offerId) {
  return quotesSiteUrl('/admin/reviews/' + offerId)
})

function quotesSiteUrl (path) {
  return 'http://quotes.carwow.co.uk'.concat(path)
}
