import './dealerships_show'
import './users_show'
import 'shared/notifications'
import 'shared/autocomplete'
import 'shared/table_sortable'
import 'shared/toastr_settings'
import 'shared/tooltip'
import 'shared/activity_logs'
import 'shared/jwt'
import 'shared/analytics'
import 'shared/ajax_error'
import 'shared/call_tracking'
import 'shared/replace_missing_image'
import 'shared/string_format'
import 'shared/handlebars_formatters'
import { Elm as DealersForGroup } from 'DealersForGroup.elm'
import { Elm as Stocklist } from 'Stocklist.elm'
import { Elm as Orders } from 'Orders.elm'
import { Elm as ShortTermCampaigns } from 'ShortTermCampaigns.elm'
import { Elm as OfferNotesTable } from 'OfferNotesTable.elm'
import { Elm as OfferNotesBulkEdit } from 'OfferNotesBulkEdit.elm'
import { Elm as DealershipPromotionsOptIn } from 'DealershipPromotionsOptIn.elm'
import { Elm as PromotionDealershipsOptIn } from 'PromotionDealershipsOptIn.elm'
import toastrPorts from 'shared/elm_toastr'
import mediumPorts from 'shared/medium_editor'
import selectizePorts from 'shared/selectize'
import ImageUploader from 'shared/image_uploader'
import MediumEditor from 'medium-editor'
import elmElement from '@carwow/carwow_theme/app/javascript/elm_element'

import Rails from '@rails/ujs'

document.addEventListener('DOMContentLoaded', () => {
  Rails.start()
})

const staticFlags = {
  jwt: window.CarwowJWT,
  dealersSiteUrl: window.Carwow.APIUrls.dealersSite
}

elmElement('carwow-dealers-for-group', DealersForGroup.DealersForGroup, {
  toastrPorts,
  staticFlags,
  mapFlags (flags) {
    return Object.assign({}, flags, {
      dealershipGroupId: parseInt(flags.dealershipGroupId, 10),
      allMakes: flags.allMakes.split(',')
    })
  }
})

elmElement('carwow-stocklist', Stocklist.Stocklist, {
  setupPorts (ports) {
    ports.modelUpdated.subscribe(() => {
      requestAnimationFrame(() => {
        $('.table-sortable').dataTable()
      })
    })
  }
})

elmElement('carwow-offer-notes-table', OfferNotesTable.OfferNotesTable, {
  staticFlags,
  mapFlags (flags) {
    return Object.assign({}, flags, {
      offerNotes: JSON.parse(flags.offerNotes),
      dealershipId: parseInt(flags.dealershipId, 10) || null
    })
  },
  setupPorts (ports) {
    toastrPorts(ports)
    mediumPorts(ports)
  }
})

elmElement('carwow-offer-notes-bulk-edit', OfferNotesBulkEdit.OfferNotesBulkEdit, {
  staticFlags: {
    jwt: window.CarwowJWT,
    baseUrl: window.Carwow.APIUrls.dealersSite
  },
  mapFlags (flags) {
    return Object.assign({}, flags, {
      dealershipId: parseInt(flags.dealershipId, 10) || null,
      models: JSON.parse(flags.models)
    })
  },
  setupPorts (ports) {
    toastrPorts(ports)
    mediumPorts(ports)
  }
})

/*
  Register volume errors for various elm components. Occasional timeouts on load
  can be handled in Honeycomb instead.
*/
if (window.Carwow && window.Carwow.registerBugsnagVolumeError) {
  window.Carwow.registerBugsnagVolumeError((error) => {
    const errorClass = error.errorClass.includes('TypeError')
    const validError = error.errorMessage.includes("Cannot read properties of undefined (reading 'replaceData')")

    return errorClass && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const carDataAdminComponent = error.errorClass.includes('carwow-car-data-admin')
    const validError = error.errorMessage.includes('Http.Timeout @')

    return carDataAdminComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const ordersComponent = error.errorClass.includes('carwow-orders')
    const messages = [
      'Http.NetworkError @',
      'Http.Timeout',
      'Http.BadStatus: 504 @'
    ]
    const validError = messages.some(message => error.errorMessage.includes(message))

    return ordersComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const performanceKpisComponent = error.errorClass.includes('carwow-performance-kpis')
    const messages = [
      'Http.NetworkError @',
      'Http.Timeout @',
      'Http.BadStatus: 503 @'
    ]
    const validError = messages.some(message => error.errorMessage.includes(message))

    return performanceKpisComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const stocklistComponent = error.errorClass.includes('carwow-stocklist')
    const validError = error.errorMessage.includes('Http.Timeout @')

    return stocklistComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const dealersForGroupComponent = error.errorClass.includes('carwow-dealers-for-group')
    const validError = error.errorMessage.includes('Http.Timeout @')

    return dealersForGroupComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const shortTermCampaignsComponent = error.errorClass.includes('carwow-short-term-campaigns')
    const initialLoadError = error.errorMessage.includes('Http.NetworkError @')

    return shortTermCampaignsComponent && initialLoadError
  })
}

elmElement('carwow-orders', Orders.Orders, {
  setupPorts (ports) {
    toastrPorts(ports)
    ports.pushQuery.subscribe(query => {
      const url = `${window.location.origin}${window.location.pathname}${query}`
      window.history.replaceState({}, '', url)
    })
  },

  staticFlags: Object.assign({
    researchSiteUrl: window.Carwow.APIUrls.researchSite,
    carDataUrl: window.Carwow.APIUrls.carData,
    quotesSiteUrl: window.Carwow.APIUrls.quotesSite,
    timezone: window.Carwow.TIMEZONE
  }, staticFlags),

  mapFlags (flags) {
    const userId = parseInt(flags.userId) || -1
    const dealershipId = parseInt(flags.dealershipId) || -1
    const dealershipGroupId = parseInt(flags.dealershipGroupId) || -1
    return Object.assign({}, flags, {
      userId,
      dealershipId,
      dealershipGroupId,
      hideFilters: userId > 0 || dealershipId > 0 || dealershipGroupId > 0,
      initialQuery: window.location.search.slice(1)
    })
  }
})

elmElement('carwow-short-term-campaigns', ShortTermCampaigns.ShortTermCampaigns, {
  staticFlags,

  setupPorts (ports) {
    toastrPorts(ports)
    selectizePorts(ports)

    ports.finishedEditing.subscribe(() => {
      const editors = document.querySelectorAll('wysiwyg-editor')
      editors.forEach(editor => {
        if (!editor._editor) return
        editor._editor.destroy()
      })
    })
  },

  mapFlags (flags) {
    return Object.assign({}, flags, {
      campaignId: parseInt(flags.campaignId) || -1,
      modelSlugs: JSON.parse(flags.modelSlugs).map(obj => obj.slug)
    })
  }
})

elmElement('carwow-dealership-promotion-opt-in', DealershipPromotionsOptIn.DealershipPromotionsOptIn, {
  staticFlags,

  setupPorts (ports) {
    toastrPorts(ports)
  },
  mapFlags (flags) {
    return Object.assign({}, flags, {
      dealershipId: parseInt(flags.dealershipId)
    })
  }
})

elmElement('carwow-promotion-dealership-opt-in', PromotionDealershipsOptIn.PromotionDealershipsOptIn, {
  staticFlags,

  setupPorts (ports) {
    toastrPorts(ports)
  },
  mapFlags (flags) {
    return Object.assign({}, flags, {
      promotionId: flags.promotionId
    })
  }
})

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.image-uploader').forEach(element => {
    // eslint-disable-next-line no-new
    new ImageUploader(element)
  })
})

customElements.define('wysiwyg-editor', class extends HTMLElement {
  get editorValue () {
    return this._editorValue
  }

  set editorValue (value) {
    if (this._editorValue === value) return
    this._editorValue = value
    if (!this._editor) return
    this._editor.setValue(value)
  }

  get fieldName () {
    return this._fieldName
  }

  set fieldName (value) {
    if (this._fieldName === value) return
    this._fieldName = value
    if (!this._editor) return
    this._editor.setValue(value)
  }

  connectedCallback () {
    this._editor = new MediumEditor(this, {
      toolbar: {
        buttons: ['bold', 'italic', 'underline', 'anchor']
      },
      placeholder: false
    })

    this._editor.setContent(this.editorValue)

    this._editor.subscribe('editableInput', (event, editable) => {
      this._editorValue = this._editor.getContent()
      this.dispatchEvent(new CustomEvent('wysiwyg-editor-input', {
        detail: {
          field: this.fieldName,
          value: this.editorValue
        }
      }))
    })
  }
})
