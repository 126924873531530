(function () {
  const token = window.CarwowJWT
  if (!token) return

  function endsWith (string, sub) {
    return string.substr(string.length - sub.length, sub.length) === sub
  }

  function startsWith (string, sub) {
    return string.substr(0, sub.length) === sub
  }

  function needsJWT (url) {
    const elem = document.createElement('a')
    elem.href = url
    const hostname = elem.hostname

    // Always include the JWT in dev requests
    if (hostname === 'localhost' || endsWith(hostname, 'carwow.local')) { return true }

    // Ignore non-carwow requests
    if (!endsWith(hostname, 'carwow.co.uk') && !endsWith(hostname, 'carwow.de') && !endsWith(hostname, 'carwow.es')) { return false }

    // Ignore requests to admin
    if (startsWith(hostname, 'admin.carwow')) { return false }

    return true
  }

  // Include the JWT if possible in every request to another service
  $(document).on('ajax:beforeSend', function (options) {
    const url = options.originalEvent.detail[1].url
    if (!needsJWT(url)) return

    options.originalEvent.detail[0].setRequestHeader('Authorization', 'Bearer ' + token)
  })

  $.ajaxPrefilter(function (options) {
    if (!needsJWT(options.url)) return

    options.headers = options.headers || {}
    if (options.headers.Authorization) return
    options.headers.Authorization = 'Bearer ' + token
  })

  $(document).on('ajax:error', function (event) {
    const status = event.originalEvent.detail[2].status
    if (status !== 401) return

    window.toastr.error('Session timed out. Please refresh the page.')
  })
})()
