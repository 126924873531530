window.Carwow = window.Carwow || {}

window.Carwow.AjaxError = function (defaultMessage) {
  return function (event) {
    const status = event.originalEvent.detail[2].status
    let errorMessage = defaultMessage

    if (status === 500) {
      errorMessage = 'Error while saving, maybe admin.carwow.co.uk is down'
    } if (status !== 500 && !defaultMessage) {
      const errors = event.originalEvent.detail[0].errors
      const errorMessages = _.map(errors, function (value, field) { return field + ': ' + value })
      errorMessage = errorMessages.join('<br/>')
    }

    let notification = $('.error-notification-fixed')

    if (notification.length === 0) {
      notification = $('<div class="error-notification-fixed"></div>').prependTo($('body'))
    }

    notification.html(errorMessage)
    notification.fadeIn().delay(5000).fadeOut()
  }
}
