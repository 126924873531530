const addDefaultTimeOnOccuredOn = () => {
  const date = new Date()
  const isoDate = date.toISOString().substring(0, 16)
  $('[data-target=#activity-modal]').click(function () {
    $('#activity-modal input[type=datetime-local]').val(isoDate)
  })
}

const addNewActivityLog = () => {
  const $form = $('#new_activity_log')
  $form
    .on('ajax:success', function (event) {
      const data = event.originalEvent.detail[2].response
      $(this).closest('.modal').modal('hide')
      $('.activity-logs tbody').prepend(data)
      toastr.success('Logged new activity')
    })
    .on('ajax:error', function (event) {
      const errors = event.originalEvent.detail[0].errors
      toastr.error(errors)
    })
}

const handlePagination = ($container) => {
  $container.on('ajax:success', '.pagination a', function (event) {
    const paginatedRecords = event.originalEvent.detail[0]
    $container.html($('body', paginatedRecords))
  })
}

const fetchActivityLogs = () => {
  const $container = $('.activity-logs-container')
  const dealershipID = $container.data('dealership-id')
  const accountManagerID = $container.data('account-manager-id')
  let paramsString = null

  if (dealershipID) {
    paramsString = 'dealership_id=' + dealershipID
  } else if (accountManagerID) {
    paramsString = 'account_manager_id=' + accountManagerID
  } else {
    return
  }

  $.ajax({ url: '/activity_logs?' + paramsString })
    .done(function (data) {
      $container.html(data)
      handlePagination($container)
    })
}

const initActivityLogs = () => {
  if ($('[data-activity-logs]').length === 0) {
    return
  }

  fetchActivityLogs()
  addDefaultTimeOnOccuredOn()
  addNewActivityLog()
}

$(document).ready(function () {
  initActivityLogs()
})
