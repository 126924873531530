window.Carwow = window.Carwow || {}

window.Carwow.Autocomplete = function (configuration) {
  const defaults = {
    target: '[data-attribute=autocomplete]',
    displayKey: null,
    callback: null,
    onChange: function () {},
    onBlur: function () {},
    data: null,
    dataSource: null,
    remoteUrl: null,
    limit: 15,
    multiple: false,
    cacheTtl: null
  }

  let settings = _.extend(defaults, configuration)
  settings = _.pick(settings, _.identity)

  const $inputBoxes = $(settings.target)
  let limit = $inputBoxes.attr('data-limit') || settings.limit

  if (limit === 0) {
    limit = Number.MAX_VALUE
  }

  const source = Carwow.Autocomplete.makeSource(settings)

  $inputBoxes.typeahead({
    highlight: true,
    minLength: 0
  }, {
    name: 'search',
    displayKey: settings.displayKey,
    source
  })
    .on('typeahead:selected', settings.callback || settings.onChange)
    .on('typeahead:cursorchanged', settings.callback)
    .on('blur', settings.onBlur)
}

window.Carwow.Autocomplete.makeSource = function (settings) {
  const datumTokenizer = function (datum) {
    return Bloodhound.tokenizers.whitespace(datum[settings.displayKey])
  }

  const options = {
    datumTokenizer,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    limit: settings.limit
  }

  if (settings.data) {
    options.local = settings.data
  }

  if (settings.cacheTtl) {
    options.prefetch = {
      url: settings.remoteUrl,
      wildcard: 'QUERY',
      ttl: settings.cacheTtl
    }
  } else if (settings.remoteUrl) {
    options.remote = {
      url: settings.remoteUrl,
      wildcard: 'QUERY'
    }
  }

  const source = new Bloodhound(options)
  source.initialize()

  return source.ttAdapter()
}

window.Carwow.Multicomplete = function (configuration) {
  const defaults = {
    target: '[data-attribute=multicomplete]',
    idKey: 'id',
    displayKey: null,
    limit: 15
  }

  let settings = _.extend(defaults, configuration)
  settings = _.pick(settings, _.identity)

  const $inputBoxes = $(settings.target)

  const source = Carwow.Autocomplete.makeSource(settings)

  $inputBoxes.tagsinput({
    typeaheadjs: {
      name: 'search',
      displayKey: settings.displayKey,
      source
    },
    itemValue: settings.idKey,
    itemText: settings.displayKey
  })
  $inputBoxes.each(function () {
    const $this = $(this)
    $this.data('initial-values').forEach(function (value) {
      $this.tagsinput('add', value)
    })
  })
}

$(document).ready(function () {
  $('[data-autocomplete-url]').each(function () {
    const hiddenFieldId = $(this).attr('name').replace('_autocomplete', '_id')

    const options = {
      target: $(this),
      remoteUrl: $(this).data('autocomplete-url'),
      displayKey: $(this).data('autocomplete-label'),
      cacheTtl: $(this).data('autocomplete-cache-time'),
      onChange: function (e, data) {
        $(this).closest('form').find('[name=' + hiddenFieldId + ']').val(data.id)
      }
    }

    if ($(this).data('autocomplete-allow-blank')) {
      options.onBlur = function (e) {
        if ($(this).val().length === 0) {
          $(this).closest('form').find('[name=' + hiddenFieldId + ']').val('')
        }
      }
    }

    window.Carwow.Autocomplete(options)
  })

  $('[data-multicomplete-url]').each(function () {
    const $this = $(this)

    window.Carwow.Multicomplete({
      target: $this,
      remoteUrl: $this.data('multicomplete-url'),
      cacheTtl: $this.data('multicomplete-cache-time'),
      displayKey: $this.data('multicomplete-label'),
      idKey: $this.data('multicomplete-id') || 'id'
    })
  })
})
