(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cB.aV === region.c$.aV)
	{
		return 'on line ' + region.cB.aV;
	}
	return 'on lines ' + region.cB.aV + ' through ' + region.c$.aV;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.gJ,
		impl.gu,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		O: func(record.O),
		cC: record.cC,
		cq: record.cq
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		(key !== 'value' || key !== 'checked' || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		value
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		value
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.O;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cC;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cq) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			var oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			var newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}



// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.gJ,
		impl.gu,
		function(sendToApp, initialModel) {
			var view = impl.gU;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.e9,
		impl.gJ,
		impl.gu,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cu && impl.cu(sendToApp)
			var view = impl.gU;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.cP);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.gy) && (_VirtualDom_doc.title = title = doc.gy);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.fX;
	var onUrlRequest = impl.fY;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cu: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.f7 === next.f7
							&& curr.aT === next.aT
							&& curr.f2.a === next.f2.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		e9: function(flags)
		{
			return A3(impl.e9, flags, _Browser_getUrl(), key);
		},
		gU: impl.gU,
		gJ: impl.gJ,
		gu: impl.gu
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { e2: 'hidden', eo: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { e2: 'mozHidden', eo: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { e2: 'msHidden', eo: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { e2: 'webkitHidden', eo: 'webkitvisibilitychange' }
		: { e2: 'hidden', eo: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		dO: _Browser_getScene(),
		d5: {
			bI: _Browser_window.pageXOffset,
			bJ: _Browser_window.pageYOffset,
			aJ: _Browser_doc.documentElement.clientWidth,
			au: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		aJ: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		au: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			dO: {
				aJ: node.scrollWidth,
				au: node.scrollHeight
			},
			d5: {
				bI: node.scrollLeft,
				bJ: node.scrollTop,
				aJ: node.clientWidth,
				au: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			dO: _Browser_getScene(),
			d5: {
				bI: x,
				bJ: y,
				aJ: _Browser_doc.documentElement.clientWidth,
				au: _Browser_doc.documentElement.clientHeight
			},
			eI: {
				bI: x + rect.left,
				bJ: y + rect.top,
				aJ: rect.width,
				au: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.eO.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.eO.b, xhr)); });
		$elm$core$Maybe$isJust(request.gE) && _Http_track(router, xhr, request.gE.a);

		try {
			xhr.open(request.fv, request.a9, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.a9));
		}

		_Http_configureRequest(xhr, request);

		request.cP.a && xhr.setRequestHeader('Content-Type', request.cP.a);
		xhr.send(request.cP.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.c7; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.al.a || 0;
	xhr.responseType = request.eO.d;
	xhr.withCredentials = request.eg;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		a9: xhr.responseURL,
		dW: xhr.status,
		gr: xhr.statusText,
		c7: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			go: event.loaded,
			dR: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			gd: event.loaded,
			dR: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.g) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.h);
		} else {
			var treeLen = builder.g * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.g);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.h) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.h);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{j: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, h: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {eV: fragment, aT: host, f$: path, f2: port_, f7: protocol, c: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Orders$OrderFiltersMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Orders$OrdersTableMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Carwow$Http$Client = $elm$core$Basics$identity;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Carwow$Http$deleteHeader = F2(
	function (key, headersList) {
		return A2(
			$elm$core$List$filter,
			function (_v0) {
				var k = _v0.a;
				return !_Utils_eq(k, key);
			},
			headersList);
	});
var $author$project$Carwow$Http$addHeader = F2(
	function (_v0, headersList) {
		var key = _v0.a;
		var value = _v0.b;
		return A2(
			$elm$core$List$cons,
			_Utils_Tuple2(key, value),
			A2($author$project$Carwow$Http$deleteHeader, key, headersList));
	});
var $author$project$Carwow$Http$header = F3(
	function (key, value, _v0) {
		var client = _v0;
		return _Utils_update(
			client,
			{
				c7: A2(
					$author$project$Carwow$Http$addHeader,
					_Utils_Tuple2(key, value),
					client.c7)
			});
	});
var $author$project$Carwow$Http$auth = $author$project$Carwow$Http$header('Authorization');
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $author$project$Carwow$Http$Get = 0;
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Carwow$Http$defaultTimeoutSecs = 10;
var $author$project$Carwow$Http$secsToMs = function (secs) {
	return $elm$core$Maybe$Just(secs * 1000);
};
var $author$project$Carwow$Http$defaultTimeoutMs = $author$project$Carwow$Http$secsToMs($author$project$Carwow$Http$defaultTimeoutSecs);
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $author$project$Carwow$Http$defaultClient = {
	cP: $elm$http$Http$emptyBody,
	bh: _List_Nil,
	c7: _List_fromArray(
		[
			_Utils_Tuple2('X-User-Agent', 'carwow/browser (Carwow.Http 1.0.2)'),
			_Utils_Tuple2(
			'X-Client-Timeout',
			$elm$core$String$fromInt($author$project$Carwow$Http$defaultTimeoutSecs))
		]),
	aT: $elm$url$Url$Builder$absolute,
	fv: 0,
	ah: _List_Nil,
	al: $author$project$Carwow$Http$defaultTimeoutMs,
	ba: false
};
var $author$project$Carwow$Http$crossOrigin = function (host) {
	return _Utils_update(
		$author$project$Carwow$Http$defaultClient,
		{
			aT: $elm$url$Url$Builder$crossOrigin(host)
		});
};
var $author$project$Orders$FinishedLoadingOrders = function (a) {
	return {$: 2, a: a};
};
var $author$project$Request$Order$emptyListConfig = function (page) {
	return {ee: $elm$core$Maybe$Nothing, cX: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing, W: $elm$core$Maybe$Nothing, fs: $elm$core$Maybe$Nothing, fT: $elm$core$Maybe$Nothing, ab: $elm$core$Maybe$Nothing, cn: page, ad: $elm$core$Maybe$Nothing, ae: $elm$core$Maybe$Nothing, af: $elm$core$Maybe$Nothing, ag: $elm$core$Maybe$Nothing, f9: $elm$core$Maybe$Nothing, ai: $elm$core$Maybe$Nothing, gq: $elm$core$Maybe$Nothing, gM: $elm$core$Maybe$Nothing};
};
var $isaacseymour$deprecated_time$Time$Date$day = function (_v0) {
	var inner = _v0;
	return inner.cW;
};
var $isaacseymour$deprecated_time$Time$Date$monthToInt = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $isaacseymour$deprecated_time$Time$Date$month = function (_v0) {
	var inner = _v0;
	return $isaacseymour$deprecated_time$Time$Date$monthToInt(inner.dm);
};
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $isaacseymour$deprecated_time$Time$Internal$padded = function (n) {
	return (n < 10) ? ('0' + $elm$core$String$fromInt(n)) : $elm$core$String$fromInt(n);
};
var $isaacseymour$deprecated_time$Time$Date$year = function (_v0) {
	var inner = _v0;
	return inner.d9;
};
var $isaacseymour$deprecated_time$Time$Iso8601$fromDate = function (date) {
	return A3(
		$elm$core$String$padLeft,
		4,
		'0',
		$elm$core$String$fromInt(
			$isaacseymour$deprecated_time$Time$Date$year(date))) + ('-' + ($isaacseymour$deprecated_time$Time$Internal$padded(
		$isaacseymour$deprecated_time$Time$Date$month(date)) + ('-' + $isaacseymour$deprecated_time$Time$Internal$padded(
		$isaacseymour$deprecated_time$Time$Date$day(date)))));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Request$Order$stringQueryCheck = F2(
	function (name, maybeString) {
		return A2(
			$elm$core$Maybe$andThen,
			function (v) {
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(name, v));
			},
			maybeString);
	});
var $author$project$Request$Order$dateQueryCheck = F2(
	function (name, maybeDate) {
		return A2(
			$author$project$Request$Order$stringQueryCheck,
			name,
			A2($elm$core$Maybe$map, $isaacseymour$deprecated_time$Time$Iso8601$fromDate, maybeDate));
	});
var $author$project$Request$Order$dealershipTypeQueryCheck = function (maybeDealershipType) {
	var dealershipTypeToString = function (dealershipType) {
		if (!dealershipType) {
			return 'retail';
		} else {
			return 'leasing';
		}
	};
	return A2(
		$author$project$Request$Order$stringQueryCheck,
		'dealership_type',
		A2($elm$core$Maybe$map, dealershipTypeToString, maybeDealershipType));
};
var $author$project$Carwow$Http$methodForRequest = function (method) {
	switch (method) {
		case 0:
			return 'GET';
		case 1:
			return 'POST';
		case 2:
			return 'PATCH';
		case 3:
			return 'PUT';
		default:
			return 'DELETE';
	}
};
var $author$project$Carwow$Http$requestUrl = function (client) {
	return A2(client.aT, client.bh, client.ah);
};
var $author$project$Carwow$Http$toContext = function (_v0) {
	var client = _v0;
	return {
		cP: client.cP,
		c7: client.c7,
		fv: $author$project$Carwow$Http$methodForRequest(client.fv),
		a9: $author$project$Carwow$Http$requestUrl(client)
	};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $author$project$Carwow$Http$BadBody = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$Carwow$Http$decodeJson = F3(
	function (context, decoder, response) {
		return A2(
			$elm$core$Result$map,
			function (value) {
				return {cP: value, bd: context, c7: response.c7, dV: response.dV};
			},
			A2(
				$elm$core$Result$mapError,
				function (err) {
					return A2($author$project$Carwow$Http$BadBody, err, response);
				},
				A2(
					$elm$core$Result$mapError,
					$elm$json$Json$Decode$errorToString,
					A2($elm$json$Json$Decode$decodeString, decoder, response.cP))));
	});
var $author$project$Carwow$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Carwow$Http$BadUrl = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Carwow$Http$NetworkError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Carwow$Http$Timeout = function (a) {
	return {$: 1, a: a};
};
var $author$project$Carwow$Http$toResponse = F3(
	function (context, metadata, body_) {
		return {
			cP: body_,
			bd: context,
			c7: $elm$core$Dict$toList(metadata.c7),
			dV: metadata.dW
		};
	});
var $author$project$Carwow$Http$toDataString = F2(
	function (context, response) {
		switch (response.$) {
			case 0:
				var url_ = response.a;
				return $elm$core$Result$Err(
					A2($author$project$Carwow$Http$BadUrl, url_, context));
			case 1:
				return $elm$core$Result$Err(
					$author$project$Carwow$Http$Timeout(context));
			case 2:
				return $elm$core$Result$Err(
					$author$project$Carwow$Http$NetworkError(context));
			case 3:
				var metadata = response.a;
				var body_ = response.b;
				return $elm$core$Result$Err(
					A2(
						$author$project$Carwow$Http$BadStatus,
						metadata.dW,
						A3($author$project$Carwow$Http$toResponse, context, metadata, body_)));
			default:
				var metadata = response.a;
				var body_ = response.b;
				return $elm$core$Result$Ok(
					A3($author$project$Carwow$Http$toResponse, context, metadata, body_));
		}
	});
var $author$project$Carwow$Http$toDataJson = F3(
	function (decoder, context, response) {
		return A2(
			$elm$core$Result$andThen,
			A2($author$project$Carwow$Http$decodeJson, context, decoder),
			A2($author$project$Carwow$Http$toDataString, context, response));
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dH: reqs, dZ: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.gE;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dH));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.dZ)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					eg: r.eg,
					cP: r.cP,
					eO: A2(_Http_mapExpect, func, r.eO),
					c7: r.c7,
					fv: r.fv,
					al: r.al,
					gE: r.gE,
					a9: r.a9
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{eg: false, cP: r.cP, eO: r.eO, c7: r.c7, fv: r.fv, al: r.al, gE: r.gE, a9: r.a9}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{eg: true, cP: r.cP, eO: r.eO, c7: r.c7, fv: r.fv, al: r.al, gE: r.gE, a9: r.a9}));
};
var $author$project$Carwow$Http$toHttpRequest = F3(
	function (msg, responseToData, _v0) {
		var client = _v0;
		var request = {
			cP: client.cP,
			eO: A2($elm$http$Http$expectStringResponse, msg, responseToData),
			c7: A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var val = _v1.b;
					return A2($elm$http$Http$header, key, val);
				},
				client.c7),
			fv: $author$project$Carwow$Http$methodForRequest(client.fv),
			al: client.al,
			gE: $elm$core$Maybe$Nothing,
			a9: $author$project$Carwow$Http$requestUrl(client)
		};
		return client.ba ? $elm$http$Http$riskyRequest(request) : $elm$http$Http$request(request);
	});
var $author$project$Carwow$Http$fetchJson = F3(
	function (decoder, msg, client) {
		return A3(
			$author$project$Carwow$Http$toHttpRequest,
			msg,
			A2(
				$author$project$Carwow$Http$toDataJson,
				decoder,
				$author$project$Carwow$Http$toContext(client)),
			A3($author$project$Carwow$Http$header, 'Accept', 'application/json', client));
	});
var $author$project$Request$Order$intQueryCheck = F2(
	function (name, maybeInt) {
		return A2(
			$author$project$Request$Order$stringQueryCheck,
			name,
			A2($elm$core$Maybe$map, $elm$core$String$fromInt, maybeInt));
	});
var $author$project$Data$Order$Order = function (id) {
	return function (offerId) {
		return function (userId) {
			return function (userEmail) {
				return function (dealershipId) {
					return function (dealershipName) {
						return function (currentState) {
							return function (history) {
								return function (notes) {
									return function (userReportedOwner) {
										return function (userReportedPhoneNumber) {
											return function (dealerReportedOwner) {
												return function (dealerReportedSalespersonName) {
													return function (salesperson) {
														return function (userReportedOn) {
															return function (distance) {
																return function (callRequestsCount) {
																	return function (userCallsCount) {
																		return function (userMessagesCount) {
																			return function (modelSlug) {
																				return function (paymentType) {
																					return function (invoiceReference) {
																						return function (purchaseType) {
																							return function (estimatedDeliveryDate) {
																								return function (accountManagerEmail) {
																									return function (accountManager) {
																										return function (reviewId) {
																											return function (dealerViewingCount) {
																												return function (userPrinted) {
																													return function (userPostCode) {
																														return function (configuredModelSlugs) {
																															return function (voucherStatus) {
																																return function (createdAt) {
																																	return function (purchaseDate) {
																																		return {ed: accountManager, cL: accountManagerEmail, el: callRequestsCount, eu: configuredModelSlugs, D: createdAt, ey: currentState, eB: dealerReportedOwner, eC: dealerReportedSalespersonName, eD: dealerViewingCount, eE: dealershipId, eF: dealershipName, eH: distance, eM: estimatedDeliveryDate, b$: history, e6: id, fj: invoiceReference, fx: modelSlug, dq: notes, fT: offerId, f0: paymentType, f8: purchaseDate, f9: purchaseType, gi: reviewId, gj: salesperson, gK: userCallsCount, gL: userEmail, gM: userId, gN: userMessagesCount, gO: userPostCode, gP: userPrinted, gQ: userReportedOn, gR: userReportedOwner, gS: userReportedPhoneNumber, gV: voucherStatus};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $isaacseymour$deprecated_time$Time$Iso8601$InvalidDate = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $isaacseymour$deprecated_time$Time$Date$clampDay = function (day_) {
	return A3($elm$core$Basics$clamp, 1, 31, day_);
};
var $isaacseymour$deprecated_time$Time$Date$Apr = 3;
var $isaacseymour$deprecated_time$Time$Date$Aug = 7;
var $isaacseymour$deprecated_time$Time$Date$Dec = 11;
var $isaacseymour$deprecated_time$Time$Date$Feb = 1;
var $isaacseymour$deprecated_time$Time$Date$Jan = 0;
var $isaacseymour$deprecated_time$Time$Date$Jul = 6;
var $isaacseymour$deprecated_time$Time$Date$Jun = 5;
var $isaacseymour$deprecated_time$Time$Date$Mar = 2;
var $isaacseymour$deprecated_time$Time$Date$May = 4;
var $isaacseymour$deprecated_time$Time$Date$Nov = 10;
var $isaacseymour$deprecated_time$Time$Date$Oct = 9;
var $isaacseymour$deprecated_time$Time$Date$Sep = 8;
var $isaacseymour$deprecated_time$Time$Date$clampMonth = function (month_) {
	switch (month_) {
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			var other = month_;
			return (other <= 1) ? 0 : 11;
	}
};
var $isaacseymour$deprecated_time$Time$Date$Date = $elm$core$Basics$identity;
var $elm$core$Basics$modBy = _Basics_modBy;
var $isaacseymour$deprecated_time$Time$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 400, y)) || ((!(!A2($elm$core$Basics$modBy, 100, y))) && (!A2($elm$core$Basics$modBy, 4, y)))) ? true : false;
};
var $isaacseymour$deprecated_time$Time$Date$daysInMonth = F2(
	function (y, m) {
		var _v0 = $isaacseymour$deprecated_time$Time$Date$clampMonth(m);
		switch (_v0) {
			case 1:
				return $isaacseymour$deprecated_time$Time$Date$isLeapYear(y) ? 29 : 28;
			case 3:
				return 30;
			case 5:
				return 30;
			case 8:
				return 30;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$core$Basics$ge = _Utils_ge;
var $isaacseymour$deprecated_time$Time$Date$isValidDate = F3(
	function (year_, month_, day_) {
		return (day_ >= 1) && (_Utils_cmp(
			day_,
			A2($isaacseymour$deprecated_time$Time$Date$daysInMonth, year_, month_)) < 1);
	});
var $isaacseymour$deprecated_time$Time$Date$firstValid = F3(
	function (year_, month_, day_) {
		var monthInt = $isaacseymour$deprecated_time$Time$Date$monthToInt(month_);
		var _v0 = A3($isaacseymour$deprecated_time$Time$Date$isValidDate, year_, monthInt, day_) ? _Utils_Tuple3(year_, month_, day_) : (A3($isaacseymour$deprecated_time$Time$Date$isValidDate, year_, monthInt, day_ - 1) ? _Utils_Tuple3(year_, month_, day_ - 1) : (A3($isaacseymour$deprecated_time$Time$Date$isValidDate, year_, monthInt, day_ - 2) ? _Utils_Tuple3(year_, month_, day_ - 2) : _Utils_Tuple3(year_, month_, day_ - 3)));
		var y = _v0.a;
		var m = _v0.b;
		var d = _v0.c;
		return {cW: d, dm: m, d9: y};
	});
var $isaacseymour$deprecated_time$Time$Date$date = F3(
	function (year_, month_, day_) {
		return A3(
			$isaacseymour$deprecated_time$Time$Date$firstValid,
			year_,
			$isaacseymour$deprecated_time$Time$Date$clampMonth(month_),
			$isaacseymour$deprecated_time$Time$Date$clampDay(day_));
	});
var $elm$parser$Parser$Advanced$Located = F3(
	function (row, col, context) {
		return {cT: col, bd: context, dL: row};
	});
var $elm$parser$Parser$Advanced$changeContext = F2(
	function (newContext, s) {
		return {cT: s.cT, bd: newContext, e: s.e, b: s.b, dL: s.dL, a: s.a};
	});
var $elm$parser$Parser$Advanced$inContext = F2(
	function (context, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(
				A2(
					$elm$parser$Parser$Advanced$changeContext,
					A2(
						$elm$core$List$cons,
						A3($elm$parser$Parser$Advanced$Located, s0.dL, s0.cT, context),
						s0.bd),
					s0));
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					a,
					A2($elm$parser$Parser$Advanced$changeContext, s0.bd, s1));
			} else {
				var step = _v1;
				return step;
			}
		};
	});
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {cT: col, ex: contextStack, dz: problem, dL: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.dL, s.cT, x, s.bd));
	});
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $isaacseymour$deprecated_time$Time$Iso8601$convertDate = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var day = _v0.c;
	return A3($isaacseymour$deprecated_time$Time$Date$isValidDate, year, month, day) ? $elm$parser$Parser$Advanced$succeed(
		A3($isaacseymour$deprecated_time$Time$Date$date, year, month, day)) : A2(
		$elm$parser$Parser$Advanced$inContext,
		'leap-year',
		$elm$parser$Parser$Advanced$problem(
			$isaacseymour$deprecated_time$Time$Iso8601$InvalidDate(
				_Utils_Tuple3(year, month, day))));
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{cT: col, bd: s0.bd, e: s0.e, b: offset, dL: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.dL, s.cT, s);
	};
};
var $isaacseymour$deprecated_time$Time$Iso8601$optional = function (_char) {
	return $elm$parser$Parser$Advanced$chompWhile(
		function (c) {
			return _Utils_eq(c, _char);
		});
};
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit = {$: 0};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cT: 1, bd: s.bd, e: s.e, b: s.b + 1, dL: s.dL + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{cT: s.cT + 1, bd: s.bd, e: s.e, b: newOffset, dL: s.dL, a: s.a}));
		};
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $isaacseymour$deprecated_time$Time$Iso8601$BadInt = {$: 5};
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingRange = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $isaacseymour$deprecated_time$Time$Iso8601$intRange = F3(
	function (lo, hi, result) {
		if (!result.$) {
			var n = result.a;
			return ((_Utils_cmp(n, lo) > -1) && (_Utils_cmp(n, hi) < 1)) ? $elm$parser$Parser$Advanced$succeed(n) : $elm$parser$Parser$Advanced$problem(
				A3($isaacseymour$deprecated_time$Time$Iso8601$ExpectingRange, n, lo, hi));
		} else {
			return $elm$parser$Parser$Advanced$problem($isaacseymour$deprecated_time$Time$Iso8601$BadInt);
		}
	});
var $isaacseymour$deprecated_time$Time$Iso8601$digitsInRange = F3(
	function (name, lo, hi) {
		return A2(
			$elm$parser$Parser$Advanced$inContext,
			name,
			A2(
				$elm$parser$Parser$Advanced$andThen,
				A2(
					$elm$core$Basics$composeL,
					A2($isaacseymour$deprecated_time$Time$Iso8601$intRange, lo, hi),
					$elm$core$String$toInt),
				$elm$parser$Parser$Advanced$getChompedString(
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed(0),
							A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)),
						A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)))));
	});
var $isaacseymour$deprecated_time$Time$Iso8601$parseDay = A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'day-in-month', 1, 31);
var $isaacseymour$deprecated_time$Time$Iso8601$parseMonth = A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'month', 1, 12);
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingNDigits = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $isaacseymour$deprecated_time$Time$Iso8601$fromMaybe = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($elm$parser$Parser$Advanced$succeed),
	$elm$core$Maybe$withDefault(
		$elm$parser$Parser$Advanced$problem($isaacseymour$deprecated_time$Time$Iso8601$BadInt)));
var $isaacseymour$deprecated_time$Time$Iso8601$digits = F2(
	function (digitsCount, chomped) {
		return _Utils_eq(
			$elm$core$String$length(chomped),
			digitsCount) ? $isaacseymour$deprecated_time$Time$Iso8601$fromMaybe(
			$elm$core$String$toInt(chomped)) : $elm$parser$Parser$Advanced$problem(
			A2($isaacseymour$deprecated_time$Time$Iso8601$ExpectingNDigits, digitsCount, chomped));
	});
var $isaacseymour$deprecated_time$Time$Iso8601$parseYear = A2(
	$elm$parser$Parser$Advanced$inContext,
	'year',
	A2(
		$elm$parser$Parser$Advanced$andThen,
		$isaacseymour$deprecated_time$Time$Iso8601$digits(4),
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A2(
							$elm$parser$Parser$Advanced$ignorer,
							$elm$parser$Parser$Advanced$succeed(0),
							A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)),
						A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)),
					A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)),
				A2($elm$parser$Parser$Advanced$chompIf, $elm$core$Char$isDigit, $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDigit)))));
var $isaacseymour$deprecated_time$Time$Iso8601$parseDate = A2(
	$elm$parser$Parser$Advanced$andThen,
	$isaacseymour$deprecated_time$Time$Iso8601$convertDate,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed(
					F3(
						function (a, b, c) {
							return _Utils_Tuple3(a, b, c);
						})),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$isaacseymour$deprecated_time$Time$Iso8601$parseYear,
					$isaacseymour$deprecated_time$Time$Iso8601$optional('-'))),
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$isaacseymour$deprecated_time$Time$Iso8601$parseMonth,
				$isaacseymour$deprecated_time$Time$Iso8601$optional('-'))),
		$isaacseymour$deprecated_time$Time$Iso8601$parseDay));
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{cT: 1, bd: _List_Nil, e: 1, b: 0, dL: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $isaacseymour$deprecated_time$Time$Iso8601$toDate = function (input) {
	return A2($elm$parser$Parser$Advanced$run, $isaacseymour$deprecated_time$Time$Iso8601$parseDate, input);
};
var $author$project$Data$dateDecoder = function () {
	var toDate = function (string) {
		var _v0 = $isaacseymour$deprecated_time$Time$Iso8601$toDate(string);
		if (!_v0.$) {
			var val = _v0.a;
			return $elm$json$Json$Decode$succeed(val);
		} else {
			return $elm$json$Json$Decode$fail('invalid date: ' + string);
		}
	};
	return A2($elm$json$Json$Decode$andThen, toDate, $elm$json$Json$Decode$string);
}();
var $author$project$Data$AccountManager$AccountManager = F4(
	function (id, email, forename, surname) {
		return {eJ: email, c3: forename, e6: id, d$: surname};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Data$AccountManager$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'surname',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'forename',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'email',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Data$AccountManager$AccountManager)))));
var $author$project$Data$OrderNote$OrderNote = F3(
	function (authorEmail, content, createdAt) {
		return {ej: authorEmail, ew: content, D: createdAt};
	});
var $isaacseymour$deprecated_time$Time$DateTime$DateTime = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $isaacseymour$deprecated_time$Time$Date$daysFromYear = function (y) {
	return (y > 0) ? ((((366 + ((y - 1) * 365)) + (((y - 1) / 4) | 0)) - (((y - 1) / 100) | 0)) + (((y - 1) / 400) | 0)) : ((y < 0) ? ((((y * 365) + ((y / 4) | 0)) - ((y / 100) | 0)) + ((y / 400) | 0)) : 0);
};
var $isaacseymour$deprecated_time$Time$Date$yearFromDays = function (ds) {
	var y = (ds / 365) | 0;
	var d = $isaacseymour$deprecated_time$Time$Date$daysFromYear(y);
	return (_Utils_cmp(ds, d) < 1) ? (y - 1) : y;
};
var $isaacseymour$deprecated_time$Time$Date$dateFromDays = function (ds) {
	var d400 = $isaacseymour$deprecated_time$Time$Date$daysFromYear(400);
	var y400 = (ds / d400) | 0;
	var d = ds % d400;
	var year_ = $isaacseymour$deprecated_time$Time$Date$yearFromDays(d + 1);
	var doy = d - $isaacseymour$deprecated_time$Time$Date$daysFromYear(year_);
	var leap = $isaacseymour$deprecated_time$Time$Date$isLeapYear(year_) ? $elm$core$Basics$add(1) : $elm$core$Basics$identity;
	var _v0 = (doy < 31) ? _Utils_Tuple2(0, doy + 1) : ((_Utils_cmp(
		doy,
		leap(59)) < 0) ? _Utils_Tuple2(1, (doy - 31) + 1) : ((_Utils_cmp(
		doy,
		leap(90)) < 0) ? _Utils_Tuple2(
		2,
		(doy - leap(59)) + 1) : ((_Utils_cmp(
		doy,
		leap(120)) < 0) ? _Utils_Tuple2(
		3,
		(doy - leap(90)) + 1) : ((_Utils_cmp(
		doy,
		leap(151)) < 0) ? _Utils_Tuple2(
		4,
		(doy - leap(120)) + 1) : ((_Utils_cmp(
		doy,
		leap(181)) < 0) ? _Utils_Tuple2(
		5,
		(doy - leap(151)) + 1) : ((_Utils_cmp(
		doy,
		leap(212)) < 0) ? _Utils_Tuple2(
		6,
		(doy - leap(181)) + 1) : ((_Utils_cmp(
		doy,
		leap(243)) < 0) ? _Utils_Tuple2(
		7,
		(doy - leap(212)) + 1) : ((_Utils_cmp(
		doy,
		leap(273)) < 0) ? _Utils_Tuple2(
		8,
		(doy - leap(243)) + 1) : ((_Utils_cmp(
		doy,
		leap(304)) < 0) ? _Utils_Tuple2(
		9,
		(doy - leap(273)) + 1) : ((_Utils_cmp(
		doy,
		leap(334)) < 0) ? _Utils_Tuple2(
		10,
		(doy - leap(304)) + 1) : _Utils_Tuple2(
		11,
		(doy - leap(334)) + 1)))))))))));
	var month_ = _v0.a;
	var day_ = _v0.b;
	return {cW: day_, dm: month_, d9: year_ + (y400 * 400)};
};
var $isaacseymour$deprecated_time$Time$Date$prevMonth = function (m) {
	switch (m) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(0);
		case 2:
			return $elm$core$Maybe$Just(1);
		case 3:
			return $elm$core$Maybe$Just(2);
		case 4:
			return $elm$core$Maybe$Just(3);
		case 5:
			return $elm$core$Maybe$Just(4);
		case 6:
			return $elm$core$Maybe$Just(5);
		case 7:
			return $elm$core$Maybe$Just(6);
		case 8:
			return $elm$core$Maybe$Just(7);
		case 9:
			return $elm$core$Maybe$Just(8);
		case 10:
			return $elm$core$Maybe$Just(9);
		default:
			return $elm$core$Maybe$Just(10);
	}
};
var $isaacseymour$deprecated_time$Time$Date$daysFromYearMonth = F2(
	function (year_, month_) {
		var go = F3(
			function (y, m, acc) {
				go:
				while (true) {
					if (m.$ === 1) {
						return acc;
					} else {
						var m_ = m.a;
						var $temp$y = y,
							$temp$m = $isaacseymour$deprecated_time$Time$Date$prevMonth(m_),
							$temp$acc = acc + A2(
							$isaacseymour$deprecated_time$Time$Date$daysInMonth,
							y,
							$isaacseymour$deprecated_time$Time$Date$monthToInt(m_));
						y = $temp$y;
						m = $temp$m;
						acc = $temp$acc;
						continue go;
					}
				}
			});
		return A3(
			go,
			year_,
			$isaacseymour$deprecated_time$Time$Date$prevMonth(month_),
			0);
	});
var $isaacseymour$deprecated_time$Time$Date$daysFromYearMonthDay = F3(
	function (year_, month_, day_) {
		var yds = $isaacseymour$deprecated_time$Time$Date$daysFromYear(year_);
		var mds = A2($isaacseymour$deprecated_time$Time$Date$daysFromYearMonth, year_, month_);
		var dds = day_ - 1;
		return (yds + mds) + dds;
	});
var $isaacseymour$deprecated_time$Time$Date$addDays = F2(
	function (days, _v0) {
		var d = _v0;
		return $isaacseymour$deprecated_time$Time$Date$dateFromDays(
			days + A3($isaacseymour$deprecated_time$Time$Date$daysFromYearMonthDay, d.d9, d.dm, d.cW));
	});
var $isaacseymour$deprecated_time$Time$Internal$dayMs = 86400000;
var $isaacseymour$deprecated_time$Time$DateTime$addMilliseconds = F2(
	function (ms, _v0) {
		var data = _v0;
		var total = ms + data.b;
		var _v1 = function () {
			if (total < 0) {
				var offset = total % $isaacseymour$deprecated_time$Time$Internal$dayMs;
				var days_ = -((($elm$core$Basics$abs(total) / $isaacseymour$deprecated_time$Time$Internal$dayMs) | 0) + 1);
				return (!offset) ? _Utils_Tuple2(days_ + 1, 0) : _Utils_Tuple2(days_, $isaacseymour$deprecated_time$Time$Internal$dayMs + (offset % $isaacseymour$deprecated_time$Time$Internal$dayMs));
			} else {
				return _Utils_Tuple2((total / $isaacseymour$deprecated_time$Time$Internal$dayMs) | 0, total % $isaacseymour$deprecated_time$Time$Internal$dayMs);
			}
		}();
		var days = _v1.a;
		var newOffset = _v1.b;
		return {
			d: A2($isaacseymour$deprecated_time$Time$Date$addDays, days, data.d),
			b: newOffset
		};
	});
var $isaacseymour$deprecated_time$Time$DateTime$makeDateTime = F2(
	function (date_, offset) {
		return {d: date_, b: offset};
	});
var $isaacseymour$deprecated_time$Time$Iso8601$convertDateTime = function (_v0) {
	var date = _v0.a;
	var offset = _v0.b;
	var tZOffset_ = _v0.c;
	return $elm$parser$Parser$Advanced$succeed(
		A2(
			$isaacseymour$deprecated_time$Time$DateTime$addMilliseconds,
			tZOffset_,
			A2($isaacseymour$deprecated_time$Time$DateTime$makeDateTime, date, offset)));
};
var $isaacseymour$deprecated_time$Time$Iso8601$IntermediateOffset = F4(
	function (hours, minutes, seconds, milliseconds) {
		return {e5: hours, ce: milliseconds, dl: minutes, dP: seconds};
	});
var $isaacseymour$deprecated_time$Time$Internal$hourMs = 3600000;
var $isaacseymour$deprecated_time$Time$Internal$minuteMs = 60000;
var $isaacseymour$deprecated_time$Time$Internal$secondMs = 1000;
var $isaacseymour$deprecated_time$Time$Internal$offsetFromTimeData = function (_v0) {
	var hour = _v0.bj;
	var minute = _v0.bq;
	var second = _v0.bA;
	var millisecond = _v0.bp;
	return (((A3($elm$core$Basics$clamp, 0, 23, hour) * $isaacseymour$deprecated_time$Time$Internal$hourMs) + (A3($elm$core$Basics$clamp, 0, 59, minute) * $isaacseymour$deprecated_time$Time$Internal$minuteMs)) + (A3($elm$core$Basics$clamp, 0, 59, second) * $isaacseymour$deprecated_time$Time$Internal$secondMs)) + A3($elm$core$Basics$clamp, 0, 999, millisecond);
};
var $isaacseymour$deprecated_time$Time$Iso8601$convertTime = function (intermediate) {
	return $elm$parser$Parser$Advanced$succeed(
		$isaacseymour$deprecated_time$Time$Internal$offsetFromTimeData(
			{bj: intermediate.e5, bp: intermediate.ce, bq: intermediate.dl, bA: intermediate.dP}));
};
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDot = {$: 2};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $isaacseymour$deprecated_time$Time$Iso8601$Other = function (a) {
	return {$: 8, a: a};
};
var $isaacseymour$deprecated_time$Time$Iso8601$fromResult = function (result) {
	if (!result.$) {
		var i = result.a;
		return $elm$parser$Parser$Advanced$succeed(i);
	} else {
		var msg = result.a;
		return $elm$parser$Parser$Advanced$problem(
			$isaacseymour$deprecated_time$Time$Iso8601$Other(msg));
	}
};
var $elm$core$Basics$pow = _Basics_pow;
var $elm$core$Basics$round = _Basics_round;
var $isaacseymour$deprecated_time$Time$Iso8601$getFraction = function (fractionString) {
	var numerator = A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(fractionString));
	var denominator = A2(
		$elm$core$Basics$pow,
		10,
		$elm$core$String$length(fractionString));
	return $elm$core$Result$Ok(
		$elm$core$Basics$round(($isaacseymour$deprecated_time$Time$Internal$secondMs * numerator) / denominator));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.dL, s.cT, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cT: newCol, bd: s.bd, e: s.e, b: newOffset, dL: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $isaacseymour$deprecated_time$Time$Iso8601$optionalFraction = A2(
	$elm$parser$Parser$Advanced$inContext,
	'fraction',
	A2(
		$elm$parser$Parser$Advanced$andThen,
		A2(
			$elm$core$Basics$composeL,
			A2($elm$core$Basics$composeL, $isaacseymour$deprecated_time$Time$Iso8601$fromResult, $isaacseymour$deprecated_time$Time$Iso8601$getFraction),
			$elm$core$String$dropLeft(1)),
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$symbol(
					A2($elm$parser$Parser$Advanced$Token, '.', $isaacseymour$deprecated_time$Time$Iso8601$ExpectingDot)),
				$elm$parser$Parser$Advanced$chompWhile($elm$core$Char$isDigit)))));
var $isaacseymour$deprecated_time$Time$Iso8601$fraction = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$isaacseymour$deprecated_time$Time$Iso8601$optionalFraction,
			$elm$parser$Parser$Advanced$succeed(0)
		]));
var $isaacseymour$deprecated_time$Time$Iso8601$parseOffset = A2(
	$elm$parser$Parser$Advanced$andThen,
	$isaacseymour$deprecated_time$Time$Iso8601$convertTime,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed($isaacseymour$deprecated_time$Time$Iso8601$IntermediateOffset),
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'hours', 0, 23),
						$isaacseymour$deprecated_time$Time$Iso8601$optional(':'))),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'minutes', 0, 59),
					$isaacseymour$deprecated_time$Time$Iso8601$optional(':'))),
			A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'seconds', 0, 59)),
		$isaacseymour$deprecated_time$Time$Iso8601$fraction));
var $isaacseymour$deprecated_time$Time$Iso8601$getTZOffset = function (_v0) {
	var polarity_ = _v0.a;
	var hrs = _v0.b;
	var min = _v0.c;
	return $elm$core$Result$Ok(((polarity_ * hrs) * $isaacseymour$deprecated_time$Time$Internal$hourMs) + ((polarity_ * min) * $isaacseymour$deprecated_time$Time$Internal$minuteMs));
};
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingSign = {$: 4};
var $isaacseymour$deprecated_time$Time$Iso8601$polarity = A2(
	$elm$parser$Parser$Advanced$inContext,
	'timezone polarity',
	A2(
		$elm$parser$Parser$Advanced$andThen,
		A2(
			$elm$core$Basics$composeL,
			$isaacseymour$deprecated_time$Time$Iso8601$fromResult,
			function (sign) {
				return (sign === '+') ? $elm$core$Result$Ok(-1) : $elm$core$Result$Ok(1);
			}),
		$elm$parser$Parser$Advanced$getChompedString(
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(0),
				A2(
					$elm$parser$Parser$Advanced$chompIf,
					function (c) {
						return (c === '+') || ((c === '-') || (c === '−'));
					},
					$isaacseymour$deprecated_time$Time$Iso8601$ExpectingSign)))));
var $isaacseymour$deprecated_time$Time$Iso8601$optionalTZOffset = A2(
	$elm$parser$Parser$Advanced$inContext,
	'offset',
	A2(
		$elm$parser$Parser$Advanced$andThen,
		A2($elm$core$Basics$composeL, $isaacseymour$deprecated_time$Time$Iso8601$fromResult, $isaacseymour$deprecated_time$Time$Iso8601$getTZOffset),
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$keeper,
					$elm$parser$Parser$Advanced$succeed(
						F3(
							function (a, b, c) {
								return _Utils_Tuple3(a, b, c);
							})),
					$isaacseymour$deprecated_time$Time$Iso8601$polarity),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'timezone hours', 0, 23),
					$isaacseymour$deprecated_time$Time$Iso8601$optional(':'))),
			A3($isaacseymour$deprecated_time$Time$Iso8601$digitsInRange, 'timezone minutes', 0, 59))));
var $isaacseymour$deprecated_time$Time$Iso8601$ExpectingZ = {$: 3};
var $isaacseymour$deprecated_time$Time$Iso8601$utc = A2(
	$elm$parser$Parser$Advanced$ignorer,
	$elm$parser$Parser$Advanced$succeed(0),
	$elm$parser$Parser$Advanced$symbol(
		A2($elm$parser$Parser$Advanced$Token, 'Z', $isaacseymour$deprecated_time$Time$Iso8601$ExpectingZ)));
var $isaacseymour$deprecated_time$Time$Iso8601$tZOffset = $elm$parser$Parser$Advanced$oneOf(
	_List_fromArray(
		[
			$isaacseymour$deprecated_time$Time$Iso8601$utc,
			$isaacseymour$deprecated_time$Time$Iso8601$optionalTZOffset,
			$elm$parser$Parser$Advanced$succeed(0)
		]));
var $isaacseymour$deprecated_time$Time$Iso8601$parseDateTime = A2(
	$elm$parser$Parser$Advanced$andThen,
	$isaacseymour$deprecated_time$Time$Iso8601$convertDateTime,
	A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$keeper,
				$elm$parser$Parser$Advanced$succeed(
					F3(
						function (a, b, c) {
							return _Utils_Tuple3(a, b, c);
						})),
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$isaacseymour$deprecated_time$Time$Iso8601$parseDate,
					$isaacseymour$deprecated_time$Time$Iso8601$optional('T'))),
			$isaacseymour$deprecated_time$Time$Iso8601$parseOffset),
		$isaacseymour$deprecated_time$Time$Iso8601$tZOffset));
var $isaacseymour$deprecated_time$Time$Iso8601$toDateTime = function (input) {
	return A2($elm$parser$Parser$Advanced$run, $isaacseymour$deprecated_time$Time$Iso8601$parseDateTime, input);
};
var $author$project$Data$timeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (time) {
		var _v0 = $isaacseymour$deprecated_time$Time$Iso8601$toDateTime(time);
		if (!_v0.$) {
			var result = _v0.a;
			return $elm$json$Json$Decode$succeed(result);
		} else {
			return $elm$json$Json$Decode$fail('Failed to parse time as ISO8601: ' + time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Data$OrderNote$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'created_at',
	$author$project$Data$timeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'content',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'author_email',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Data$OrderNote$OrderNote))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3($NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder, path, valDecoder, fallback),
			decoder);
	});
var $author$project$Data$Order$OrderTransition = F7(
	function (toState, createdAt, accountManagerEmail, reasonCategory, reasonDetail, notes, performedBy) {
		return {cL: accountManagerEmail, D: createdAt, dq: notes, f1: performedBy, gb: reasonCategory, gc: reasonDetail, cF: toState};
	});
var $author$project$Data$Order$Cancelled = 3;
var $author$project$Data$Order$Confirmed = 2;
var $author$project$Data$Order$Pending = 0;
var $author$project$Data$Order$Voided = 1;
var $author$project$Data$Order$stateDecoder = function () {
	var decodeState = function (stateString) {
		switch (stateString) {
			case 'pending':
				return $elm$json$Json$Decode$succeed(0);
			case 'voided':
				return $elm$json$Json$Decode$succeed(1);
			case 'confirmed':
				return $elm$json$Json$Decode$succeed(2);
			case 'cancelled':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Unknown state ' + stateString);
		}
	};
	return A2($elm$json$Json$Decode$andThen, decodeState, $elm$json$Json$Decode$string);
}();
var $author$project$Data$Order$orderTransitionDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
	_List_fromArray(
		['performed_by']),
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
		_List_fromArray(
			['metadata', 'notes']),
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
			_List_fromArray(
				['metadata', 'reason_detail']),
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
				_List_fromArray(
					['metadata', 'reason_category']),
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
					_List_fromArray(
						['metadata', 'account_manager_email']),
					$elm$json$Json$Decode$string,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'created_at',
						$author$project$Data$timeDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'to_state',
							$author$project$Data$Order$stateDecoder,
							$elm$json$Json$Decode$succeed($author$project$Data$Order$OrderTransition))))))));
var $author$project$Data$Order$Demo = 2;
var $author$project$Data$Order$FactoryOrder = 1;
var $author$project$Data$Order$PreReg = 3;
var $author$project$Data$Order$Stock = 0;
var $author$project$Data$Order$Used = 4;
var $author$project$Data$Order$purchaseTypeDecoder = function () {
	var decodePurchaseType = function (purchaseTypeString) {
		switch (purchaseTypeString) {
			case 'stock':
				return $elm$json$Json$Decode$succeed(0);
			case 'factory_order':
				return $elm$json$Json$Decode$succeed(1);
			case 'demo':
				return $elm$json$Json$Decode$succeed(2);
			case 'pre_reg':
				return $elm$json$Json$Decode$succeed(3);
			case 'used':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('Unknown purchase type ' + purchaseTypeString);
		}
	};
	return A2($elm$json$Json$Decode$andThen, decodePurchaseType, $elm$json$Json$Decode$string);
}();
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Data$Order$InQueue = 1;
var $author$project$Data$Order$NotSent = 0;
var $author$project$Data$Order$Sent = 2;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Data$Order$Reward = F3(
	function (id, choosenReward, voucherSent) {
		return {eq: choosenReward, e6: id, d6: voucherSent};
	});
var $author$project$Data$Order$rewardDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'voucher_sent',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'chosen_reward',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Data$Order$Reward))));
var $author$project$Data$Order$voucherStatusDecoder = function () {
	var toVoucherState = F2(
		function (inQueue, reward) {
			return inQueue ? 1 : (A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.d6;
					},
					reward)) ? 2 : 0);
		});
	return A3(
		$elm$json$Json$Decode$map2,
		toVoucherState,
		A2($elm$json$Json$Decode$field, 'in_voucher_queue', $elm$json$Json$Decode$bool),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'reward', $author$project$Data$Order$rewardDecoder)));
}();
var $author$project$Data$Order$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'purchase_date',
	$elm$json$Json$Decode$nullable($author$project$Data$dateDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'created_at',
		$author$project$Data$timeDecoder,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			$author$project$Data$Order$voucherStatusDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
				_List_fromArray(
					['lead', 'model_slugs']),
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['lead', 'user_postcode']),
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['lead', 'user_printed']),
						A2(
							$elm$json$Json$Decode$map,
							$elm$core$Maybe$withDefault(false),
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool)),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
							_List_fromArray(
								['lead', 'lead_viewings_count']),
							A2(
								$elm$json$Json$Decode$map,
								$elm$core$Maybe$withDefault(0),
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
								_List_fromArray(
									['review', 'id']),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
									_List_fromArray(
										['dealership', 'account_manager']),
									$author$project$Data$AccountManager$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
										_List_fromArray(
											['dealership', 'account_manager', 'email']),
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'estimated_delivery_date',
											$elm$json$Json$Decode$nullable($author$project$Data$dateDecoder),
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'purchase_type',
												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Data$Order$purchaseTypeDecoder),
												$elm$core$Maybe$Nothing,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'invoice_reference',
													$elm$json$Json$Decode$string,
													'',
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'payment_type',
														$elm$json$Json$Decode$string,
														'',
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'model_slug',
															$elm$json$Json$Decode$string,
															'',
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																_List_fromArray(
																	['lead', 'user_messages_count']),
																$elm$json$Json$Decode$int,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																	_List_fromArray(
																		['lead', 'user_calls_count']),
																	$elm$json$Json$Decode$int,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																		_List_fromArray(
																			['lead', 'call_requests_count']),
																		$elm$json$Json$Decode$int,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																			_List_fromArray(
																				['lead', 'distance']),
																			$elm$json$Json$Decode$int,
																			A3(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																				'user_reported_on',
																				$elm$json$Json$Decode$nullable($author$project$Data$timeDecoder),
																				A4(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																					'user_reported_salesperson_name',
																					$elm$json$Json$Decode$string,
																					'',
																					A4(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																						'dealer_reported_salesperson_name',
																						$elm$json$Json$Decode$string,
																						'',
																						A4(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																							'dealer_reported_registered_owner',
																							$elm$json$Json$Decode$string,
																							'',
																							A4(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																								'user_reported_phone_number',
																								$elm$json$Json$Decode$string,
																								'',
																								A4(
																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																									'user_reported_registered_owner',
																									$elm$json$Json$Decode$string,
																									'',
																									A4(
																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																										'order_notes',
																										$elm$json$Json$Decode$list($author$project$Data$OrderNote$decoder),
																										_List_Nil,
																										A3(
																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																											'history',
																											$elm$json$Json$Decode$list($author$project$Data$Order$orderTransitionDecoder),
																											A3(
																												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																												'state',
																												$author$project$Data$Order$stateDecoder,
																												A3(
																													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																													_List_fromArray(
																														['dealership', 'name']),
																													$elm$json$Json$Decode$string,
																													A3(
																														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																														'dealership_id',
																														$elm$json$Json$Decode$int,
																														A3(
																															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
																															_List_fromArray(
																																['lead', 'user_email']),
																															$elm$json$Json$Decode$string,
																															A3(
																																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																'user_id',
																																$elm$json$Json$Decode$int,
																																A3(
																																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																	'offer_id',
																																	$elm$json$Json$Decode$int,
																																	A3(
																																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																		'id',
																																		$elm$json$Json$Decode$int,
																																		$elm$json$Json$Decode$succeed($author$project$Data$Order$Order)))))))))))))))))))))))))))))))))));
var $author$project$Data$Order$listDecoder = $elm$json$Json$Decode$list($author$project$Data$Order$decoder);
var $author$project$Carwow$Http$path = F2(
	function (endpoint, _v0) {
		var client = _v0;
		return _Utils_update(
			client,
			{bh: endpoint});
	});
var $author$project$Request$Order$purchaseTypeQueryCheck = function (maybePurchaseType) {
	var purchaseTypeToString = function (purchaseType) {
		switch (purchaseType) {
			case 0:
				return 'stock';
			case 1:
				return 'factory_order';
			case 2:
				return 'demo';
			case 3:
				return 'pre_reg';
			default:
				return 'used';
		}
	};
	return A2(
		$author$project$Request$Order$stringQueryCheck,
		'purchase_type',
		A2($elm$core$Maybe$map, purchaseTypeToString, maybePurchaseType));
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Carwow$Http$query = F2(
	function (params, _v0) {
		var client = _v0;
		var newParams = _Utils_ap(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var k = _v1.a;
					var v = _v1.b;
					return A2($elm$url$Url$Builder$string, k, v);
				},
				params),
			client.ah);
		return _Utils_update(
			client,
			{ah: newParams});
	});
var $author$project$Request$Order$salesmatchingQueryCheck = function (maybeSalesmatchingType) {
	var salesmatchingTypeToString = function (salesmatchingType) {
		if (!salesmatchingType) {
			return 'only';
		} else {
			return 'none';
		}
	};
	return A2(
		$author$project$Request$Order$stringQueryCheck,
		'salesmatching',
		A2($elm$core$Maybe$map, salesmatchingTypeToString, maybeSalesmatchingType));
};
var $author$project$Request$Order$scopeQueryCheck = function (maybeState) {
	var stateToString = function (state) {
		switch (state) {
			case 0:
				return 'pending';
			case 1:
				return 'voided';
			case 2:
				return 'confirmed';
			default:
				return 'cancelled';
		}
	};
	return A2(
		$author$project$Request$Order$stringQueryCheck,
		'state',
		A2($elm$core$Maybe$map, stateToString, maybeState));
};
var $author$project$Request$Order$list = F3(
	function (client, config, toMsg) {
		var queries = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$author$project$Request$Order$scopeQueryCheck(config.gq),
					A2($author$project$Request$Order$intQueryCheck, 'account_manager_id', config.ee),
					A2($author$project$Request$Order$stringQueryCheck, 'make_slug', config.fs),
					A2($author$project$Request$Order$intQueryCheck, 'dealership_id', config.eE),
					A2($author$project$Request$Order$intQueryCheck, 'dealership_group_id', config.cX),
					$author$project$Request$Order$dealershipTypeQueryCheck(config.W),
					A2($author$project$Request$Order$intQueryCheck, 'user_id', config.gM),
					A2($author$project$Request$Order$intQueryCheck, 'offer_id', config.fT),
					A2($author$project$Request$Order$dateQueryCheck, 'was_pending_after', config.ad),
					A2($author$project$Request$Order$dateQueryCheck, 'was_pending_before', config.ae),
					A2($author$project$Request$Order$dateQueryCheck, 'purchase_date_range_start', config.ag),
					A2($author$project$Request$Order$dateQueryCheck, 'purchase_date_range_end', config.af),
					$author$project$Request$Order$purchaseTypeQueryCheck(config.f9),
					$author$project$Request$Order$salesmatchingQueryCheck(config.ai),
					A2($author$project$Request$Order$intQueryCheck, 'page', config.cn),
					A2($author$project$Request$Order$intQueryCheck, 'id', config.ab)
				]));
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$Order$listDecoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$query,
				queries,
				A2(
					$author$project$Carwow$Http$path,
					_List_fromArray(
						['api', 'v2', 'orders', 'search']),
					client)));
	});
var $author$project$OrderFilters$updateListConfig = F2(
	function (_v0, listConfig) {
		var query = _v0.c;
		return _Utils_update(
			listConfig,
			{
				ee: A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.e6;
					},
					query.ed),
				cX: A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.e6;
					},
					query.z),
				eE: A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.e6;
					},
					query.y),
				W: query.W,
				fs: A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.dS;
					},
					query.Z),
				fT: query.fT,
				ab: query.ab,
				ad: query.ad,
				ae: query.ae,
				af: query.af,
				ag: query.ag,
				f9: query.f9,
				ai: query.ai,
				gq: query.aD,
				gM: A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.e6;
					},
					query.C)
			});
	});
var $author$project$Orders$fetchOrders = function (model) {
	var config = A2(
		$author$project$OrderFilters$updateListConfig,
		model.ax,
		$author$project$Request$Order$emptyListConfig(model.ar));
	return A3($author$project$Request$Order$list, model.bU, config, $author$project$Orders$FinishedLoadingOrders);
};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$OrderFilters$PendingAfter = 1;
var $author$project$OrderFilters$PendingBefore = 0;
var $author$project$OrderFilters$PurchaseDateEnd = 3;
var $author$project$OrderFilters$PurchaseDateStart = 2;
var $author$project$Data$Dealership$Dealership = F2(
	function (id, name) {
		return {e6: id, fD: name};
	});
var $author$project$Data$DealershipGroup$DealershipGroup = F2(
	function (id, name) {
		return {e6: id, fD: name};
	});
var $author$project$Data$User$User = F3(
	function (id, email, name) {
		return {eJ: email, e6: id, fD: name};
	});
var $author$project$OrderFilters$applyFlags = F2(
	function (_v0, query) {
		var dealershipId = _v0.eE;
		var userId = _v0.gM;
		var dealershipGroupId = _v0.cX;
		var query1 = (dealershipId > 0) ? _Utils_update(
			query,
			{
				y: $elm$core$Maybe$Just(
					A2($author$project$Data$Dealership$Dealership, dealershipId, ''))
			}) : query;
		var query2 = (userId > 0) ? _Utils_update(
			query1,
			{
				C: $elm$core$Maybe$Just(
					A3($author$project$Data$User$User, userId, '', $elm$core$Maybe$Nothing))
			}) : query1;
		return (dealershipGroupId > 0) ? _Utils_update(
			query2,
			{
				z: $elm$core$Maybe$Just(
					A2($author$project$Data$DealershipGroup$DealershipGroup, dealershipGroupId, ''))
			}) : query2;
	});
var $author$project$OrderFilters$DatePickerMsg = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$OrderFilters$SetDate = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$OrderFilters$datePickerConfig = function (picker) {
	return {
		gp: $author$project$OrderFilters$SetDate(picker),
		gY: $author$project$OrderFilters$DatePickerMsg(picker)
	};
};
var $author$project$OrderFilters$emptyQuery = {ed: $elm$core$Maybe$Nothing, y: $elm$core$Maybe$Nothing, z: $elm$core$Maybe$Nothing, W: $elm$core$Maybe$Nothing, Z: $elm$core$Maybe$Nothing, fT: $elm$core$Maybe$Nothing, ab: $elm$core$Maybe$Nothing, ad: $elm$core$Maybe$Nothing, ae: $elm$core$Maybe$Nothing, af: $elm$core$Maybe$Nothing, ag: $elm$core$Maybe$Nothing, f9: $elm$core$Maybe$Nothing, ai: $elm$core$Maybe$Nothing, aD: $elm$core$Maybe$Nothing, C: $elm$core$Maybe$Nothing};
var $author$project$OrderFilters$FinishedLoadingAccountManager = function (a) {
	return {$: 4, a: a};
};
var $author$project$Request$AccountManager$find = F3(
	function (client, id, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$AccountManager$decoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$path,
				_List_fromArray(
					[
						'api',
						'v2',
						'account-managers',
						$elm$core$String$fromInt(id)
					]),
				client));
	});
var $author$project$OrderFilters$fetchAccountManager = F2(
	function (client, accountManager) {
		return A3($author$project$Request$AccountManager$find, client, accountManager.e6, $author$project$OrderFilters$FinishedLoadingAccountManager);
	});
var $author$project$OrderFilters$FinishedLoadingDealership = function (a) {
	return {$: 9, a: a};
};
var $author$project$Data$Dealership$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Data$Dealership$Dealership)));
var $author$project$Carwow$Http$get = function (_v0) {
	var client = _v0;
	return _Utils_update(
		client,
		{fv: 0});
};
var $author$project$Request$Dealership$get = F3(
	function (client, id, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$Dealership$decoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$path,
				_List_fromArray(
					[
						'api',
						'v2',
						'dealerships',
						$elm$core$String$fromInt(id)
					]),
				$author$project$Carwow$Http$get(client)));
	});
var $author$project$OrderFilters$fetchDealership = F2(
	function (client, dealership) {
		return A3($author$project$Request$Dealership$get, client, dealership.e6, $author$project$OrderFilters$FinishedLoadingDealership);
	});
var $author$project$OrderFilters$FinishedLoadingDealershipGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Data$DealershipGroup$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Data$DealershipGroup$DealershipGroup)));
var $author$project$Request$DealershipGroup$get = F3(
	function (client, id, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$DealershipGroup$decoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$path,
				_List_fromArray(
					[
						'api',
						'v2',
						'dealership_groups',
						$elm$core$String$fromInt(id)
					]),
				$author$project$Carwow$Http$get(client)));
	});
var $author$project$OrderFilters$fetchDealershipGroup = F2(
	function (client, dealershipGroup) {
		return A3($author$project$Request$DealershipGroup$get, client, dealershipGroup.e6, $author$project$OrderFilters$FinishedLoadingDealershipGroup);
	});
var $author$project$OrderFilters$FinishedLoadingMakes = function (a) {
	return {$: 6, a: a};
};
var $author$project$Data$Make$Make = F5(
	function (name, slug, brandSlug, newStockAvailable, usedStockAvailable) {
		return {cR: brandSlug, fD: name, ck: newStockAvailable, dS: slug, cG: usedStockAvailable};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Data$Make$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Data$Make$Make,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'brand_slug', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'new_stock_available', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'used_stock_available', $elm$json$Json$Decode$bool));
var $author$project$Data$Make$listDecoder = $elm$json$Json$Decode$list($author$project$Data$Make$decoder);
var $author$project$Request$Make$list = F2(
	function (client, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$Make$listDecoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$path,
				_List_fromArray(
					['legacy', 'makes']),
				client));
	});
var $author$project$OrderFilters$fetchMakes = function (client) {
	return A2($author$project$Request$Make$list, client, $author$project$OrderFilters$FinishedLoadingMakes);
};
var $author$project$OrderFilters$FinishedLoadingUser = function (a) {
	return {$: 15, a: a};
};
var $author$project$Data$User$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'email',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Data$User$User))));
var $author$project$Request$User$get = F3(
	function (client, id, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$User$decoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$path,
				_List_fromArray(
					[
						'api',
						'v2',
						'users',
						$elm$core$String$fromInt(id)
					]),
				client));
	});
var $author$project$OrderFilters$fetchUser = F2(
	function (client, user) {
		return A3($author$project$Request$User$get, client, user.e6, $author$project$OrderFilters$FinishedLoadingUser);
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$OrderFilters$filterWhereContains = F3(
	function (toSearchText, list, query) {
		var queryLower = $elm$core$String$toLower(query);
		var matchesQuery = function (item) {
			return A2(
				$elm$core$String$contains,
				queryLower,
				$elm$core$String$toLower(
					toSearchText(item)));
		};
		return A2($elm$core$List$filter, matchesQuery, list);
	});
var $author$project$Data$AccountManager$fullName = function (accountManager) {
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[accountManager.c3, accountManager.d$]));
};
var $jinjor$elm_debounce$Debounce$Debounce = $elm$core$Basics$identity;
var $jinjor$elm_debounce$Debounce$init = {G: _List_Nil, aW: false};
var $author$project$UI$Autocomplete$InternalModel = $elm$core$Basics$identity;
var $author$project$UI$Autocomplete$OnInput = function (a) {
	return {$: 2, a: a};
};
var $author$project$UI$Autocomplete$OnSelected = function (a) {
	return {$: 4, a: a};
};
var $sporto$elm_select$Select$PrivateConfig = $elm$core$Basics$identity;
var $sporto$elm_select$Select$Styles$inputId = 'elm-select-input';
var $sporto$elm_select$Select$Config$transformQuery = function (query) {
	return $elm$core$Maybe$Just(query);
};
var $sporto$elm_select$Select$Config$newConfig = F2(
	function (onSelect, toLabel) {
		return {
			er: '',
			es: _List_Nil,
			et: '',
			ez: $elm$core$Maybe$Nothing,
			eK: false,
			eW: true,
			eX: $elm$core$Maybe$Nothing,
			eY: $elm$core$Maybe$Nothing,
			eZ: $elm$core$Maybe$Nothing,
			e_: $elm$core$Maybe$Nothing,
			e$: _List_fromArray(
				[' ']),
			e3: '',
			e4: _List_Nil,
			fb: '',
			fd: '',
			fe: _List_Nil,
			b8: $sporto$elm_select$Select$Styles$inputId,
			ff: _List_Nil,
			fg: '',
			fh: _List_Nil,
			fk: '',
			fl: $elm$core$Maybe$Nothing,
			fm: _List_Nil,
			ft: '',
			fu: _List_Nil,
			fy: '',
			fz: '',
			fA: _List_Nil,
			fB: _List_Nil,
			fO: 'No results found',
			fP: '',
			fQ: true,
			fR: _List_Nil,
			fU: $elm$core$Maybe$Nothing,
			fV: $elm$core$Maybe$Nothing,
			fW: $elm$core$Maybe$Nothing,
			dt: onSelect,
			f4: '',
			f5: '',
			f6: _List_Nil,
			dE: '',
			dF: _List_Nil,
			gl: 2000,
			d1: toLabel,
			gG: $sporto$elm_select$Select$Config$transformQuery,
			gH: '',
			gI: _List_Nil
		};
	});
var $sporto$elm_select$Select$newConfig = F2(
	function (onSelectMessage, toLabel) {
		return A2($sporto$elm_select$Select$Config$newConfig, onSelectMessage, toLabel);
	});
var $sporto$elm_select$Select$unwrapConfig = function (config) {
	var c = config;
	return c;
};
var $sporto$elm_select$Select$mapConfig = F2(
	function (fn, config) {
		var config_ = $sporto$elm_select$Select$unwrapConfig(config);
		return fn(config_);
	});
var $sporto$elm_select$Select$withHighlightedItemClass = F2(
	function (_class, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{e3: _class});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withInputClass = F2(
	function (classes, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{fb: classes});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withInputStyles = F2(
	function (styles, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{ff: styles});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withItemClass = F2(
	function (classes, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{fk: classes});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withMenuClass = F2(
	function (classes, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{ft: classes});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withNotFoundShown = F2(
	function (shown, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{fQ: shown});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $sporto$elm_select$Select$withOnQuery = F2(
	function (msg, config) {
		var fn = function (c) {
			return _Utils_update(
				c,
				{
					fV: $elm$core$Maybe$Just(msg)
				});
		};
		return A2($sporto$elm_select$Select$mapConfig, fn, config);
	});
var $author$project$UI$Autocomplete$makeSelectConfig = function (config) {
	return A2(
		$sporto$elm_select$Select$withHighlightedItemClass,
		'autocomplete-select-item--highlighted',
		A2(
			$sporto$elm_select$Select$withOnQuery,
			$author$project$UI$Autocomplete$OnInput,
			A2(
				$sporto$elm_select$Select$withNotFoundShown,
				false,
				A2(
					$sporto$elm_select$Select$withItemClass,
					'autocomplete-select-item',
					A2(
						$sporto$elm_select$Select$withMenuClass,
						'autocomplete-select',
						A2(
							$sporto$elm_select$Select$withInputStyles,
							_List_fromArray(
								[
									_Utils_Tuple2('border', 'JUSTUSECSS!!!'),
									_Utils_Tuple2('font-size', 'inherit')
								]),
							A2(
								$sporto$elm_select$Select$withInputClass,
								'form-control',
								A2($sporto$elm_select$Select$newConfig, $author$project$UI$Autocomplete$OnSelected, config.aQ))))))));
};
var $sporto$elm_select$Select$PrivateState = $elm$core$Basics$identity;
var $sporto$elm_select$Select$Models$newState = function (id) {
	return {c9: $elm$core$Maybe$Nothing, e6: id, c: $elm$core$Maybe$Nothing};
};
var $sporto$elm_select$Select$newState = function (id) {
	return $sporto$elm_select$Select$Models$newState(id);
};
var $author$project$UI$Autocomplete$init = function (_v0) {
	var id = _v0.e6;
	var display = _v0.aQ;
	return {
		bL: _List_Nil,
		aP: $jinjor$elm_debounce$Debounce$init,
		be: 250,
		bY: F2(
			function (list, _v1) {
				return list;
			}),
		c: '',
		ct: $author$project$UI$Autocomplete$makeSelectConfig(
			{aQ: display, e6: id}),
		bB: $sporto$elm_select$Select$newState(id)
	};
};
var $author$project$UI$DatePicker$DatePickerMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$UI$DatePicker$InternalModel = $elm$core$Basics$identity;
var $CurrySoftware$elm_datepicker$DatePicker$CurrentDate = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$DatePicker = $elm$core$Basics$identity;
var $elm$time$Time$May = 4;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$initDate = A3($justinmimbs$date$Date$fromCalendarDate, 1992, 4, 31);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cB, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		cW: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		dm: month,
		d9: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cW;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dm;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).d9;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $CurrySoftware$elm_datepicker$DatePicker$init = _Utils_Tuple2(
	{
		u: $elm$core$Maybe$Just($CurrySoftware$elm_datepicker$DatePicker$Date$initDate),
		N: false,
		A: $elm$core$Maybe$Nothing,
		I: false,
		R: $CurrySoftware$elm_datepicker$DatePicker$Date$initDate
	},
	A2($elm$core$Task$perform, $CurrySoftware$elm_datepicker$DatePicker$CurrentDate, $justinmimbs$date$Date$today));
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$UI$DatePicker$init = function (_v0) {
	var wrapper = _v0.gY;
	var _v1 = $CurrySoftware$elm_datepicker$DatePicker$init;
	var picker = _v1.a;
	var cmd = _v1.b;
	return _Utils_Tuple2(
		picker,
		A2(
			$elm$core$Platform$Cmd$map,
			wrapper,
			A2($elm$core$Platform$Cmd$map, $author$project$UI$DatePicker$DatePickerMsg, cmd)));
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {X: frag, ac: params, S: unvisited, gT: value, an: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.S;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.gT);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.gT);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.f$),
					$elm$url$Url$Parser$prepareQuery(url.c),
					url.eV,
					$elm$core$Basics$identity)));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.an;
		var unvisited = _v1.S;
		var params = _v1.ac;
		var frag = _v1.X;
		var value = _v1.gT;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $author$project$OrderFilters$OrdersQuery = function (pendingBefore) {
	return function (pendingAfter) {
		return function (purchaseDateStart) {
			return function (purchaseDateEnd) {
				return function (scope) {
					return function (purchaseType) {
						return function (salesmatchingType) {
							return function (accountManager) {
								return function (dealership) {
									return function (dealershipGroup) {
										return function (dealershipType) {
											return function (user) {
												return function (make) {
													return function (offerId) {
														return function (orderId) {
															return {ed: accountManager, y: dealership, z: dealershipGroup, W: dealershipType, Z: make, fT: offerId, ab: orderId, ad: pendingAfter, ae: pendingBefore, af: purchaseDateEnd, ag: purchaseDateStart, f9: purchaseType, ai: salesmatchingType, aD: scope, C: user};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $author$project$Data$Order$Leasing = 1;
var $author$project$Data$Order$Retail = 0;
var $author$project$Data$Order$dealershipTypeDecoder = function () {
	var decoderDealership = function (value) {
		switch (value) {
			case 'retail':
				return $elm$json$Json$Decode$succeed(0);
			case 'leasing':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown dealership type ' + value);
		}
	};
	return A2($elm$json$Json$Decode$andThen, decoderDealership, $elm$json$Json$Decode$string);
}();
var $author$project$Data$Make$fromSlug = function (slug) {
	return {cR: '', fD: '', ck: false, dS: slug, cG: false};
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$Parser$Query$int = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$String$toInt(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$Query$map = F2(
	function (func, _v0) {
		var a = _v0;
		return function (dict) {
			return func(
				a(dict));
		};
	});
var $elm$url$Url$Parser$Query$map2 = F3(
	function (func, _v0, _v1) {
		var a = _v0;
		var b = _v1;
		return function (dict) {
			return A2(
				func,
				a(dict),
				b(dict));
		};
	});
var $author$project$Data$Order$ExcludeSalesmatching = 1;
var $author$project$Data$Order$OnlySalesmatching = 0;
var $author$project$Data$Order$salesmatchingTypeDecoder = function () {
	var decoderSalesmatching = function (value) {
		switch (value) {
			case 'only':
				return $elm$json$Json$Decode$succeed(0);
			case 'none':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown salesmatching filter type ' + value);
		}
	};
	return A2($elm$json$Json$Decode$andThen, decoderSalesmatching, $elm$json$Json$Decode$string);
}();
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$OrderFilters$queryParser = function () {
	var string = $elm$url$Url$Parser$Query$string;
	var makeParser = A2(
		$elm$url$Url$Parser$Query$map,
		$elm$core$Maybe$map($author$project$Data$Make$fromSlug),
		string('make_slug'));
	var _int = $elm$url$Url$Parser$Query$int;
	var modelParser = F2(
		function (key, mapper) {
			return A2(
				$elm$url$Url$Parser$Query$map,
				$elm$core$Maybe$map(mapper),
				_int(key));
		});
	var custom = $elm$url$Url$Parser$Query$custom;
	var dateParser = function (key) {
		return A2(
			custom,
			key,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$head,
				$elm$core$Maybe$andThen(
					A2($elm$core$Basics$composeR, $isaacseymour$deprecated_time$Time$Iso8601$toDate, $elm$core$Result$toMaybe))));
	};
	var jsonParser = F2(
		function (decoder, key) {
			return A2(
				custom,
				key,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$andThen(
						A2(
							$elm$core$Basics$composeR,
							$elm$json$Json$Encode$string,
							A2(
								$elm$core$Basics$composeR,
								$elm$json$Json$Encode$encode(0),
								A2(
									$elm$core$Basics$composeR,
									$elm$json$Json$Decode$decodeString(decoder),
									$elm$core$Result$toMaybe))))));
		});
	var apply = F2(
		function (argParser, funcParser) {
			return A3($elm$url$Url$Parser$Query$map2, $elm$core$Basics$apL, funcParser, argParser);
		});
	return A2(
		apply,
		_int('order_id'),
		A2(
			apply,
			_int('offer_id'),
			A2(
				apply,
				makeParser,
				A2(
					apply,
					A2(
						modelParser,
						'user_id',
						function (id) {
							return A3($author$project$Data$User$User, id, '', $elm$core$Maybe$Nothing);
						}),
					A2(
						apply,
						A2(jsonParser, $author$project$Data$Order$dealershipTypeDecoder, 'dealership_type'),
						A2(
							apply,
							A2(
								modelParser,
								'dealership_group_id',
								function (id) {
									return A2($author$project$Data$DealershipGroup$DealershipGroup, id, '');
								}),
							A2(
								apply,
								A2(
									modelParser,
									'dealership_id',
									function (id) {
										return A2($author$project$Data$Dealership$Dealership, id, '');
									}),
								A2(
									apply,
									A2(
										modelParser,
										'account_manager_id',
										function (id) {
											return A4($author$project$Data$AccountManager$AccountManager, id, '', '', '');
										}),
									A2(
										apply,
										A2(jsonParser, $author$project$Data$Order$salesmatchingTypeDecoder, 'salesmatching'),
										A2(
											apply,
											A2(jsonParser, $author$project$Data$Order$purchaseTypeDecoder, 'purchase_type'),
											A2(
												apply,
												A2(jsonParser, $author$project$Data$Order$stateDecoder, 'scope'),
												A2(
													apply,
													dateParser('purchase_date_range_end'),
													A2(
														apply,
														dateParser('purchase_date_range_start'),
														A2(
															apply,
															dateParser('pending_after'),
															A2(
																$elm$url$Url$Parser$Query$map,
																$author$project$OrderFilters$OrdersQuery,
																dateParser('pending_before'))))))))))))))));
}();
var $author$project$Data$User$show = function (user) {
	return A2(
		$elm$core$Maybe$withDefault,
		user.eJ,
		A2(
			$elm$core$Maybe$map,
			function (name) {
				return name + (' (' + (user.eJ + ')'));
			},
			user.fD));
};
var $author$project$UI$Autocomplete$withFilter = F2(
	function (filterCandidates, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{bY: filterCandidates});
	});
var $author$project$OrderFilters$init = function (config) {
	var url = {
		eV: $elm$core$Maybe$Nothing,
		aT: '',
		f$: '',
		f2: $elm$core$Maybe$Nothing,
		f7: 1,
		c: $elm$core$Maybe$Just(config.fa)
	};
	var quotesSiteHttpClient = A2(
		$author$project$Carwow$Http$auth,
		'Bearer ' + config.H,
		$author$project$Carwow$Http$crossOrigin(config.ga));
	var query = A2(
		$author$project$OrderFilters$applyFlags,
		config,
		A2(
			$elm$core$Maybe$withDefault,
			$author$project$OrderFilters$emptyQuery,
			A2(
				$elm$url$Url$Parser$parse,
				$elm$url$Url$Parser$query($author$project$OrderFilters$queryParser),
				url)));
	var dealersSiteHttpClient = A2(
		$author$project$Carwow$Http$auth,
		'Bearer ' + config.H,
		$author$project$Carwow$Http$crossOrigin(config.L));
	var carDataHttpClient = $author$project$Carwow$Http$crossOrigin(config.em);
	var _v0 = $author$project$UI$DatePicker$init(
		$author$project$OrderFilters$datePickerConfig(2));
	var purchaseDateStartPicker = _v0.a;
	var purchaseDateStartPickerCmd = _v0.b;
	var _v1 = $author$project$UI$DatePicker$init(
		$author$project$OrderFilters$datePickerConfig(3));
	var purchaseDateEndPicker = _v1.a;
	var purchaseDateEndPickerCmd = _v1.b;
	var _v2 = $author$project$UI$DatePicker$init(
		$author$project$OrderFilters$datePickerConfig(0));
	var pendingBeforePicker = _v2.a;
	var pendingBeforePickerCmd = _v2.b;
	var _v3 = $author$project$UI$DatePicker$init(
		$author$project$OrderFilters$datePickerConfig(1));
	var pendingAfterPicker = _v3.a;
	var pendingAfterPickerCmd = _v3.b;
	var cmds = _List_fromArray(
		[
			pendingBeforePickerCmd,
			pendingAfterPickerCmd,
			purchaseDateStartPickerCmd,
			purchaseDateEndPickerCmd,
			$author$project$OrderFilters$fetchMakes(carDataHttpClient),
			A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$OrderFilters$fetchAccountManager(dealersSiteHttpClient),
				query.ed)),
			A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$OrderFilters$fetchUser(quotesSiteHttpClient),
				query.C)),
			A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$OrderFilters$fetchDealershipGroup(dealersSiteHttpClient),
				query.z)),
			A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$OrderFilters$fetchDealership(dealersSiteHttpClient),
				query.y))
		]);
	return _Utils_Tuple2(
		{
			T: $author$project$UI$Autocomplete$init(
				{aQ: $author$project$Data$AccountManager$fullName, e6: 'account-managers'}),
			aK: _List_Nil,
			bU: dealersSiteHttpClient,
			L: config.L,
			U: $author$project$UI$Autocomplete$init(
				{
					aQ: function ($) {
						return $.fD;
					},
					e6: 'dealerships'
				}),
			V: $author$project$UI$Autocomplete$init(
				{
					aQ: function ($) {
						return $.fD;
					},
					e6: 'dealerships'
				}),
			aN: _List_Nil,
			aO: $krisajenkins$remotedata$RemoteData$NotAsked,
			H: config.H,
			_: A2(
				$author$project$UI$Autocomplete$withFilter,
				$author$project$OrderFilters$filterWhereContains(
					function ($) {
						return $.fD;
					}),
				$author$project$UI$Autocomplete$init(
					{
						aQ: function ($) {
							return $.fD;
						},
						e6: 'makes'
					})),
			bn: _List_Nil,
			aw: $jinjor$elm_debounce$Debounce$init,
			ay: $jinjor$elm_debounce$Debounce$init,
			a0: pendingAfterPicker,
			a1: pendingBeforePicker,
			a2: purchaseDateEndPicker,
			a3: purchaseDateStartPicker,
			c: query,
			bw: quotesSiteHttpClient,
			am: $author$project$UI$Autocomplete$init(
				{aQ: $author$project$Data$User$show, e6: 'users'}),
			aH: _List_Nil
		},
		$elm$core$Platform$Cmd$batch(cmds));
};
var $author$project$OrdersTable$NewNow = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $isaacseymour$deprecated_time$Time$TimeZone$Default = function (a) {
	return {$: 0, a: a};
};
var $isaacseymour$deprecated_time$Time$TimeZone$TimeZone = $elm$core$Basics$identity;
var $isaacseymour$deprecated_time$Time$TimeZone$errorZone = function (errors) {
	return {
		fD: 'error: ' + errors,
		aj: $isaacseymour$deprecated_time$Time$TimeZone$Default(
			{k: 'error', b: 0, bH: 0})
	};
};
var $isaacseymour$deprecated_time$Time$TimeZone$More = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $isaacseymour$deprecated_time$Time$TimeZone$PackedTimeZone = F5(
	function (name, abbrevs, offsets, indices, diffs) {
		return {cK: abbrevs, c_: diffs, b6: indices, fD: name, ds: offsets};
	});
var $isaacseymour$deprecated_time$Time$TimeZone$Span = F3(
	function (until, abbreviation, offset) {
		return {k: abbreviation, b: offset, bH: until};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{j: nodeList, g: nodeListSize, h: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$core$List$map3 = _List_map3;
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $isaacseymour$deprecated_time$Time$TimeZone$parseSpace = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(' '));
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $isaacseymour$deprecated_time$Time$TimeZone$parseAbbrevs = function () {
	var abbrev = $elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile(
			function (c) {
				return (c !== ' ') && (c !== '|');
			}));
	var next = A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$isaacseymour$deprecated_time$Time$TimeZone$parseSpace),
		abbrev);
	var helper = function (revTerms) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$andThen,
					function (s) {
						return helper(
							A2($elm$core$List$cons, s, revTerms));
					},
					next),
					$elm$parser$Parser$succeed(
					$elm$core$List$reverse(revTerms))
				]));
	};
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		A2(
			$elm$parser$Parser$andThen,
			function (s) {
				return helper(
					_List_fromArray(
						[s]));
			},
			abbrev));
}();
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60Digit = function (c) {
	return $elm$core$Char$isDigit(c) || ($elm$core$Char$isUpper(c) || $elm$core$Char$isLower(c));
};
var $isaacseymour$deprecated_time$Time$TimeZone$parseSuccessfulFrac = $elm$parser$Parser$getChompedString(
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				function (c) {
					return c === '.';
				})),
		$elm$parser$Parser$chompWhile(
			function (c) {
				return $isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60Digit(c);
			})));
var $isaacseymour$deprecated_time$Time$TimeZone$parseFrac = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			$isaacseymour$deprecated_time$Time$TimeZone$parseSuccessfulFrac,
			$elm$parser$Parser$succeed('')
		]));
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $isaacseymour$deprecated_time$Time$TimeZone$parseSign = function () {
	var minusOne = function (hyphen) {
		return (hyphen === '-') ? $elm$parser$Parser$succeed(-1) : $elm$parser$Parser$problem('failed to chomp minus');
	};
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				minusOne,
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf(
						function (c) {
							return c === '-';
						}))),
				$elm$parser$Parser$succeed(1)
			]));
}();
var $isaacseymour$deprecated_time$Time$TimeZone$parseWhole = $elm$parser$Parser$getChompedString(
	$elm$parser$Parser$chompWhile(
		function (c) {
			return $isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60Digit(c);
		}));
var $isaacseymour$deprecated_time$Time$TimeZone$pipe = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq('|'));
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60 = F3(
	function (sign, whole, frac) {
		var toNum = function (c) {
			var n = $elm$core$Char$toCode(c);
			return (n > 96) ? (n - 87) : ((n > 64) ? (n - 29) : (n - 48));
		};
		var toWhole = F2(
			function (cs, acc) {
				toWhole:
				while (true) {
					if (!cs.b) {
						return acc;
					} else {
						var c = cs.a;
						var cs_ = cs.b;
						var $temp$cs = cs_,
							$temp$acc = (60 * acc) + toNum(c);
						cs = $temp$cs;
						acc = $temp$acc;
						continue toWhole;
					}
				}
			});
		var toFrac = F3(
			function (cs, mul, acc) {
				toFrac:
				while (true) {
					var mul_ = mul / 60;
					if (!cs.b) {
						return acc;
					} else {
						var c = cs.a;
						var cs_ = cs.b;
						var $temp$cs = cs_,
							$temp$mul = mul_,
							$temp$acc = acc + (mul_ * toNum(c));
						cs = $temp$cs;
						mul = $temp$mul;
						acc = $temp$acc;
						continue toFrac;
					}
				}
			});
		return sign * A3(
			toFrac,
			$elm$core$String$toList(frac),
			1,
			A2(
				toWhole,
				$elm$core$String$toList(whole),
				0));
	});
var $isaacseymour$deprecated_time$Time$TimeZone$parseDiffs = function () {
	var emptyDiffs = A2(
		$elm$parser$Parser$andThen,
		function (_v2) {
			return $elm$parser$Parser$succeed(_List_Nil);
		},
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$isaacseymour$deprecated_time$Time$TimeZone$pipe));
	var diffsEnd = A2(
		$elm$parser$Parser$andThen,
		function (_v1) {
			return $elm$parser$Parser$succeed(_List_Nil);
		},
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$end));
	var convertBase60Times60000 = function (_v0) {
		var sign = _v0.a;
		var whole = _v0.b;
		var frac = _v0.c;
		return ((whole === '') && (frac === '')) ? $elm$parser$Parser$problem('expected an alphanumeric character or .') : $elm$parser$Parser$succeed(
			60000 * A3($isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60, sign, whole, frac));
	};
	var diff = A2(
		$elm$parser$Parser$andThen,
		convertBase60Times60000,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$keeper,
					$elm$parser$Parser$succeed(
						F3(
							function (a, b, c) {
								return _Utils_Tuple3(a, b, c);
							})),
					$isaacseymour$deprecated_time$Time$TimeZone$parseSign),
				$isaacseymour$deprecated_time$Time$TimeZone$parseWhole),
			$isaacseymour$deprecated_time$Time$TimeZone$parseFrac));
	var next = A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$isaacseymour$deprecated_time$Time$TimeZone$parseSpace),
		diff);
	var helper = function (revTerms) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$andThen,
					function (f) {
						return helper(
							A2($elm$core$List$cons, f, revTerms));
					},
					next),
					$elm$parser$Parser$succeed(
					$elm$core$List$reverse(revTerms))
				]));
	};
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				emptyDiffs,
				diffsEnd,
				A2(
				$elm$parser$Parser$andThen,
				function (f) {
					return helper(
						_List_fromArray(
							[f]));
				},
				diff)
			]));
}();
var $isaacseymour$deprecated_time$Time$TimeZone$parseIndices = function () {
	var convertDecimal = function (digit) {
		var _v0 = $elm$core$String$toInt(digit);
		if (_v0.$ === 1) {
			return $elm$parser$Parser$problem('failed to parse int from ' + digit);
		} else {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		}
	};
	var index = A2(
		$elm$parser$Parser$andThen,
		convertDecimal,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompIf(
				function (c) {
					return $elm$core$Char$isDigit(c);
				})));
	var next = A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		index);
	var helper = function (revTerms) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$andThen,
					function (i) {
						return helper(
							A2($elm$core$List$cons, i, revTerms));
					},
					next),
					$elm$parser$Parser$succeed(
					$elm$core$List$reverse(revTerms))
				]));
	};
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		A2(
			$elm$parser$Parser$andThen,
			function (i) {
				return helper(
					_List_fromArray(
						[i]));
			},
			index));
}();
var $isaacseymour$deprecated_time$Time$TimeZone$parseName = $elm$parser$Parser$getChompedString(
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompWhile(
			$elm$core$Basics$neq('|'))));
var $isaacseymour$deprecated_time$Time$TimeZone$parseOffsets = function () {
	var convertFrac = function (frac) {
		return $elm$parser$Parser$succeed(frac);
	};
	var convertBase60 = function (_v0) {
		var sign = _v0.a;
		var whole = _v0.b;
		var frac = _v0.c;
		return ((whole === '') && (frac === '')) ? $elm$parser$Parser$problem('expected an alphanumeric character or .') : $elm$parser$Parser$succeed(
			A3($isaacseymour$deprecated_time$Time$TimeZone$unsafeBase60, sign, whole, frac));
	};
	var offset_ = A2(
		$elm$parser$Parser$andThen,
		convertBase60,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$keeper,
					$elm$parser$Parser$succeed(
						F3(
							function (a, b, c) {
								return _Utils_Tuple3(a, b, c);
							})),
					$isaacseymour$deprecated_time$Time$TimeZone$parseSign),
				$isaacseymour$deprecated_time$Time$TimeZone$parseWhole),
			$isaacseymour$deprecated_time$Time$TimeZone$parseFrac));
	var next = A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$isaacseymour$deprecated_time$Time$TimeZone$parseSpace),
		offset_);
	var helper = function (revTerms) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$andThen,
					function (f) {
						return helper(
							A2($elm$core$List$cons, f, revTerms));
					},
					next),
					$elm$parser$Parser$succeed(
					$elm$core$List$reverse(revTerms))
				]));
	};
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		A2(
			$elm$parser$Parser$andThen,
			function (f) {
				return helper(
					_List_fromArray(
						[f]));
			},
			offset_));
}();
var $elm_community$list_extra$List$Extra$scanl = F3(
	function (f, b, xs) {
		var scan1 = F2(
			function (x, accAcc) {
				if (accAcc.b) {
					var acc = accAcc.a;
					return A2(
						$elm$core$List$cons,
						A2(f, x, acc),
						accAcc);
				} else {
					return _List_Nil;
				}
			});
		return $elm$core$List$reverse(
			A3(
				$elm$core$List$foldl,
				scan1,
				_List_fromArray(
					[b]),
				xs));
	});
var $elm_community$list_extra$List$Extra$scanl1 = F2(
	function (f, xs_) {
		if (!xs_.b) {
			return _List_Nil;
		} else {
			var x = xs_.a;
			var xs = xs_.b;
			return A3($elm_community$list_extra$List$Extra$scanl, f, x, xs);
		}
	});
var $isaacseymour$deprecated_time$Time$TimeZone$packedTimeZone = function () {
	var unpack_ = function (data) {
		var untils = A2($elm_community$list_extra$List$Extra$scanl1, $elm$core$Basics$add, data.c_);
		var paddedUntils = _Utils_ap(
			untils,
			_List_fromArray(
				[1 / 0]));
		var offsetsArray = $elm$core$Array$fromList(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul($isaacseymour$deprecated_time$Time$Internal$minuteMs),
				A2($elm$core$List$map, $elm$core$Basics$round, data.ds)));
		var offsets = A2(
			$elm$core$List$filterMap,
			function (index) {
				return A2($elm$core$Array$get, index, offsetsArray);
			},
			data.b6);
		var abbrArray = $elm$core$Array$fromList(data.cK);
		var abbrevs = A2(
			$elm$core$List$filterMap,
			function (index) {
				return A2($elm$core$Array$get, index, abbrArray);
			},
			data.b6);
		return (!_Utils_eq(
			$elm$core$List$length(abbrevs),
			$elm$core$List$length(paddedUntils))) ? $elm$parser$Parser$problem(
			'abbrevs was of length ' + ($elm$core$String$fromInt(
				$elm$core$List$length(abbrevs)) + ('expected ' + $elm$core$String$fromInt(
				$elm$core$List$length(paddedUntils))))) : ((!_Utils_eq(
			$elm$core$List$length(offsets),
			$elm$core$List$length(paddedUntils))) ? $elm$parser$Parser$problem(
			'offsets was of length ' + ($elm$core$String$fromInt(
				$elm$core$List$length(offsets)) + ('expected ' + $elm$core$String$fromInt(
				$elm$core$List$length(paddedUntils))))) : $elm$parser$Parser$succeed(
			{
				fD: data.fD,
				aj: A4($elm$core$List$map3, $isaacseymour$deprecated_time$Time$TimeZone$Span, paddedUntils, abbrevs, offsets)
			}));
	};
	var decode = A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($isaacseymour$deprecated_time$Time$TimeZone$PackedTimeZone),
						A2($elm$parser$Parser$ignorer, $isaacseymour$deprecated_time$Time$TimeZone$parseName, $isaacseymour$deprecated_time$Time$TimeZone$pipe)),
					A2($elm$parser$Parser$ignorer, $isaacseymour$deprecated_time$Time$TimeZone$parseAbbrevs, $isaacseymour$deprecated_time$Time$TimeZone$pipe)),
				A2($elm$parser$Parser$ignorer, $isaacseymour$deprecated_time$Time$TimeZone$parseOffsets, $isaacseymour$deprecated_time$Time$TimeZone$pipe)),
			A2($elm$parser$Parser$ignorer, $isaacseymour$deprecated_time$Time$TimeZone$parseIndices, $isaacseymour$deprecated_time$Time$TimeZone$pipe)),
		$isaacseymour$deprecated_time$Time$TimeZone$parseDiffs);
	var convert = function (unpacked) {
		var _v0 = $elm$core$List$reverse(unpacked.aj);
		if (!_v0.b) {
			return $elm$parser$Parser$problem('no spans');
		} else {
			var _default = _v0.a;
			var others = _v0.b;
			return $elm$parser$Parser$succeed(
				{
					fD: unpacked.fD,
					aj: A3(
						$elm$core$List$foldl,
						$isaacseymour$deprecated_time$Time$TimeZone$More,
						$isaacseymour$deprecated_time$Time$TimeZone$Default(_default),
						others)
				});
		}
	};
	return A2(
		$elm$parser$Parser$andThen,
		convert,
		A2($elm$parser$Parser$andThen, unpack_, decode));
}();
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {cT: col, dz: problem, dL: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.dL, p.cT, p.dz);
};
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $isaacseymour$deprecated_time$Time$TimeZone$unpack = function (data) {
	return A2($elm$parser$Parser$run, $isaacseymour$deprecated_time$Time$TimeZone$packedTimeZone, data);
};
var $isaacseymour$deprecated_time$Time$TimeZoneData$unpack = function (data) {
	var _v0 = $isaacseymour$deprecated_time$Time$TimeZone$unpack(data);
	if (_v0.$ === 1) {
		var errors = _v0.a;
		return $isaacseymour$deprecated_time$Time$TimeZone$errorZone(
			'failed to parse zone \'' + (data + ('\': ' + $elm$parser$Parser$deadEndsToString(errors))));
	} else {
		var zone = _v0.a;
		return zone;
	}
};
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Abidjan|LMT GMT|g.8 0|01|-2ldXH.Q|48e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_abidjan = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_accra_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Accra|LMT GMT GHST|.Q 0 -k|012121212121212121212121212121212121212121212121|-26BbX.8 6tzX.8 MnE 1BAk MnE 1BAk MnE 1BAk MnE 1C0k MnE 1BAk MnE 1BAk MnE 1BAk MnE 1C0k MnE 1BAk MnE 1BAk MnE 1BAk MnE 1C0k MnE 1BAk MnE 1BAk MnE 1BAk MnE 1C0k MnE 1BAk MnE 1BAk MnE 1BAk MnE 1C0k MnE 1BAk MnE 1BAk MnE|41e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_accra = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_accra_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Nairobi|LMT EAT BEAT BEAUT|-2r.g -30 -2u -2J|01231|-1F3Cr.g 3Dzr.g okMu MFXJ|47e5');
var $isaacseymour$deprecated_time$Time$TimeZone$setName = F2(
	function (newName, _v0) {
		var tz = _v0;
		return _Utils_update(
			tz,
			{fD: newName});
	});
var $isaacseymour$deprecated_time$Time$TimeZoneData$link = F2(
	function (name, tz) {
		return A2($isaacseymour$deprecated_time$Time$TimeZone$setName, name, tz);
	});
var $isaacseymour$deprecated_time$Time$TimeZones$africa_addis_ababa = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Addis_Ababa', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_algiers_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Algiers|PMT WET WEST CET CEST|-9.l 0 -10 -10 -20|0121212121212121343431312123431213|-2nco9.l cNb9.l HA0 19A0 1iM0 11c0 1oo0 Wo0 1rc0 QM0 1EM0 UM0 DA0 Imo0 rd0 De0 9Xz0 1fb0 1ap0 16K0 2yo0 mEp0 hwL0 jxA0 11A0 dDd0 17b0 11B0 1cN0 2Dy0 1cN0 1fB0 1cL0|26e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_algiers = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_algiers_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_asmara = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Asmara', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_asmera = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Asmera', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_bamako = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Bamako', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Lagos|LMT WAT|-d.A -10|01|-22y0d.A|17e6');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_bangui = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Bangui', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_banjul = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Banjul', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_bissau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Bissau|LMT WAT GMT|12.k 10 0|012|-2ldWV.E 2xonV.E|39e4');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_bissau = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_bissau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Maputo|LMT CAT|-2a.k -20|01|-2GJea.k|26e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_blantyre = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Blantyre', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_brazzaville = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Brazzaville', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_bujumbura = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Bujumbura', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_cairo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Cairo|EET EEST|-20 -30|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-1bIO0 vb0 1ip0 11z0 1iN0 1nz0 12p0 1pz0 10N0 1pz0 16p0 1jz0 s3d0 Vz0 1oN0 11b0 1oO0 10N0 1pz0 10N0 1pb0 10N0 1pb0 10N0 1pb0 10N0 1pz0 10N0 1pb0 10N0 1pb0 11d0 1oL0 11d0 1pb0 11d0 1oL0 11d0 1oL0 11d0 1oL0 11d0 1pb0 11d0 1oL0 11d0 1oL0 11d0 1oL0 11d0 1pb0 11d0 1oL0 11d0 1oL0 11d0 1oL0 11d0 1pb0 11d0 1oL0 11d0 1WL0 rd0 1Rz0 wp0 1pb0 11d0 1oL0 11d0 1oL0 11d0 1oL0 11d0 1pb0 11d0 1qL0 Xd0 1oL0 11d0 1oL0 11d0 1pb0 11d0 1oL0 11d0 1oL0 11d0 1ny0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 WL0 1qN0 Rb0 1wp0 On0 1zd0 Lz0 1EN0 Fb0 c10 8n0 8Nd0 gL0 e10 mn0|15e6');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_cairo = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_cairo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_casablanca_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Casablanca|LMT WET WEST CET|u.k 0 -10 -10|0121212121212121213121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2gMnt.E 130Lt.E rb0 Dd0 dVb0 b6p0 TX0 EoB0 LL0 gnd0 rz0 43d0 AL0 1Nd0 XX0 1Cp0 pz0 dEp0 4mn0 SyN0 AL0 1Nd0 wn0 1FB0 Db0 1zd0 Lz0 1Nf0 wM0 co0 go0 1o00 s00 dA0 vc0 11A0 A00 e00 y00 11A0 uM0 e00 Dc0 11A0 s00 e00 IM0 WM0 mo0 gM0 LA0 WM0 jA0 e00 Rc0 11A0 e00 e00 U00 11A0 8o0 e00 11A0 11A0 5A0 e00 17c0 1fA0 1a00 1a00 1fA0 17c0 1io0 14o0 1lc0 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1lc0 14o0 1fA0|32e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_casablanca = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_casablanca_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_ceuta_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Ceuta|WET WEST CET CEST|0 -10 -10 -20|010101010101010101010232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-25KN0 11z0 drd0 18o0 3I00 17c0 1fA0 1a00 1io0 1a00 1y7p0 LL0 gnd0 rz0 43d0 AL0 1Nd0 XX0 1Cp0 pz0 dEp0 4VB0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|85e3');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_ceuta = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_ceuta_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_conakry = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Conakry', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_dakar = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Dakar', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_dar_es_salaam = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Dar_es_Salaam', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_djibouti = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Djibouti', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_douala = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Douala', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_el_aaiun_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/El_Aaiun|LMT WAT WET WEST|Q.M 10 0 -10|01232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-1rDz7.c 1GVA7.c 6L0 AL0 1Nd0 XX0 1Cp0 pz0 1cBB0 AL0 1Nd0 wn0 1FB0 Db0 1zd0 Lz0 1Nf0 wM0 co0 go0 1o00 s00 dA0 vc0 11A0 A00 e00 y00 11A0 uM0 e00 Dc0 11A0 s00 e00 IM0 WM0 mo0 gM0 LA0 WM0 jA0 e00 Rc0 11A0 e00 e00 U00 11A0 8o0 e00 11A0 11A0 5A0 e00 17c0 1fA0 1a00 1a00 1fA0 17c0 1io0 14o0 1lc0 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1lc0 14o0 1fA0|20e4');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_el_aaiun = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_el_aaiun_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_freetown = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Freetown', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_gaborone = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Gaborone', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_harare = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Harare', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_johannesburg_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Johannesburg|SAST SAST SAST|-1u -20 -30|012121|-2GJdu 1Ajdu 1cL0 1cN0 1cL0|84e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_johannesburg = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_johannesburg_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_khartoum_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Khartoum|LMT CAT CAST EAT|-2a.8 -20 -30 -30|01212121212121212121212121212121213|-1yW2a.8 1zK0a.8 16L0 1iN0 17b0 1jd0 17b0 1ip0 17z0 1i10 17X0 1hB0 18n0 1hd0 19b0 1gp0 19z0 1iN0 17b0 1ip0 17z0 1i10 18n0 1hd0 18L0 1gN0 19b0 1gp0 19z0 1iN0 17z0 1i10 17X0 yGd0|51e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_juba = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Juba', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_khartoum_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_kampala = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Kampala', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_khartoum = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_khartoum_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_kigali = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Kigali', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_kinshasa = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Kinshasa', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_lagos = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_libreville = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Libreville', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_lome = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Lome', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_luanda = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Luanda', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_lubumbashi = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Lubumbashi', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_lusaka = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Lusaka', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_malabo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Malabo', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_maputo = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_maputo_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_maseru = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Maseru', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_johannesburg_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_mbabane = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Mbabane', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_johannesburg_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_mogadishu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Mogadishu', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_monrovia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Monrovia|MMT LRT GMT|H.8 I.u 0|012|-23Lzg.Q 29s01.m|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_monrovia = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_monrovia_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_nairobi = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_ndjamena_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Ndjamena|LMT WAT WAST|-10.c -10 -20|0121|-2le10.c 2J3c0.c Wn0|13e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_ndjamena = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_ndjamena_l;
var $isaacseymour$deprecated_time$Time$TimeZones$africa_niamey = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Niamey', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_nouakchott = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Nouakchott', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_ouagadougou = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Ouagadougou', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_porto_novo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Porto-Novo', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_lagos_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_sao_tome = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Sao_Tome', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$africa_timbuktu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Africa/Timbuktu', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_tripoli_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Tripoli|LMT CET CEST EET|-Q.I -10 -20 -20|012121213121212121212121213123123|-21JcQ.I 1hnBQ.I vx0 4iP0 xx0 4eN0 Bb0 7ip0 U0n0 A10 1db0 1cN0 1db0 1dd0 1db0 1eN0 1bb0 1e10 1cL0 1c10 1db0 1dd0 1db0 1cN0 1db0 1q10 fAn0 1ep0 1db0 AKq0 TA0 1o00|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_tripoli = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_tripoli_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_tunis_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Tunis|PMT CET CEST|-9.l -10 -20|0121212121212121212121212121212121|-2nco9.l 18pa9.l 1qM0 DA0 3Tc0 11B0 1ze0 WM0 7z0 3d0 14L0 1cN0 1f90 1ar0 16J0 1gXB0 WM0 1rA0 11c0 nwo0 Ko0 1cM0 1cM0 1rA0 10M0 zuM0 10N0 1aN0 1qM0 WM0 1qM0 11A0 1o00|20e5');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_tunis = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_tunis_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$africa_windhoek_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Africa/Windhoek|SWAT SAST SAST CAT WAT WAST|-1u -20 -30 -20 -10 -20|012134545454545454545454545454545454545454545454545454545454545454545454545454545454545454545|-2GJdu 1Ajdu 1cL0 1SqL0 9NA0 11D0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 11B0 1nX0 11B0|32e4');
var $isaacseymour$deprecated_time$Time$TimeZones$africa_windhoek = $isaacseymour$deprecated_time$Time$TimeZoneData$africa_windhoek_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_adak_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Adak|NST NWT NPT BST BDT AHST HST HDT|b0 a0 a0 b0 a0 a0 a0 90|012034343434343434343434343434343456767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-17SX0 8wW0 iB0 Qlb0 52O0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 cm0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|326');
var $isaacseymour$deprecated_time$Time$TimeZones$america_adak = $isaacseymour$deprecated_time$Time$TimeZoneData$america_adak_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_anchorage_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Anchorage|CAT CAWT CAPT AHST AHDT YST AKST AKDT|a0 90 90 a0 90 90 90 80|012034343434343434343434343434343456767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-17T00 8wX0 iA0 Qlb0 52O0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 cm0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|30e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_anchorage = $isaacseymour$deprecated_time$Time$TimeZoneData$america_anchorage_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Port_of_Spain|LMT AST|46.4 40|01|-2kNvR.U|43e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_anguilla = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Anguilla', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_antigua = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Antigua', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_araguaina_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Araguaina|LMT BRT BRST|3c.M 30 20|0121212121212121212121212121212121212121212121212121|-2glwL.c HdKL.c 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 dMN0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 ny10 Lz0|14e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_araguaina = $isaacseymour$deprecated_time$Time$TimeZoneData$america_araguaina_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_buenos_aires_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Buenos_Aires|CMT ART ARST ART ARST|4g.M 40 30 30 20|0121212121212121212121212121212121212121213434343434343234343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wp0 Rb0 1wp0 TX0 g0p0 10M0 j3c0 uL0 1qN0 WL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_buenos_aires = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_buenos_aires_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_catamarca_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Catamarca|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|0121212121212121212121212121212121212121213434343454343235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wq0 Ra0 1wp0 TX0 g0p0 10M0 ako0 7B0 8zb0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_catamarca = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_catamarca_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_comodrivadavia = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Argentina/ComodRivadavia', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_catamarca_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_cordoba_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Cordoba|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|0121212121212121212121212121212121212121213434343454343234343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wq0 Ra0 1wp0 TX0 g0p0 10M0 j3c0 uL0 1qN0 WL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_cordoba = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_cordoba_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_jujuy_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Jujuy|CMT ART ARST ART ARST WART WARST|4g.M 40 30 30 20 40 30|01212121212121212121212121212121212121212134343456543432343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1ze0 TX0 1ld0 WK0 1wp0 TX0 g0p0 10M0 j3c0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_jujuy = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_jujuy_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_la_rioja_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/La_Rioja|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|01212121212121212121212121212121212121212134343434534343235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Qn0 qO0 16n0 Rb0 1wp0 TX0 g0p0 10M0 ako0 7B0 8zb0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_la_rioja = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_la_rioja_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_mendoza_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Mendoza|CMT ART ARST ART ARST WART WARST|4g.M 40 30 30 20 40 30|0121212121212121212121212121212121212121213434345656543235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1u20 SL0 1vd0 Tb0 1wp0 TW0 g0p0 10M0 agM0 Op0 7TX0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_mendoza = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_mendoza_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_rio_gallegos_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Rio_Gallegos|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|0121212121212121212121212121212121212121213434343434343235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wp0 Rb0 1wp0 TX0 g0p0 10M0 ako0 7B0 8zb0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_rio_gallegos = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_rio_gallegos_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_salta_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Salta|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|01212121212121212121212121212121212121212134343434543432343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wq0 Ra0 1wp0 TX0 g0p0 10M0 j3c0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_salta = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_salta_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_san_juan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/San_Juan|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|01212121212121212121212121212121212121212134343434534343235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Qn0 qO0 16n0 Rb0 1wp0 TX0 g0p0 10M0 ak00 m10 8lb0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_san_juan = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_san_juan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_san_luis_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/San_Luis|CMT ART ARST ART ARST WART WARST|4g.M 40 30 30 20 40 30|01212121212121212121212121212121212121212134343456536353465653|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 XX0 1q20 SL0 AN0 kin0 10M0 ak00 m10 8lb0 8L0 jd0 1qN0 WL0 1qN0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_san_luis = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_san_luis_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_tucuman_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Tucuman|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|012121212121212121212121212121212121212121343434345434323534343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wq0 Ra0 1wp0 TX0 g0p0 10M0 ako0 4N0 8BX0 uL0 1qN0 WL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_tucuman = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_tucuman_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_ushuaia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Argentina/Ushuaia|CMT ART ARST ART ARST WART|4g.M 40 30 30 20 40|0121212121212121212121212121212121212121213434343434343235343|-20UHH.c pKnH.c Mn0 1iN0 Tb0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 1C10 LX0 1C10 LX0 1C10 LX0 1C10 Mn0 MN0 2jz0 MN0 4lX0 u10 5Lb0 1pB0 Fnz0 u10 uL0 1vd0 SL0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 zvd0 Bz0 1tB0 TX0 1wp0 Rb0 1wp0 Rb0 1wp0 TX0 g0p0 10M0 ajA0 8p0 8zb0 uL0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_ushuaia = $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_ushuaia_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_curacao_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Curacao|LMT ANT AST|4z.L 4u 40|012|-2kV7o.d 28KLS.d|15e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_aruba = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Aruba', $isaacseymour$deprecated_time$Time$TimeZoneData$america_curacao_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_asuncion_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Asuncion|AMT PYT PYT PYST|3O.E 40 30 30|012131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313|-1x589.k 1DKM9.k 3CL0 3Dd0 10L0 1pB0 10n0 1pB0 10n0 1pB0 1cL0 1dd0 1db0 1dd0 1cL0 1dd0 1cL0 1dd0 1cL0 1dd0 1db0 1dd0 1cL0 1dd0 1cL0 1dd0 1cL0 1dd0 1db0 1dd0 1cL0 1lB0 14n0 1dd0 1cL0 1fd0 WL0 1rd0 1aL0 1dB0 Xz0 1qp0 Xb0 1qN0 10L0 1rB0 TX0 1tB0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 1cL0 WN0 1qL0 11B0 1nX0 1ip0 WL0 1qN0 WL0 1qN0 WL0 1tB0 TX0 1tB0 TX0 1tB0 19X0 1a10 1fz0 1a10 1fz0 1cN0 17b0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1ip0 17b0 1ip0 17b0 1ip0|28e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_asuncion = $isaacseymour$deprecated_time$Time$TimeZoneData$america_asuncion_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_atikokan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Atikokan|CST CDT CWT CPT EST|60 50 50 50 50|0101234|-25TQ0 1in0 Rnb0 3je0 8x30 iw0|28e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_atikokan = $isaacseymour$deprecated_time$Time$TimeZoneData$america_atikokan_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_atka = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Atka', $isaacseymour$deprecated_time$Time$TimeZoneData$america_adak_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_bahia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Bahia|LMT BRT BRST|2y.4 30 20|01212121212121212121212121212121212121212121212121212121212121|-2glxp.U HdLp.U 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 l5B0 Rb0|27e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_bahia = $isaacseymour$deprecated_time$Time$TimeZoneData$america_bahia_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_bahia_banderas_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Bahia_Banderas|LMT MST CST PST MDT CDT|71 70 60 80 60 50|0121212131414141414141414141414141414152525252525252525252525252525252525252525252525252525252|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 otX0 gmN0 P2N0 13Vd0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nW0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|84e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_bahia_banderas = $isaacseymour$deprecated_time$Time$TimeZoneData$america_bahia_banderas_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_barbados_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Barbados|LMT BMT AST ADT|3W.t 3W.t 40 30|01232323232|-1Q0I1.v jsM0 1ODC1.v IL0 1ip0 17b0 1ip0 17b0 1ld0 13b0|28e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_barbados = $isaacseymour$deprecated_time$Time$TimeZoneData$america_barbados_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_belem_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Belem|LMT BRT BRST|3d.U 30 20|012121212121212121212121212121|-2glwK.4 HdKK.4 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0|20e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_belem = $isaacseymour$deprecated_time$Time$TimeZoneData$america_belem_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_belize_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Belize|LMT CST CHDT CDT|5Q.M 60 5u 50|01212121212121212121212121212121212121212121212121213131|-2kBu7.c fPA7.c Onu 1zcu Rbu 1wou Rbu 1wou Rbu 1zcu Onu 1zcu Onu 1zcu Rbu 1wou Rbu 1wou Rbu 1wou Rbu 1zcu Onu 1zcu Onu 1zcu Rbu 1wou Rbu 1wou Rbu 1zcu Onu 1zcu Onu 1zcu Onu 1zcu Rbu 1wou Rbu 1wou Rbu 1zcu Onu 1zcu Onu 1zcu Rbu 1wou Rbu 1f0Mu qn0 lxB0 mn0|57e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_belize = $isaacseymour$deprecated_time$Time$TimeZoneData$america_belize_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_blanc_sablon_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Blanc-Sablon|AST ADT AWT APT|40 30 30 30|010230|-25TS0 1in0 UGp0 8x50 iu0|11e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_blanc_sablon = $isaacseymour$deprecated_time$Time$TimeZoneData$america_blanc_sablon_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_boa_vista_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Boa_Vista|LMT AMT AMST|42.E 40 30|0121212121212121212121212121212121|-2glvV.k HdKV.k 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 smp0 WL0 1tB0 2L0|62e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_boa_vista = $isaacseymour$deprecated_time$Time$TimeZoneData$america_boa_vista_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_bogota_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Bogota|BMT COT COST|4U.g 50 40|0121|-2eb73.I 38yo3.I 2en0|90e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_bogota = $isaacseymour$deprecated_time$Time$TimeZoneData$america_bogota_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_boise_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Boise|PST PDT MST MWT MPT MDT|80 70 70 60 60 60|0101023425252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252|-261q0 1nX0 11B0 1nX0 8C10 JCL0 8x20 ix0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 Dd0 1Kn0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_boise = $isaacseymour$deprecated_time$Time$TimeZoneData$america_boise_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_buenos_aires = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Buenos_Aires', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_buenos_aires_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_cambridge_bay_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Cambridge_Bay|-00 MST MWT MPT MDDT MDT CST CDT EST|0 70 60 60 50 60 60 50 50|0123141515151515151515151515151515151515151515678651515151515151515151515151515151515151515151515151515151515151515151515151|-21Jc0 RO90 8x20 ix0 LCL0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11A0 1nX0 2K0 WQ0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_cambridge_bay = $isaacseymour$deprecated_time$Time$TimeZoneData$america_cambridge_bay_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_campo_grande_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Campo_Grande|LMT AMT AMST|3C.s 40 30|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212|-2glwl.w HdLl.w 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 1C10 Lz0 1Ip0 HX0 1zd0 On0 1HB0 IL0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1zd0 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0|77e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_campo_grande = $isaacseymour$deprecated_time$Time$TimeZoneData$america_campo_grande_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_cancun_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Cancun|LMT CST EST EDT CDT|5L.4 60 50 40 50|0123232341414141414141414141414141414141412|-1UQG0 2q2o0 yLB0 1lb0 14p0 1lb0 14p0 Lz0 xB0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 Dd0|63e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_cancun = $isaacseymour$deprecated_time$Time$TimeZoneData$america_cancun_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_caracas_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Caracas|CMT VET VET|4r.E 4u 40|01212|-2kV7w.k 28KM2.k 1IwOu kqo0|29e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_caracas = $isaacseymour$deprecated_time$Time$TimeZoneData$america_caracas_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_catamarca = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Catamarca', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_catamarca_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_cayenne_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Cayenne|LMT GFT GFT|3t.k 40 30|012|-2mrwu.E 2gWou.E|58e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_cayenne = $isaacseymour$deprecated_time$Time$TimeZoneData$america_cayenne_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_panama_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Panama|CMT EST|5j.A 50|01|-2uduE.o|15e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_cayman = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Cayman', $isaacseymour$deprecated_time$Time$TimeZoneData$america_panama_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_chicago_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Chicago|CST CDT EST CWT CPT|60 50 50 50 50|01010101010101010101010101010101010102010101010103401010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 1wp0 TX0 WN0 1qL0 1cN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 11B0 1Hz0 14p0 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x30 iw0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|92e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_chicago = $isaacseymour$deprecated_time$Time$TimeZoneData$america_chicago_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_chihuahua_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Chihuahua|LMT MST CST CDT MDT|74.k 70 60 50 60|0121212323241414141414141414141414141414141414141414141414141414141414141414141414141414141|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 2zQN0 1lb0 14p0 1lb0 14q0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|81e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_chihuahua = $isaacseymour$deprecated_time$Time$TimeZoneData$america_chihuahua_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_coral_harbour = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Coral_Harbour', $isaacseymour$deprecated_time$Time$TimeZoneData$america_atikokan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_cordoba = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Cordoba', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_cordoba_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_costa_rica_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Costa_Rica|SJMT CST CDT|5A.d 60 50|0121212121|-1Xd6n.L 2lu0n.L Db0 1Kp0 Db0 pRB0 15b0 1kp0 mL0|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_costa_rica = $isaacseymour$deprecated_time$Time$TimeZoneData$america_costa_rica_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_creston_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Creston|MST PST|70 80|010|-29DR0 43B0|53e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_creston = $isaacseymour$deprecated_time$Time$TimeZoneData$america_creston_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_cuiaba_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Cuiaba|LMT AMT AMST|3I.k 40 30|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212|-2glwf.E HdLf.E 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 4a10 HX0 1zd0 On0 1HB0 IL0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1zd0 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0|54e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_cuiaba = $isaacseymour$deprecated_time$Time$TimeZoneData$america_cuiaba_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_curacao = $isaacseymour$deprecated_time$Time$TimeZoneData$america_curacao_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_danmarkshavn_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Danmarkshavn|LMT WGT WGST GMT|1e.E 30 20 0|01212121212121212121212121212121213|-2a5WJ.k 2z5fJ.k 19U0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 DC0|8');
var $isaacseymour$deprecated_time$Time$TimeZones$america_danmarkshavn = $isaacseymour$deprecated_time$Time$TimeZoneData$america_danmarkshavn_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_dawson_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Dawson|YST YDT YWT YPT YDDT PST PDT|90 80 80 80 70 80 70|0101023040565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565|-25TN0 1in0 1o10 13V0 Ser0 8x00 iz0 LCL0 1fA0 jrA0 fNd0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|13e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_dawson = $isaacseymour$deprecated_time$Time$TimeZoneData$america_dawson_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_dawson_creek_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Dawson_Creek|PST PDT PWT PPT MST|80 70 70 70 70|0102301010101010101010101010101010101010101010101010101014|-25TO0 1in0 UGp0 8x10 iy0 3NB0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 ML0|12e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_dawson_creek = $isaacseymour$deprecated_time$Time$TimeZoneData$america_dawson_creek_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_denver_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Denver|MST MDT MWT MPT|70 60 60 60|01010101023010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261r0 1nX0 11B0 1nX0 11B0 1qL0 WN0 mn0 Ord0 8x20 ix0 LCN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|26e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_denver = $isaacseymour$deprecated_time$Time$TimeZoneData$america_denver_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_detroit_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Detroit|LMT CST EST EWT EPT EDT|5w.b 60 50 40 40 40|01234252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252|-2Cgir.N peqr.N 156L0 8x40 iv0 6fd0 11z0 Jy10 SL0 dnB0 1cL0 s10 1Vz0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|37e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_detroit = $isaacseymour$deprecated_time$Time$TimeZoneData$america_detroit_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_dominica = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Dominica', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_edmonton_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Edmonton|LMT MST MDT MWT MPT|7x.Q 70 60 60 60|01212121212121341212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2yd4q.8 shdq.8 1in0 17d0 hz0 2dB0 1fz0 1a10 11z0 1qN0 WL0 1qN0 11z0 IGN0 8x20 ix0 3NB0 11z0 LFB0 1cL0 3Cp0 1cL0 66N0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|10e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_edmonton = $isaacseymour$deprecated_time$Time$TimeZoneData$america_edmonton_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_eirunepe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Eirunepe|LMT ACT ACST AMT|4D.s 50 40 40|0121212121212121212121212121212131|-2glvk.w HdLk.w 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 dPB0 On0 yTd0 d5X0|31e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_eirunepe = $isaacseymour$deprecated_time$Time$TimeZoneData$america_eirunepe_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_el_salvador_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/El_Salvador|LMT CST CDT|5U.M 60 50|012121|-1XiG3.c 2Fvc3.c WL0 1qN0 WL0|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_el_salvador = $isaacseymour$deprecated_time$Time$TimeZoneData$america_el_salvador_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_tijuana_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Tijuana|LMT MST PST PDT PWT PPT|7M.4 70 80 70 70 70|012123245232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-1UQE0 4PX0 8mM0 8lc0 SN0 1cL0 pHB0 83r0 zI0 5O10 1Rz0 cOO0 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 BUp0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 U10 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|20e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_ensenada = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Ensenada', $isaacseymour$deprecated_time$Time$TimeZoneData$america_tijuana_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_nelson_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Fort_Nelson|PST PDT PWT PPT MST|80 70 70 70 70|01023010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010104|-25TO0 1in0 UGp0 8x10 iy0 3NB0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0|39e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_fort_nelson = $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_nelson_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_wayne_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Fort_Wayne|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|010101023010101010101010101040454545454545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 QI10 Db0 RB0 8x30 iw0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 5Tz0 1o10 qLb0 1cL0 1cN0 1cL0 1qhd0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_fort_wayne = $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_wayne_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_fortaleza_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Fortaleza|LMT BRT BRST|2y 30 20|0121212121212121212121212121212121212121|-2glxq HdLq 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 nsp0 WL0 1tB0 5z0 2mN0 On0|34e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_fortaleza = $isaacseymour$deprecated_time$Time$TimeZoneData$america_fortaleza_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_glace_bay_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Glace_Bay|LMT AST ADT AWT APT|3X.M 40 30 30 30|012134121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2IsI0.c CwO0.c 1in0 UGp0 8x50 iu0 iq10 11z0 Jg10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|19e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_glace_bay = $isaacseymour$deprecated_time$Time$TimeZoneData$america_glace_bay_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_godthab_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Godthab|LMT WGT WGST|3q.U 30 20|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2a5Ux.4 2z5dx.4 19U0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|17e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_godthab = $isaacseymour$deprecated_time$Time$TimeZoneData$america_godthab_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_goose_bay_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Goose_Bay|NST NDT NST NDT NWT NPT AST ADT ADDT|3u.Q 2u.Q 3u 2u 2u 2u 40 30 20|010232323232323245232323232323232323232323232323232323232326767676767676767676767676767676767676767676768676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-25TSt.8 1in0 DXb0 2HbX.8 WL0 1qN0 WL0 1qN0 WL0 1tB0 TX0 1tB0 WL0 1qN0 WL0 1qN0 7UHu itu 1tB0 WL0 1qN0 WL0 1qN0 WL0 1qN0 WL0 1tB0 WL0 1ld0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 S10 g0u 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14n1 1lb0 14p0 1nW0 11C0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zcX Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|76e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_goose_bay = $isaacseymour$deprecated_time$Time$TimeZoneData$america_goose_bay_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_grand_turk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Grand_Turk|KMT EST EDT AST|57.b 50 40 40|0121212121212121212121212121212121212121212121212121212121212121212121212123|-2l1uQ.N 2HHBQ.N 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|37e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_grand_turk = $isaacseymour$deprecated_time$Time$TimeZoneData$america_grand_turk_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_grenada = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Grenada', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_guadeloupe = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Guadeloupe', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_guatemala_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Guatemala|LMT CST CDT|62.4 60 50|0121212121|-24KhV.U 2efXV.U An0 mtd0 Nz0 ifB0 17b0 zDB0 11z0|13e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_guatemala = $isaacseymour$deprecated_time$Time$TimeZoneData$america_guatemala_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_guayaquil_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Guayaquil|QMT ECT|5e 50|01|-1yVSK|27e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_guayaquil = $isaacseymour$deprecated_time$Time$TimeZoneData$america_guayaquil_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_guyana_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Guyana|LMT GBGT GYT GYT GYT|3Q.E 3J 3J 30 40|01234|-2dvU7.k 24JzQ.k mlc0 Bxbf|80e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_guyana = $isaacseymour$deprecated_time$Time$TimeZoneData$america_guyana_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_halifax_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Halifax|LMT AST ADT AWT APT|4e.o 40 30 30 30|0121212121212121212121212121212121212121212121212134121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2IsHJ.A xzzJ.A 1db0 3I30 1in0 3HX0 IL0 1E10 ML0 1yN0 Pb0 1Bd0 Mn0 1Bd0 Rz0 1w10 Xb0 1w10 LX0 1w10 Xb0 1w10 Lz0 1C10 Jz0 1E10 OL0 1yN0 Un0 1qp0 Xb0 1qp0 11X0 1w10 Lz0 1HB0 LX0 1C10 FX0 1w10 Xb0 1qp0 Xb0 1BB0 LX0 1td0 Xb0 1qp0 Xb0 Rf0 8x50 iu0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 3Qp0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 3Qp0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 6i10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|39e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_halifax = $isaacseymour$deprecated_time$Time$TimeZoneData$america_halifax_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_havana_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Havana|HMT CST CDT|5t.A 50 40|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1Meuu.o 72zu.o ML0 sld0 An0 1Nd0 Db0 1Nd0 An0 6Ep0 An0 1Nd0 An0 JDd0 Mn0 1Ap0 On0 1fd0 11X0 1qN0 WL0 1wp0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 14n0 1ld0 14L0 1kN0 15b0 1kp0 1cL0 1cN0 1fz0 1a10 1fz0 1fB0 11z0 14p0 1nX0 11B0 1nX0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 14n0 1ld0 14n0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 1a10 1in0 1a10 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 17c0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 11A0 6i00 Rc0 1wo0 U00 1tA0 Rc0 1wo0 U00 1wo0 U00 1zc0 U00 1qM0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0|21e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_havana = $isaacseymour$deprecated_time$Time$TimeZoneData$america_havana_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_hermosillo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Hermosillo|LMT MST CST PST MDT|7n.Q 70 60 80 60|0121212131414141|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 otX0 gmN0 P2N0 13Vd0 1lb0 14p0 1lb0 14p0 1lb0|64e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_hermosillo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_hermosillo_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_indianapolis = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Indiana/Indianapolis', $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_wayne_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_knox_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Knox|CST CDT CWT CPT EST|60 50 50 50 50|0101023010101010101010101010101010101040101010101010101010101010101010101010101010101010141010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 3NB0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 11z0 1o10 11z0 1o10 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 3Cn0 8wp0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 z8o0 1o00 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_knox = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_knox_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_marengo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Marengo|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|0101023010101010101010104545454545414545454545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 dyN0 11z0 6fd0 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 jrz0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1VA0 LA0 1BX0 1e6p0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_marengo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_marengo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_petersburg_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Petersburg|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|01010230101010101010101010104010101010101010101010141014545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 njX0 WN0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 3Fb0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 19co0 1o00 Rd0 1zb0 Oo0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_petersburg = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_petersburg_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_tell_city_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Tell_City|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|01010230101010101010101010101010454541010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 1o10 11z0 g0p0 11z0 1o10 11z0 1qL0 WN0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 caL0 1cL0 1cN0 1cL0 1qhd0 1o00 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_tell_city = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_tell_city_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_vevay_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Vevay|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|010102304545454545454545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 kPB0 Awn0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1lnd0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_vevay = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_vevay_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_vincennes_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Vincennes|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|01010230101010101010101010101010454541014545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 1o10 11z0 g0p0 11z0 1o10 11z0 1qL0 WN0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 caL0 1cL0 1cN0 1cL0 1qhd0 1o00 Rd0 1zb0 Oo0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_vincennes = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_vincennes_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_winamac_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Indiana/Winamac|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|01010230101010101010101010101010101010454541054545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 jrz0 1cL0 1cN0 1cL0 1qhd0 1o00 Rd0 1za0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_winamac = $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_winamac_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_indianapolis = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Indianapolis', $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_wayne_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_inuvik_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Inuvik|-00 PST PDDT MST MDT|0 80 60 70 60|0121343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343|-FnA0 tWU0 1fA0 wPe0 2pz0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|35e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_inuvik = $isaacseymour$deprecated_time$Time$TimeZoneData$america_inuvik_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_iqaluit_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Iqaluit|-00 EWT EPT EST EDDT EDT CST CDT|0 40 40 50 30 40 60 50|01234353535353535353535353535353535353535353567353535353535353535353535353535353535353535353535353535353535353535353535353|-16K00 7nX0 iv0 LCL0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11C0 1nX0 11A0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|67e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_iqaluit = $isaacseymour$deprecated_time$Time$TimeZoneData$america_iqaluit_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_jamaica_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Jamaica|KMT EST EDT|57.b 50 40|0121212121212121212121|-2l1uQ.N 2uM1Q.N 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0|94e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_jamaica = $isaacseymour$deprecated_time$Time$TimeZoneData$america_jamaica_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_jujuy = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Jujuy', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_jujuy_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_juneau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Juneau|PST PWT PPT PDT YDT YST AKST AKDT|80 70 70 70 80 90 90 80|01203030303030303030303030403030356767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-17T20 8x10 iy0 Vo10 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cM0 1cM0 1cL0 1cN0 1fz0 1a10 1fz0 co0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|33e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_juneau = $isaacseymour$deprecated_time$Time$TimeZoneData$america_juneau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_kentucky_louisville_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Kentucky/Louisville|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|0101010102301010101010101010101010101454545454545414545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 3Fd0 Nb0 LPd0 11z0 RB0 8x30 iw0 Bb0 10N0 2bB0 8in0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 xz0 gso0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1VA0 LA0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_kentucky_louisville = $isaacseymour$deprecated_time$Time$TimeZoneData$america_kentucky_louisville_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_kentucky_monticello_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Kentucky/Monticello|CST CDT CWT CPT EST EDT|60 50 50 50 50 40|0101023010101010101010101010101010101010101010101010101010101010101010101454545454545454545454545454545454545454545454545454545454545454545454545454|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 SWp0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11A0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_kentucky_monticello = $isaacseymour$deprecated_time$Time$TimeZoneData$america_kentucky_monticello_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_knox_in = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Knox_IN', $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_knox_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_kralendijk = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Kralendijk', $isaacseymour$deprecated_time$Time$TimeZoneData$america_curacao_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_la_paz_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/La_Paz|CMT BOST BOT|4w.A 3w.A 40|012|-1x37r.o 13b0|19e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_la_paz = $isaacseymour$deprecated_time$Time$TimeZoneData$america_la_paz_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_lima_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Lima|LMT PET PEST|58.A 50 40|0121212121212121|-2tyGP.o 1bDzP.o zX0 1aN0 1cL0 1cN0 1cL0 1PrB0 zX0 1O10 zX0 6Gp0 zX0 98p0 zX0|11e6');
var $isaacseymour$deprecated_time$Time$TimeZones$america_lima = $isaacseymour$deprecated_time$Time$TimeZoneData$america_lima_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_los_angeles_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Los_Angeles|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6');
var $isaacseymour$deprecated_time$Time$TimeZones$america_los_angeles = $isaacseymour$deprecated_time$Time$TimeZoneData$america_los_angeles_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_louisville = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Louisville', $isaacseymour$deprecated_time$Time$TimeZoneData$america_kentucky_louisville_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_lower_princes = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Lower_Princes', $isaacseymour$deprecated_time$Time$TimeZoneData$america_curacao_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_maceio_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Maceio|LMT BRT BRST|2m.Q 30 20|012121212121212121212121212121212121212121|-2glxB.8 HdLB.8 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 dMN0 Lz0 8Q10 WL0 1tB0 5z0 2mN0 On0|93e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_maceio = $isaacseymour$deprecated_time$Time$TimeZoneData$america_maceio_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_managua_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Managua|MMT CST EST CDT|5J.c 60 50 50|0121313121213131|-1quie.M 1yAMe.M 4mn0 9Up0 Dz0 1K10 Dz0 s3F0 1KH0 DB0 9In0 k8p0 19X0 1o30 11y0|22e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_managua = $isaacseymour$deprecated_time$Time$TimeZoneData$america_managua_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_manaus_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Manaus|LMT AMT AMST|40.4 40 30|01212121212121212121212121212121|-2glvX.U HdKX.U 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 dPB0 On0|19e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_manaus = $isaacseymour$deprecated_time$Time$TimeZoneData$america_manaus_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_marigot = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Marigot', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_martinique_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Martinique|FFMT AST ADT|44.k 40 30|0121|-2mPTT.E 2LPbT.E 19X0|39e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_martinique = $isaacseymour$deprecated_time$Time$TimeZoneData$america_martinique_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_matamoros_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Matamoros|LMT CST CDT|6E 60 50|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1UQG0 2FjC0 1nX0 i6p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 U10 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|45e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_matamoros = $isaacseymour$deprecated_time$Time$TimeZoneData$america_matamoros_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_mazatlan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Mazatlan|LMT MST CST PST MDT|75.E 70 60 80 60|0121212131414141414141414141414141414141414141414141414141414141414141414141414141414141414141|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 otX0 gmN0 P2N0 13Vd0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|44e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_mazatlan = $isaacseymour$deprecated_time$Time$TimeZoneData$america_mazatlan_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_mendoza = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Mendoza', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_mendoza_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_menominee_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Menominee|CST CDT CWT CPT EST|60 50 50 50 50|01010230101041010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 1o10 11z0 LCN0 1fz0 6410 9Jb0 1cM0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|85e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_menominee = $isaacseymour$deprecated_time$Time$TimeZoneData$america_menominee_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_merida_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Merida|LMT CST EST CDT|5W.s 60 50 50|0121313131313131313131313131313131313131313131313131313131313131313131313131313131313131|-1UQG0 2q2o0 2hz0 wu30 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_merida = $isaacseymour$deprecated_time$Time$TimeZoneData$america_merida_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_metlakatla_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Metlakatla|PST PWT PPT PDT AKST AKDT|80 70 70 70 90 80|0120303030303030303030303030303030454545454545454545454545454545454545454545454|-17T20 8x10 iy0 Vo10 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1hU10 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|14e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_metlakatla = $isaacseymour$deprecated_time$Time$TimeZoneData$america_metlakatla_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_mexico_city_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Mexico_City|LMT MST CST CDT CWT|6A.A 70 60 50 50|012121232324232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 gEn0 TX0 3xd0 Jb0 6zB0 SL0 e5d0 17b0 1Pff0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|20e6');
var $isaacseymour$deprecated_time$Time$TimeZones$america_mexico_city = $isaacseymour$deprecated_time$Time$TimeZoneData$america_mexico_city_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_miquelon_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Miquelon|LMT AST PMST PMDT|3I.E 40 30 20|012323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-2mKkf.k 2LTAf.k gQ10 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|61e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_miquelon = $isaacseymour$deprecated_time$Time$TimeZoneData$america_miquelon_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_moncton_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Moncton|EST AST ADT AWT APT|50 40 30 30 30|012121212121212121212134121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2IsH0 CwN0 1in0 zAo0 An0 1Nd0 An0 1Nd0 An0 1Nd0 An0 1Nd0 An0 1Nd0 An0 1K10 Lz0 1zB0 NX0 1u10 Wn0 S20 8x50 iu0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 3Cp0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14n1 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 ReX 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|64e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_moncton = $isaacseymour$deprecated_time$Time$TimeZoneData$america_moncton_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_monterrey_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Monterrey|LMT CST CDT|6F.g 60 50|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1UQG0 2FjC0 1nX0 i6p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0|41e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_monterrey = $isaacseymour$deprecated_time$Time$TimeZoneData$america_monterrey_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_montevideo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Montevideo|MMT UYT UYHST UYST UYT UYHST|3I.I 3u 30 20 30 2u|012121212121212121212121213434343434345454543453434343434343434343434343434343434343434|-20UIf.g 8jzJ.g 1cLu 1dcu 1cLu 1dcu 1cLu ircu 11zu 1o0u 11zu 1o0u 11zu 1qMu WLu 1qMu WLu 1qMu WLu 1qMu 11zu 1o0u 11zu NAu 11bu 2iMu zWu Dq10 19X0 pd0 jz0 cm10 19X0 1fB0 1on0 11d0 1oL0 1nB0 1fzu 1aou 1fzu 1aou 1fzu 3nAu Jb0 3MN0 1SLu 4jzu 2PB0 Lb0 3Dd0 1pb0 ixd0 An0 1MN0 An0 1wp0 On0 1wp0 Rb0 1zd0 On0 1wp0 Rb0 s8p0 1fB0 1ip0 11z0 1ld0 14n0 1o10 11z0 1o10 11z0 1o10 14n0 1ld0 14n0 1ld0 14n0 1o10 11z0 1o10 11z0 1o10 11z0|17e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_montevideo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_montevideo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_toronto_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Toronto|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101012301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-25TR0 1in0 11Wu 1nzu 1fD0 WJ0 1wr0 Nb0 1Ap0 On0 1zd0 On0 1wp0 TX0 1tB0 TX0 1tB0 TX0 1tB0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 4kM0 8x40 iv0 1o10 11z0 1nX0 11z0 1o10 11z0 1o10 1qL0 11D0 1nX0 11B0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|65e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_montreal = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Montreal', $isaacseymour$deprecated_time$Time$TimeZoneData$america_toronto_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_montserrat = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Montserrat', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_nassau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Nassau|LMT EST EDT|59.u 50 40|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2kNuO.u 26XdO.u 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|24e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_nassau = $isaacseymour$deprecated_time$Time$TimeZoneData$america_nassau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_new_york_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6');
var $isaacseymour$deprecated_time$Time$TimeZones$america_new_york = $isaacseymour$deprecated_time$Time$TimeZoneData$america_new_york_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_nipigon_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Nipigon|EST EDT EWT EPT|50 40 40 40|010123010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-25TR0 1in0 Rnb0 3je0 8x40 iv0 19yN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|16e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_nipigon = $isaacseymour$deprecated_time$Time$TimeZoneData$america_nipigon_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_nome_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Nome|NST NWT NPT BST BDT YST AKST AKDT|b0 a0 a0 b0 a0 90 90 80|012034343434343434343434343434343456767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676767676|-17SX0 8wW0 iB0 Qlb0 52O0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 cl0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|38e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_nome = $isaacseymour$deprecated_time$Time$TimeZoneData$america_nome_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_noronha_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Noronha|LMT FNT FNST|29.E 20 10|0121212121212121212121212121212121212121|-2glxO.k HdKO.k 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 nsp0 WL0 1tB0 2L0 2pB0 On0|30e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_noronha = $isaacseymour$deprecated_time$Time$TimeZoneData$america_noronha_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_beulah_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/North_Dakota/Beulah|MST MDT MWT MPT CST CDT|70 60 60 60 60 50|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101014545454545454545454545454545454545454545454545454545454|-261r0 1nX0 11B0 1nX0 SgN0 8x20 ix0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Oo0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_beulah = $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_beulah_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_center_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/North_Dakota/Center|MST MDT MWT MPT CST CDT|70 60 60 60 60 50|010102301010101010101010101010101010101010101010101010101014545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-261r0 1nX0 11B0 1nX0 SgN0 8x20 ix0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14o0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_center = $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_center_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_new_salem_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/North_Dakota/New_Salem|MST MDT MWT MPT CST CDT|70 60 60 60 60 50|010102301010101010101010101010101010101010101010101010101010101010101010101010101454545454545454545454545454545454545454545454545454545454545454545454|-261r0 1nX0 11B0 1nX0 SgN0 8x20 ix0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14o0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_new_salem = $isaacseymour$deprecated_time$Time$TimeZoneData$america_north_dakota_new_salem_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_ojinaga_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Ojinaga|LMT MST CST CDT MDT|6V.E 70 60 50 60|0121212323241414141414141414141414141414141414141414141414141414141414141414141414141414141|-1UQF0 deL0 8lc0 17c0 10M0 1dd0 2zQN0 1lb0 14p0 1lb0 14q0 1lb0 14p0 1nX0 11B0 1nX0 1fB0 WL0 1fB0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 U10 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|23e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_ojinaga = $isaacseymour$deprecated_time$Time$TimeZoneData$america_ojinaga_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_panama = $isaacseymour$deprecated_time$Time$TimeZoneData$america_panama_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_pangnirtung_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Pangnirtung|-00 AST AWT APT ADDT ADT EDT EST CST CDT|0 40 30 30 20 30 40 50 60 50|012314151515151515151515151515151515167676767689767676767676767676767676767676767676767676767676767676767676767676767676767|-1XiM0 PnG0 8x50 iu0 LCL0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1o00 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11C0 1nX0 11A0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|14e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_pangnirtung = $isaacseymour$deprecated_time$Time$TimeZoneData$america_pangnirtung_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_paramaribo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Paramaribo|LMT PMT PMT NEGT SRT SRT|3E.E 3E.Q 3E.A 3u 3u 30|012345|-2nDUj.k Wqo0.c qanX.I 1dmLN.o lzc0|24e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_paramaribo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_paramaribo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_phoenix_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Phoenix|MST MDT MWT|70 60 60|01010202010|-261r0 1nX0 11B0 1nX0 SgN0 4Al1 Ap0 1db0 SWqX 1cL0|42e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_phoenix = $isaacseymour$deprecated_time$Time$TimeZoneData$america_phoenix_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_au_prince_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Port-au-Prince|PPMT EST EDT|4N 50 40|01212121212121212121212121212121212121212121|-28RHb 2FnMb 19X0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14q0 1o00 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 14o0 1o00 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 i6n0 1nX0 11B0 1nX0 d430 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|23e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_port_au_prince = $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_au_prince_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_port_of_spain = $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_rio_branco_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Rio_Branco|LMT ACT ACST AMT|4v.c 50 40 40|01212121212121212121212121212131|-2glvs.M HdLs.M 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 NBd0 d5X0|31e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_porto_acre = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Porto_Acre', $isaacseymour$deprecated_time$Time$TimeZoneData$america_rio_branco_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_porto_velho_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Porto_Velho|LMT AMT AMST|4f.A 40 30|012121212121212121212121212121|-2glvI.o HdKI.o 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0|37e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_porto_velho = $isaacseymour$deprecated_time$Time$TimeZoneData$america_porto_velho_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_puerto_rico_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Puerto_Rico|AST AWT APT|40 30 30|0120|-17lU0 7XT0 iu0|24e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_puerto_rico = $isaacseymour$deprecated_time$Time$TimeZoneData$america_puerto_rico_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_rainy_river_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Rainy_River|CST CDT CWT CPT|60 50 50 50|010123010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-25TQ0 1in0 Rnb0 3je0 8x30 iw0 19yN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|842');
var $isaacseymour$deprecated_time$Time$TimeZones$america_rainy_river = $isaacseymour$deprecated_time$Time$TimeZoneData$america_rainy_river_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_rankin_inlet_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Rankin_Inlet|-00 CST CDDT CDT EST|0 60 40 50 50|012131313131313131313131313131313131313131313431313131313131313131313131313131313131313131313131313131313131313131313131|-vDc0 keu0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|26e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_rankin_inlet = $isaacseymour$deprecated_time$Time$TimeZoneData$america_rankin_inlet_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_recife_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Recife|LMT BRT BRST|2j.A 30 20|0121212121212121212121212121212121212121|-2glxE.o HdLE.o 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 nsp0 WL0 1tB0 2L0 2pB0 On0|33e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_recife = $isaacseymour$deprecated_time$Time$TimeZoneData$america_recife_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_regina_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Regina|LMT MST MDT MWT MPT CST|6W.A 70 60 60 60 60|012121212121212121212121341212121212121212121212121215|-2AD51.o uHe1.o 1in0 s2L0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 66N0 1cL0 1cN0 19X0 1fB0 1cL0 1fB0 1cL0 1cN0 1cL0 M30 8x20 ix0 1ip0 1cL0 1ip0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 3NB0 1cL0 1cN0|19e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_regina = $isaacseymour$deprecated_time$Time$TimeZoneData$america_regina_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_resolute_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Resolute|-00 CST CDDT CDT EST|0 60 40 50 50|012131313131313131313131313131313131313131313431313131313431313131313131313131313131313131313131313131313131313131313131|-SnA0 GWS0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|229');
var $isaacseymour$deprecated_time$Time$TimeZones$america_resolute = $isaacseymour$deprecated_time$Time$TimeZoneData$america_resolute_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_rio_branco = $isaacseymour$deprecated_time$Time$TimeZoneData$america_rio_branco_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_rosario = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Rosario', $isaacseymour$deprecated_time$Time$TimeZoneData$america_argentina_cordoba_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_santa_isabel = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Santa_Isabel', $isaacseymour$deprecated_time$Time$TimeZoneData$america_tijuana_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_santarem_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Santarem|LMT AMT AMST BRT|3C.M 40 30 30|0121212121212121212121212121213|-2glwl.c HdLl.c 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 qe10 xb0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 NBd0|21e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_santarem = $isaacseymour$deprecated_time$Time$TimeZoneData$america_santarem_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_santiago_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Santiago|SMT CLT CLT CLST CLST|4G.K 50 40 40 30|010203131313131212421242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424|-2q2jh.e fJAh.e 5knG.K 1Vzh.e jRAG.K 1pbh.e 11d0 1oL0 11d0 1oL0 11d0 1oL0 11d0 1pb0 11d0 nHX0 op0 9Bz0 jb0 1oN0 ko0 Qeo0 WL0 1zd0 On0 1ip0 11z0 1o10 11z0 1qN0 WL0 1ld0 14n0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 WL0 1qN0 1cL0 1cN0 11z0 1o10 11z0 1qN0 WL0 1fB0 19X0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1ip0 1fz0 1fB0 11z0 1qN0 WL0 1qN0 WL0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1o10 19X0 1fB0 1nX0 G10 1EL0 Op0 1zb0 Rd0 1wn0 Rd0 46n0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0|62e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_santiago = $isaacseymour$deprecated_time$Time$TimeZoneData$america_santiago_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_santo_domingo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Santo_Domingo|SDMT EST EDT EHDT AST|4E 50 40 4u 40|01213131313131414|-1ttjk 1lJMk Mn0 6sp0 Lbu 1Cou yLu 1RAu wLu 1QMu xzu 1Q0u xXu 1PAu 13jB0 e00|29e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_santo_domingo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_santo_domingo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_sao_paulo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Sao_Paulo|LMT BRT BRST|36.s 30 20|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212|-2glwR.w HdKR.w 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 pTd0 PX0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 1C10 Lz0 1Ip0 HX0 1zd0 On0 1HB0 IL0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1zd0 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1C10 Lz0 1C10 Lz0 1C10 Lz0 1C10 On0 1zd0 Rb0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0|20e6');
var $isaacseymour$deprecated_time$Time$TimeZones$america_sao_paulo = $isaacseymour$deprecated_time$Time$TimeZoneData$america_sao_paulo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_scoresbysund_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Scoresbysund|LMT CGT CGST EGST EGT|1r.Q 20 10 0 10|0121343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434|-2a5Ww.8 2z5ew.8 1a00 1cK0 1cL0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|452');
var $isaacseymour$deprecated_time$Time$TimeZones$america_scoresbysund = $isaacseymour$deprecated_time$Time$TimeZoneData$america_scoresbysund_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_shiprock = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Shiprock', $isaacseymour$deprecated_time$Time$TimeZoneData$america_denver_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_sitka_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Sitka|PST PWT PPT PDT YST AKST AKDT|80 70 70 70 90 90 80|01203030303030303030303030303030345656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565|-17T20 8x10 iy0 Vo10 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 co0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|90e2');
var $isaacseymour$deprecated_time$Time$TimeZones$america_sitka = $isaacseymour$deprecated_time$Time$TimeZoneData$america_sitka_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_barthelemy = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/St_Barthelemy', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_st_johns_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/St_Johns|NST NDT NST NDT NWT NPT NDDT|3u.Q 2u.Q 3u 2u 2u 2u 1u|01010101010101010101010101010101010102323232323232324523232323232323232323232323232323232323232323232323232323232323232323232323232323232326232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-28oit.8 14L0 1nB0 1in0 1gm0 Dz0 1JB0 1cL0 1cN0 1cL0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1fB0 1cL0 1cN0 1cL0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1fB0 19X0 1fB0 1cL0 1fB0 19X0 1fB0 19X0 10O0 eKX.8 19X0 1iq0 WL0 1qN0 WL0 1qN0 WL0 1tB0 TX0 1tB0 WL0 1qN0 WL0 1qN0 7UHu itu 1tB0 WL0 1qN0 WL0 1qN0 WL0 1qN0 WL0 1tB0 WL0 1ld0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14n1 1lb0 14p0 1nW0 11C0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zcX Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|11e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_johns = $isaacseymour$deprecated_time$Time$TimeZoneData$america_st_johns_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_kitts = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/St_Kitts', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_lucia = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/St_Lucia', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_thomas = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/St_Thomas', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZones$america_st_vincent = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/St_Vincent', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_swift_current_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Swift_Current|LMT MST MDT MWT MPT CST|7b.k 70 60 60 60 60|012134121212121212121215|-2AD4M.E uHdM.E 1in0 UGp0 8x20 ix0 1o10 17b0 1ip0 11z0 1o10 11z0 1o10 11z0 isN0 1cL0 3Cp0 1cL0 1cN0 11z0 1qN0 WL0 pMp0|16e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_swift_current = $isaacseymour$deprecated_time$Time$TimeZoneData$america_swift_current_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_tegucigalpa_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Tegucigalpa|LMT CST CDT|5M.Q 60 50|01212121|-1WGGb.8 2ETcb.8 WL0 1qN0 WL0 GRd0 AL0|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_tegucigalpa = $isaacseymour$deprecated_time$Time$TimeZoneData$america_tegucigalpa_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_thule_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Thule|LMT AST ADT|4z.8 40 30|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2a5To.Q 31NBo.Q 1cL0 1cN0 1cL0 1fB0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|656');
var $isaacseymour$deprecated_time$Time$TimeZones$america_thule = $isaacseymour$deprecated_time$Time$TimeZoneData$america_thule_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_thunder_bay_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Thunder_Bay|CST EST EWT EPT EDT|60 50 40 40 40|0123141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141414141|-2q5S0 1iaN0 8x40 iv0 XNB0 1cL0 1cN0 1fz0 1cN0 1cL0 3Cp0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|11e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_thunder_bay = $isaacseymour$deprecated_time$Time$TimeZoneData$america_thunder_bay_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_tijuana = $isaacseymour$deprecated_time$Time$TimeZoneData$america_tijuana_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_toronto = $isaacseymour$deprecated_time$Time$TimeZoneData$america_toronto_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_tortola = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Tortola', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_vancouver_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Vancouver|PST PDT PWT PPT|80 70 70 70|0102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-25TO0 1in0 UGp0 8x10 iy0 1o10 17b0 1ip0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|23e5');
var $isaacseymour$deprecated_time$Time$TimeZones$america_vancouver = $isaacseymour$deprecated_time$Time$TimeZoneData$america_vancouver_l;
var $isaacseymour$deprecated_time$Time$TimeZones$america_virgin = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'America/Virgin', $isaacseymour$deprecated_time$Time$TimeZoneData$america_port_of_spain_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_whitehorse_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Whitehorse|YST YDT YWT YPT YDDT PST PDT|90 80 80 80 70 80 70|0101023040565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565|-25TN0 1in0 1o10 13V0 Ser0 8x00 iz0 LCL0 1fA0 3NA0 vrd0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|23e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_whitehorse = $isaacseymour$deprecated_time$Time$TimeZoneData$america_whitehorse_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_winnipeg_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Winnipeg|CST CDT CWT CPT|60 50 50 50|010101023010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aIi0 WL0 3ND0 1in0 Jap0 Rb0 aCN0 8x30 iw0 1tB0 11z0 1ip0 11z0 1o10 11z0 1o10 11z0 1rd0 10L0 1op0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 1cL0 1cN0 11z0 6i10 WL0 6i10 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 14o0 1lc0 14o0 1o00 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 14o0 1o00 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1o00 11A0 1o00 11A0 1o00 14o0 1lc0 14o0 1lc0 14o0 1o00 11A0 1o00 11A0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|66e4');
var $isaacseymour$deprecated_time$Time$TimeZones$america_winnipeg = $isaacseymour$deprecated_time$Time$TimeZoneData$america_winnipeg_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_yakutat_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Yakutat|YST YWT YPT YDT AKST AKDT|90 80 80 80 90 80|01203030303030303030303030303030304545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-17T10 8x00 iz0 Vo10 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 cn0 10q0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|642');
var $isaacseymour$deprecated_time$Time$TimeZones$america_yakutat = $isaacseymour$deprecated_time$Time$TimeZoneData$america_yakutat_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$america_yellowknife_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('America/Yellowknife|-00 MST MWT MPT MDDT MDT|0 70 60 60 50 60|012314151515151515151515151515151515151515151515151515151515151515151515151515151515151515151515151515151515151515151515151|-1pdA0 hix0 8x20 ix0 LCL0 1fA0 zgO0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|19e3');
var $isaacseymour$deprecated_time$Time$TimeZones$america_yellowknife = $isaacseymour$deprecated_time$Time$TimeZoneData$america_yellowknife_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_casey_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Casey|-00 +08 +11|0 -80 -b0|0121212|-2q00 1DjS0 T90 40P0 KL0 blz0|10');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_casey = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_casey_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_davis_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Davis|-00 +07 +05|0 -70 -50|01012121|-vyo0 iXt0 alj0 1D7v0 VB0 3Wn0 KN0|70');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_davis = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_davis_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_dumontdurville_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/DumontDUrville|-00 +10|0 -a0|0101|-U0o0 cfq0 bFm0|80');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_dumontdurville = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_dumontdurville_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_macquarie_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Macquarie|AEST AEDT -00 MIST|-a0 -b0 0 -b0|0102010101010101010101010101010101010101010101010101010101010101010101010101010101010101013|-29E80 19X0 4SL0 1ayy0 Lvs0 1cM0 1o00 Rc0 1wo0 Rc0 1wo0 U00 1wo0 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 11A0 1qM0 WM0 1qM0 Oo0 1zc0 Oo0 1zc0 Oo0 1wo0 WM0 1tA0 WM0 1tA0 U00 1tA0 U00 1tA0 11A0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 11A0 1o00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1cM0 1cM0 1cM0|1');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_macquarie = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_macquarie_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_mawson_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Mawson|-00 +06 +05|0 -60 -50|012|-CEo0 2fyk0|60');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_mawson = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_mawson_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_auckland_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Auckland|NZMT NZST NZST NZDT|-bu -cu -c0 -d0|01020202020202020202020202023232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323|-1GCVu Lz0 1tB0 11zu 1o0u 11zu 1o0u 11zu 1o0u 14nu 1lcu 14nu 1lcu 1lbu 11Au 1nXu 11Au 1nXu 11Au 1nXu 11Au 1nXu 11Au 1qLu WMu 1qLu 11Au 1n1bu IM0 1C00 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1qM0 14o0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1io0 17c0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1io0 17c0 1io0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00|14e5');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_mcmurdo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Antarctica/McMurdo', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_auckland_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_palmer_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Palmer|-00 ARST ART ART ARST CLT CLST|0 30 40 30 20 40 30|0121212121234356565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656565656|-cao0 nD0 1vd0 SL0 1vd0 17z0 1cN0 1fz0 1cN0 1cL0 1cN0 asn0 Db0 jsN0 14N0 11z0 1o10 11z0 1qN0 WL0 1qN0 WL0 1qN0 1cL0 1cN0 11z0 1o10 11z0 1qN0 WL0 1fB0 19X0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1ip0 1fz0 1fB0 11z0 1qN0 WL0 1qN0 WL0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1o10 19X0 1fB0 1nX0 G10 1EL0 Op0 1zb0 Rd0 1wn0 Rd0 46n0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0|40');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_palmer = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_palmer_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_rothera_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Rothera|-00 -03|0 30|01|gOo0|130');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_rothera = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_rothera_l;
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_south_pole = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Antarctica/South_Pole', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_auckland_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_syowa_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Syowa|-00 +03|0 -30|01|-vs00|20');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_syowa = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_syowa_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_troll_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Troll|-00 +00 +02|0 0 -20|01212121212121212121212121212121212121212121212121212121212121212121|1puo0 hd0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|40');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_troll = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_troll_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_vostok_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Antarctica/Vostok|-00 +06|0 -60|01|-tjA0|25');
var $isaacseymour$deprecated_time$Time$TimeZones$antarctica_vostok = $isaacseymour$deprecated_time$Time$TimeZoneData$antarctica_vostok_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_oslo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Oslo|CET CEST|-10 -20|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2awM0 Qm0 W6o0 5pf0 WM0 1fA0 1cM0 1cM0 1cM0 1cM0 wJc0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1qM0 WM0 zpc0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|62e4');
var $isaacseymour$deprecated_time$Time$TimeZones$arctic_longyearbyen = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Arctic/Longyearbyen', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_oslo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_riyadh_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Riyadh|LMT AST|-36.Q -30|01|-TvD6.Q|57e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_aden = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Aden', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_riyadh_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_almaty_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Almaty|LMT +05 +06 +07|-57.M -50 -60 -70|012323232323232323232321232323232323232323232323232|-1Pc57.M eUo7.M 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0|15e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_almaty = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_almaty_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_amman_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Amman|LMT EET EEST|-2n.I -20 -30|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1yW2n.I 1HiMn.I KL0 1oN0 11b0 1oN0 11b0 1pd0 1dz0 1cp0 11b0 1op0 11b0 fO10 1db0 1e10 1cL0 1cN0 1cL0 1cN0 1fz0 1pd0 10n0 1ld0 14n0 1hB0 15b0 1ip0 19X0 1cN0 1cL0 1cN0 17b0 1ld0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1So0 y00 1fc0 1dc0 1co0 1dc0 1cM0 1cM0 1cM0 1o00 11A0 1lc0 17c0 1cM0 1cM0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 4bX0 Dd0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0|25e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_amman = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_amman_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_anadyr_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Anadyr|LMT +12 +13 +14 +11|-bN.U -c0 -d0 -e0 -b0|01232121212121212121214121212121212121212121212121212121212141|-1PcbN.U eUnN.U 23CL0 1db0 2q10 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 2sp0 WM0|13e3');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_anadyr = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_anadyr_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_aqtau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Aqtau|LMT +04 +05 +06|-3l.4 -40 -50 -60|012323232323232323232123232312121212121212121212|-1Pc3l.4 eUnl.4 24PX0 2pX0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0|15e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_aqtau = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_aqtau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_aqtobe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Aqtobe|LMT +04 +05 +06|-3M.E -40 -50 -60|0123232323232323232321232323232323232323232323232|-1Pc3M.E eUnM.E 23CL0 3Db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0|27e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_aqtobe = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_aqtobe_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ashgabat_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Ashgabat|LMT +04 +05 +06|-3R.w -40 -50 -60|0123232323232323232323212|-1Pc3R.w eUnR.w 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0|41e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ashgabat = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ashgabat_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ashkhabad = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Ashkhabad', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ashgabat_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_baghdad_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Baghdad|BMT AST ADT|-2V.A -30 -40|012121212121212121212121212121212121212121212121212121|-26BeV.A 2ACnV.A 11b0 1cp0 1dz0 1dd0 1db0 1cN0 1cp0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1de0 1dc0 1dc0 1dc0 1cM0 1dc0 1cM0 1dc0 1cM0 1dc0 1dc0 1dc0 1cM0 1dc0 1cM0 1dc0 1cM0 1dc0 1dc0 1dc0 1cM0 1dc0 1cM0 1dc0 1cM0 1dc0 1dc0 1dc0 1cM0 1dc0 1cM0 1dc0 1cM0 1dc0|66e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_baghdad = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_baghdad_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_qatar_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Qatar|LMT GST AST|-3q.8 -40 -30|012|-21Jfq.8 27BXq.8|96e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_bahrain = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Bahrain', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_qatar_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_baku_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Baku|LMT +03 +04 +05|-3j.o -30 -40 -50|01232323232323232323232123232323232323232323232323232323232323232|-1Pc3j.o 1jUoj.o WCL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 1cM0 9Je0 1o00 11z0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00|27e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_baku = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_baku_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bangkok_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Bangkok|BMT ICT|-6G.4 -70|01|-218SG.4|15e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_bangkok = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bangkok_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_barnaul_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Barnaul|LMT +06 +07 +08|-5z -60 -70 -80|0123232323232323232323212323232321212121212121212121212121212121212|-21S5z pCnz 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 p90 LE0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3rd0');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_barnaul = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_barnaul_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_beirut_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Beirut|EET EEST|-20 -30|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-21aq0 1on0 1410 1db0 19B0 1in0 1ip0 WL0 1lQp0 11b0 1oN0 11b0 1oN0 11b0 1pd0 11b0 1oN0 11b0 q6N0 En0 1oN0 11b0 1oN0 11b0 1oN0 11b0 1pd0 11b0 1oN0 11b0 1op0 11b0 dA10 17b0 1iN0 17b0 1iN0 17b0 1iN0 17b0 1vB0 SL0 1mp0 13z0 1iN0 17b0 1iN0 17b0 1jd0 12n0 1a10 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0|22e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_beirut = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_beirut_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bishkek_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Bishkek|LMT +05 +06 +07|-4W.o -50 -60 -70|012323232323232323232321212121212121212121212121212|-1Pc4W.o eUnW.o 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2e00 1tX0 17b0 1ip0 17b0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1cPu 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0|87e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_bishkek = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bishkek_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_brunei_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Brunei|LMT BNT BNT|-7D.E -7u -80|012|-1KITD.E gDc9.E|42e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_brunei = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_brunei_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kolkata_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kolkata|HMT BURT IST IST|-5R.k -6u -5u -6u|01232|-18LFR.k 1unn.k HB0 7zX0|15e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_calcutta = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Calcutta', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kolkata_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_chita_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Chita|LMT +08 +09 +10|-7x.Q -80 -90 -a0|012323232323232323232321232323232323232323232323232323232323232312|-21Q7x.Q pAnx.Q 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3re0|33e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_chita = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_chita_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_choibalsan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Choibalsan|LMT ULAT ULAT CHOST CHOT CHOT CHOST|-7C -70 -80 -a0 -90 -80 -90|0123434343434343434343434343434343434343434343456565656565656565656565656565656565656565656565|-2APHC 2UkoC cKn0 1da0 1dd0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 6hD0 11z0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 3Db0 h1f0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0|38e3');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_choibalsan = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_choibalsan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Shanghai|CST CDT|-80 -90|01010101010101010|-1c1I0 LX0 16p0 1jz0 1Myp0 Rb0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0|23e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_chongqing = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Chongqing', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l);
var $isaacseymour$deprecated_time$Time$TimeZones$asia_chungking = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Chungking', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_colombo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Colombo|MMT +0530 +06 +0630|-5j.w -5u -60 -6u|01231321|-2zOtj.w 1rFbN.w 1zzu 7Apu 23dz0 11zu n3cu|22e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_colombo = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_colombo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dhaka_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Dhaka|HMT BURT IST DACT BDT BDST|-5R.k -6u -5u -60 -60 -70|01213454|-18LFR.k 1unn.k HB0 m6n0 LqMu 1x6n0 1i00|16e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_dacca = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Dacca', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dhaka_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_damascus_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Damascus|LMT EET EEST|-2p.c -20 -30|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-21Jep.c Hep.c 17b0 1ip0 17b0 1ip0 17b0 1ip0 19X0 1xRB0 11X0 1oN0 10L0 1pB0 11b0 1oN0 10L0 1mp0 13X0 1oN0 11b0 1pd0 11b0 1oN0 11b0 1oN0 11b0 1oN0 11b0 1pd0 11b0 1oN0 11b0 1oN0 11b0 1oN0 11b0 1pd0 11b0 1oN0 Nb0 1AN0 Nb0 bcp0 19X0 1gp0 19X0 3ld0 1xX0 Vd0 1Bz0 Sp0 1vX0 10p0 1dz0 1cN0 1cL0 1db0 1db0 1g10 1an0 1ap0 1db0 1fd0 1db0 1cN0 1db0 1dd0 1db0 1cp0 1dz0 1c10 1dX0 1cN0 1db0 1dd0 1db0 1cN0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1db0 1cN0 1db0 1cN0 19z0 1fB0 1qL0 11B0 1on0 Wp0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0|26e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_damascus = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_damascus_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_dhaka = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dhaka_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dili_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Dili|LMT TLT JST TLT WITA|-8m.k -80 -90 -90 -80|012343|-2le8m.k 1dnXm.k 8HA0 1ew00 Xld0|19e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_dili = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dili_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dubai_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Dubai|LMT GST|-3F.c -40|01|-21JfF.c|39e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_dubai = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dubai_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dushanbe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Dushanbe|LMT +05 +06 +07|-4z.c -50 -60 -70|012323232323232323232321|-1Pc4z.c eUnz.c 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2hB0|76e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_dushanbe = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dushanbe_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_famagusta_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Famagusta|LMT EET EEST +03|-2f.M -20 -30 -30|01212121212121212121212121212121212121212121212121212121212121212121212121212121212123|-1Vc2f.M 2a3cf.M 1cL0 1qp0 Xz0 19B0 19X0 1fB0 1db0 1cp0 1cL0 1fB0 19X0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1o30 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 15U0');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_famagusta = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_famagusta_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_gaza_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Gaza|EET EET EEST IST IDT|-20 -30 -30 -20 -30|010101010102020202020202020202023434343434343434343434343430202020202020202020202020202020202020202020202020202020202020202020202020202020202020|-1c2q0 5Rb0 10r0 1px0 10N0 1pz0 16p0 1jB0 16p0 1jx0 pBd0 Vz0 1oN0 11b0 1oO0 10N0 1pz0 10N0 1pb0 10N0 1pb0 10N0 1pb0 10N0 1pz0 10N0 1pb0 10N0 1pb0 11d0 1oL0 dW0 hfB0 Db0 1fB0 Rb0 npB0 11z0 1C10 IL0 1s10 10n0 1o10 WL0 1zd0 On0 1ld0 11z0 1o10 14n0 1o10 14n0 1nd0 12n0 1nd0 Xz0 1q10 12n0 M10 C00 17c0 1io0 17c0 1io0 17c0 1o00 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 17c0 1io0 18N0 1bz0 19z0 1gp0 1610 1iL0 11z0 1o10 14o0 1lA1 SKX 1xd1 MKX 1AN0 1a00 1fA0 1cL0 1cN0 1nX0 1210 1nz0 1220 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0|18e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_gaza = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_gaza_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_harbin = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Harbin', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hebron_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Hebron|EET EET EEST IST IDT|-20 -30 -30 -20 -30|01010101010202020202020202020202343434343434343434343434343020202020202020202020202020202020202020202020202020202020202020202020202020202020202020|-1c2q0 5Rb0 10r0 1px0 10N0 1pz0 16p0 1jB0 16p0 1jx0 pBd0 Vz0 1oN0 11b0 1oO0 10N0 1pz0 10N0 1pb0 10N0 1pb0 10N0 1pb0 10N0 1pz0 10N0 1pb0 10N0 1pb0 11d0 1oL0 dW0 hfB0 Db0 1fB0 Rb0 npB0 11z0 1C10 IL0 1s10 10n0 1o10 WL0 1zd0 On0 1ld0 11z0 1o10 14n0 1o10 14n0 1nd0 12n0 1nd0 Xz0 1q10 12n0 M10 C00 17c0 1io0 17c0 1io0 17c0 1o00 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 17c0 1io0 18N0 1bz0 19z0 1gp0 1610 1iL0 12L0 1mN0 14o0 1lc0 Tb0 1xd1 MKX bB0 cn0 1cN0 1a00 1fA0 1cL0 1cN0 1nX0 1210 1nz0 1220 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 1qL0|25e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_hebron = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hebron_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ho_chi_minh_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Ho_Chi_Minh|LMT PLMT ICT IDT JST|-76.E -76.u -70 -80 -90|0123423232|-2yC76.E bK00.a 1h7b6.u 5lz0 18o0 3Oq0 k5b0 aW00 BAM0|90e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ho_chi_minh = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ho_chi_minh_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hong_kong_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Hong_Kong|LMT HKT HKST JST|-7A.G -80 -90 -90|0121312121212121212121212121212121212121212121212121212121212121212121|-2CFHA.G 1sEP6.G 1cL0 ylu 93X0 1qQu 1tX0 Rd0 1In0 NB0 1cL0 11B0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1kL0 14N0 1nX0 U10 1tz0 U10 1wn0 Rd0 1wn0 U10 1tz0 U10 1tz0 U10 1tz0 U10 1wn0 Rd0 1wn0 Rd0 1wn0 U10 1tz0 U10 1tz0 17d0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 s10 1Vz0 1cN0 1cL0 1cN0 1cL0 6fd0 14n0|73e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_hong_kong = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hong_kong_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hovd_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Hovd|LMT HOVT HOVT HOVST|-66.A -60 -70 -80|012323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-2APG6.A 2Uko6.A cKn0 1db0 1dd0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 6hD0 11z0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 kEp0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0|81e3');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_hovd = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hovd_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_irkutsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Irkutsk|IMT +07 +08 +09|-6V.5 -70 -80 -90|01232323232323232323232123232323232323232323232323232323232323232|-21zGV.5 pjXV.5 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|60e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_irkutsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_irkutsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_istanbul_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Istanbul|IMT EET EEST +04 +03|-1U.U -20 -30 -40 -30|012121212121212121212121212121212121212121212121212121234343434342121212121212121212121212121212121212121212121212121212121212124|-2ogNU.U dzzU.U 11b0 8tB0 1on0 1410 1db0 19B0 1in0 3Rd0 Un0 1oN0 11b0 zSp0 CL0 mN0 1Vz0 1gN0 1pz0 5Rd0 1fz0 1yp0 ML0 1kp0 17b0 1ip0 17b0 1fB0 19X0 1jB0 18L0 1ip0 17z0 qdd0 xX0 3S10 Tz0 dA10 11z0 1o10 11z0 1qN0 11z0 1ze0 11B0 WM0 1qO0 WI0 1nX0 1rB0 10L0 11B0 1in0 17d0 1in0 2pX0 19E0 1fU0 16Q0 1iI0 16Q0 1iI0 1Vd0 pb0 3Kp0 14o0 1de0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1a00 1fA0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WO0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 Xc0 1qo0 WM0 1qM0 11A0 1o00 1200 1nA0 11A0 1tA0 U00 15w0|13e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_istanbul = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Istanbul', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_istanbul_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jakarta_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Jakarta|BMT JAVT WIB JST WIB WIB|-77.c -7k -7u -90 -80 -70|01232425|-1Q0Tk luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_jakarta = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jakarta_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jayapura_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Jayapura|LMT WIT ACST|-9m.M -90 -9u|0121|-1uu9m.M sMMm.M L4nu|26e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_jayapura = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jayapura_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jerusalem_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Jerusalem|JMT IST IDT IDDT|-2k.E -20 -30 -40|01212121212132121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-26Bek.E SyMk.E 5Rb0 10r0 1px0 10N0 1pz0 16p0 1jB0 16p0 1jx0 3LB0 Em0 or0 1cn0 1dB0 16n0 10O0 1ja0 1tC0 14o0 1cM0 1a00 11A0 1Na0 An0 1MP0 AJ0 1Kp0 LC0 1oo0 Wl0 EQN0 Db0 1fB0 Rb0 npB0 11z0 1C10 IL0 1s10 10n0 1o10 WL0 1zd0 On0 1ld0 11z0 1o10 14n0 1o10 14n0 1nd0 12n0 1nd0 Xz0 1q10 12n0 1hB0 1dX0 1ep0 1aL0 1eN0 17X0 1nf0 11z0 1tB0 19W0 1e10 17b0 1ep0 1gL0 18N0 1fz0 1eN0 17b0 1gq0 1gn0 19d0 1dz0 1c10 17X0 1hB0 1gn0 19d0 1dz0 1c10 17X0 1kp0 1dz0 1c10 1aL0 1eN0 1oL0 10N0 1oL0 10N0 1oL0 10N0 1rz0 W10 1rz0 W10 1rz0 10N0 1oL0 10N0 1oL0 10N0 1rz0 W10 1rz0 W10 1rz0 10N0 1oL0 10N0 1oL0 10N0 1oL0 10N0 1rz0 W10 1rz0 W10 1rz0 10N0 1oL0 10N0 1oL0 10N0 1rz0 W10 1rz0 W10 1rz0 W10 1rz0 10N0 1oL0 10N0 1oL0|81e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_jerusalem = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jerusalem_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kabul_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kabul|AFT AFT|-40 -4u|01|-10Qs0|46e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kabul = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kabul_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kamchatka_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kamchatka|LMT +11 +12 +13|-ay.A -b0 -c0 -d0|012323232323232323232321232323232323232323232323232323232323212|-1SLKy.A ivXy.A 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 2sp0 WM0|18e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kamchatka = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kamchatka_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_karachi_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Karachi|LMT IST IST KART PKT PKST|-4s.c -5u -6u -50 -50 -60|012134545454|-2xoss.c 1qOKW.c 7zX0 eup0 LqMu 1fy00 1cL0 dK10 11b0 1610 1jX0|24e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_karachi = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_karachi_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_urumqi_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Urumqi|LMT XJT|-5O.k -60|01|-1GgtO.k|32e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kashgar = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Kashgar', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_urumqi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kathmandu_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kathmandu|LMT IST NPT|-5F.g -5u -5J|012|-21JhF.g 2EGMb.g|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kathmandu = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kathmandu_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_katmandu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Katmandu', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kathmandu_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_khandyga_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Khandyga|LMT +08 +09 +10 +11|-92.d -80 -90 -a0 -b0|0123232323232323232323212323232323232323232323232343434343434343432|-21Q92.d pAp2.d 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 qK0 yN0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 17V0 7zD0|66e2');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_khandyga = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_khandyga_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kolkata = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kolkata_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_krasnoyarsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Krasnoyarsk|LMT +06 +07 +08|-6b.q -60 -70 -80|01232323232323232323232123232323232323232323232323232323232323232|-21Hib.q prAb.q 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|10e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_krasnoyarsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_krasnoyarsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kuala_lumpur_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kuala_Lumpur|SMT MALT MALST MALT MALT JST MYT|-6T.p -70 -7k -7k -7u -90 -80|01234546|-2Bg6T.p 17anT.p 7hXE dM00 17bO 8Fyu 1so1u|71e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kuala_lumpur = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kuala_lumpur_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kuching_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Kuching|LMT BORT BORT BORTST JST MYT|-7l.k -7u -80 -8k -90 -80|01232323232323232425|-1KITl.k gDbP.k 6ynu AnE 1O0k AnE 1NAk AnE 1NAk AnE 1NAk AnE 1O0k AnE 1NAk AnE pAk 8Fz0 1so10|13e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kuching = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_kuching_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_kuwait = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Kuwait', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_riyadh_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_macau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Macau|LMT MOT MOST CST|-7y.k -80 -90 -80|0121212121212121212121212121212121212121213|-2le7y.k 1XO34.k 1wn0 Rd0 1wn0 R9u 1wqu U10 1tz0 TVu 1tz0 17gu 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cJu 1cL0 1cN0 1fz0 1cN0 1cOu 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cJu 1cL0 1cN0 1fz0 1cN0 1cL0 KEp0|57e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_macao = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Macao', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_macau_l);
var $isaacseymour$deprecated_time$Time$TimeZones$asia_macau = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_macau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_magadan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Magadan|LMT +10 +11 +12|-a3.c -a0 -b0 -c0|012323232323232323232321232323232323232323232323232323232323232312|-1Pca3.c eUo3.c 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3Cq0|95e3');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_magadan = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_magadan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_makassar_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Makassar|LMT MMT WITA JST|-7V.A -7V.A -80 -90|01232|-21JjV.A vfc0 myLV.A 8ML0|15e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_makassar = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_makassar_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_manila_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Manila|PHT PHST JST|-80 -90 -90|010201010|-1kJI0 AL0 cK10 65X0 mXB0 vX0 VK10 1db0|24e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_manila = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_manila_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_muscat = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Muscat', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_dubai_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_nicosia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Nicosia|LMT EET EEST|-2d.s -20 -30|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1Vc2d.s 2a3cd.s 1cL0 1qp0 Xz0 19B0 19X0 1fB0 1db0 1cp0 1cL0 1fB0 19X0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1o30 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|32e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_nicosia = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_nicosia_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_novokuznetsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Novokuznetsk|LMT +06 +07 +08|-5M.M -60 -70 -80|012323232323232323232321232323232323232323232323232323232323212|-1PctM.M eULM.M 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 2sp0 WM0|55e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_novokuznetsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_novokuznetsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_novosibirsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Novosibirsk|LMT +06 +07 +08|-5v.E -60 -70 -80|0123232323232323232323212323212121212121212121212121212121212121212|-21Qnv.E pAFv.E 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 ml0 Os0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 4eN0|15e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_novosibirsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_novosibirsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_omsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Omsk|LMT +05 +06 +07|-4R.u -50 -60 -70|01232323232323232323232123232323232323232323232323232323232323232|-224sR.u pMLR.u 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_omsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_omsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_oral_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Oral|LMT +04 +05 +06|-3p.o -40 -50 -60|01232323232323232121212121212121212121212121212|-1Pc3p.o eUnp.o 23CL0 3Db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1fA0 1cM0 1cM0 IM0 1EM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0|27e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_oral = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_oral_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_phnom_penh = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Phnom_Penh', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bangkok_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_pontianak_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Pontianak|LMT PMT WIB JST WIB WITA WIB|-7h.k -7h.k -7u -90 -80 -80 -70|012324256|-2ua7h.k XE00 munL.k 8Rau 6kpu 4PXu xhcu Wqnu|23e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_pontianak = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_pontianak_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_pyongyang_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Pyongyang|LMT KST JCST JST KST|-8n -8u -90 -90 -90|012341|-2um8n 97XR 12FXu jdA0 2Onc0|29e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_pyongyang = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_pyongyang_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_qatar = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_qatar_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_qyzylorda_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Qyzylorda|LMT +04 +05 +06|-4l.Q -40 -50 -60|0123232323232323232323232323232323232323232323|-1Pc4l.Q eUol.Q 23CL0 3Db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 3ao0 1EM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0|73e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_qyzylorda = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_qyzylorda_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_rangoon_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Rangoon|RMT BURT JST MMT|-6o.E -6u -90 -6u|0123|-21Jio.E SmnS.E 7j9u|48e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_rangoon = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_rangoon_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_riyadh = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_riyadh_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_saigon = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Saigon', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ho_chi_minh_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_sakhalin_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Sakhalin|LMT +09 +11 +12 +10|-9u.M -90 -b0 -c0 -a0|01232323232323232323232423232323232424242424242424242424242424242|-2AGVu.M 1BoMu.M 1qFa0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 2pB0 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3rd0|58e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_sakhalin = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_sakhalin_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_samarkand_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Samarkand|LMT +04 +05 +06|-4r.R -40 -50 -60|01232323232323232323232|-1Pc4r.R eUor.R 23CL0 3Db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0|36e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_samarkand = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_samarkand_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_seoul_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Seoul|LMT KST JCST JST KST KDT KDT|-8r.Q -8u -90 -90 -90 -9u -a0|01234151515151515146464|-2um8r.Q 97XV.Q 12FXu jjA0 kKo0 2I0u OL0 1FB0 Rb0 1qN0 TX0 1tB0 TX0 1tB0 TX0 1tB0 TX0 2ap0 12FBu 11A0 1o00 11A0|23e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_seoul = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_seoul_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_shanghai = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_singapore_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Singapore|SMT MALT MALST MALT MALT JST SGT SGT|-6T.p -70 -7k -7k -7u -90 -7u -80|012345467|-2Bg6T.p 17anT.p 7hXE dM00 17bO 8Fyu Mspu DTA0|56e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_singapore = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_singapore_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_srednekolymsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Srednekolymsk|LMT +10 +11 +12|-ae.Q -a0 -b0 -c0|01232323232323232323232123232323232323232323232323232323232323232|-1Pcae.Q eUoe.Q 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|35e2');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_srednekolymsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_srednekolymsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_taipei_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Taipei|JWST JST CST CDT|-80 -90 -80 -90|01232323232323232323232323232323232323232|-1iw80 joM0 1yo0 Tz0 1ip0 1jX0 1cN0 11b0 1oN0 11b0 1oN0 11b0 1oN0 11b0 10N0 1BX0 10p0 1pz0 10p0 1pz0 10p0 1db0 1dd0 1db0 1cN0 1db0 1cN0 1db0 1cN0 1db0 1BB0 ML0 1Bd0 ML0 uq10 1db0 1cN0 1db0 97B0 AL0|74e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_taipei = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_taipei_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tashkent_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Tashkent|LMT +05 +06 +07|-4B.b -50 -60 -70|012323232323232323232321|-1Pc4B.b eUnB.b 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0|23e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tashkent = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tashkent_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tbilisi_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Tbilisi|TBMT +03 +04 +05|-2X.b -30 -40 -50|0123232323232323232323212121232323232323232323212|-1Pc2X.b 1jUnX.b WCL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 1cK0 1cL0 1cN0 1cL0 1cN0 2pz0 1cL0 1fB0 3Nz0 11B0 1nX0 11B0 1qL0 WN0 1qL0 WN0 1qL0 11B0 1nX0 11B0 1nX0 11B0 An0 Os0 WM0|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tbilisi = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tbilisi_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tehran_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Tehran|LMT TMT IRST IRST IRDT IRDT|-3p.I -3p.I -3u -40 -50 -4u|01234325252525252525252525252525252525252525252525252525252525252525252525252525252525252525252525252|-2btDp.I 1d3c0 1huLT.I TXu 1pz0 sN0 vAu 1cL0 1dB0 1en0 pNB0 UL0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 64p0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0 1cN0 1dz0 1cp0 1dz0 1cp0 1dz0 1cp0 1dz0|14e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tehran = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tehran_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tel_aviv = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Tel_Aviv', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jerusalem_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_thimphu_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Thimphu|LMT IST BTT|-5W.A -5u -60|012|-Su5W.A 1BGMs.A|79e3');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_thimbu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Thimbu', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_thimphu_l);
var $isaacseymour$deprecated_time$Time$TimeZones$asia_thimphu = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_thimphu_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tokyo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Tokyo|JCST JST JDT|-90 -90 -a0|0121212121|-1iw90 pKq0 QL0 1lB0 13X0 1zB0 NX0 1zB0 NX0|38e6');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tokyo = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tokyo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tomsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Tomsk|LMT +06 +07 +08|-5D.P -60 -70 -80|0123232323232323232323212323232323232323232323212121212121212121212|-21NhD.P pxzD.P 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 co0 1bB0 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3Qp0|10e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_tomsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tomsk_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ujung_pandang = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Ujung_Pandang', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_makassar_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ulaanbaatar_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Ulaanbaatar|LMT ULAT ULAT ULAST|-77.w -70 -80 -90|012323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-2APH7.w 2Uko7.w cKn0 1db0 1dd0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 6hD0 11z0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 kEp0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1fx0 1cP0 1cJ0 1cP0 1cJ0 1cP0 1cJ0|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ulaanbaatar = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ulaanbaatar_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ulan_bator = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Ulan_Bator', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ulaanbaatar_l);
var $isaacseymour$deprecated_time$Time$TimeZones$asia_urumqi = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_urumqi_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ust_nera_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Ust-Nera|LMT +08 +09 +12 +11 +10|-9w.S -80 -90 -c0 -b0 -a0|012343434343434343434345434343434343434343434343434343434343434345|-21Q9w.S pApw.S 23CL0 1d90 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 17V0 7zD0|65e2');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_ust_nera = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_ust_nera_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_vientiane = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Vientiane', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_bangkok_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_vladivostok_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Vladivostok|LMT +09 +10 +11|-8L.v -90 -a0 -b0|01232323232323232323232123232323232323232323232323232323232323232|-1SJIL.v itXL.v 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|60e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_vladivostok = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_vladivostok_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yakutsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Yakutsk|LMT +08 +09 +10|-8C.W -80 -90 -a0|01232323232323232323232123232323232323232323232323232323232323232|-21Q8C.W pAoC.W 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|28e4');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_yakutsk = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yakutsk_l;
var $isaacseymour$deprecated_time$Time$TimeZones$asia_yangon = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Asia/Yangon', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_rangoon_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yekaterinburg_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Yekaterinburg|LMT PMT +04 +05 +06|-42.x -3J.5 -40 -50 -60|012343434343434343434343234343434343434343434343434343434343434343|-2ag42.x 7mQh.s qBvJ.5 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|14e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_yekaterinburg = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yekaterinburg_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yerevan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Asia/Yerevan|LMT +03 +04 +05|-2W -30 -40 -50|0123232323232323232323212121212323232323232323232323232323232|-1Pc2W 1jUnW WCL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 2pB0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 4RX0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0|13e5');
var $isaacseymour$deprecated_time$Time$TimeZones$asia_yerevan = $isaacseymour$deprecated_time$Time$TimeZoneData$asia_yerevan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_azores_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Azores|HMT AZOT AZOST AZOMT AZOT AZOST WET|1S.w 20 10 0 10 0 0|01212121212121212121212121212121212121212121232123212321232121212121212121212121212121212121212121454545454545454545454545454545456545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-2ldW5.s aPX5.s Sp0 LX0 1vc0 Tc0 1uM0 SM0 1vc0 Tc0 1vc0 SM0 1vc0 6600 1co0 3E00 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 3I00 17c0 1cM0 1cM0 3Fc0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 1tA0 1cM0 1dc0 1400 gL0 IM0 s10 U00 dX0 Rc0 pd0 Rc0 gL0 Oo0 pd0 Rc0 gL0 Oo0 pd0 14o0 1cM0 1cP0 1cM0 1cM0 1cM0 1cM0 1cM0 3Co0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 qIl0 1cM0 1fA0 1cM0 1cM0 1cN0 1cL0 1cN0 1cM0 1cM0 1cM0 1cM0 1cN0 1cL0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cL0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|25e4');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_azores = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_azores_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_bermuda_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Bermuda|LMT AST ADT|4j.i 40 30|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1BnRE.G 1LTbE.G 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|65e3');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_bermuda = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_bermuda_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_canary_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Canary|LMT CANT WET WEST|11.A 10 0 -10|01232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-1UtaW.o XPAW.o 1lAK0 1a10 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|54e4');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_canary = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_canary_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_cape_verde_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Cape_Verde|LMT CVT CVST CVT|1y.4 20 10 10|01213|-2xomp.U 1qOMp.U 7zX0 1djf0|50e4');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_cape_verde = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_cape_verde_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_faroe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Faroe|LMT WET WEST|r.4 0 -10|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2uSnw.U 2Wgow.U 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|49e3');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_faeroe = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Atlantic/Faeroe', $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_faroe_l);
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_faroe = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_faroe_l;
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_jan_mayen = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Atlantic/Jan_Mayen', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_oslo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_madeira_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Madeira|FMT MADT MADST MADMT WET WEST|17.A 10 0 -10 0 -10|01212121212121212121212121212121212121212121232123212321232121212121212121212121212121212121212121454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-2ldWQ.o aPWQ.o Sp0 LX0 1vc0 Tc0 1uM0 SM0 1vc0 Tc0 1vc0 SM0 1vc0 6600 1co0 3E00 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 3I00 17c0 1cM0 1cM0 3Fc0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 1tA0 1cM0 1dc0 1400 gL0 IM0 s10 U00 dX0 Rc0 pd0 Rc0 gL0 Oo0 pd0 Rc0 gL0 Oo0 pd0 14o0 1cM0 1cP0 1cM0 1cM0 1cM0 1cM0 1cM0 3Co0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 qIl0 1cM0 1fA0 1cM0 1cM0 1cN0 1cL0 1cN0 1cM0 1cM0 1cM0 1cM0 1cN0 1cL0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|27e4');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_madeira = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_madeira_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_reykjavik_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Reykjavik|LMT IST ISST GMT|1s 10 0 0|012121212121212121212121212121212121212121212121212121212121212121213|-2uWmw mfaw 1Bd0 ML0 1LB0 Cn0 1LB0 3fX0 C10 HrX0 1cO0 LB0 1EL0 LA0 1C00 Oo0 1wo0 Rc0 1wo0 Rc0 1wo0 Rc0 1zc0 Oo0 1zc0 14o0 1lc0 14o0 1lc0 14o0 1o00 11A0 1lc0 14o0 1o00 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1o00 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1o00 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1lc0 14o0 1o00 14o0|12e4');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_reykjavik = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_reykjavik_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_south_georgia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/South_Georgia|GST|20|0||30');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_south_georgia = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_south_georgia_l;
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_st_helena = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Atlantic/St_Helena', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_abidjan_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_stanley_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Atlantic/Stanley|SMT FKT FKST FKT FKST|3P.o 40 30 30 20|0121212121212134343212121212121212121212121212121212121212121212121212|-2kJw8.A 12bA8.A 19X0 1fB0 19X0 1ip0 19X0 1fB0 19X0 1fB0 19X0 1fB0 Cn0 1Cc10 WL0 1qL0 U10 1tz0 U10 1qM0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1tz0 U10 1tz0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1tz0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qL0 WN0 1qN0 U10 1wn0 Rd0 1wn0 U10 1tz0 U10 1tz0 U10 1tz0 U10 1tz0 U10 1wn0 U10 1tz0 U10 1tz0 U10|21e2');
var $isaacseymour$deprecated_time$Time$TimeZones$atlantic_stanley = $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_stanley_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_sydney_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Sydney|AEST AEDT|-a0 -b0|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-293lX xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 14o0 1o00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 U00 1qM0 WM0 1tA0 WM0 1tA0 U00 1tA0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 11A0 1o00 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 14o0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|40e5');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_act = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/ACT', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_sydney_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_adelaide_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Adelaide|ACST ACDT|-9u -au|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-293lt xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 U00 1qM0 WM0 1tA0 WM0 1tA0 U00 1tA0 U00 1tA0 Oo0 1zc0 WM0 1qM0 Rc0 1zc0 U00 1tA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 14o0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|11e5');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_adelaide = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_adelaide_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_brisbane_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Brisbane|AEST AEDT|-a0 -b0|01010101010101010|-293lX xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 H1A0 Oo0 1zc0 Oo0 1zc0 Oo0|20e5');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_brisbane = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_brisbane_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_broken_hill_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Broken_Hill|ACST ACDT|-9u -au|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-293lt xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 14o0 1o00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 U00 1qM0 WM0 1tA0 WM0 1tA0 U00 1tA0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 14o0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|18e3');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_broken_hill = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_broken_hill_l;
var $isaacseymour$deprecated_time$Time$TimeZones$australia_canberra = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/Canberra', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_sydney_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_currie_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Currie|AEST AEDT|-a0 -b0|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-29E80 19X0 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 11A0 1qM0 WM0 1qM0 Oo0 1zc0 Oo0 1zc0 Oo0 1wo0 WM0 1tA0 WM0 1tA0 U00 1tA0 U00 1tA0 11A0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 11A0 1o00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|746');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_currie = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_currie_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_darwin_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Darwin|ACST ACDT|-9u -au|010101010|-293lt xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0|12e4');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_darwin = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_darwin_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_eucla_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Eucla|ACWST ACWDT|-8J -9J|0101010101010101010|-293kI xcX 10jd0 yL0 1cN0 1cL0 1gSp0 Oo0 l5A0 Oo0 iJA0 G00 zU00 IM0 1qM0 11A0 1o00 11A0|368');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_eucla = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_eucla_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_hobart_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Hobart|AEST AEDT|-a0 -b0|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-29E80 19X0 10jd0 yL0 1cN0 1cL0 1fB0 19X0 VfB0 1cM0 1o00 Rc0 1wo0 Rc0 1wo0 U00 1wo0 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 11A0 1qM0 WM0 1qM0 Oo0 1zc0 Oo0 1zc0 Oo0 1wo0 WM0 1tA0 WM0 1tA0 U00 1tA0 U00 1tA0 11A0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 11A0 1o00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|21e4');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_hobart = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_hobart_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_lord_howe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Lord_Howe|AEST LHST LHDT LHDT|-a0 -au -bu -b0|0121212121313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313131313|raC0 1zdu Rb0 1zd0 On0 1zd0 On0 1zd0 On0 1zd0 TXu 1qMu WLu 1tAu WLu 1tAu TXu 1tAu Onu 1zcu Onu 1zcu Onu 1zcu Rbu 1zcu Onu 1zcu Onu 1zcu 11zu 1o0u 11zu 1o0u 11zu 1o0u 11zu 1qMu WLu 11Au 1nXu 1qMu 11zu 1o0u 11zu 1o0u 11zu 1qMu WLu 1qMu 11zu 1o0u WLu 1qMu 14nu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1fAu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1fAu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1fzu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1fAu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1cMu 1cLu 1fAu 1cLu 1cMu 1cLu 1cMu|347');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_lhi = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/LHI', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_lord_howe_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_lindeman_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Lindeman|AEST AEDT|-a0 -b0|010101010101010101010|-293lX xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 H1A0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0|10');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_lindeman = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_lindeman_l;
var $isaacseymour$deprecated_time$Time$TimeZones$australia_lord_howe = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_lord_howe_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_melbourne_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Melbourne|AEST AEDT|-a0 -b0|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101|-293lX xcX 10jd0 yL0 1cN0 1cL0 1fB0 19X0 17c10 LA0 1C00 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 U00 1qM0 WM0 1qM0 11A0 1tA0 U00 1tA0 U00 1tA0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 11A0 1o00 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 14o0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0|39e5');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_melbourne = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_melbourne_l;
var $isaacseymour$deprecated_time$Time$TimeZones$australia_north = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/North', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_darwin_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_nsw = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/NSW', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_sydney_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$australia_perth_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Australia/Perth|AWST AWDT|-80 -90|0101010101010101010|-293jX xcX 10jd0 yL0 1cN0 1cL0 1gSp0 Oo0 l5A0 Oo0 iJA0 G00 zU00 IM0 1qM0 11A0 1o00 11A0|18e5');
var $isaacseymour$deprecated_time$Time$TimeZones$australia_perth = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_perth_l;
var $isaacseymour$deprecated_time$Time$TimeZones$australia_queensland = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/Queensland', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_brisbane_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_south = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/South', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_adelaide_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_sydney = $isaacseymour$deprecated_time$Time$TimeZoneData$australia_sydney_l;
var $isaacseymour$deprecated_time$Time$TimeZones$australia_tasmania = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/Tasmania', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_hobart_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_victoria = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/Victoria', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_melbourne_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_west = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/West', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_perth_l);
var $isaacseymour$deprecated_time$Time$TimeZones$australia_yancowinna = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Australia/Yancowinna', $isaacseymour$deprecated_time$Time$TimeZoneData$australia_broken_hill_l);
var $isaacseymour$deprecated_time$Time$TimeZones$brazil_acre = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Brazil/Acre', $isaacseymour$deprecated_time$Time$TimeZoneData$america_rio_branco_l);
var $isaacseymour$deprecated_time$Time$TimeZones$brazil_denoronha = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Brazil/DeNoronha', $isaacseymour$deprecated_time$Time$TimeZoneData$america_noronha_l);
var $isaacseymour$deprecated_time$Time$TimeZones$brazil_east = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Brazil/East', $isaacseymour$deprecated_time$Time$TimeZoneData$america_sao_paulo_l);
var $isaacseymour$deprecated_time$Time$TimeZones$brazil_west = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Brazil/West', $isaacseymour$deprecated_time$Time$TimeZoneData$america_manaus_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_atlantic = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Atlantic', $isaacseymour$deprecated_time$Time$TimeZoneData$america_halifax_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_central = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Central', $isaacseymour$deprecated_time$Time$TimeZoneData$america_winnipeg_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_east_saskatchewan = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/East-Saskatchewan', $isaacseymour$deprecated_time$Time$TimeZoneData$america_regina_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_eastern = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Eastern', $isaacseymour$deprecated_time$Time$TimeZoneData$america_toronto_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_mountain = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Mountain', $isaacseymour$deprecated_time$Time$TimeZoneData$america_edmonton_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_newfoundland = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Newfoundland', $isaacseymour$deprecated_time$Time$TimeZoneData$america_st_johns_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_pacific = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Pacific', $isaacseymour$deprecated_time$Time$TimeZoneData$america_vancouver_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_saskatchewan = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Saskatchewan', $isaacseymour$deprecated_time$Time$TimeZoneData$america_regina_l);
var $isaacseymour$deprecated_time$Time$TimeZones$canada_yukon = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Canada/Yukon', $isaacseymour$deprecated_time$Time$TimeZoneData$america_whitehorse_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$cet_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('CET|CET CEST|-10 -20|01010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 1cM0 1cM0 16M0 1gMM0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00');
var $isaacseymour$deprecated_time$Time$TimeZones$cet = $isaacseymour$deprecated_time$Time$TimeZoneData$cet_l;
var $isaacseymour$deprecated_time$Time$TimeZones$chile_continental = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Chile/Continental', $isaacseymour$deprecated_time$Time$TimeZoneData$america_santiago_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_easter_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Easter|EMT EAST EASST EAST EASST|7h.s 70 60 60 50|0121212121212121212121212121234343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434|-1uSgG.w 1s4IG.w WL0 1zd0 On0 1ip0 11z0 1o10 11z0 1qN0 WL0 1ld0 14n0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 WL0 1qN0 1cL0 1cN0 11z0 1o10 11z0 1qN0 WL0 1fB0 19X0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1ip0 1fz0 1fB0 11z0 1qN0 WL0 1qN0 WL0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 17b0 1ip0 11z0 1o10 19X0 1fB0 1nX0 G10 1EL0 Op0 1zb0 Rd0 1wn0 Rd0 46n0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Dd0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0 1Nb0 Ap0|30e2');
var $isaacseymour$deprecated_time$Time$TimeZones$chile_easterisland = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Chile/EasterIsland', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_easter_l);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $isaacseymour$deprecated_time$Time$TimeZoneData$cst6cdt_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('CST6CDT|CST CDT CWT CPT|60 50 50 50|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261s0 1nX0 11B0 1nX0 SgN0 8x30 iw0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$cst6cdt = $isaacseymour$deprecated_time$Time$TimeZoneData$cst6cdt_l;
var $isaacseymour$deprecated_time$Time$TimeZones$cuba = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Cuba', $isaacseymour$deprecated_time$Time$TimeZoneData$america_havana_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$eet_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('EET|EET EEST|-20 -30|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|hDB0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00');
var $isaacseymour$deprecated_time$Time$TimeZones$eet = $isaacseymour$deprecated_time$Time$TimeZoneData$eet_l;
var $isaacseymour$deprecated_time$Time$TimeZones$egypt = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Egypt', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_cairo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_dublin_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Dublin|DMT IST GMT BST IST|p.l -y.D 0 -10 -10|01232323232324242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242424242|-2ax9y.D Rc0 1fzy.D 14M0 1fc0 1g00 1co0 1dc0 1co0 1oo0 1400 1dc0 19A0 1io0 1io0 WM0 1o00 14o0 1o00 17c0 1io0 17c0 1fA0 1a00 1lc0 17c0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1cM0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1io0 1qM0 Dc0 g5X0 14p0 1wn0 17d0 1io0 11A0 1o00 17c0 1fA0 1a00 1fA0 1cM0 1fA0 1a00 17c0 1fA0 1a00 1io0 17c0 1lc0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1a00 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1tA0 IM0 90o0 U00 1tA0 U00 1tA0 U00 1tA0 U00 1tA0 WM0 1qM0 WM0 1qM0 WM0 1tA0 U00 1tA0 U00 1tA0 11z0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 14o0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$eire = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Eire', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_dublin_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$est_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('EST|EST|50|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$est = $isaacseymour$deprecated_time$Time$TimeZoneData$est_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$est5edt_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('EST5EDT|EST EDT EWT EPT|50 40 40 40|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 SgN0 8x40 iv0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$est5edt = $isaacseymour$deprecated_time$Time$TimeZoneData$est5edt_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+0|GMT|0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/GMT', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_0 = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/GMT0', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_0 = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/GMT-0', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_1_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-1|+01|-10|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_1 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_1_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_10_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-10|+10|-a0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_10 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_10_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_11_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-11|+11|-b0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_11 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_11_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_12_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-12|+12|-c0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_12 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_12_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_13_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-13|+13|-d0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_13 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_13_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_14_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-14|+14|-e0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_14 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_14_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_2_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-2|+02|-20|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_2 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_2_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_3_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-3|+03|-30|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_3 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_3_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_4_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-4|+04|-40|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_4 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_4_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_5_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-5|+05|-50|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_5 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_5_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_6_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-6|+06|-60|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_6 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_6_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_7_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-7|+07|-70|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_7 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_7_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_8_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-8|+08|-80|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_8 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_8_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_9_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT-9|+09|-90|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_9 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_minus_9_l;
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_0 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_1_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+1|-01|10|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_1 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_1_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_10_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+10|-10|a0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_10 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_10_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_11_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+11|-11|b0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_11 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_11_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_12_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+12|-12|c0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_12 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_12_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_2_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+2|-02|20|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_2 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_2_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_3_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+3|-03|30|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_3 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_3_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_4_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+4|-04|40|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_4 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_4_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_5_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+5|-05|50|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_5 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_5_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_6_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+6|-06|60|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_6 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_6_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_7_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+7|-07|70|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_7 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_7_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_8_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+8|-08|80|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_8 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_8_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_9_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/GMT+9|-09|90|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_9 = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_9_l;
var $isaacseymour$deprecated_time$Time$TimeZones$etc_greenwich = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/Greenwich', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_uct_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/UCT|UCT|0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_uct = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_uct_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Etc/UTC|UTC|0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$etc_universal = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/Universal', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l);
var $isaacseymour$deprecated_time$Time$TimeZones$etc_utc = $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l;
var $isaacseymour$deprecated_time$Time$TimeZones$etc_zulu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Etc/Zulu', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_amsterdam_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Amsterdam|AMT NST NEST NET CEST CET|-j.w -1j.w -1k -k -20 -10|010101010101010101010101010101010101010101012323234545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545|-2aFcj.w 11b0 1iP0 11A0 1io0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1co0 1io0 1yo0 Pc0 1a00 1fA0 1Bc0 Mo0 1tc0 Uo0 1tA0 U00 1uo0 W00 1s00 VA0 1so0 Vc0 1sM0 UM0 1wo0 Rc0 1u00 Wo0 1rA0 W00 1s00 VA0 1sM0 UM0 1w00 fV0 BCX.w 1tA0 U00 1u00 Wo0 1sm0 601k WM0 1fA0 1cM0 1cM0 1cM0 16M0 1gMM0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|16e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_amsterdam = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_amsterdam_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_andorra_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Andorra|WET CET CEST|0 -10 -20|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-UBA0 1xIN0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|79e3');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_andorra = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_andorra_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_astrakhan_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Astrakhan|LMT +03 +04 +05|-3c.c -30 -40 -50|012323232323232323212121212121212121212121212121212121212121212|-1Pcrc.c eUMc.c 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1fA0 1cM0 3Co0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3rd0');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_astrakhan = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_astrakhan_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_athens_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Athens|AMT EET EEST CEST CET|-1y.Q -20 -30 -20 -10|012123434121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2a61x.Q CNbx.Q mn0 kU10 9b0 3Es0 Xa0 1fb0 1dd0 k3X0 Nz0 SCp0 1vc0 SO0 1cM0 1a00 1ao0 1fc0 1a10 1fG0 1cg0 1dX0 1bX0 1cQ0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|35e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_athens = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_athens_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/London|GMT BST BDST|0 -10 -20|0101010101010101010101010101010101010101010101010121212121210101210101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2axa0 Rc0 1fA0 14M0 1fc0 1g00 1co0 1dc0 1co0 1oo0 1400 1dc0 19A0 1io0 1io0 WM0 1o00 14o0 1o00 17c0 1io0 17c0 1fA0 1a00 1lc0 17c0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1cM0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1io0 1qM0 Dc0 2Rz0 Dc0 1zc0 Oo0 1zc0 Rc0 1wo0 17c0 1iM0 FA0 xB0 1fA0 1a00 14o0 bb0 LA0 xB0 Rc0 1wo0 11A0 1o00 17c0 1fA0 1a00 1fA0 1cM0 1fA0 1a00 17c0 1fA0 1a00 1io0 17c0 1lc0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1a00 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1tA0 IM0 90o0 U00 1tA0 U00 1tA0 U00 1tA0 U00 1tA0 WM0 1qM0 WM0 1qM0 WM0 1tA0 U00 1tA0 U00 1tA0 11z0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 14o0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|10e6');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_belfast = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Belfast', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Belgrade|CET CEST|-10 -20|01010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-19RC0 3IP0 WM0 1fA0 1cM0 1cM0 1rc0 Qo0 1vmo0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_belgrade = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_berlin_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Berlin|CET CEST CEMT|-10 -20 -30|01010101010101210101210101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 1cM0 1cM0 kL0 Nc0 m10 WM0 1ao0 1cp0 dX0 jz0 Dd0 1io0 17c0 1fA0 1a00 1ehA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|41e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_berlin = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_berlin_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_prague_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Prague|CET CEST|-10 -20|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 16M0 1lc0 1tA0 17A0 11c0 1io0 17c0 1io0 17c0 1fc0 1ao0 1bNc0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|13e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_bratislava = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Bratislava', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_prague_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_brussels_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Brussels|WET CET CEST WEST|0 -10 -20 -10|0121212103030303030303030303030303030303030303030303212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2ehc0 3zX0 11c0 1iO0 11A0 1o00 11A0 my0 Ic0 1qM0 Rc0 1EM0 UM0 1u00 10o0 1io0 1io0 17c0 1a00 1fA0 1cM0 1cM0 1io0 17c0 1fA0 1a00 1io0 1a30 1io0 17c0 1fA0 1a00 1io0 17c0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 y00 5Wn0 WM0 1fA0 1cM0 16M0 1iM0 16M0 1C00 Uo0 1eeo0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|21e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_brussels = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_brussels_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_bucharest_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Bucharest|BMT EET EEST|-1I.o -20 -30|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1xApI.o 20LI.o RA0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1Axc0 On0 1fA0 1a10 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cK0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cL0 1cN0 1cL0 1fB0 1nX0 11E0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|19e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_bucharest = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_bucharest_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_budapest_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Budapest|CET CEST|-10 -20|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1ip0 17b0 1op0 1tb0 Q2m0 3Ne0 WM0 1fA0 1cM0 1cM0 1oJ0 1dc0 1030 1fA0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1iM0 1fA0 8Ha0 Rb0 1wN0 Rb0 1BB0 Lz0 1C20 LB0 SNX0 1a10 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|17e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_budapest = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_budapest_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zurich_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Zurich|CET CEST|-10 -20|01010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-19Lc0 11A0 1o00 11A0 1xG10 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|38e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_busingen = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Busingen', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zurich_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_chisinau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Chisinau|CMT BMT EET EEST CEST CET MSK MSD|-1T -1I.o -20 -30 -20 -10 -30 -40|012323232323232323234545467676767676767676767323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232323232|-26jdT wGMa.A 20LI.o RA0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 27A0 2en0 39g0 WM0 1fA0 1cM0 V90 1t7z0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 gL0 WO0 1cM0 1cM0 1cK0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1nX0 11D0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|67e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_chisinau = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_chisinau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_copenhagen_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Copenhagen|CET CEST|-10 -20|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2azC0 Tz0 VuO0 60q0 WM0 1fA0 1cM0 1cM0 1cM0 S00 1HA0 Nc0 1C00 Dc0 1Nc0 Ao0 1h5A0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_copenhagen = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_copenhagen_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_dublin = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_dublin_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_gibraltar_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Gibraltar|GMT BST BDST CET CEST|0 -10 -20 -10 -20|010101010101010101010101010101010101010101010101012121212121010121010101010101010101034343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343|-2axa0 Rc0 1fA0 14M0 1fc0 1g00 1co0 1dc0 1co0 1oo0 1400 1dc0 19A0 1io0 1io0 WM0 1o00 14o0 1o00 17c0 1io0 17c0 1fA0 1a00 1lc0 17c0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1cM0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1io0 1qM0 Dc0 2Rz0 Dc0 1zc0 Oo0 1zc0 Rc0 1wo0 17c0 1iM0 FA0 xB0 1fA0 1a00 14o0 bb0 LA0 xB0 Rc0 1wo0 11A0 1o00 17c0 1fA0 1a00 1fA0 1cM0 1fA0 1a00 17c0 1fA0 1a00 1io0 17c0 1lc0 17c0 1fA0 10Jz0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|30e3');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_gibraltar = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_gibraltar_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_guernsey = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Guernsey', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_helsinki_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Helsinki|HMT EET EEST|-1D.N -20 -30|0121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-1WuND.N OULD.N 1dA0 1xGq0 1cM0 1cM0 1cM0 1cN0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_helsinki = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_helsinki_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_isle_of_man = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Isle_of_Man', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_istanbul = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_istanbul_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_jersey = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Jersey', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kaliningrad_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Kaliningrad|CET CEST CET CEST MSK MSD EEST EET +03|-10 -20 -20 -30 -30 -40 -30 -20 -30|0101010101010232454545454545454546767676767676767676767676767676767676767676787|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 1cM0 Am0 Lb0 1en0 op0 1pNz0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|44e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_kaliningrad = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kaliningrad_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kiev_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Kiev|KMT EET MSK CEST CET MSD EEST|-22.4 -20 -30 -20 -10 -40 -30|0123434252525252525252525256161616161616161616161616161616161616161616161616161616161616161616161616161616161616161616161|-1Pc22.4 eUo2.4 rnz0 2Hg0 WM0 1fA0 da0 1v4m0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 Db0 3220 1cK0 1cL0 1cN0 1cL0 1cN0 1cL0 1cQ0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|34e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_kiev = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kiev_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kirov_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Kirov|LMT +03 +04 +05|-3i.M -30 -40 -50|01232323232323232321212121212121212121212121212121212121212121|-22WM0 qH90 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1fA0 1cM0 3Co0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|48e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_kirov = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_kirov_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_lisbon_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Lisbon|LMT WET WEST WEMT CET CEST|A.J 0 -10 -20 -10 -20|012121212121212121212121212121212121212121212321232123212321212121212121212121212121212121212121214121212121212121212121212121212124545454212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2ldXn.f aPWn.f Sp0 LX0 1vc0 Tc0 1uM0 SM0 1vc0 Tc0 1vc0 SM0 1vc0 6600 1co0 3E00 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 3I00 17c0 1cM0 1cM0 3Fc0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 1tA0 1cM0 1dc0 1400 gL0 IM0 s10 U00 dX0 Rc0 pd0 Rc0 gL0 Oo0 pd0 Rc0 gL0 Oo0 pd0 14o0 1cM0 1cP0 1cM0 1cM0 1cM0 1cM0 1cM0 3Co0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 pvy0 1cM0 1cM0 1fA0 1cM0 1cM0 1cN0 1cL0 1cN0 1cM0 1cM0 1cM0 1cM0 1cN0 1cL0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|27e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_lisbon = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_lisbon_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_ljubljana = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Ljubljana', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_london = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_luxembourg_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Luxembourg|LMT CET CEST WET WEST WEST WET|-o.A -10 -20 0 -10 -20 -10|0121212134343434343434343434343434343434343434343434565651212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2DG0o.A t6mo.A TB0 1nX0 Up0 1o20 11A0 rW0 CM0 1qP0 R90 1EO0 UK0 1u20 10m0 1ip0 1in0 17e0 19W0 1fB0 1db0 1cp0 1in0 17d0 1fz0 1a10 1in0 1a10 1in0 17f0 1fA0 1a00 1io0 17c0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Dc0 vA0 60L0 WM0 1fA0 1cM0 17c0 1io0 16M0 1C00 Uo0 1eeo0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|54e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_luxembourg = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_luxembourg_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_madrid_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Madrid|WET WEST WEMT CET CEST|0 -10 -20 -10 -20|01010101010101010101010121212121234343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343|-28dd0 11A0 1go0 19A0 1co0 1dA0 b1A0 18o0 3I00 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 iyo0 Rc0 18o0 1hc0 1io0 1a00 14o0 5aL0 MM0 1vc0 17A0 1i00 1bc0 1eo0 17d0 1in0 17A0 6hA0 10N0 XIL0 1a10 1in0 17d0 19X0 1cN0 1fz0 1a10 1fX0 1cp0 1cO0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|62e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_madrid = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_madrid_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_malta_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Malta|CET CEST|-10 -20|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2arB0 Lz0 1cN0 1db0 1410 1on0 Wp0 1qL0 17d0 1cL0 M3B0 5M20 WM0 1fA0 1co0 17c0 1iM0 16m0 1de0 1lc0 14m0 1lc0 WO0 1qM0 GTW0 On0 1C10 LA0 1C00 LA0 1EM0 LA0 1C00 LA0 1zc0 Oo0 1C00 Oo0 1co0 1cM0 1lA0 Xc0 1qq0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1iN0 19z0 1fB0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|42e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_malta = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_malta_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_mariehamn = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Mariehamn', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_helsinki_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_minsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Minsk|MMT EET MSK CEST CET MSD EEST +03|-1O -20 -30 -20 -10 -40 -30 -30|01234343252525252525252525261616161616161616161616161616161616161617|-1Pc1O eUnO qNX0 3gQ0 WM0 1fA0 1cM0 Al0 1tsn0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 3Fc0 1cN0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0|19e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_minsk = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_minsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_monaco_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Monaco|PMT WET WEST WEMT CET CEST|-9.l 0 -10 -20 -10 -20|01212121212121212121212121212121212121212121212121232323232345454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-2nco9.l cNb9.l HA0 19A0 1iM0 11c0 1oo0 Wo0 1rc0 QM0 1EM0 UM0 1u00 10o0 1io0 1wo0 Rc0 1a00 1fA0 1cM0 1cM0 1io0 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 1fA0 1a00 1io0 17c0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Df0 2RV0 11z0 11B0 1ze0 WM0 1fA0 1cM0 1fa0 1aq0 16M0 1ekn0 1cL0 1fC0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|38e3');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_monaco = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_monaco_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_moscow_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Moscow|MMT MMT MST MDST MSD MSK +05 EET EEST MSK|-2u.h -2v.j -3v.j -4v.j -40 -30 -50 -20 -30 -40|012132345464575454545454545454545458754545454545454545454545454545454545454595|-2ag2u.h 2pyW.W 1bA0 11X0 GN0 1Hb0 c4v.j ik0 3DA0 dz0 15A0 c10 2q10 iM10 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cN0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|16e6');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_moscow = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_moscow_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_nicosia = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Nicosia', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_nicosia_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_oslo = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_oslo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_paris_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Paris|PMT WET WEST CEST CET WEMT|-9.l 0 -10 -20 -10 -20|0121212121212121212121212121212121212121212121212123434352543434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434343434|-2nco8.l cNb8.l HA0 19A0 1iM0 11c0 1oo0 Wo0 1rc0 QM0 1EM0 UM0 1u00 10o0 1io0 1wo0 Rc0 1a00 1fA0 1cM0 1cM0 1io0 17c0 1fA0 1a00 1io0 1a00 1io0 17c0 1fA0 1a00 1io0 17c0 1cM0 1cM0 1a00 1io0 1cM0 1cM0 1a00 1fA0 1io0 17c0 1cM0 1cM0 1a00 1fA0 1io0 1qM0 Df0 Ik0 5M30 WM0 1fA0 1cM0 Vx0 hB0 1aq0 16M0 1ekn0 1cL0 1fC0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|11e6');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_paris = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_paris_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_podgorica = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Podgorica', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_prague = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_prague_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_riga_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Riga|RMT LST EET MSK CEST CET MSD EEST|-1A.y -2A.y -20 -30 -20 -10 -40 -30|010102345454536363636363636363727272727272727272727272727272727272727272727272727272727272727272727272727272727272727272727272|-25TzA.y 11A0 1iM0 ko0 gWm0 yDXA.y 2bX0 3fE0 WM0 1fA0 1cM0 1cM0 4m0 1sLy0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cN0 1o00 11A0 1o00 11A0 1qM0 3oo0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|64e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_riga = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_riga_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_rome_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Rome|CET CEST|-10 -20|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2arB0 Lz0 1cN0 1db0 1410 1on0 Wp0 1qL0 17d0 1cL0 M3B0 5M20 WM0 1fA0 1cM0 16M0 1iM0 16m0 1de0 1lc0 14m0 1lc0 WO0 1qM0 GTW0 On0 1C10 LA0 1C00 LA0 1EM0 LA0 1C00 LA0 1zc0 Oo0 1C00 Oo0 1C00 LA0 1zc0 Oo0 1C00 LA0 1C00 LA0 1zc0 Oo0 1C00 Oo0 1zc0 Oo0 1fC0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|39e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_rome = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_rome_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_samara_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Samara|LMT +03 +04 +05|-3k.k -30 -40 -50|0123232323232323232121232323232323232323232323232323232323212|-22WM0 qH90 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1fA0 2y10 14m0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 2sp0 WM0|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_samara = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_samara_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_san_marino = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/San_Marino', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_rome_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_sarajevo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Sarajevo', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_simferopol_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Simferopol|SMT EET MSK CEST CET MSD EEST MSK|-2g -20 -30 -20 -10 -40 -30 -40|012343432525252525252525252161616525252616161616161616161616161616161616172|-1Pc2g eUog rEn0 2qs0 WM0 1fA0 1cM0 3V0 1u0L0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1Q00 4eL0 1cL0 1cN0 1cL0 1cN0 dX0 WL0 1cN0 1cL0 1fB0 1o30 11B0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11z0 1nW0|33e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_simferopol = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_simferopol_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_skopje = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Skopje', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_sofia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Sofia|EET CET CEST EEST|-20 -10 -20 -30|01212103030303030303030303030303030303030303030303030303030303030303030303030303030303030303030303030303030303030303030303030|-168L0 WM0 1fA0 1cM0 1cM0 1cN0 1mKH0 1dd0 1fb0 1ap0 1fb0 1a20 1fy0 1a30 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cK0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 1nX0 11E0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|12e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_sofia = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_sofia_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_stockholm_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Stockholm|CET CEST|-10 -20|01010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2azC0 TB0 2yDe0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|15e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_stockholm = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_stockholm_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_tallinn_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Tallinn|TMT CET CEST EET MSK MSD EEST|-1D -10 -20 -20 -30 -40 -30|012103421212454545454545454546363636363636363636363636363636363636363636363636363636363636363636363636363636363636363636363|-26oND teD 11A0 1Ta0 4rXl KSLD 2FX0 2Jg0 WM0 1fA0 1cM0 18J0 1sTX0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o10 11A0 1qM0 5QM0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|41e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_tallinn = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_tallinn_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_tirane_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Tirane|LMT CET CEST|-1j.k -10 -20|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2glBj.k 14pcj.k 5LC0 WM0 4M0 1fCK0 10n0 1op0 11z0 1pd0 11z0 1qN0 WL0 1qp0 Xb0 1qp0 Xb0 1qp0 11z0 1lB0 11z0 1qN0 11z0 1iN0 16n0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|42e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_tirane = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_tirane_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_tiraspol = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Tiraspol', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_chisinau_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_ulyanovsk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Ulyanovsk|LMT +03 +04 +05 +02|-3d.A -30 -40 -50 -20|01232323232323232321214121212121212121212121212121212121212121212|-22WM0 qH90 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1fA0 2pB0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0 3rd0');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_ulyanovsk = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_ulyanovsk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_uzhgorod_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Uzhgorod|CET CEST MSK MSD EET EEST|-10 -20 -30 -40 -20 -30|010101023232323232323232320454545454545454545454545454545454545454545454545454545454545454545454545454545454545454545454|-1cqL0 6i00 WM0 1fA0 1cM0 1ml0 1Cp0 1r3W0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1Q00 1Nf0 2pw0 1cL0 1cN0 1cL0 1cN0 1cL0 1cQ0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|11e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_uzhgorod = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_uzhgorod_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_vaduz = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Vaduz', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zurich_l);
var $isaacseymour$deprecated_time$Time$TimeZones$europe_vatican = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Vatican', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_rome_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_vienna_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Vienna|CET CEST|-10 -20|0101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 3KM0 14o0 LA00 6i00 WM0 1fA0 1cM0 1cM0 1cM0 400 2qM0 1a00 1cM0 1cM0 1io0 17c0 1gHa0 19X0 1cP0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|18e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_vienna = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_vienna_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_vilnius_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Vilnius|WMT KMT CET EET MSK CEST MSD EEST|-1o -1z.A -10 -20 -30 -20 -40 -30|012324525254646464646464646473737373737373737352537373737373737373737373737373737373737373737373737373737373737373737373|-293do 6ILM.o 1Ooz.A zz0 Mfd0 29W0 3is0 WM0 1fA0 1cM0 LV0 1tgL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11B0 1o00 11A0 1qM0 8io0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|54e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_vilnius = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_vilnius_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_volgograd_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Volgograd|LMT +03 +04 +05|-2V.E -30 -40 -50|01232323232323232121212121212121212121212121212121212121212121|-21IqV.E psLV.E 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 2pB0 1cM0 1cM0 1cM0 1fA0 1cM0 3Co0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|10e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_volgograd = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_volgograd_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_warsaw_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Warsaw|WMT CET CEST EET EEST|-1o -10 -20 -20 -30|012121234312121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2ctdo 1LXo 11d0 1iO0 11A0 1o00 11A0 1on0 11A0 6zy0 HWP0 5IM0 WM0 1fA0 1cM0 1dz0 1mL0 1en0 15B0 1aq0 1nA0 11A0 1io0 17c0 1fA0 1a00 iDX0 LA0 1cM0 1cM0 1C00 Oo0 1cM0 1cM0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1C00 LA0 uso0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cN0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|17e5');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_warsaw = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_warsaw_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_zagreb = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Europe/Zagreb', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_belgrade_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zaporozhye_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Europe/Zaporozhye|CUT EET MSK CEST CET MSD EEST|-2k -20 -30 -20 -10 -40 -30|01234342525252525252525252526161616161616161616161616161616161616161616161616161616161616161616161616161616161616161616161|-1Pc2k eUok rdb0 2RE0 WM0 1fA0 8m0 1v9a0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cK0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cQ0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|77e4');
var $isaacseymour$deprecated_time$Time$TimeZones$europe_zaporozhye = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zaporozhye_l;
var $isaacseymour$deprecated_time$Time$TimeZones$europe_zurich = $isaacseymour$deprecated_time$Time$TimeZoneData$europe_zurich_l;
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $isaacseymour$deprecated_time$Time$TimeZones$gb = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GB', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZones$gb_eire = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GB-Eire', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_london_l);
var $isaacseymour$deprecated_time$Time$TimeZones$gmt = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GMT', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$gmt_0 = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GMT0', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$gmt_minus_0 = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GMT-0', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$gmt_plus_0 = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'GMT+0', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$greenwich = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Greenwich', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_gmt_plus_0_l);
var $isaacseymour$deprecated_time$Time$TimeZones$hongkong = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Hongkong', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_hong_kong_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$hst_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('HST|HST|a0|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$hst = $isaacseymour$deprecated_time$Time$TimeZoneData$hst_l;
var $isaacseymour$deprecated_time$Time$TimeZones$iceland = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Iceland', $isaacseymour$deprecated_time$Time$TimeZoneData$atlantic_reykjavik_l);
var $isaacseymour$deprecated_time$Time$TimeZones$indian_antananarivo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Indian/Antananarivo', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_chagos_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Chagos|LMT IOT IOT|-4N.E -50 -60|012|-2xosN.E 3AGLN.E|30e2');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_chagos = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_chagos_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_christmas_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Christmas|CXT|-70|0||21e2');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_christmas = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_christmas_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_cocos_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Cocos|CCT|-6u|0||596');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_cocos = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_cocos_l;
var $isaacseymour$deprecated_time$Time$TimeZones$indian_comoro = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Indian/Comoro', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_kerguelen_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Kerguelen|-00 +05|0 -50|01|-MG00|130');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_kerguelen = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_kerguelen_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_mahe_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Mahe|LMT SCT|-3F.M -40|01|-2yO3F.M|79e3');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_mahe = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_mahe_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_maldives_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Maldives|MMT MVT|-4S -50|01|-olgS|35e4');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_maldives = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_maldives_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_mauritius_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Mauritius|LMT MUT MUST|-3O -40 -50|012121|-2xorO 34unO 14L0 12kr0 11z0|15e4');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_mauritius = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_mauritius_l;
var $isaacseymour$deprecated_time$Time$TimeZones$indian_mayotte = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Indian/Mayotte', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_nairobi_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$indian_reunion_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Indian/Reunion|LMT RET|-3F.Q -40|01|-2mDDF.Q|84e4');
var $isaacseymour$deprecated_time$Time$TimeZones$indian_reunion = $isaacseymour$deprecated_time$Time$TimeZoneData$indian_reunion_l;
var $isaacseymour$deprecated_time$Time$TimeZones$iran = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Iran', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tehran_l);
var $isaacseymour$deprecated_time$Time$TimeZones$israel = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Israel', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_jerusalem_l);
var $isaacseymour$deprecated_time$Time$TimeZones$jamaica = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Jamaica', $isaacseymour$deprecated_time$Time$TimeZoneData$america_jamaica_l);
var $isaacseymour$deprecated_time$Time$TimeZones$japan = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Japan', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_tokyo_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kwajalein_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Kwajalein|MHT KWAT MHT|-b0 c0 -c0|012|-AX0 W9X0|14e3');
var $isaacseymour$deprecated_time$Time$TimeZones$kwajalein = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Kwajalein', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kwajalein_l);
var $isaacseymour$deprecated_time$Time$TimeZones$libya = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Libya', $isaacseymour$deprecated_time$Time$TimeZoneData$africa_tripoli_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$met_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('MET|MET MEST|-10 -20|01010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2aFe0 11d0 1iO0 11A0 1o00 11A0 Qrc0 6i00 WM0 1fA0 1cM0 1cM0 1cM0 16M0 1gMM0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00');
var $isaacseymour$deprecated_time$Time$TimeZones$met = $isaacseymour$deprecated_time$Time$TimeZoneData$met_l;
var $isaacseymour$deprecated_time$Time$TimeZones$mexico_bajanorte = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Mexico/BajaNorte', $isaacseymour$deprecated_time$Time$TimeZoneData$america_tijuana_l);
var $isaacseymour$deprecated_time$Time$TimeZones$mexico_bajasur = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Mexico/BajaSur', $isaacseymour$deprecated_time$Time$TimeZoneData$america_mazatlan_l);
var $isaacseymour$deprecated_time$Time$TimeZones$mexico_general = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Mexico/General', $isaacseymour$deprecated_time$Time$TimeZoneData$america_mexico_city_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$mst_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('MST|MST|70|0|');
var $isaacseymour$deprecated_time$Time$TimeZones$mst = $isaacseymour$deprecated_time$Time$TimeZoneData$mst_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$mst7mdt_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('MST7MDT|MST MDT MWT MPT|70 60 60 60|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261r0 1nX0 11B0 1nX0 SgN0 8x20 ix0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$mst7mdt = $isaacseymour$deprecated_time$Time$TimeZoneData$mst7mdt_l;
var $isaacseymour$deprecated_time$Time$TimeZones$navajo = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Navajo', $isaacseymour$deprecated_time$Time$TimeZoneData$america_denver_l);
var $isaacseymour$deprecated_time$Time$TimeZones$nz = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'NZ', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_auckland_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chatham_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Chatham|CHAST CHAST CHADT|-cf -cJ -dJ|012121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121212|-WqAf 1adef IM0 1C00 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Oo0 1zc0 Rc0 1zc0 Oo0 1qM0 14o0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1io0 17c0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1lc0 14o0 1lc0 14o0 1lc0 17c0 1io0 17c0 1io0 17c0 1io0 17c0 1io0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00|600');
var $isaacseymour$deprecated_time$Time$TimeZones$nz_chat = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'NZ-CHAT', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chatham_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_apia_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Apia|LMT WSST SST SDT WSDT WSST|bq.U bu b0 a0 -e0 -d0|01232345454545454545454545454545454545454545454545454545454|-2nDMx.4 1yW03.4 2rRbu 1ff0 1a00 CI0 AQ0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1io0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1a00 1fA0 1cM0 1fA0 1a00 1fA0 1a00|37e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_apia = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_apia_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_auckland = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_auckland_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_bougainville_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Bougainville|PGT JST BST|-a0 -90 -b0|0102|-16Wy0 7CN0 2MQp0|18e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_bougainville = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_bougainville_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_chatham = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chatham_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chuuk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Chuuk|CHUT|-a0|0||49e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_chuuk = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chuuk_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_easter = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_easter_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_efate_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Efate|LMT VUT VUST|-bd.g -b0 -c0|0121212121212121212121|-2l9nd.g 2Szcd.g 1cL0 1oN0 10L0 1fB0 19X0 1fB0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1fB0 Lz0 1Nd0 An0|66e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_efate = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_efate_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_enderbury_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Enderbury|PHOT PHOT PHOT|c0 b0 -d0|012|nIc0 B8n0|1');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_enderbury = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_enderbury_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_fakaofo_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Fakaofo|TKT TKT|b0 -d0|01|1Gfn0|483');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_fakaofo = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_fakaofo_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_fiji_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Fiji|LMT FJT FJST|-bT.I -c0 -d0|0121212121212121212121212121212121212121212121212121212121212121|-2bUzT.I 3m8NT.I LA0 1EM0 IM0 nJc0 LA0 1o00 Rc0 1wo0 Ao0 1Nc0 Ao0 1Q00 xz0 1SN0 uM0 1SM0 uM0 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0|88e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_fiji = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_fiji_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_funafuti_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Funafuti|TVT|-c0|0||45e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_funafuti = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_funafuti_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_galapagos_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Galapagos|LMT ECT GALT|5W.o 50 60|012|-1yVS1.A 2dTz1.A|25e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_galapagos = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_galapagos_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_gambier_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Gambier|LMT GAMT|8X.M 90|01|-2jof0.c|125');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_gambier = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_gambier_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_guadalcanal_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Guadalcanal|LMT SBT|-aD.M -b0|01|-2joyD.M|11e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_guadalcanal = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_guadalcanal_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_guam_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Guam|GST ChST|-a0 -a0|01|1fpq0|17e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_guam = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_guam_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_honolulu_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Honolulu|HST HDT HST|au 9u a0|010102|-1thLu 8x0 lef0 8Pz0 46p0|37e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_honolulu = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_honolulu_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_johnston = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Johnston', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_honolulu_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kiritimati_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Kiritimati|LINT LINT LINT|aE a0 -e0|012|nIaE B8nk|51e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_kiritimati = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kiritimati_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kosrae_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Kosrae|KOST KOST|-b0 -c0|010|-AX0 1bdz0|66e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_kosrae = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kosrae_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_kwajalein = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_kwajalein_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_majuro_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Majuro|MHT MHT|-b0 -c0|01|-AX0|28e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_majuro = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_majuro_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_marquesas_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Marquesas|LMT MART|9i 9u|01|-2joeG|86e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_marquesas = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_marquesas_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pago_pago_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Pago_Pago|LMT NST BST SST|bm.M b0 b0 b0|0123|-2nDMB.c 2gVzB.c EyM0|37e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_midway = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Midway', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pago_pago_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_nauru_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Nauru|LMT NRT JST NRT|-b7.E -bu -90 -c0|01213|-1Xdn7.E PvzB.E 5RCu 1ouJu|10e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_nauru = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_nauru_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_niue_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Niue|NUT NUT NUT|bk bu b0|012|-KfME 17y0a|12e2');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_niue = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_niue_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_norfolk_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Norfolk|NMT NFT NFST NFT|-bc -bu -cu -b0|01213|-Kgbc W01G On0 1COp0|25e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_norfolk = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_norfolk_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_noumea_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Noumea|LMT NCT NCST|-b5.M -b0 -c0|01212121|-2l9n5.M 2EqM5.M xX0 1PB0 yn0 HeP0 Ao0|98e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_noumea = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_noumea_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_pago_pago = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pago_pago_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_palau_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Palau|PWT|-90|0||21e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_palau = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_palau_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pitcairn_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Pitcairn|PNT PST|8u 80|01|18Vku|56');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_pitcairn = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pitcairn_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pohnpei_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Pohnpei|PONT|-b0|0||34e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_pohnpei = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pohnpei_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_ponape = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Ponape', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pohnpei_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_port_moresby_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Port_Moresby|PGT|-a0|0||25e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_port_moresby = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_port_moresby_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_rarotonga_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Rarotonga|CKT CKHST CKT|au 9u a0|012121212121212121212121212|lyWu IL0 1zcu Onu 1zcu Onu 1zcu Rbu 1zcu Onu 1zcu Onu 1zcu Onu 1zcu Onu 1zcu Onu 1zcu Rbu 1zcu Onu 1zcu Onu 1zcu Onu|13e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_rarotonga = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_rarotonga_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_saipan = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Saipan', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_guam_l);
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_samoa = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Samoa', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pago_pago_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tahiti_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Tahiti|LMT TAHT|9W.g a0|01|-2joe1.I|18e4');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_tahiti = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tahiti_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tarawa_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Tarawa|GILT|-c0|0||29e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_tarawa = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tarawa_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tongatapu_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Tongatapu|+1220 +13 +14|-ck -d0 -e0|0121212121212121212121212121212121212121212121212121|-1aB0k 2n5dk 15A0 1wo0 xz0 1Q10 xz0 zWN0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0 1VA0 s00 1VA0 s00 1VA0 uM0 1SM0 uM0 1SM0 uM0 1SM0 uM0|75e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_tongatapu = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_tongatapu_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_truk = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Truk', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chuuk_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_wake_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Wake|WAKT|-c0|0||16e3');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_wake = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_wake_l;
var $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_wallis_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('Pacific/Wallis|WFT|-c0|0||94');
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_wallis = $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_wallis_l;
var $isaacseymour$deprecated_time$Time$TimeZones$pacific_yap = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Pacific/Yap', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_chuuk_l);
var $isaacseymour$deprecated_time$Time$TimeZones$poland = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Poland', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_warsaw_l);
var $isaacseymour$deprecated_time$Time$TimeZones$portugal = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Portugal', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_lisbon_l);
var $isaacseymour$deprecated_time$Time$TimeZones$prc = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'PRC', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_shanghai_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$pst8pdt_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('PST8PDT|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 QwN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0');
var $isaacseymour$deprecated_time$Time$TimeZones$pst8pdt = $isaacseymour$deprecated_time$Time$TimeZoneData$pst8pdt_l;
var $isaacseymour$deprecated_time$Time$TimeZones$roc = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'ROC', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_taipei_l);
var $isaacseymour$deprecated_time$Time$TimeZones$rok = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'ROK', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_seoul_l);
var $isaacseymour$deprecated_time$Time$TimeZones$singapore = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Singapore', $isaacseymour$deprecated_time$Time$TimeZoneData$asia_singapore_l);
var $isaacseymour$deprecated_time$Time$TimeZones$turkey = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Turkey', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_istanbul_l);
var $isaacseymour$deprecated_time$Time$TimeZones$uct = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'UCT', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_uct_l);
var $isaacseymour$deprecated_time$Time$TimeZones$universal = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Universal', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_alaska = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Alaska', $isaacseymour$deprecated_time$Time$TimeZoneData$america_anchorage_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_aleutian = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Aleutian', $isaacseymour$deprecated_time$Time$TimeZoneData$america_adak_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_arizona = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Arizona', $isaacseymour$deprecated_time$Time$TimeZoneData$america_phoenix_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_central = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Central', $isaacseymour$deprecated_time$Time$TimeZoneData$america_chicago_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_east_indiana = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/East-Indiana', $isaacseymour$deprecated_time$Time$TimeZoneData$america_fort_wayne_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_eastern = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Eastern', $isaacseymour$deprecated_time$Time$TimeZoneData$america_new_york_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_hawaii = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Hawaii', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_honolulu_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_indiana_starke = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Indiana-Starke', $isaacseymour$deprecated_time$Time$TimeZoneData$america_indiana_knox_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_michigan = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Michigan', $isaacseymour$deprecated_time$Time$TimeZoneData$america_detroit_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_mountain = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Mountain', $isaacseymour$deprecated_time$Time$TimeZoneData$america_denver_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_pacific = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Pacific', $isaacseymour$deprecated_time$Time$TimeZoneData$america_los_angeles_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_pacific_new = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Pacific-New', $isaacseymour$deprecated_time$Time$TimeZoneData$america_los_angeles_l);
var $isaacseymour$deprecated_time$Time$TimeZones$us_samoa = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'US/Samoa', $isaacseymour$deprecated_time$Time$TimeZoneData$pacific_pago_pago_l);
var $isaacseymour$deprecated_time$Time$TimeZones$utc = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'UTC', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l);
var $isaacseymour$deprecated_time$Time$TimeZones$w_su = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'W-SU', $isaacseymour$deprecated_time$Time$TimeZoneData$europe_moscow_l);
var $isaacseymour$deprecated_time$Time$TimeZoneData$wet_l = $isaacseymour$deprecated_time$Time$TimeZoneData$unpack('WET|WET WEST|0 -10|010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|hDB0 1a00 1fA0 1cM0 1cM0 1cM0 1fA0 1a00 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00');
var $isaacseymour$deprecated_time$Time$TimeZones$wet = $isaacseymour$deprecated_time$Time$TimeZoneData$wet_l;
var $isaacseymour$deprecated_time$Time$TimeZones$zulu = A2($isaacseymour$deprecated_time$Time$TimeZoneData$link, 'Zulu', $isaacseymour$deprecated_time$Time$TimeZoneData$etc_utc_l);
var $isaacseymour$deprecated_time$Time$TimeZones$all = $elm$core$Dict$fromList(
	$elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					_Utils_Tuple2('Africa/Abidjan', $isaacseymour$deprecated_time$Time$TimeZones$africa_abidjan),
					_Utils_Tuple2('Africa/Accra', $isaacseymour$deprecated_time$Time$TimeZones$africa_accra),
					_Utils_Tuple2('Africa/Addis_Ababa', $isaacseymour$deprecated_time$Time$TimeZones$africa_addis_ababa),
					_Utils_Tuple2('Africa/Algiers', $isaacseymour$deprecated_time$Time$TimeZones$africa_algiers),
					_Utils_Tuple2('Africa/Asmara', $isaacseymour$deprecated_time$Time$TimeZones$africa_asmara),
					_Utils_Tuple2('Africa/Asmera', $isaacseymour$deprecated_time$Time$TimeZones$africa_asmera),
					_Utils_Tuple2('Africa/Bamako', $isaacseymour$deprecated_time$Time$TimeZones$africa_bamako),
					_Utils_Tuple2('Africa/Bangui', $isaacseymour$deprecated_time$Time$TimeZones$africa_bangui),
					_Utils_Tuple2('Africa/Banjul', $isaacseymour$deprecated_time$Time$TimeZones$africa_banjul),
					_Utils_Tuple2('Africa/Bissau', $isaacseymour$deprecated_time$Time$TimeZones$africa_bissau),
					_Utils_Tuple2('Africa/Blantyre', $isaacseymour$deprecated_time$Time$TimeZones$africa_blantyre),
					_Utils_Tuple2('Africa/Brazzaville', $isaacseymour$deprecated_time$Time$TimeZones$africa_brazzaville),
					_Utils_Tuple2('Africa/Bujumbura', $isaacseymour$deprecated_time$Time$TimeZones$africa_bujumbura),
					_Utils_Tuple2('Africa/Cairo', $isaacseymour$deprecated_time$Time$TimeZones$africa_cairo),
					_Utils_Tuple2('Africa/Casablanca', $isaacseymour$deprecated_time$Time$TimeZones$africa_casablanca),
					_Utils_Tuple2('Africa/Ceuta', $isaacseymour$deprecated_time$Time$TimeZones$africa_ceuta),
					_Utils_Tuple2('Africa/Conakry', $isaacseymour$deprecated_time$Time$TimeZones$africa_conakry),
					_Utils_Tuple2('Africa/Dakar', $isaacseymour$deprecated_time$Time$TimeZones$africa_dakar),
					_Utils_Tuple2('Africa/Dar_es_Salaam', $isaacseymour$deprecated_time$Time$TimeZones$africa_dar_es_salaam),
					_Utils_Tuple2('Africa/Djibouti', $isaacseymour$deprecated_time$Time$TimeZones$africa_djibouti),
					_Utils_Tuple2('Africa/Douala', $isaacseymour$deprecated_time$Time$TimeZones$africa_douala),
					_Utils_Tuple2('Africa/El_Aaiun', $isaacseymour$deprecated_time$Time$TimeZones$africa_el_aaiun),
					_Utils_Tuple2('Africa/Freetown', $isaacseymour$deprecated_time$Time$TimeZones$africa_freetown),
					_Utils_Tuple2('Africa/Gaborone', $isaacseymour$deprecated_time$Time$TimeZones$africa_gaborone),
					_Utils_Tuple2('Africa/Harare', $isaacseymour$deprecated_time$Time$TimeZones$africa_harare),
					_Utils_Tuple2('Africa/Johannesburg', $isaacseymour$deprecated_time$Time$TimeZones$africa_johannesburg),
					_Utils_Tuple2('Africa/Juba', $isaacseymour$deprecated_time$Time$TimeZones$africa_juba),
					_Utils_Tuple2('Africa/Kampala', $isaacseymour$deprecated_time$Time$TimeZones$africa_kampala),
					_Utils_Tuple2('Africa/Khartoum', $isaacseymour$deprecated_time$Time$TimeZones$africa_khartoum),
					_Utils_Tuple2('Africa/Kigali', $isaacseymour$deprecated_time$Time$TimeZones$africa_kigali),
					_Utils_Tuple2('Africa/Kinshasa', $isaacseymour$deprecated_time$Time$TimeZones$africa_kinshasa),
					_Utils_Tuple2('Africa/Lagos', $isaacseymour$deprecated_time$Time$TimeZones$africa_lagos),
					_Utils_Tuple2('Africa/Libreville', $isaacseymour$deprecated_time$Time$TimeZones$africa_libreville),
					_Utils_Tuple2('Africa/Lome', $isaacseymour$deprecated_time$Time$TimeZones$africa_lome),
					_Utils_Tuple2('Africa/Luanda', $isaacseymour$deprecated_time$Time$TimeZones$africa_luanda),
					_Utils_Tuple2('Africa/Lubumbashi', $isaacseymour$deprecated_time$Time$TimeZones$africa_lubumbashi),
					_Utils_Tuple2('Africa/Lusaka', $isaacseymour$deprecated_time$Time$TimeZones$africa_lusaka),
					_Utils_Tuple2('Africa/Malabo', $isaacseymour$deprecated_time$Time$TimeZones$africa_malabo),
					_Utils_Tuple2('Africa/Maputo', $isaacseymour$deprecated_time$Time$TimeZones$africa_maputo),
					_Utils_Tuple2('Africa/Maseru', $isaacseymour$deprecated_time$Time$TimeZones$africa_maseru),
					_Utils_Tuple2('Africa/Mbabane', $isaacseymour$deprecated_time$Time$TimeZones$africa_mbabane),
					_Utils_Tuple2('Africa/Mogadishu', $isaacseymour$deprecated_time$Time$TimeZones$africa_mogadishu),
					_Utils_Tuple2('Africa/Monrovia', $isaacseymour$deprecated_time$Time$TimeZones$africa_monrovia),
					_Utils_Tuple2('Africa/Nairobi', $isaacseymour$deprecated_time$Time$TimeZones$africa_nairobi),
					_Utils_Tuple2('Africa/Ndjamena', $isaacseymour$deprecated_time$Time$TimeZones$africa_ndjamena),
					_Utils_Tuple2('Africa/Niamey', $isaacseymour$deprecated_time$Time$TimeZones$africa_niamey),
					_Utils_Tuple2('Africa/Nouakchott', $isaacseymour$deprecated_time$Time$TimeZones$africa_nouakchott),
					_Utils_Tuple2('Africa/Ouagadougou', $isaacseymour$deprecated_time$Time$TimeZones$africa_ouagadougou),
					_Utils_Tuple2('Africa/Porto-Novo', $isaacseymour$deprecated_time$Time$TimeZones$africa_porto_novo),
					_Utils_Tuple2('Africa/Sao_Tome', $isaacseymour$deprecated_time$Time$TimeZones$africa_sao_tome)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Africa/Timbuktu', $isaacseymour$deprecated_time$Time$TimeZones$africa_timbuktu),
					_Utils_Tuple2('Africa/Tripoli', $isaacseymour$deprecated_time$Time$TimeZones$africa_tripoli),
					_Utils_Tuple2('Africa/Tunis', $isaacseymour$deprecated_time$Time$TimeZones$africa_tunis),
					_Utils_Tuple2('Africa/Windhoek', $isaacseymour$deprecated_time$Time$TimeZones$africa_windhoek),
					_Utils_Tuple2('America/Adak', $isaacseymour$deprecated_time$Time$TimeZones$america_adak),
					_Utils_Tuple2('America/Anchorage', $isaacseymour$deprecated_time$Time$TimeZones$america_anchorage),
					_Utils_Tuple2('America/Anguilla', $isaacseymour$deprecated_time$Time$TimeZones$america_anguilla),
					_Utils_Tuple2('America/Antigua', $isaacseymour$deprecated_time$Time$TimeZones$america_antigua),
					_Utils_Tuple2('America/Araguaina', $isaacseymour$deprecated_time$Time$TimeZones$america_araguaina),
					_Utils_Tuple2('America/Argentina/Buenos_Aires', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_buenos_aires),
					_Utils_Tuple2('America/Argentina/Catamarca', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_catamarca),
					_Utils_Tuple2('America/Argentina/ComodRivadavia', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_comodrivadavia),
					_Utils_Tuple2('America/Argentina/Cordoba', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_cordoba),
					_Utils_Tuple2('America/Argentina/Jujuy', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_jujuy),
					_Utils_Tuple2('America/Argentina/La_Rioja', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_la_rioja),
					_Utils_Tuple2('America/Argentina/Mendoza', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_mendoza),
					_Utils_Tuple2('America/Argentina/Rio_Gallegos', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_rio_gallegos),
					_Utils_Tuple2('America/Argentina/Salta', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_salta),
					_Utils_Tuple2('America/Argentina/San_Juan', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_san_juan),
					_Utils_Tuple2('America/Argentina/San_Luis', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_san_luis),
					_Utils_Tuple2('America/Argentina/Tucuman', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_tucuman),
					_Utils_Tuple2('America/Argentina/Ushuaia', $isaacseymour$deprecated_time$Time$TimeZones$america_argentina_ushuaia),
					_Utils_Tuple2('America/Aruba', $isaacseymour$deprecated_time$Time$TimeZones$america_aruba),
					_Utils_Tuple2('America/Asuncion', $isaacseymour$deprecated_time$Time$TimeZones$america_asuncion),
					_Utils_Tuple2('America/Atikokan', $isaacseymour$deprecated_time$Time$TimeZones$america_atikokan),
					_Utils_Tuple2('America/Atka', $isaacseymour$deprecated_time$Time$TimeZones$america_atka),
					_Utils_Tuple2('America/Bahia', $isaacseymour$deprecated_time$Time$TimeZones$america_bahia),
					_Utils_Tuple2('America/Bahia_Banderas', $isaacseymour$deprecated_time$Time$TimeZones$america_bahia_banderas),
					_Utils_Tuple2('America/Barbados', $isaacseymour$deprecated_time$Time$TimeZones$america_barbados),
					_Utils_Tuple2('America/Belem', $isaacseymour$deprecated_time$Time$TimeZones$america_belem),
					_Utils_Tuple2('America/Belize', $isaacseymour$deprecated_time$Time$TimeZones$america_belize),
					_Utils_Tuple2('America/Blanc-Sablon', $isaacseymour$deprecated_time$Time$TimeZones$america_blanc_sablon),
					_Utils_Tuple2('America/Boa_Vista', $isaacseymour$deprecated_time$Time$TimeZones$america_boa_vista),
					_Utils_Tuple2('America/Bogota', $isaacseymour$deprecated_time$Time$TimeZones$america_bogota),
					_Utils_Tuple2('America/Boise', $isaacseymour$deprecated_time$Time$TimeZones$america_boise),
					_Utils_Tuple2('America/Buenos_Aires', $isaacseymour$deprecated_time$Time$TimeZones$america_buenos_aires),
					_Utils_Tuple2('America/Cambridge_Bay', $isaacseymour$deprecated_time$Time$TimeZones$america_cambridge_bay),
					_Utils_Tuple2('America/Campo_Grande', $isaacseymour$deprecated_time$Time$TimeZones$america_campo_grande),
					_Utils_Tuple2('America/Cancun', $isaacseymour$deprecated_time$Time$TimeZones$america_cancun),
					_Utils_Tuple2('America/Caracas', $isaacseymour$deprecated_time$Time$TimeZones$america_caracas),
					_Utils_Tuple2('America/Catamarca', $isaacseymour$deprecated_time$Time$TimeZones$america_catamarca),
					_Utils_Tuple2('America/Cayenne', $isaacseymour$deprecated_time$Time$TimeZones$america_cayenne),
					_Utils_Tuple2('America/Cayman', $isaacseymour$deprecated_time$Time$TimeZones$america_cayman),
					_Utils_Tuple2('America/Chicago', $isaacseymour$deprecated_time$Time$TimeZones$america_chicago),
					_Utils_Tuple2('America/Chihuahua', $isaacseymour$deprecated_time$Time$TimeZones$america_chihuahua),
					_Utils_Tuple2('America/Coral_Harbour', $isaacseymour$deprecated_time$Time$TimeZones$america_coral_harbour),
					_Utils_Tuple2('America/Cordoba', $isaacseymour$deprecated_time$Time$TimeZones$america_cordoba),
					_Utils_Tuple2('America/Costa_Rica', $isaacseymour$deprecated_time$Time$TimeZones$america_costa_rica),
					_Utils_Tuple2('America/Creston', $isaacseymour$deprecated_time$Time$TimeZones$america_creston),
					_Utils_Tuple2('America/Cuiaba', $isaacseymour$deprecated_time$Time$TimeZones$america_cuiaba)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('America/Curacao', $isaacseymour$deprecated_time$Time$TimeZones$america_curacao),
					_Utils_Tuple2('America/Danmarkshavn', $isaacseymour$deprecated_time$Time$TimeZones$america_danmarkshavn),
					_Utils_Tuple2('America/Dawson', $isaacseymour$deprecated_time$Time$TimeZones$america_dawson),
					_Utils_Tuple2('America/Dawson_Creek', $isaacseymour$deprecated_time$Time$TimeZones$america_dawson_creek),
					_Utils_Tuple2('America/Denver', $isaacseymour$deprecated_time$Time$TimeZones$america_denver),
					_Utils_Tuple2('America/Detroit', $isaacseymour$deprecated_time$Time$TimeZones$america_detroit),
					_Utils_Tuple2('America/Dominica', $isaacseymour$deprecated_time$Time$TimeZones$america_dominica),
					_Utils_Tuple2('America/Edmonton', $isaacseymour$deprecated_time$Time$TimeZones$america_edmonton),
					_Utils_Tuple2('America/Eirunepe', $isaacseymour$deprecated_time$Time$TimeZones$america_eirunepe),
					_Utils_Tuple2('America/El_Salvador', $isaacseymour$deprecated_time$Time$TimeZones$america_el_salvador),
					_Utils_Tuple2('America/Ensenada', $isaacseymour$deprecated_time$Time$TimeZones$america_ensenada),
					_Utils_Tuple2('America/Fort_Nelson', $isaacseymour$deprecated_time$Time$TimeZones$america_fort_nelson),
					_Utils_Tuple2('America/Fort_Wayne', $isaacseymour$deprecated_time$Time$TimeZones$america_fort_wayne),
					_Utils_Tuple2('America/Fortaleza', $isaacseymour$deprecated_time$Time$TimeZones$america_fortaleza),
					_Utils_Tuple2('America/Glace_Bay', $isaacseymour$deprecated_time$Time$TimeZones$america_glace_bay),
					_Utils_Tuple2('America/Godthab', $isaacseymour$deprecated_time$Time$TimeZones$america_godthab),
					_Utils_Tuple2('America/Goose_Bay', $isaacseymour$deprecated_time$Time$TimeZones$america_goose_bay),
					_Utils_Tuple2('America/Grand_Turk', $isaacseymour$deprecated_time$Time$TimeZones$america_grand_turk),
					_Utils_Tuple2('America/Grenada', $isaacseymour$deprecated_time$Time$TimeZones$america_grenada),
					_Utils_Tuple2('America/Guadeloupe', $isaacseymour$deprecated_time$Time$TimeZones$america_guadeloupe),
					_Utils_Tuple2('America/Guatemala', $isaacseymour$deprecated_time$Time$TimeZones$america_guatemala),
					_Utils_Tuple2('America/Guayaquil', $isaacseymour$deprecated_time$Time$TimeZones$america_guayaquil),
					_Utils_Tuple2('America/Guyana', $isaacseymour$deprecated_time$Time$TimeZones$america_guyana),
					_Utils_Tuple2('America/Halifax', $isaacseymour$deprecated_time$Time$TimeZones$america_halifax),
					_Utils_Tuple2('America/Havana', $isaacseymour$deprecated_time$Time$TimeZones$america_havana),
					_Utils_Tuple2('America/Hermosillo', $isaacseymour$deprecated_time$Time$TimeZones$america_hermosillo),
					_Utils_Tuple2('America/Indiana/Indianapolis', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_indianapolis),
					_Utils_Tuple2('America/Indiana/Knox', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_knox),
					_Utils_Tuple2('America/Indiana/Marengo', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_marengo),
					_Utils_Tuple2('America/Indiana/Petersburg', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_petersburg),
					_Utils_Tuple2('America/Indiana/Tell_City', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_tell_city),
					_Utils_Tuple2('America/Indiana/Vevay', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_vevay),
					_Utils_Tuple2('America/Indiana/Vincennes', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_vincennes),
					_Utils_Tuple2('America/Indiana/Winamac', $isaacseymour$deprecated_time$Time$TimeZones$america_indiana_winamac),
					_Utils_Tuple2('America/Indianapolis', $isaacseymour$deprecated_time$Time$TimeZones$america_indianapolis),
					_Utils_Tuple2('America/Inuvik', $isaacseymour$deprecated_time$Time$TimeZones$america_inuvik),
					_Utils_Tuple2('America/Iqaluit', $isaacseymour$deprecated_time$Time$TimeZones$america_iqaluit),
					_Utils_Tuple2('America/Jamaica', $isaacseymour$deprecated_time$Time$TimeZones$america_jamaica),
					_Utils_Tuple2('America/Jujuy', $isaacseymour$deprecated_time$Time$TimeZones$america_jujuy),
					_Utils_Tuple2('America/Juneau', $isaacseymour$deprecated_time$Time$TimeZones$america_juneau),
					_Utils_Tuple2('America/Kentucky/Louisville', $isaacseymour$deprecated_time$Time$TimeZones$america_kentucky_louisville),
					_Utils_Tuple2('America/Kentucky/Monticello', $isaacseymour$deprecated_time$Time$TimeZones$america_kentucky_monticello),
					_Utils_Tuple2('America/Knox_IN', $isaacseymour$deprecated_time$Time$TimeZones$america_knox_in),
					_Utils_Tuple2('America/Kralendijk', $isaacseymour$deprecated_time$Time$TimeZones$america_kralendijk),
					_Utils_Tuple2('America/La_Paz', $isaacseymour$deprecated_time$Time$TimeZones$america_la_paz),
					_Utils_Tuple2('America/Lima', $isaacseymour$deprecated_time$Time$TimeZones$america_lima),
					_Utils_Tuple2('America/Los_Angeles', $isaacseymour$deprecated_time$Time$TimeZones$america_los_angeles),
					_Utils_Tuple2('America/Louisville', $isaacseymour$deprecated_time$Time$TimeZones$america_louisville),
					_Utils_Tuple2('America/Lower_Princes', $isaacseymour$deprecated_time$Time$TimeZones$america_lower_princes),
					_Utils_Tuple2('America/Maceio', $isaacseymour$deprecated_time$Time$TimeZones$america_maceio)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('America/Managua', $isaacseymour$deprecated_time$Time$TimeZones$america_managua),
					_Utils_Tuple2('America/Manaus', $isaacseymour$deprecated_time$Time$TimeZones$america_manaus),
					_Utils_Tuple2('America/Marigot', $isaacseymour$deprecated_time$Time$TimeZones$america_marigot),
					_Utils_Tuple2('America/Martinique', $isaacseymour$deprecated_time$Time$TimeZones$america_martinique),
					_Utils_Tuple2('America/Matamoros', $isaacseymour$deprecated_time$Time$TimeZones$america_matamoros),
					_Utils_Tuple2('America/Mazatlan', $isaacseymour$deprecated_time$Time$TimeZones$america_mazatlan),
					_Utils_Tuple2('America/Mendoza', $isaacseymour$deprecated_time$Time$TimeZones$america_mendoza),
					_Utils_Tuple2('America/Menominee', $isaacseymour$deprecated_time$Time$TimeZones$america_menominee),
					_Utils_Tuple2('America/Merida', $isaacseymour$deprecated_time$Time$TimeZones$america_merida),
					_Utils_Tuple2('America/Metlakatla', $isaacseymour$deprecated_time$Time$TimeZones$america_metlakatla),
					_Utils_Tuple2('America/Mexico_City', $isaacseymour$deprecated_time$Time$TimeZones$america_mexico_city),
					_Utils_Tuple2('America/Miquelon', $isaacseymour$deprecated_time$Time$TimeZones$america_miquelon),
					_Utils_Tuple2('America/Moncton', $isaacseymour$deprecated_time$Time$TimeZones$america_moncton),
					_Utils_Tuple2('America/Monterrey', $isaacseymour$deprecated_time$Time$TimeZones$america_monterrey),
					_Utils_Tuple2('America/Montevideo', $isaacseymour$deprecated_time$Time$TimeZones$america_montevideo),
					_Utils_Tuple2('America/Montreal', $isaacseymour$deprecated_time$Time$TimeZones$america_montreal),
					_Utils_Tuple2('America/Montserrat', $isaacseymour$deprecated_time$Time$TimeZones$america_montserrat),
					_Utils_Tuple2('America/Nassau', $isaacseymour$deprecated_time$Time$TimeZones$america_nassau),
					_Utils_Tuple2('America/New_York', $isaacseymour$deprecated_time$Time$TimeZones$america_new_york),
					_Utils_Tuple2('America/Nipigon', $isaacseymour$deprecated_time$Time$TimeZones$america_nipigon),
					_Utils_Tuple2('America/Nome', $isaacseymour$deprecated_time$Time$TimeZones$america_nome),
					_Utils_Tuple2('America/Noronha', $isaacseymour$deprecated_time$Time$TimeZones$america_noronha),
					_Utils_Tuple2('America/North_Dakota/Beulah', $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_beulah),
					_Utils_Tuple2('America/North_Dakota/Center', $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_center),
					_Utils_Tuple2('America/North_Dakota/New_Salem', $isaacseymour$deprecated_time$Time$TimeZones$america_north_dakota_new_salem),
					_Utils_Tuple2('America/Ojinaga', $isaacseymour$deprecated_time$Time$TimeZones$america_ojinaga),
					_Utils_Tuple2('America/Panama', $isaacseymour$deprecated_time$Time$TimeZones$america_panama),
					_Utils_Tuple2('America/Pangnirtung', $isaacseymour$deprecated_time$Time$TimeZones$america_pangnirtung),
					_Utils_Tuple2('America/Paramaribo', $isaacseymour$deprecated_time$Time$TimeZones$america_paramaribo),
					_Utils_Tuple2('America/Phoenix', $isaacseymour$deprecated_time$Time$TimeZones$america_phoenix),
					_Utils_Tuple2('America/Port-au-Prince', $isaacseymour$deprecated_time$Time$TimeZones$america_port_au_prince),
					_Utils_Tuple2('America/Port_of_Spain', $isaacseymour$deprecated_time$Time$TimeZones$america_port_of_spain),
					_Utils_Tuple2('America/Porto_Acre', $isaacseymour$deprecated_time$Time$TimeZones$america_porto_acre),
					_Utils_Tuple2('America/Porto_Velho', $isaacseymour$deprecated_time$Time$TimeZones$america_porto_velho),
					_Utils_Tuple2('America/Puerto_Rico', $isaacseymour$deprecated_time$Time$TimeZones$america_puerto_rico),
					_Utils_Tuple2('America/Rainy_River', $isaacseymour$deprecated_time$Time$TimeZones$america_rainy_river),
					_Utils_Tuple2('America/Rankin_Inlet', $isaacseymour$deprecated_time$Time$TimeZones$america_rankin_inlet),
					_Utils_Tuple2('America/Recife', $isaacseymour$deprecated_time$Time$TimeZones$america_recife),
					_Utils_Tuple2('America/Regina', $isaacseymour$deprecated_time$Time$TimeZones$america_regina),
					_Utils_Tuple2('America/Resolute', $isaacseymour$deprecated_time$Time$TimeZones$america_resolute),
					_Utils_Tuple2('America/Rio_Branco', $isaacseymour$deprecated_time$Time$TimeZones$america_rio_branco),
					_Utils_Tuple2('America/Rosario', $isaacseymour$deprecated_time$Time$TimeZones$america_rosario),
					_Utils_Tuple2('America/Santa_Isabel', $isaacseymour$deprecated_time$Time$TimeZones$america_santa_isabel),
					_Utils_Tuple2('America/Santarem', $isaacseymour$deprecated_time$Time$TimeZones$america_santarem),
					_Utils_Tuple2('America/Santiago', $isaacseymour$deprecated_time$Time$TimeZones$america_santiago),
					_Utils_Tuple2('America/Santo_Domingo', $isaacseymour$deprecated_time$Time$TimeZones$america_santo_domingo),
					_Utils_Tuple2('America/Sao_Paulo', $isaacseymour$deprecated_time$Time$TimeZones$america_sao_paulo),
					_Utils_Tuple2('America/Scoresbysund', $isaacseymour$deprecated_time$Time$TimeZones$america_scoresbysund),
					_Utils_Tuple2('America/Shiprock', $isaacseymour$deprecated_time$Time$TimeZones$america_shiprock),
					_Utils_Tuple2('America/Sitka', $isaacseymour$deprecated_time$Time$TimeZones$america_sitka)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('America/St_Barthelemy', $isaacseymour$deprecated_time$Time$TimeZones$america_st_barthelemy),
					_Utils_Tuple2('America/St_Johns', $isaacseymour$deprecated_time$Time$TimeZones$america_st_johns),
					_Utils_Tuple2('America/St_Kitts', $isaacseymour$deprecated_time$Time$TimeZones$america_st_kitts),
					_Utils_Tuple2('America/St_Lucia', $isaacseymour$deprecated_time$Time$TimeZones$america_st_lucia),
					_Utils_Tuple2('America/St_Thomas', $isaacseymour$deprecated_time$Time$TimeZones$america_st_thomas),
					_Utils_Tuple2('America/St_Vincent', $isaacseymour$deprecated_time$Time$TimeZones$america_st_vincent),
					_Utils_Tuple2('America/Swift_Current', $isaacseymour$deprecated_time$Time$TimeZones$america_swift_current),
					_Utils_Tuple2('America/Tegucigalpa', $isaacseymour$deprecated_time$Time$TimeZones$america_tegucigalpa),
					_Utils_Tuple2('America/Thule', $isaacseymour$deprecated_time$Time$TimeZones$america_thule),
					_Utils_Tuple2('America/Thunder_Bay', $isaacseymour$deprecated_time$Time$TimeZones$america_thunder_bay),
					_Utils_Tuple2('America/Tijuana', $isaacseymour$deprecated_time$Time$TimeZones$america_tijuana),
					_Utils_Tuple2('America/Toronto', $isaacseymour$deprecated_time$Time$TimeZones$america_toronto),
					_Utils_Tuple2('America/Tortola', $isaacseymour$deprecated_time$Time$TimeZones$america_tortola),
					_Utils_Tuple2('America/Vancouver', $isaacseymour$deprecated_time$Time$TimeZones$america_vancouver),
					_Utils_Tuple2('America/Virgin', $isaacseymour$deprecated_time$Time$TimeZones$america_virgin),
					_Utils_Tuple2('America/Whitehorse', $isaacseymour$deprecated_time$Time$TimeZones$america_whitehorse),
					_Utils_Tuple2('America/Winnipeg', $isaacseymour$deprecated_time$Time$TimeZones$america_winnipeg),
					_Utils_Tuple2('America/Yakutat', $isaacseymour$deprecated_time$Time$TimeZones$america_yakutat),
					_Utils_Tuple2('America/Yellowknife', $isaacseymour$deprecated_time$Time$TimeZones$america_yellowknife),
					_Utils_Tuple2('Antarctica/Casey', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_casey),
					_Utils_Tuple2('Antarctica/Davis', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_davis),
					_Utils_Tuple2('Antarctica/DumontDUrville', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_dumontdurville),
					_Utils_Tuple2('Antarctica/Macquarie', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_macquarie),
					_Utils_Tuple2('Antarctica/Mawson', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_mawson),
					_Utils_Tuple2('Antarctica/McMurdo', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_mcmurdo),
					_Utils_Tuple2('Antarctica/Palmer', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_palmer),
					_Utils_Tuple2('Antarctica/Rothera', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_rothera),
					_Utils_Tuple2('Antarctica/South_Pole', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_south_pole),
					_Utils_Tuple2('Antarctica/Syowa', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_syowa),
					_Utils_Tuple2('Antarctica/Troll', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_troll),
					_Utils_Tuple2('Antarctica/Vostok', $isaacseymour$deprecated_time$Time$TimeZones$antarctica_vostok),
					_Utils_Tuple2('Arctic/Longyearbyen', $isaacseymour$deprecated_time$Time$TimeZones$arctic_longyearbyen),
					_Utils_Tuple2('Asia/Aden', $isaacseymour$deprecated_time$Time$TimeZones$asia_aden),
					_Utils_Tuple2('Asia/Almaty', $isaacseymour$deprecated_time$Time$TimeZones$asia_almaty),
					_Utils_Tuple2('Asia/Amman', $isaacseymour$deprecated_time$Time$TimeZones$asia_amman),
					_Utils_Tuple2('Asia/Anadyr', $isaacseymour$deprecated_time$Time$TimeZones$asia_anadyr),
					_Utils_Tuple2('Asia/Aqtau', $isaacseymour$deprecated_time$Time$TimeZones$asia_aqtau),
					_Utils_Tuple2('Asia/Aqtobe', $isaacseymour$deprecated_time$Time$TimeZones$asia_aqtobe),
					_Utils_Tuple2('Asia/Ashgabat', $isaacseymour$deprecated_time$Time$TimeZones$asia_ashgabat),
					_Utils_Tuple2('Asia/Ashkhabad', $isaacseymour$deprecated_time$Time$TimeZones$asia_ashkhabad),
					_Utils_Tuple2('Asia/Baghdad', $isaacseymour$deprecated_time$Time$TimeZones$asia_baghdad),
					_Utils_Tuple2('Asia/Bahrain', $isaacseymour$deprecated_time$Time$TimeZones$asia_bahrain),
					_Utils_Tuple2('Asia/Baku', $isaacseymour$deprecated_time$Time$TimeZones$asia_baku),
					_Utils_Tuple2('Asia/Bangkok', $isaacseymour$deprecated_time$Time$TimeZones$asia_bangkok),
					_Utils_Tuple2('Asia/Barnaul', $isaacseymour$deprecated_time$Time$TimeZones$asia_barnaul),
					_Utils_Tuple2('Asia/Beirut', $isaacseymour$deprecated_time$Time$TimeZones$asia_beirut),
					_Utils_Tuple2('Asia/Bishkek', $isaacseymour$deprecated_time$Time$TimeZones$asia_bishkek),
					_Utils_Tuple2('Asia/Brunei', $isaacseymour$deprecated_time$Time$TimeZones$asia_brunei),
					_Utils_Tuple2('Asia/Calcutta', $isaacseymour$deprecated_time$Time$TimeZones$asia_calcutta),
					_Utils_Tuple2('Asia/Chita', $isaacseymour$deprecated_time$Time$TimeZones$asia_chita)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Asia/Choibalsan', $isaacseymour$deprecated_time$Time$TimeZones$asia_choibalsan),
					_Utils_Tuple2('Asia/Chongqing', $isaacseymour$deprecated_time$Time$TimeZones$asia_chongqing),
					_Utils_Tuple2('Asia/Chungking', $isaacseymour$deprecated_time$Time$TimeZones$asia_chungking),
					_Utils_Tuple2('Asia/Colombo', $isaacseymour$deprecated_time$Time$TimeZones$asia_colombo),
					_Utils_Tuple2('Asia/Dacca', $isaacseymour$deprecated_time$Time$TimeZones$asia_dacca),
					_Utils_Tuple2('Asia/Damascus', $isaacseymour$deprecated_time$Time$TimeZones$asia_damascus),
					_Utils_Tuple2('Asia/Dhaka', $isaacseymour$deprecated_time$Time$TimeZones$asia_dhaka),
					_Utils_Tuple2('Asia/Dili', $isaacseymour$deprecated_time$Time$TimeZones$asia_dili),
					_Utils_Tuple2('Asia/Dubai', $isaacseymour$deprecated_time$Time$TimeZones$asia_dubai),
					_Utils_Tuple2('Asia/Dushanbe', $isaacseymour$deprecated_time$Time$TimeZones$asia_dushanbe),
					_Utils_Tuple2('Asia/Famagusta', $isaacseymour$deprecated_time$Time$TimeZones$asia_famagusta),
					_Utils_Tuple2('Asia/Gaza', $isaacseymour$deprecated_time$Time$TimeZones$asia_gaza),
					_Utils_Tuple2('Asia/Harbin', $isaacseymour$deprecated_time$Time$TimeZones$asia_harbin),
					_Utils_Tuple2('Asia/Hebron', $isaacseymour$deprecated_time$Time$TimeZones$asia_hebron),
					_Utils_Tuple2('Asia/Ho_Chi_Minh', $isaacseymour$deprecated_time$Time$TimeZones$asia_ho_chi_minh),
					_Utils_Tuple2('Asia/Hong_Kong', $isaacseymour$deprecated_time$Time$TimeZones$asia_hong_kong),
					_Utils_Tuple2('Asia/Hovd', $isaacseymour$deprecated_time$Time$TimeZones$asia_hovd),
					_Utils_Tuple2('Asia/Irkutsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_irkutsk),
					_Utils_Tuple2('Asia/Istanbul', $isaacseymour$deprecated_time$Time$TimeZones$asia_istanbul),
					_Utils_Tuple2('Asia/Jakarta', $isaacseymour$deprecated_time$Time$TimeZones$asia_jakarta),
					_Utils_Tuple2('Asia/Jayapura', $isaacseymour$deprecated_time$Time$TimeZones$asia_jayapura),
					_Utils_Tuple2('Asia/Jerusalem', $isaacseymour$deprecated_time$Time$TimeZones$asia_jerusalem),
					_Utils_Tuple2('Asia/Kabul', $isaacseymour$deprecated_time$Time$TimeZones$asia_kabul),
					_Utils_Tuple2('Asia/Kamchatka', $isaacseymour$deprecated_time$Time$TimeZones$asia_kamchatka),
					_Utils_Tuple2('Asia/Karachi', $isaacseymour$deprecated_time$Time$TimeZones$asia_karachi),
					_Utils_Tuple2('Asia/Kashgar', $isaacseymour$deprecated_time$Time$TimeZones$asia_kashgar),
					_Utils_Tuple2('Asia/Kathmandu', $isaacseymour$deprecated_time$Time$TimeZones$asia_kathmandu),
					_Utils_Tuple2('Asia/Katmandu', $isaacseymour$deprecated_time$Time$TimeZones$asia_katmandu),
					_Utils_Tuple2('Asia/Khandyga', $isaacseymour$deprecated_time$Time$TimeZones$asia_khandyga),
					_Utils_Tuple2('Asia/Kolkata', $isaacseymour$deprecated_time$Time$TimeZones$asia_kolkata),
					_Utils_Tuple2('Asia/Krasnoyarsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_krasnoyarsk),
					_Utils_Tuple2('Asia/Kuala_Lumpur', $isaacseymour$deprecated_time$Time$TimeZones$asia_kuala_lumpur),
					_Utils_Tuple2('Asia/Kuching', $isaacseymour$deprecated_time$Time$TimeZones$asia_kuching),
					_Utils_Tuple2('Asia/Kuwait', $isaacseymour$deprecated_time$Time$TimeZones$asia_kuwait),
					_Utils_Tuple2('Asia/Macao', $isaacseymour$deprecated_time$Time$TimeZones$asia_macao),
					_Utils_Tuple2('Asia/Macau', $isaacseymour$deprecated_time$Time$TimeZones$asia_macau),
					_Utils_Tuple2('Asia/Magadan', $isaacseymour$deprecated_time$Time$TimeZones$asia_magadan),
					_Utils_Tuple2('Asia/Makassar', $isaacseymour$deprecated_time$Time$TimeZones$asia_makassar),
					_Utils_Tuple2('Asia/Manila', $isaacseymour$deprecated_time$Time$TimeZones$asia_manila),
					_Utils_Tuple2('Asia/Muscat', $isaacseymour$deprecated_time$Time$TimeZones$asia_muscat),
					_Utils_Tuple2('Asia/Nicosia', $isaacseymour$deprecated_time$Time$TimeZones$asia_nicosia),
					_Utils_Tuple2('Asia/Novokuznetsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_novokuznetsk),
					_Utils_Tuple2('Asia/Novosibirsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_novosibirsk),
					_Utils_Tuple2('Asia/Omsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_omsk),
					_Utils_Tuple2('Asia/Oral', $isaacseymour$deprecated_time$Time$TimeZones$asia_oral),
					_Utils_Tuple2('Asia/Phnom_Penh', $isaacseymour$deprecated_time$Time$TimeZones$asia_phnom_penh),
					_Utils_Tuple2('Asia/Pontianak', $isaacseymour$deprecated_time$Time$TimeZones$asia_pontianak),
					_Utils_Tuple2('Asia/Pyongyang', $isaacseymour$deprecated_time$Time$TimeZones$asia_pyongyang),
					_Utils_Tuple2('Asia/Qatar', $isaacseymour$deprecated_time$Time$TimeZones$asia_qatar),
					_Utils_Tuple2('Asia/Qyzylorda', $isaacseymour$deprecated_time$Time$TimeZones$asia_qyzylorda)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Asia/Rangoon', $isaacseymour$deprecated_time$Time$TimeZones$asia_rangoon),
					_Utils_Tuple2('Asia/Riyadh', $isaacseymour$deprecated_time$Time$TimeZones$asia_riyadh),
					_Utils_Tuple2('Asia/Saigon', $isaacseymour$deprecated_time$Time$TimeZones$asia_saigon),
					_Utils_Tuple2('Asia/Sakhalin', $isaacseymour$deprecated_time$Time$TimeZones$asia_sakhalin),
					_Utils_Tuple2('Asia/Samarkand', $isaacseymour$deprecated_time$Time$TimeZones$asia_samarkand),
					_Utils_Tuple2('Asia/Seoul', $isaacseymour$deprecated_time$Time$TimeZones$asia_seoul),
					_Utils_Tuple2('Asia/Shanghai', $isaacseymour$deprecated_time$Time$TimeZones$asia_shanghai),
					_Utils_Tuple2('Asia/Singapore', $isaacseymour$deprecated_time$Time$TimeZones$asia_singapore),
					_Utils_Tuple2('Asia/Srednekolymsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_srednekolymsk),
					_Utils_Tuple2('Asia/Taipei', $isaacseymour$deprecated_time$Time$TimeZones$asia_taipei),
					_Utils_Tuple2('Asia/Tashkent', $isaacseymour$deprecated_time$Time$TimeZones$asia_tashkent),
					_Utils_Tuple2('Asia/Tbilisi', $isaacseymour$deprecated_time$Time$TimeZones$asia_tbilisi),
					_Utils_Tuple2('Asia/Tehran', $isaacseymour$deprecated_time$Time$TimeZones$asia_tehran),
					_Utils_Tuple2('Asia/Tel_Aviv', $isaacseymour$deprecated_time$Time$TimeZones$asia_tel_aviv),
					_Utils_Tuple2('Asia/Thimbu', $isaacseymour$deprecated_time$Time$TimeZones$asia_thimbu),
					_Utils_Tuple2('Asia/Thimphu', $isaacseymour$deprecated_time$Time$TimeZones$asia_thimphu),
					_Utils_Tuple2('Asia/Tokyo', $isaacseymour$deprecated_time$Time$TimeZones$asia_tokyo),
					_Utils_Tuple2('Asia/Tomsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_tomsk),
					_Utils_Tuple2('Asia/Ujung_Pandang', $isaacseymour$deprecated_time$Time$TimeZones$asia_ujung_pandang),
					_Utils_Tuple2('Asia/Ulaanbaatar', $isaacseymour$deprecated_time$Time$TimeZones$asia_ulaanbaatar),
					_Utils_Tuple2('Asia/Ulan_Bator', $isaacseymour$deprecated_time$Time$TimeZones$asia_ulan_bator),
					_Utils_Tuple2('Asia/Urumqi', $isaacseymour$deprecated_time$Time$TimeZones$asia_urumqi),
					_Utils_Tuple2('Asia/Ust-Nera', $isaacseymour$deprecated_time$Time$TimeZones$asia_ust_nera),
					_Utils_Tuple2('Asia/Vientiane', $isaacseymour$deprecated_time$Time$TimeZones$asia_vientiane),
					_Utils_Tuple2('Asia/Vladivostok', $isaacseymour$deprecated_time$Time$TimeZones$asia_vladivostok),
					_Utils_Tuple2('Asia/Yakutsk', $isaacseymour$deprecated_time$Time$TimeZones$asia_yakutsk),
					_Utils_Tuple2('Asia/Yangon', $isaacseymour$deprecated_time$Time$TimeZones$asia_yangon),
					_Utils_Tuple2('Asia/Yekaterinburg', $isaacseymour$deprecated_time$Time$TimeZones$asia_yekaterinburg),
					_Utils_Tuple2('Asia/Yerevan', $isaacseymour$deprecated_time$Time$TimeZones$asia_yerevan),
					_Utils_Tuple2('Atlantic/Azores', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_azores),
					_Utils_Tuple2('Atlantic/Bermuda', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_bermuda),
					_Utils_Tuple2('Atlantic/Canary', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_canary),
					_Utils_Tuple2('Atlantic/Cape_Verde', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_cape_verde),
					_Utils_Tuple2('Atlantic/Faeroe', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_faeroe),
					_Utils_Tuple2('Atlantic/Faroe', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_faroe),
					_Utils_Tuple2('Atlantic/Jan_Mayen', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_jan_mayen),
					_Utils_Tuple2('Atlantic/Madeira', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_madeira),
					_Utils_Tuple2('Atlantic/Reykjavik', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_reykjavik),
					_Utils_Tuple2('Atlantic/South_Georgia', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_south_georgia),
					_Utils_Tuple2('Atlantic/St_Helena', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_st_helena),
					_Utils_Tuple2('Atlantic/Stanley', $isaacseymour$deprecated_time$Time$TimeZones$atlantic_stanley),
					_Utils_Tuple2('Australia/ACT', $isaacseymour$deprecated_time$Time$TimeZones$australia_act),
					_Utils_Tuple2('Australia/Adelaide', $isaacseymour$deprecated_time$Time$TimeZones$australia_adelaide),
					_Utils_Tuple2('Australia/Brisbane', $isaacseymour$deprecated_time$Time$TimeZones$australia_brisbane),
					_Utils_Tuple2('Australia/Broken_Hill', $isaacseymour$deprecated_time$Time$TimeZones$australia_broken_hill),
					_Utils_Tuple2('Australia/Canberra', $isaacseymour$deprecated_time$Time$TimeZones$australia_canberra),
					_Utils_Tuple2('Australia/Currie', $isaacseymour$deprecated_time$Time$TimeZones$australia_currie),
					_Utils_Tuple2('Australia/Darwin', $isaacseymour$deprecated_time$Time$TimeZones$australia_darwin),
					_Utils_Tuple2('Australia/Eucla', $isaacseymour$deprecated_time$Time$TimeZones$australia_eucla),
					_Utils_Tuple2('Australia/Hobart', $isaacseymour$deprecated_time$Time$TimeZones$australia_hobart)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Australia/LHI', $isaacseymour$deprecated_time$Time$TimeZones$australia_lhi),
					_Utils_Tuple2('Australia/Lindeman', $isaacseymour$deprecated_time$Time$TimeZones$australia_lindeman),
					_Utils_Tuple2('Australia/Lord_Howe', $isaacseymour$deprecated_time$Time$TimeZones$australia_lord_howe),
					_Utils_Tuple2('Australia/Melbourne', $isaacseymour$deprecated_time$Time$TimeZones$australia_melbourne),
					_Utils_Tuple2('Australia/North', $isaacseymour$deprecated_time$Time$TimeZones$australia_north),
					_Utils_Tuple2('Australia/NSW', $isaacseymour$deprecated_time$Time$TimeZones$australia_nsw),
					_Utils_Tuple2('Australia/Perth', $isaacseymour$deprecated_time$Time$TimeZones$australia_perth),
					_Utils_Tuple2('Australia/Queensland', $isaacseymour$deprecated_time$Time$TimeZones$australia_queensland),
					_Utils_Tuple2('Australia/South', $isaacseymour$deprecated_time$Time$TimeZones$australia_south),
					_Utils_Tuple2('Australia/Sydney', $isaacseymour$deprecated_time$Time$TimeZones$australia_sydney),
					_Utils_Tuple2('Australia/Tasmania', $isaacseymour$deprecated_time$Time$TimeZones$australia_tasmania),
					_Utils_Tuple2('Australia/Victoria', $isaacseymour$deprecated_time$Time$TimeZones$australia_victoria),
					_Utils_Tuple2('Australia/West', $isaacseymour$deprecated_time$Time$TimeZones$australia_west),
					_Utils_Tuple2('Australia/Yancowinna', $isaacseymour$deprecated_time$Time$TimeZones$australia_yancowinna),
					_Utils_Tuple2('Brazil/Acre', $isaacseymour$deprecated_time$Time$TimeZones$brazil_acre),
					_Utils_Tuple2('Brazil/DeNoronha', $isaacseymour$deprecated_time$Time$TimeZones$brazil_denoronha),
					_Utils_Tuple2('Brazil/East', $isaacseymour$deprecated_time$Time$TimeZones$brazil_east),
					_Utils_Tuple2('Brazil/West', $isaacseymour$deprecated_time$Time$TimeZones$brazil_west),
					_Utils_Tuple2('Canada/Atlantic', $isaacseymour$deprecated_time$Time$TimeZones$canada_atlantic),
					_Utils_Tuple2('Canada/Central', $isaacseymour$deprecated_time$Time$TimeZones$canada_central),
					_Utils_Tuple2('Canada/East-Saskatchewan', $isaacseymour$deprecated_time$Time$TimeZones$canada_east_saskatchewan),
					_Utils_Tuple2('Canada/Eastern', $isaacseymour$deprecated_time$Time$TimeZones$canada_eastern),
					_Utils_Tuple2('Canada/Mountain', $isaacseymour$deprecated_time$Time$TimeZones$canada_mountain),
					_Utils_Tuple2('Canada/Newfoundland', $isaacseymour$deprecated_time$Time$TimeZones$canada_newfoundland),
					_Utils_Tuple2('Canada/Pacific', $isaacseymour$deprecated_time$Time$TimeZones$canada_pacific),
					_Utils_Tuple2('Canada/Saskatchewan', $isaacseymour$deprecated_time$Time$TimeZones$canada_saskatchewan),
					_Utils_Tuple2('Canada/Yukon', $isaacseymour$deprecated_time$Time$TimeZones$canada_yukon),
					_Utils_Tuple2('CET', $isaacseymour$deprecated_time$Time$TimeZones$cet),
					_Utils_Tuple2('Chile/Continental', $isaacseymour$deprecated_time$Time$TimeZones$chile_continental),
					_Utils_Tuple2('Chile/EasterIsland', $isaacseymour$deprecated_time$Time$TimeZones$chile_easterisland),
					_Utils_Tuple2('CST6CDT', $isaacseymour$deprecated_time$Time$TimeZones$cst6cdt),
					_Utils_Tuple2('Cuba', $isaacseymour$deprecated_time$Time$TimeZones$cuba),
					_Utils_Tuple2('EET', $isaacseymour$deprecated_time$Time$TimeZones$eet),
					_Utils_Tuple2('Egypt', $isaacseymour$deprecated_time$Time$TimeZones$egypt),
					_Utils_Tuple2('Eire', $isaacseymour$deprecated_time$Time$TimeZones$eire),
					_Utils_Tuple2('EST', $isaacseymour$deprecated_time$Time$TimeZones$est),
					_Utils_Tuple2('EST5EDT', $isaacseymour$deprecated_time$Time$TimeZones$est5edt),
					_Utils_Tuple2('Etc/GMT', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt),
					_Utils_Tuple2('Etc/GMT0', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_0),
					_Utils_Tuple2('Etc/GMT-0', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_0),
					_Utils_Tuple2('Etc/GMT-1', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_1),
					_Utils_Tuple2('Etc/GMT-10', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_10),
					_Utils_Tuple2('Etc/GMT-11', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_11),
					_Utils_Tuple2('Etc/GMT-12', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_12),
					_Utils_Tuple2('Etc/GMT-13', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_13),
					_Utils_Tuple2('Etc/GMT-14', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_14),
					_Utils_Tuple2('Etc/GMT-2', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_2),
					_Utils_Tuple2('Etc/GMT-3', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_3),
					_Utils_Tuple2('Etc/GMT-4', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_4),
					_Utils_Tuple2('Etc/GMT-5', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_5)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Etc/GMT-6', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_6),
					_Utils_Tuple2('Etc/GMT-7', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_7),
					_Utils_Tuple2('Etc/GMT-8', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_8),
					_Utils_Tuple2('Etc/GMT-9', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_minus_9),
					_Utils_Tuple2('Etc/GMT+0', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_0),
					_Utils_Tuple2('Etc/GMT+1', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_1),
					_Utils_Tuple2('Etc/GMT+10', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_10),
					_Utils_Tuple2('Etc/GMT+11', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_11),
					_Utils_Tuple2('Etc/GMT+12', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_12),
					_Utils_Tuple2('Etc/GMT+2', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_2),
					_Utils_Tuple2('Etc/GMT+3', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_3),
					_Utils_Tuple2('Etc/GMT+4', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_4),
					_Utils_Tuple2('Etc/GMT+5', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_5),
					_Utils_Tuple2('Etc/GMT+6', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_6),
					_Utils_Tuple2('Etc/GMT+7', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_7),
					_Utils_Tuple2('Etc/GMT+8', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_8),
					_Utils_Tuple2('Etc/GMT+9', $isaacseymour$deprecated_time$Time$TimeZones$etc_gmt_plus_9),
					_Utils_Tuple2('Etc/Greenwich', $isaacseymour$deprecated_time$Time$TimeZones$etc_greenwich),
					_Utils_Tuple2('Etc/UCT', $isaacseymour$deprecated_time$Time$TimeZones$etc_uct),
					_Utils_Tuple2('Etc/Universal', $isaacseymour$deprecated_time$Time$TimeZones$etc_universal),
					_Utils_Tuple2('Etc/UTC', $isaacseymour$deprecated_time$Time$TimeZones$etc_utc),
					_Utils_Tuple2('Etc/Zulu', $isaacseymour$deprecated_time$Time$TimeZones$etc_zulu),
					_Utils_Tuple2('Europe/Amsterdam', $isaacseymour$deprecated_time$Time$TimeZones$europe_amsterdam),
					_Utils_Tuple2('Europe/Andorra', $isaacseymour$deprecated_time$Time$TimeZones$europe_andorra),
					_Utils_Tuple2('Europe/Astrakhan', $isaacseymour$deprecated_time$Time$TimeZones$europe_astrakhan),
					_Utils_Tuple2('Europe/Athens', $isaacseymour$deprecated_time$Time$TimeZones$europe_athens),
					_Utils_Tuple2('Europe/Belfast', $isaacseymour$deprecated_time$Time$TimeZones$europe_belfast),
					_Utils_Tuple2('Europe/Belgrade', $isaacseymour$deprecated_time$Time$TimeZones$europe_belgrade),
					_Utils_Tuple2('Europe/Berlin', $isaacseymour$deprecated_time$Time$TimeZones$europe_berlin),
					_Utils_Tuple2('Europe/Bratislava', $isaacseymour$deprecated_time$Time$TimeZones$europe_bratislava),
					_Utils_Tuple2('Europe/Brussels', $isaacseymour$deprecated_time$Time$TimeZones$europe_brussels),
					_Utils_Tuple2('Europe/Bucharest', $isaacseymour$deprecated_time$Time$TimeZones$europe_bucharest),
					_Utils_Tuple2('Europe/Budapest', $isaacseymour$deprecated_time$Time$TimeZones$europe_budapest),
					_Utils_Tuple2('Europe/Busingen', $isaacseymour$deprecated_time$Time$TimeZones$europe_busingen),
					_Utils_Tuple2('Europe/Chisinau', $isaacseymour$deprecated_time$Time$TimeZones$europe_chisinau),
					_Utils_Tuple2('Europe/Copenhagen', $isaacseymour$deprecated_time$Time$TimeZones$europe_copenhagen),
					_Utils_Tuple2('Europe/Dublin', $isaacseymour$deprecated_time$Time$TimeZones$europe_dublin),
					_Utils_Tuple2('Europe/Gibraltar', $isaacseymour$deprecated_time$Time$TimeZones$europe_gibraltar),
					_Utils_Tuple2('Europe/Guernsey', $isaacseymour$deprecated_time$Time$TimeZones$europe_guernsey),
					_Utils_Tuple2('Europe/Helsinki', $isaacseymour$deprecated_time$Time$TimeZones$europe_helsinki),
					_Utils_Tuple2('Europe/Isle_of_Man', $isaacseymour$deprecated_time$Time$TimeZones$europe_isle_of_man),
					_Utils_Tuple2('Europe/Istanbul', $isaacseymour$deprecated_time$Time$TimeZones$europe_istanbul),
					_Utils_Tuple2('Europe/Jersey', $isaacseymour$deprecated_time$Time$TimeZones$europe_jersey),
					_Utils_Tuple2('Europe/Kaliningrad', $isaacseymour$deprecated_time$Time$TimeZones$europe_kaliningrad),
					_Utils_Tuple2('Europe/Kiev', $isaacseymour$deprecated_time$Time$TimeZones$europe_kiev),
					_Utils_Tuple2('Europe/Kirov', $isaacseymour$deprecated_time$Time$TimeZones$europe_kirov),
					_Utils_Tuple2('Europe/Lisbon', $isaacseymour$deprecated_time$Time$TimeZones$europe_lisbon),
					_Utils_Tuple2('Europe/Ljubljana', $isaacseymour$deprecated_time$Time$TimeZones$europe_ljubljana),
					_Utils_Tuple2('Europe/London', $isaacseymour$deprecated_time$Time$TimeZones$europe_london),
					_Utils_Tuple2('Europe/Luxembourg', $isaacseymour$deprecated_time$Time$TimeZones$europe_luxembourg)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Europe/Madrid', $isaacseymour$deprecated_time$Time$TimeZones$europe_madrid),
					_Utils_Tuple2('Europe/Malta', $isaacseymour$deprecated_time$Time$TimeZones$europe_malta),
					_Utils_Tuple2('Europe/Mariehamn', $isaacseymour$deprecated_time$Time$TimeZones$europe_mariehamn),
					_Utils_Tuple2('Europe/Minsk', $isaacseymour$deprecated_time$Time$TimeZones$europe_minsk),
					_Utils_Tuple2('Europe/Monaco', $isaacseymour$deprecated_time$Time$TimeZones$europe_monaco),
					_Utils_Tuple2('Europe/Moscow', $isaacseymour$deprecated_time$Time$TimeZones$europe_moscow),
					_Utils_Tuple2('Europe/Nicosia', $isaacseymour$deprecated_time$Time$TimeZones$europe_nicosia),
					_Utils_Tuple2('Europe/Oslo', $isaacseymour$deprecated_time$Time$TimeZones$europe_oslo),
					_Utils_Tuple2('Europe/Paris', $isaacseymour$deprecated_time$Time$TimeZones$europe_paris),
					_Utils_Tuple2('Europe/Podgorica', $isaacseymour$deprecated_time$Time$TimeZones$europe_podgorica),
					_Utils_Tuple2('Europe/Prague', $isaacseymour$deprecated_time$Time$TimeZones$europe_prague),
					_Utils_Tuple2('Europe/Riga', $isaacseymour$deprecated_time$Time$TimeZones$europe_riga),
					_Utils_Tuple2('Europe/Rome', $isaacseymour$deprecated_time$Time$TimeZones$europe_rome),
					_Utils_Tuple2('Europe/Samara', $isaacseymour$deprecated_time$Time$TimeZones$europe_samara),
					_Utils_Tuple2('Europe/San_Marino', $isaacseymour$deprecated_time$Time$TimeZones$europe_san_marino),
					_Utils_Tuple2('Europe/Sarajevo', $isaacseymour$deprecated_time$Time$TimeZones$europe_sarajevo),
					_Utils_Tuple2('Europe/Simferopol', $isaacseymour$deprecated_time$Time$TimeZones$europe_simferopol),
					_Utils_Tuple2('Europe/Skopje', $isaacseymour$deprecated_time$Time$TimeZones$europe_skopje),
					_Utils_Tuple2('Europe/Sofia', $isaacseymour$deprecated_time$Time$TimeZones$europe_sofia),
					_Utils_Tuple2('Europe/Stockholm', $isaacseymour$deprecated_time$Time$TimeZones$europe_stockholm),
					_Utils_Tuple2('Europe/Tallinn', $isaacseymour$deprecated_time$Time$TimeZones$europe_tallinn),
					_Utils_Tuple2('Europe/Tirane', $isaacseymour$deprecated_time$Time$TimeZones$europe_tirane),
					_Utils_Tuple2('Europe/Tiraspol', $isaacseymour$deprecated_time$Time$TimeZones$europe_tiraspol),
					_Utils_Tuple2('Europe/Ulyanovsk', $isaacseymour$deprecated_time$Time$TimeZones$europe_ulyanovsk),
					_Utils_Tuple2('Europe/Uzhgorod', $isaacseymour$deprecated_time$Time$TimeZones$europe_uzhgorod),
					_Utils_Tuple2('Europe/Vaduz', $isaacseymour$deprecated_time$Time$TimeZones$europe_vaduz),
					_Utils_Tuple2('Europe/Vatican', $isaacseymour$deprecated_time$Time$TimeZones$europe_vatican),
					_Utils_Tuple2('Europe/Vienna', $isaacseymour$deprecated_time$Time$TimeZones$europe_vienna),
					_Utils_Tuple2('Europe/Vilnius', $isaacseymour$deprecated_time$Time$TimeZones$europe_vilnius),
					_Utils_Tuple2('Europe/Volgograd', $isaacseymour$deprecated_time$Time$TimeZones$europe_volgograd),
					_Utils_Tuple2('Europe/Warsaw', $isaacseymour$deprecated_time$Time$TimeZones$europe_warsaw),
					_Utils_Tuple2('Europe/Zagreb', $isaacseymour$deprecated_time$Time$TimeZones$europe_zagreb),
					_Utils_Tuple2('Europe/Zaporozhye', $isaacseymour$deprecated_time$Time$TimeZones$europe_zaporozhye),
					_Utils_Tuple2('Europe/Zurich', $isaacseymour$deprecated_time$Time$TimeZones$europe_zurich),
					_Utils_Tuple2('GB', $isaacseymour$deprecated_time$Time$TimeZones$gb),
					_Utils_Tuple2('GB-Eire', $isaacseymour$deprecated_time$Time$TimeZones$gb_eire),
					_Utils_Tuple2('GMT', $isaacseymour$deprecated_time$Time$TimeZones$gmt),
					_Utils_Tuple2('GMT0', $isaacseymour$deprecated_time$Time$TimeZones$gmt_0),
					_Utils_Tuple2('GMT-0', $isaacseymour$deprecated_time$Time$TimeZones$gmt_minus_0),
					_Utils_Tuple2('GMT+0', $isaacseymour$deprecated_time$Time$TimeZones$gmt_plus_0),
					_Utils_Tuple2('Greenwich', $isaacseymour$deprecated_time$Time$TimeZones$greenwich),
					_Utils_Tuple2('Hongkong', $isaacseymour$deprecated_time$Time$TimeZones$hongkong),
					_Utils_Tuple2('HST', $isaacseymour$deprecated_time$Time$TimeZones$hst),
					_Utils_Tuple2('Iceland', $isaacseymour$deprecated_time$Time$TimeZones$iceland),
					_Utils_Tuple2('Indian/Antananarivo', $isaacseymour$deprecated_time$Time$TimeZones$indian_antananarivo),
					_Utils_Tuple2('Indian/Chagos', $isaacseymour$deprecated_time$Time$TimeZones$indian_chagos),
					_Utils_Tuple2('Indian/Christmas', $isaacseymour$deprecated_time$Time$TimeZones$indian_christmas),
					_Utils_Tuple2('Indian/Cocos', $isaacseymour$deprecated_time$Time$TimeZones$indian_cocos),
					_Utils_Tuple2('Indian/Comoro', $isaacseymour$deprecated_time$Time$TimeZones$indian_comoro),
					_Utils_Tuple2('Indian/Kerguelen', $isaacseymour$deprecated_time$Time$TimeZones$indian_kerguelen)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Indian/Mahe', $isaacseymour$deprecated_time$Time$TimeZones$indian_mahe),
					_Utils_Tuple2('Indian/Maldives', $isaacseymour$deprecated_time$Time$TimeZones$indian_maldives),
					_Utils_Tuple2('Indian/Mauritius', $isaacseymour$deprecated_time$Time$TimeZones$indian_mauritius),
					_Utils_Tuple2('Indian/Mayotte', $isaacseymour$deprecated_time$Time$TimeZones$indian_mayotte),
					_Utils_Tuple2('Indian/Reunion', $isaacseymour$deprecated_time$Time$TimeZones$indian_reunion),
					_Utils_Tuple2('Iran', $isaacseymour$deprecated_time$Time$TimeZones$iran),
					_Utils_Tuple2('Israel', $isaacseymour$deprecated_time$Time$TimeZones$israel),
					_Utils_Tuple2('Jamaica', $isaacseymour$deprecated_time$Time$TimeZones$jamaica),
					_Utils_Tuple2('Japan', $isaacseymour$deprecated_time$Time$TimeZones$japan),
					_Utils_Tuple2('Kwajalein', $isaacseymour$deprecated_time$Time$TimeZones$kwajalein),
					_Utils_Tuple2('Libya', $isaacseymour$deprecated_time$Time$TimeZones$libya),
					_Utils_Tuple2('MET', $isaacseymour$deprecated_time$Time$TimeZones$met),
					_Utils_Tuple2('Mexico/BajaNorte', $isaacseymour$deprecated_time$Time$TimeZones$mexico_bajanorte),
					_Utils_Tuple2('Mexico/BajaSur', $isaacseymour$deprecated_time$Time$TimeZones$mexico_bajasur),
					_Utils_Tuple2('Mexico/General', $isaacseymour$deprecated_time$Time$TimeZones$mexico_general),
					_Utils_Tuple2('MST', $isaacseymour$deprecated_time$Time$TimeZones$mst),
					_Utils_Tuple2('MST7MDT', $isaacseymour$deprecated_time$Time$TimeZones$mst7mdt),
					_Utils_Tuple2('Navajo', $isaacseymour$deprecated_time$Time$TimeZones$navajo),
					_Utils_Tuple2('NZ', $isaacseymour$deprecated_time$Time$TimeZones$nz),
					_Utils_Tuple2('NZ-CHAT', $isaacseymour$deprecated_time$Time$TimeZones$nz_chat),
					_Utils_Tuple2('Pacific/Apia', $isaacseymour$deprecated_time$Time$TimeZones$pacific_apia),
					_Utils_Tuple2('Pacific/Auckland', $isaacseymour$deprecated_time$Time$TimeZones$pacific_auckland),
					_Utils_Tuple2('Pacific/Bougainville', $isaacseymour$deprecated_time$Time$TimeZones$pacific_bougainville),
					_Utils_Tuple2('Pacific/Chatham', $isaacseymour$deprecated_time$Time$TimeZones$pacific_chatham),
					_Utils_Tuple2('Pacific/Chuuk', $isaacseymour$deprecated_time$Time$TimeZones$pacific_chuuk),
					_Utils_Tuple2('Pacific/Easter', $isaacseymour$deprecated_time$Time$TimeZones$pacific_easter),
					_Utils_Tuple2('Pacific/Efate', $isaacseymour$deprecated_time$Time$TimeZones$pacific_efate),
					_Utils_Tuple2('Pacific/Enderbury', $isaacseymour$deprecated_time$Time$TimeZones$pacific_enderbury),
					_Utils_Tuple2('Pacific/Fakaofo', $isaacseymour$deprecated_time$Time$TimeZones$pacific_fakaofo),
					_Utils_Tuple2('Pacific/Fiji', $isaacseymour$deprecated_time$Time$TimeZones$pacific_fiji),
					_Utils_Tuple2('Pacific/Funafuti', $isaacseymour$deprecated_time$Time$TimeZones$pacific_funafuti),
					_Utils_Tuple2('Pacific/Galapagos', $isaacseymour$deprecated_time$Time$TimeZones$pacific_galapagos),
					_Utils_Tuple2('Pacific/Gambier', $isaacseymour$deprecated_time$Time$TimeZones$pacific_gambier),
					_Utils_Tuple2('Pacific/Guadalcanal', $isaacseymour$deprecated_time$Time$TimeZones$pacific_guadalcanal),
					_Utils_Tuple2('Pacific/Guam', $isaacseymour$deprecated_time$Time$TimeZones$pacific_guam),
					_Utils_Tuple2('Pacific/Honolulu', $isaacseymour$deprecated_time$Time$TimeZones$pacific_honolulu),
					_Utils_Tuple2('Pacific/Johnston', $isaacseymour$deprecated_time$Time$TimeZones$pacific_johnston),
					_Utils_Tuple2('Pacific/Kiritimati', $isaacseymour$deprecated_time$Time$TimeZones$pacific_kiritimati),
					_Utils_Tuple2('Pacific/Kosrae', $isaacseymour$deprecated_time$Time$TimeZones$pacific_kosrae),
					_Utils_Tuple2('Pacific/Kwajalein', $isaacseymour$deprecated_time$Time$TimeZones$pacific_kwajalein),
					_Utils_Tuple2('Pacific/Majuro', $isaacseymour$deprecated_time$Time$TimeZones$pacific_majuro),
					_Utils_Tuple2('Pacific/Marquesas', $isaacseymour$deprecated_time$Time$TimeZones$pacific_marquesas),
					_Utils_Tuple2('Pacific/Midway', $isaacseymour$deprecated_time$Time$TimeZones$pacific_midway),
					_Utils_Tuple2('Pacific/Nauru', $isaacseymour$deprecated_time$Time$TimeZones$pacific_nauru),
					_Utils_Tuple2('Pacific/Niue', $isaacseymour$deprecated_time$Time$TimeZones$pacific_niue),
					_Utils_Tuple2('Pacific/Norfolk', $isaacseymour$deprecated_time$Time$TimeZones$pacific_norfolk),
					_Utils_Tuple2('Pacific/Noumea', $isaacseymour$deprecated_time$Time$TimeZones$pacific_noumea),
					_Utils_Tuple2('Pacific/Pago_Pago', $isaacseymour$deprecated_time$Time$TimeZones$pacific_pago_pago),
					_Utils_Tuple2('Pacific/Palau', $isaacseymour$deprecated_time$Time$TimeZones$pacific_palau),
					_Utils_Tuple2('Pacific/Pitcairn', $isaacseymour$deprecated_time$Time$TimeZones$pacific_pitcairn)
				]),
				_List_fromArray(
				[
					_Utils_Tuple2('Pacific/Pohnpei', $isaacseymour$deprecated_time$Time$TimeZones$pacific_pohnpei),
					_Utils_Tuple2('Pacific/Ponape', $isaacseymour$deprecated_time$Time$TimeZones$pacific_ponape),
					_Utils_Tuple2('Pacific/Port_Moresby', $isaacseymour$deprecated_time$Time$TimeZones$pacific_port_moresby),
					_Utils_Tuple2('Pacific/Rarotonga', $isaacseymour$deprecated_time$Time$TimeZones$pacific_rarotonga),
					_Utils_Tuple2('Pacific/Saipan', $isaacseymour$deprecated_time$Time$TimeZones$pacific_saipan),
					_Utils_Tuple2('Pacific/Samoa', $isaacseymour$deprecated_time$Time$TimeZones$pacific_samoa),
					_Utils_Tuple2('Pacific/Tahiti', $isaacseymour$deprecated_time$Time$TimeZones$pacific_tahiti),
					_Utils_Tuple2('Pacific/Tarawa', $isaacseymour$deprecated_time$Time$TimeZones$pacific_tarawa),
					_Utils_Tuple2('Pacific/Tongatapu', $isaacseymour$deprecated_time$Time$TimeZones$pacific_tongatapu),
					_Utils_Tuple2('Pacific/Truk', $isaacseymour$deprecated_time$Time$TimeZones$pacific_truk),
					_Utils_Tuple2('Pacific/Wake', $isaacseymour$deprecated_time$Time$TimeZones$pacific_wake),
					_Utils_Tuple2('Pacific/Wallis', $isaacseymour$deprecated_time$Time$TimeZones$pacific_wallis),
					_Utils_Tuple2('Pacific/Yap', $isaacseymour$deprecated_time$Time$TimeZones$pacific_yap),
					_Utils_Tuple2('Poland', $isaacseymour$deprecated_time$Time$TimeZones$poland),
					_Utils_Tuple2('Portugal', $isaacseymour$deprecated_time$Time$TimeZones$portugal),
					_Utils_Tuple2('PRC', $isaacseymour$deprecated_time$Time$TimeZones$prc),
					_Utils_Tuple2('PST8PDT', $isaacseymour$deprecated_time$Time$TimeZones$pst8pdt),
					_Utils_Tuple2('ROC', $isaacseymour$deprecated_time$Time$TimeZones$roc),
					_Utils_Tuple2('ROK', $isaacseymour$deprecated_time$Time$TimeZones$rok),
					_Utils_Tuple2('Singapore', $isaacseymour$deprecated_time$Time$TimeZones$singapore),
					_Utils_Tuple2('Turkey', $isaacseymour$deprecated_time$Time$TimeZones$turkey),
					_Utils_Tuple2('UCT', $isaacseymour$deprecated_time$Time$TimeZones$uct),
					_Utils_Tuple2('Universal', $isaacseymour$deprecated_time$Time$TimeZones$universal),
					_Utils_Tuple2('US/Alaska', $isaacseymour$deprecated_time$Time$TimeZones$us_alaska),
					_Utils_Tuple2('US/Aleutian', $isaacseymour$deprecated_time$Time$TimeZones$us_aleutian),
					_Utils_Tuple2('US/Arizona', $isaacseymour$deprecated_time$Time$TimeZones$us_arizona),
					_Utils_Tuple2('US/Central', $isaacseymour$deprecated_time$Time$TimeZones$us_central),
					_Utils_Tuple2('US/East-Indiana', $isaacseymour$deprecated_time$Time$TimeZones$us_east_indiana),
					_Utils_Tuple2('US/Eastern', $isaacseymour$deprecated_time$Time$TimeZones$us_eastern),
					_Utils_Tuple2('US/Hawaii', $isaacseymour$deprecated_time$Time$TimeZones$us_hawaii),
					_Utils_Tuple2('US/Indiana-Starke', $isaacseymour$deprecated_time$Time$TimeZones$us_indiana_starke),
					_Utils_Tuple2('US/Michigan', $isaacseymour$deprecated_time$Time$TimeZones$us_michigan),
					_Utils_Tuple2('US/Mountain', $isaacseymour$deprecated_time$Time$TimeZones$us_mountain),
					_Utils_Tuple2('US/Pacific', $isaacseymour$deprecated_time$Time$TimeZones$us_pacific),
					_Utils_Tuple2('US/Pacific-New', $isaacseymour$deprecated_time$Time$TimeZones$us_pacific_new),
					_Utils_Tuple2('US/Samoa', $isaacseymour$deprecated_time$Time$TimeZones$us_samoa),
					_Utils_Tuple2('UTC', $isaacseymour$deprecated_time$Time$TimeZones$utc),
					_Utils_Tuple2('W-SU', $isaacseymour$deprecated_time$Time$TimeZones$w_su),
					_Utils_Tuple2('WET', $isaacseymour$deprecated_time$Time$TimeZones$wet),
					_Utils_Tuple2('Zulu', $isaacseymour$deprecated_time$Time$TimeZones$zulu)
				])
			])));
var $isaacseymour$deprecated_time$Time$TimeZones$fromName = function (name) {
	return A2($elm$core$Dict$get, name, $isaacseymour$deprecated_time$Time$TimeZones$all);
};
var $author$project$Carwow$Http$sameOrigin = $author$project$Carwow$Http$defaultClient;
var $author$project$OrdersTable$init = function (flags) {
	var parsedTimezone = function () {
		var _v0 = $isaacseymour$deprecated_time$Time$TimeZones$fromName(flags.bF);
		if (_v0.$ === 1) {
			return $isaacseymour$deprecated_time$Time$TimeZones$utc;
		} else {
			var t = _v0.a;
			return t;
		}
	}();
	var adminHttpClient = A2($author$project$Carwow$Http$auth, 'Bearer ' + flags.H, $author$project$Carwow$Http$sameOrigin);
	return _Utils_Tuple2(
		{bK: adminHttpClient, bt: $elm$core$Maybe$Nothing, f_: $krisajenkins$remotedata$RemoteData$Loading, bF: parsedTimezone},
		A2($elm$core$Task$perform, $author$project$OrdersTable$NewNow, $elm$time$Time$now));
};
var $author$project$Orders$init = function (flags) {
	var dealersSiteHttpClient = A2(
		$author$project$Carwow$Http$auth,
		'Bearer ' + flags.H,
		$author$project$Carwow$Http$crossOrigin(flags.L));
	var _v0 = $author$project$OrdersTable$init(
		{H: flags.H, bF: flags.bF});
	var ordersTable = _v0.a;
	var tableCmd = _v0.b;
	var _v1 = $author$project$OrderFilters$init(flags);
	var orderFilters = _v1.a;
	var filterCmd = _v1.b;
	var model = {ar: $elm$core$Maybe$Nothing, bU: dealersSiteHttpClient, Y: flags.Y, ax: orderFilters, az: $krisajenkins$remotedata$RemoteData$Loading, x: ordersTable};
	var cmd = $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				$author$project$Orders$fetchOrders(model),
				A2($elm$core$Platform$Cmd$map, $author$project$Orders$OrdersTableMsg, tableCmd),
				A2($elm$core$Platform$Cmd$map, $author$project$Orders$OrderFiltersMsg, filterCmd)
			]));
	return _Utils_Tuple2(model, cmd);
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $author$project$PaginatedList$PaginatedList = $elm$core$Basics$identity;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$parser$Parser$ExpectingKeyword = function (a) {
	return {$: 9, a: a};
};
var $elm$parser$Parser$Advanced$keyword = function (_v0) {
	var kwd = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(kwd);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, kwd, s.b, s.dL, s.cT, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return (_Utils_eq(newOffset, -1) || (0 <= A3(
			$elm$parser$Parser$Advanced$isSubChar,
			function (c) {
				return $elm$core$Char$isAlphaNum(c) || (c === '_');
			},
			newOffset,
			s.a))) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{cT: newCol, bd: s.bd, e: s.e, b: newOffset, dL: newRow, a: s.a});
	};
};
var $elm$parser$Parser$keyword = function (kwd) {
	return $elm$parser$Parser$Advanced$keyword(
		A2(
			$elm$parser$Parser$Advanced$Token,
			kwd,
			$elm$parser$Parser$ExpectingKeyword(kwd)));
};
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $elm$parser$Parser$spaces = $elm$parser$Parser$Advanced$spaces;
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $elm$parser$Parser$ExpectingVariable = {$: 7};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$parser$Parser$Advanced$varHelp = F7(
	function (isGood, offset, row, col, src, indent, context) {
		varHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, src);
			if (_Utils_eq(newOffset, -1)) {
				return {cT: col, bd: context, e: indent, b: offset, dL: row, a: src};
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$src = src,
						$temp$indent = indent,
						$temp$context = context;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					src = $temp$src;
					indent = $temp$indent;
					context = $temp$context;
					continue varHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$src = src,
						$temp$indent = indent,
						$temp$context = context;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					src = $temp$src;
					indent = $temp$indent;
					context = $temp$context;
					continue varHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$variable = function (i) {
	return function (s) {
		var firstOffset = A3($elm$parser$Parser$Advanced$isSubChar, i.cB, s.b, s.a);
		if (_Utils_eq(firstOffset, -1)) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, i.c1));
		} else {
			var s1 = _Utils_eq(firstOffset, -2) ? A7($elm$parser$Parser$Advanced$varHelp, i.da, s.b + 1, s.dL + 1, 1, s.a, s.e, s.bd) : A7($elm$parser$Parser$Advanced$varHelp, i.da, firstOffset, s.dL, s.cT + 1, s.a, s.e, s.bd);
			var name = A3($elm$core$String$slice, s.b, s1.b, s.a);
			return A2($elm$core$Set$member, name, i.dK) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, i.c1)) : A3($elm$parser$Parser$Advanced$Good, true, name, s1);
		}
	};
};
var $elm$parser$Parser$variable = function (i) {
	return $elm$parser$Parser$Advanced$variable(
		{c1: $elm$parser$Parser$ExpectingVariable, da: i.da, dK: i.dK, cB: i.cB});
};
var $jackfranklin$elm_parse_link_header$LinkHeader$initialParser = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(
				F2(
					function (u, r) {
						return {dD: r, a9: u};
					})),
			$elm$parser$Parser$symbol('<')),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$variable(
								{
									da: $elm$core$Basics$neq('>'),
									dK: $elm$core$Set$empty,
									cB: $elm$core$Char$isLower
								}),
							$elm$parser$Parser$symbol('>')),
						$elm$parser$Parser$symbol(';')),
					$elm$parser$Parser$spaces),
				$elm$parser$Parser$keyword('rel=')),
			$elm$parser$Parser$symbol('\"'))),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$variable(
				{da: $elm$core$Char$isAlpha, dK: $elm$core$Set$empty, cB: $elm$core$Char$isLower}),
			$elm$parser$Parser$symbol('\"')),
		$elm$parser$Parser$end));
var $jackfranklin$elm_parse_link_header$LinkHeader$WebLink = F2(
	function (rel, url) {
		return {dD: rel, a9: url};
	});
var $jackfranklin$elm_parse_link_header$LinkHeader$RelFirst = function (a) {
	return {$: 2, a: a};
};
var $jackfranklin$elm_parse_link_header$LinkHeader$RelLast = function (a) {
	return {$: 3, a: a};
};
var $jackfranklin$elm_parse_link_header$LinkHeader$RelNext = function (a) {
	return {$: 0, a: a};
};
var $jackfranklin$elm_parse_link_header$LinkHeader$RelPrev = function (a) {
	return {$: 1, a: a};
};
var $jackfranklin$elm_parse_link_header$LinkHeader$RelUnknown = function (a) {
	return {$: 4, a: a};
};
var $jackfranklin$elm_parse_link_header$LinkHeader$relStringToType = F2(
	function (string, page) {
		switch (string) {
			case 'next':
				return $jackfranklin$elm_parse_link_header$LinkHeader$RelNext(page);
			case 'last':
				return $jackfranklin$elm_parse_link_header$LinkHeader$RelLast(page);
			case 'first':
				return $jackfranklin$elm_parse_link_header$LinkHeader$RelFirst(page);
			case 'prev':
				return $jackfranklin$elm_parse_link_header$LinkHeader$RelPrev(page);
			default:
				return $jackfranklin$elm_parse_link_header$LinkHeader$RelUnknown(page);
		}
	});
var $jackfranklin$elm_parse_link_header$LinkHeader$linkParser = function (_v0) {
	var url = _v0.a9;
	var rel = _v0.dD;
	var urlParser = $elm$url$Url$Parser$query(
		$elm$url$Url$Parser$Query$int('page'));
	var parsePage = function (u) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$Basics$identity,
			A2(
				$elm$url$Url$Parser$parse,
				urlParser,
				function (url_) {
					return _Utils_update(
						url_,
						{f$: ''});
				}(u)));
	};
	var maybePage = A2(
		$elm$core$Maybe$andThen,
		parsePage,
		$elm$url$Url$fromString(url));
	if (maybePage.$ === 1) {
		return $elm$parser$Parser$problem('invalid URL');
	} else {
		var page = maybePage.a;
		return $elm$parser$Parser$succeed(
			A2(
				$jackfranklin$elm_parse_link_header$LinkHeader$WebLink,
				A2($jackfranklin$elm_parse_link_header$LinkHeader$relStringToType, rel, page),
				url));
	}
};
var $jackfranklin$elm_parse_link_header$LinkHeader$parseHeader = $elm$parser$Parser$run(
	A2($elm$parser$Parser$andThen, $jackfranklin$elm_parse_link_header$LinkHeader$linkParser, $jackfranklin$elm_parse_link_header$LinkHeader$initialParser));
var $elm$core$String$trim = _String_trim;
var $jackfranklin$elm_parse_link_header$LinkHeader$parse = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Result$toMaybe,
		A2(
			$elm$core$List$map,
			$jackfranklin$elm_parse_link_header$LinkHeader$parseHeader,
			A2(
				$elm$core$List$map,
				$elm$core$String$trim,
				A2($elm$core$String$split, ',', str))));
};
var $author$project$PaginatedList$pagesFromHeaders = function (headers) {
	var linksText = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2(
				$elm$core$List$filterMap,
				function (header) {
					return A2($elm$core$Dict$get, header, headers);
				},
				_List_fromArray(
					['links', 'Links']))));
	var relLinks = $jackfranklin$elm_parse_link_header$LinkHeader$parse(linksText);
	var extractIfPrev = function (_v3) {
		var rel = _v3.dD;
		if (rel.$ === 1) {
			var _int = rel.a;
			return $elm$core$Maybe$Just(_int);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var prevPage_ = $elm$core$List$head(
		A2($elm$core$List$filterMap, extractIfPrev, relLinks));
	var extractIfNext = function (_v1) {
		var rel = _v1.dD;
		if (!rel.$) {
			var _int = rel.a;
			return $elm$core$Maybe$Just(_int);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var nextPage_ = $elm$core$List$head(
		A2($elm$core$List$filterMap, extractIfNext, relLinks));
	return _Utils_Tuple2(prevPage_, nextPage_);
};
var $author$project$PaginatedList$totalCountFromHeaders = function (headers) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$andThen,
			$elm$core$String$toInt,
			$elm$core$List$head(
				A2(
					$elm$core$List$filterMap,
					function (header) {
						return A2($elm$core$Dict$get, header, headers);
					},
					_List_fromArray(
						['x-total-count', 'X-Total-Count'])))));
};
var $author$project$PaginatedList$fromResponse = function (response) {
	var _v0 = $author$project$PaginatedList$pagesFromHeaders(
		$elm$core$Dict$fromList(response.c7));
	var prevPage_ = _v0.a;
	var nextPage_ = _v0.b;
	return {
		cV: response.cP,
		$7: nextPage_,
		dy: prevPage_,
		d3: $author$project$PaginatedList$totalCountFromHeaders(
			$elm$core$Dict$fromList(response.c7))
	};
};
var $author$project$Carwow$Bugsnag$Internal$List = function (a) {
	return {$: 2, a: a};
};
var $author$project$Carwow$Bugsnag$Internal$String = function (a) {
	return {$: 0, a: a};
};
var $author$project$Carwow$Bugsnag$Internal$Error = $elm$core$Basics$identity;
var $author$project$Carwow$Bugsnag$addTab = F3(
	function (name, attributes, _v0) {
		var error = _v0;
		return _Utils_update(
			error,
			{
				cc: A3($elm$core$Dict$insert, name, attributes, error.cc)
			});
	});
var $author$project$Carwow$Bugsnag$Http$addContextTab = function (context) {
	return A2(
		$author$project$Carwow$Bugsnag$addTab,
		'context',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'method',
				$author$project$Carwow$Bugsnag$Internal$String(context.fv)),
				_Utils_Tuple2(
				'url',
				$author$project$Carwow$Bugsnag$Internal$String(context.a9)),
				_Utils_Tuple2(
				'headers',
				$author$project$Carwow$Bugsnag$Internal$List(context.c7))
			]));
};
var $author$project$Carwow$Bugsnag$addMessage = F2(
	function (message, _v0) {
		var error = _v0;
		return _Utils_update(
			error,
			{
				cb: A2(
					$elm$core$List$append,
					error.cb,
					_List_fromArray(
						[message]))
			});
	});
var $author$project$Carwow$Bugsnag$Internal$Int = function (a) {
	return {$: 1, a: a};
};
var $author$project$Carwow$Bugsnag$Http$addResponseTab = function (response) {
	return A2(
		$author$project$Carwow$Bugsnag$addTab,
		'response',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'status',
				$author$project$Carwow$Bugsnag$Internal$Int(response.dV)),
				_Utils_Tuple2(
				'headers',
				$author$project$Carwow$Bugsnag$Internal$List(response.c7)),
				_Utils_Tuple2(
				'body',
				$author$project$Carwow$Bugsnag$Internal$String(response.cP))
			]));
};
var $author$project$Carwow$Bugsnag$groupBy = F2(
	function (group, _v0) {
		var error = _v0;
		return _Utils_update(
			error,
			{
				b_: A2($elm$core$List$cons, group, error.b_)
			});
	});
var $author$project$Carwow$Bugsnag$newError = function (error) {
	return {eL: error, e1: $elm$core$Maybe$Nothing, b_: _List_Nil, cb: _List_Nil, cc: $elm$core$Dict$empty};
};
var $author$project$Carwow$Bugsnag$Http$newError = F2(
	function (group, error) {
		return A2(
			$author$project$Carwow$Bugsnag$groupBy,
			group,
			function () {
				switch (error.$) {
					case 0:
						var url = error.a;
						var context = error.b;
						return A2(
							$author$project$Carwow$Bugsnag$Http$addContextTab,
							context,
							A2(
								$author$project$Carwow$Bugsnag$addMessage,
								url,
								$author$project$Carwow$Bugsnag$newError('Http.BadUrl')));
					case 1:
						var context = error.a;
						return A2(
							$author$project$Carwow$Bugsnag$Http$addContextTab,
							context,
							A2(
								$author$project$Carwow$Bugsnag$addMessage,
								context.a9,
								$author$project$Carwow$Bugsnag$newError('Http.Timeout')));
					case 2:
						var context = error.a;
						return A2(
							$author$project$Carwow$Bugsnag$Http$addContextTab,
							context,
							A2(
								$author$project$Carwow$Bugsnag$addMessage,
								context.a9,
								$author$project$Carwow$Bugsnag$newError('Http.NetworkError')));
					case 3:
						var response = error.b;
						return A2(
							$author$project$Carwow$Bugsnag$Http$addResponseTab,
							response,
							A2(
								$author$project$Carwow$Bugsnag$Http$addContextTab,
								response.bd,
								A2(
									$author$project$Carwow$Bugsnag$addMessage,
									response.cP,
									A2(
										$author$project$Carwow$Bugsnag$addMessage,
										response.bd.a9,
										$author$project$Carwow$Bugsnag$newError(
											'Http.BadStatus: ' + $elm$core$String$fromInt(response.dV))))));
					default:
						var errorMessage = error.a;
						var response = error.b;
						return A2(
							$author$project$Carwow$Bugsnag$Http$addResponseTab,
							response,
							A2(
								$author$project$Carwow$Bugsnag$Http$addContextTab,
								response.bd,
								A2(
									$author$project$Carwow$Bugsnag$addMessage,
									errorMessage,
									A2(
										$author$project$Carwow$Bugsnag$addMessage,
										response.bd.a9,
										$author$project$Carwow$Bugsnag$newError('Http.BadBody')))));
				}
			}());
	});
var $author$project$Carwow$Bugsnag$bugsnagNotify = _Platform_outgoingPort('bugsnagNotify', $elm$core$Basics$identity);
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Carwow$Bugsnag$Internal$encodeValue = function (value) {
	switch (value.$) {
		case 0:
			var string = value.a;
			return $elm$json$Json$Encode$string(string);
		case 1:
			var _int = value.a;
			return $elm$json$Json$Encode$int(_int);
		default:
			var list = value.a;
			return A3(
				$elm$json$Json$Encode$dict,
				$elm$core$Basics$identity,
				$elm$json$Json$Encode$string,
				$elm$core$Dict$fromList(list));
	}
};
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Carwow$Bugsnag$Internal$encodeValueList = A2(
	$elm$core$Basics$composeL,
	$elm$json$Json$Encode$object,
	$elm$core$List$map(
		$elm$core$Tuple$mapSecond($author$project$Carwow$Bugsnag$Internal$encodeValue)));
var $author$project$Carwow$Bugsnag$Internal$encodeError = function (_v0) {
	var error = _v0;
	var errorWithGroups = function () {
		var _v1 = error.b_;
		if (!_v1.b) {
			return error.eL;
		} else {
			return A2(
				$elm$core$String$join,
				' @ ',
				_List_fromArray(
					[
						error.eL,
						A2($elm$core$String$join, ', ', error.b_)
					]));
		}
	}();
	var groupingHash = A2($elm$core$Maybe$withDefault, errorWithGroups, error.e1);
	var message = A2(
		$elm$core$String$join,
		'\n',
		A2($elm$core$List$cons, errorWithGroups, error.cb));
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'message',
				$elm$json$Json$Encode$string(message)),
				_Utils_Tuple2(
				'groupingHash',
				$elm$json$Json$Encode$string(groupingHash)),
				_Utils_Tuple2(
				'metaData',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $author$project$Carwow$Bugsnag$Internal$encodeValueList, error.cc))
			]));
};
var $author$project$Carwow$Bugsnag$notify = A2($elm$core$Basics$composeR, $author$project$Carwow$Bugsnag$Internal$encodeError, $author$project$Carwow$Bugsnag$bugsnagNotify);
var $author$project$Carwow$Bugsnag$Http$notify = F2(
	function (group, error) {
		return $author$project$Carwow$Bugsnag$notify(
			A2($author$project$Carwow$Bugsnag$Http$newError, group, error));
	});
var $author$project$ErrorHandler$notifyBugsnag = F2(
	function (title, error) {
		return A2($author$project$Carwow$Bugsnag$Http$notify, title, error);
	});
var $author$project$Toastr$errToString = function (e) {
	switch (e.$) {
		case 0:
			var url = e.a;
			return 'Invalid url: ' + url;
		case 1:
			return 'Timeout reaching the server. Check your connection and try again.';
		case 2:
			return 'Error reaching the server. Please check your connection!';
		case 3:
			var statusCode = e.a;
			return 'Server error: ' + $elm$core$String$fromInt(statusCode);
		default:
			var err = e.a;
			return 'Error: ' + err;
	}
};
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $author$project$Carwow$Http$toHttpError = function (error) {
	switch (error.$) {
		case 0:
			var badUrl = error.a;
			return $elm$http$Http$BadUrl(badUrl);
		case 1:
			return $elm$http$Http$Timeout;
		case 2:
			return $elm$http$Http$NetworkError;
		case 3:
			var code = error.a;
			return $elm$http$Http$BadStatus(code);
		default:
			var err = error.a;
			return $elm$http$Http$BadBody(err);
	}
};
var $author$project$Toastr$errorToString = A2($elm$core$Basics$composeR, $author$project$Carwow$Http$toHttpError, $author$project$Toastr$errToString);
var $author$project$Toastr$SimpleError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Toastr$OutgoingError = F2(
	function (summary, detail) {
		return {cZ: detail, d_: summary};
	});
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Toastr$error = _Platform_outgoingPort(
	'error',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'detail',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.cZ)),
					_Utils_Tuple2(
					'summary',
					$elm$json$Json$Encode$string($.d_))
				]));
	});
var $author$project$Toastr$showError = function (e) {
	var outgoingErr = function () {
		if (!e.$) {
			var err = e.a;
			return A2($author$project$Toastr$OutgoingError, err, $elm$core$Maybe$Nothing);
		} else {
			var err = e.a;
			var detail = e.b;
			return A2(
				$author$project$Toastr$OutgoingError,
				err,
				$elm$core$Maybe$Just(
					$author$project$Toastr$errToString(detail)));
		}
	}();
	return $author$project$Toastr$error(outgoingErr);
};
var $author$project$Toastr$simpleError = A2($elm$core$Basics$composeR, $author$project$Toastr$SimpleError, $author$project$Toastr$showError);
var $author$project$ErrorHandler$notifyUser = function (error) {
	return $author$project$Toastr$simpleError(
		$author$project$Toastr$errorToString(error));
};
var $author$project$ErrorHandler$notify = F2(
	function (error, title) {
		if (error.$ === 3) {
			var code = error.a;
			return (code < 500) ? $author$project$ErrorHandler$notifyUser(error) : $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$ErrorHandler$notifyUser(error),
						A2($author$project$ErrorHandler$notifyBugsnag, title, error)
					]));
		} else {
			return $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$ErrorHandler$notifyUser(error),
						A2($author$project$ErrorHandler$notifyBugsnag, title, error)
					]));
		}
	});
var $author$project$Orders$pushQuery = _Platform_outgoingPort('pushQuery', $elm$json$Json$Encode$string);
var $author$project$PaginatedList$toList = function (_v0) {
	var list = _v0;
	return list.cV;
};
var $author$project$OrdersTable$Closed = 1;
var $author$project$OrdersTable$Viewing = 0;
var $author$project$OrdersTable$wrapOrder = function (order) {
	return {aX: '', aY: 0, o: order, aC: 1};
};
var $author$project$OrdersTable$fromPage = function (page) {
	return A2(
		$elm$core$List$map,
		$author$project$OrdersTable$wrapOrder,
		$author$project$PaginatedList$toList(page));
};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$OrdersTable$setOrdersPage = F2(
	function (page, model) {
		var orders = A2($krisajenkins$remotedata$RemoteData$map, $author$project$OrdersTable$fromPage, page);
		return _Utils_update(
			model,
			{f_: orders});
	});
var $author$project$Data$Order$dealershipTypeToString = function (t) {
	if (!t) {
		return 'retail';
	} else {
		return 'leasing';
	}
};
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Data$Order$purchaseTypeToString = function (t) {
	switch (t) {
		case 0:
			return 'Stock';
		case 1:
			return 'Factory Order';
		case 2:
			return 'Demo';
		case 3:
			return 'Pre-Reg';
		default:
			return 'Used';
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Data$Order$salesmatchingTypeToString = function (t) {
	if (!t) {
		return 'only';
	} else {
		return 'none';
	}
};
var $author$project$Data$Order$stateToString = function (state) {
	switch (state) {
		case 0:
			return 'pending';
		case 1:
			return 'voided';
		case 2:
			return 'confirmed';
		default:
			return 'cancelled';
	}
};
var $author$project$OrderFilters$toQuery = function (_v0) {
	var query = _v0.c;
	var string = $elm$url$Url$Builder$string;
	var _int = $elm$url$Url$Builder$int;
	var dateField = function (key) {
		return $elm$core$Maybe$map(
			A2(
				$elm$core$Basics$composeR,
				$isaacseymour$deprecated_time$Time$Iso8601$fromDate,
				$elm$url$Url$Builder$string(key)));
	};
	var queries = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				A2(dateField, 'pending_before', query.ae),
				A2(dateField, 'pending_after', query.ad),
				A2(dateField, 'purchase_date_range_start', query.ag),
				A2(dateField, 'purchase_date_range_end', query.af),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Data$Order$stateToString,
					string('scope')),
				query.aD),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Data$Order$purchaseTypeToString,
					string('purchase_type')),
				query.f9),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Data$Order$salesmatchingTypeToString,
					string('salesmatching')),
				query.ai),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.e6;
					},
					_int('account_manager_id')),
				query.ed),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.e6;
					},
					_int('dealership_id')),
				query.y),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.e6;
					},
					_int('dealership_group_id')),
				query.z),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Data$Order$dealershipTypeToString,
					string('dealership_type')),
				query.W),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.e6;
					},
					_int('user_id')),
				query.C),
				A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.dS;
					},
					string('make_slug')),
				query.Z),
				A2(
				$elm$core$Maybe$map,
				_int('offer_id'),
				query.fT),
				A2(
				$elm$core$Maybe$map,
				_int('order_id'),
				query.ab)
			]));
	return A2($elm$url$Url$Builder$relative, _List_Nil, queries);
};
var $author$project$OrderFilters$AccountManagerAutocomplete = function (a) {
	return {$: 2, a: a};
};
var $author$project$OrderFilters$DealershipAutocomplete = function (a) {
	return {$: 7, a: a};
};
var $author$project$OrderFilters$DealershipGroupAutocomplete = function (a) {
	return {$: 10, a: a};
};
var $author$project$OrderFilters$MakeAutocomplete = function (a) {
	return {$: 5, a: a};
};
var $author$project$OrderFilters$OfferIdDebounce = function (a) {
	return {$: 16, a: a};
};
var $author$project$OrderFilters$OrderIdDebounce = function (a) {
	return {$: 19, a: a};
};
var $author$project$OrderFilters$SetOfferId = function (a) {
	return {$: 18, a: a};
};
var $author$project$OrderFilters$SetOrderId = function (a) {
	return {$: 21, a: a};
};
var $author$project$OrderFilters$UserAutocomplete = function (a) {
	return {$: 13, a: a};
};
var $jinjor$elm_debounce$Debounce$Later = function (a) {
	return {$: 2, a: a};
};
var $jinjor$elm_debounce$Debounce$later = $jinjor$elm_debounce$Debounce$Later;
var $author$project$OrderFilters$debounceConfig = function (msg) {
	return {
		gs: $jinjor$elm_debounce$Debounce$later(250.0),
		gF: msg
	};
};
var $author$project$OrderFilters$debouncedId = function (msg) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toInt,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Task$succeed,
			$elm$core$Task$perform(msg)));
};
var $author$project$Toastr$DetailedError = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Toastr$detailedError = F2(
	function (message, err) {
		return $author$project$Toastr$showError(
			A2($author$project$Toastr$DetailedError, message, err));
	});
var $author$project$Toastr$fromRemoteData = F2(
	function (errorMessage, data) {
		if (data.$ === 2) {
			var err = data.a;
			return $author$project$Toastr$showError(
				A2($author$project$Toastr$DetailedError, errorMessage, err));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $jinjor$elm_debounce$Debounce$Flush = function (a) {
	return {$: 1, a: a};
};
var $jinjor$elm_debounce$Debounce$SendIfLengthNotChangedFrom = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Process$sleep = _Process_sleep;
var $jinjor$elm_debounce$Debounce$delayCmd = F2(
	function (delay, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(delay));
	});
var $jinjor$elm_debounce$Debounce$length = function (_v0) {
	var input = _v0.G;
	return $elm$core$List$length(input);
};
var $jinjor$elm_debounce$Debounce$push = F3(
	function (config, a, _v0) {
		var d = _v0;
		var newDebounce = _Utils_update(
			d,
			{
				G: A2($elm$core$List$cons, a, d.G)
			});
		var selfCmd = function () {
			var _v1 = config.gs;
			switch (_v1.$) {
				case 0:
					var offset = _v1.a;
					return d.aW ? $elm$core$Platform$Cmd$none : A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						offset,
						$jinjor$elm_debounce$Debounce$Flush($elm$core$Maybe$Nothing));
				case 1:
					var offset = _v1.a;
					var delay = _v1.b;
					return d.aW ? $elm$core$Platform$Cmd$none : A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						offset,
						$jinjor$elm_debounce$Debounce$Flush(
							$elm$core$Maybe$Just(delay)));
				default:
					var delay = _v1.a;
					return A2(
						$jinjor$elm_debounce$Debounce$delayCmd,
						delay,
						$jinjor$elm_debounce$Debounce$SendIfLengthNotChangedFrom(
							$jinjor$elm_debounce$Debounce$length(newDebounce)));
			}
		}();
		return _Utils_Tuple2(
			newDebounce,
			A2($elm$core$Platform$Cmd$map, config.gF, selfCmd));
	});
var $jinjor$elm_debounce$Debounce$takeLast = F3(
	function (send, head, tail) {
		return _Utils_Tuple2(
			_List_Nil,
			send(head));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $jinjor$elm_debounce$Debounce$update = F4(
	function (config, send, msg, _v0) {
		var d = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(d, $elm$core$Platform$Cmd$none);
			case 1:
				var tryAgainAfter = msg.a;
				var _v2 = d.G;
				if (_v2.b) {
					var head = _v2.a;
					var tail = _v2.b;
					var selfCmd = function () {
						if (!tryAgainAfter.$) {
							var delay = tryAgainAfter.a;
							return A2(
								$jinjor$elm_debounce$Debounce$delayCmd,
								delay,
								$jinjor$elm_debounce$Debounce$Flush(
									$elm$core$Maybe$Just(delay)));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					var _v3 = A2(send, head, tail);
					var input = _v3.a;
					var sendCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{G: input, aW: true}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									sendCmd,
									A2($elm$core$Platform$Cmd$map, config.gF, selfCmd)
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{aW: false}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var lastInputLength = msg.a;
				var _v5 = _Utils_Tuple2(
					_Utils_cmp(
						$elm$core$List$length(d.G),
						lastInputLength) < 1,
					d.G);
				if (_v5.a && _v5.b.b) {
					var _v6 = _v5.b;
					var head = _v6.a;
					var tail = _v6.b;
					var _v7 = A2(send, head, tail);
					var input = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							d,
							{G: input}),
						cmd);
				} else {
					return _Utils_Tuple2(d, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$UI$Autocomplete$None = {$: 2};
var $author$project$UI$Autocomplete$Query = function (a) {
	return {$: 1, a: a};
};
var $author$project$UI$Autocomplete$Selected = function (a) {
	return {$: 0, a: a};
};
var $author$project$UI$Autocomplete$DebounceMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$UI$Autocomplete$debounceConfig = function (delay) {
	return {
		gs: $jinjor$elm_debounce$Debounce$later(delay),
		gF: $author$project$UI$Autocomplete$DebounceMsg
	};
};
var $author$project$UI$Autocomplete$OnQuery = function (a) {
	return {$: 3, a: a};
};
var $author$project$UI$Autocomplete$doQuery = A2(
	$elm$core$Basics$composeR,
	$elm$core$Task$succeed,
	$elm$core$Task$perform($author$project$UI$Autocomplete$OnQuery));
var $sporto$elm_select$Select$unwrapModel = function (model) {
	var m = model;
	return m;
};
var $sporto$elm_select$Select$unwrapMsg = function (msg) {
	var m = msg;
	return m;
};
var $sporto$elm_select$Select$Update$update = F3(
	function (config, msg, model) {
		var queryChangeCmd = function (value) {
			var _v7 = config.fV;
			if (_v7.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				var constructor = _v7.a;
				return A2(
					$elm$core$Task$perform,
					constructor,
					$elm$core$Task$succeed(value));
			}
		};
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var newHightlightedItem = function () {
					var _v1 = model.c9;
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Just(0);
					} else {
						var n = _v1.a;
						return $elm$core$Maybe$Just(n + 1);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c9: newHightlightedItem}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var newHightlightedItem = function () {
					var _v2 = model.c9;
					if (_v2.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						if (!_v2.a) {
							return $elm$core$Maybe$Nothing;
						} else {
							var n = _v2.a;
							return $elm$core$Maybe$Just(n - 1);
						}
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c9: newHightlightedItem}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var cmd = function () {
					var _v4 = config.fU;
					if (_v4.$ === 1) {
						return $elm$core$Platform$Cmd$none;
					} else {
						var focusMessage = _v4.a;
						return A2(
							$elm$core$Task$perform,
							function (x) {
								return focusMessage;
							},
							$elm$core$Task$succeed($elm$core$Maybe$Nothing));
					}
				}();
				var _v3 = config.eK;
				if (_v3) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								c: $elm$core$Maybe$Just('')
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									config.eK ? queryChangeCmd('') : $elm$core$Platform$Cmd$none
								])));
				} else {
					return _Utils_Tuple2(model, cmd);
				}
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var cmd = A2(
					$elm$core$Task$perform,
					config.dt,
					$elm$core$Task$succeed($elm$core$Maybe$Nothing));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c: $elm$core$Maybe$Nothing}),
					cmd);
			case 4:
				var item = msg.a;
				var cmd = function () {
					var _v5 = config.fW;
					if (!_v5.$) {
						var onRemoveItem = _v5.a;
						return A2(
							$elm$core$Task$perform,
							onRemoveItem,
							$elm$core$Task$succeed(item));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(model, cmd);
			case 8:
				var value = msg.a;
				var maybeQuery = config.gG(value);
				var cmd = function () {
					if (maybeQuery.$ === 1) {
						return $elm$core$Platform$Cmd$none;
					} else {
						var query = maybeQuery.a;
						return queryChangeCmd(query);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							c9: $elm$core$Maybe$Nothing,
							c: $elm$core$Maybe$Just(value)
						}),
					cmd);
			default:
				var item = msg.a;
				var cmd = A2(
					$elm$core$Task$perform,
					config.dt,
					$elm$core$Task$succeed(
						$elm$core$Maybe$Just(item)));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c: $elm$core$Maybe$Nothing}),
					cmd);
		}
	});
var $sporto$elm_select$Select$update = F3(
	function (config, msg, model) {
		var msg_ = $sporto$elm_select$Select$unwrapMsg(msg);
		var model_ = $sporto$elm_select$Select$unwrapModel(model);
		var config_ = $sporto$elm_select$Select$unwrapConfig(config);
		var _v0 = A3($sporto$elm_select$Select$Update$update, config_, msg_, model_);
		var mdl = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(mdl, cmd);
	});
var $author$project$UI$Autocomplete$update = F2(
	function (msg, _v0) {
		var state = _v0;
		switch (msg.$) {
			case 0:
				var selectMsg = msg.a;
				var _v2 = A3($sporto$elm_select$Select$update, state.ct, selectMsg, state.bB);
				var newState = _v2.a;
				var cmd = _v2.b;
				return _Utils_Tuple3(
					_Utils_update(
						state,
						{bB: newState}),
					cmd,
					$author$project$UI$Autocomplete$None);
			case 1:
				var innerMsg = msg.a;
				var _v3 = A4(
					$jinjor$elm_debounce$Debounce$update,
					$author$project$UI$Autocomplete$debounceConfig(state.be),
					$jinjor$elm_debounce$Debounce$takeLast($author$project$UI$Autocomplete$doQuery),
					innerMsg,
					state.aP);
				var newDebounce = _v3.a;
				var cmd = _v3.b;
				return _Utils_Tuple3(
					_Utils_update(
						state,
						{aP: newDebounce}),
					cmd,
					$author$project$UI$Autocomplete$None);
			case 3:
				var query = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						state,
						{c: query}),
					$elm$core$Platform$Cmd$none,
					$author$project$UI$Autocomplete$Query(query));
			case 4:
				var maybeItem = msg.a;
				return _Utils_Tuple3(
					state,
					$elm$core$Platform$Cmd$none,
					$author$project$UI$Autocomplete$Selected(maybeItem));
			default:
				var string = msg.a;
				var _v4 = A3(
					$jinjor$elm_debounce$Debounce$push,
					$author$project$UI$Autocomplete$debounceConfig(state.be),
					string,
					state.aP);
				var newDebounce = _v4.a;
				var cmd = _v4.b;
				return _Utils_Tuple3(
					_Utils_update(
						state,
						{aP: newDebounce}),
					cmd,
					$author$project$UI$Autocomplete$None);
		}
	});
var $elm$time$Time$Sun = 6;
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {cW: d, dm: m, d9: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		cm: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		d9: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.d9, 0, date.cm);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.cW;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.dm;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.cm;
	});
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		d7: 1 + (((rd - week1Day1) / 7) | 0),
		d8: wy,
		gW: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.d7;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.d8;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.br(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.cg(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.br(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.bT(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.ao(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.ao(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.ao(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.cH(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.ao(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.ao(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.b, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	bT: $justinmimbs$date$Date$withOrdinalSuffix,
	cg: $justinmimbs$date$Date$monthToName,
	br: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	cH: $justinmimbs$date$Date$weekdayToName,
	ao: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $CurrySoftware$elm_datepicker$DatePicker$formatCell = function (day) {
	return $elm$html$Html$text(day);
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay = function (day) {
	switch (day) {
		case 0:
			return 'Mo';
		case 1:
			return 'Tu';
		case 2:
			return 'We';
		case 3:
			return 'Th';
		case 4:
			return 'Fr';
		case 5:
			return 'Sa';
		default:
			return 'Su';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.dz;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $CurrySoftware$elm_datepicker$DatePicker$Date$Off = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$off = $CurrySoftware$elm_datepicker$DatePicker$Date$Off;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $CurrySoftware$elm_datepicker$DatePicker$defaultSettings = {
	bM: $CurrySoftware$elm_datepicker$DatePicker$formatCell,
	aM: $CurrySoftware$elm_datepicker$DatePicker$off,
	aq: 'elm-datepicker--',
	bP: _List_Nil,
	bR: $justinmimbs$date$Date$format('yyyy-MM-dd'),
	bS: $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay,
	bi: 6,
	b7: _List_fromArray(
		[
			$elm$html$Html$Attributes$required(false)
		]),
	fc: _List_Nil,
	b8: $elm$core$Maybe$Nothing,
	b9: $elm$core$Maybe$Nothing,
	bl: $elm$core$Basics$always(false),
	cf: $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth,
	co: $justinmimbs$date$Date$fromIsoString,
	cp: 'Please pick a date...',
	cJ: $elm$core$String$fromInt
};
var $author$project$UI$DatePicker$settings = _Utils_update(
	$CurrySoftware$elm_datepicker$DatePicker$defaultSettings,
	{
		fc: _List_fromArray(
			[
				_Utils_Tuple2('form-control', true)
			])
	});
var $author$project$UI$DatePicker$toWorse = function (input) {
	return A3(
		$isaacseymour$deprecated_time$Time$Date$date,
		$justinmimbs$date$Date$year(input),
		$justinmimbs$date$Date$monthNumber(input),
		$justinmimbs$date$Date$day(input));
};
var $CurrySoftware$elm_datepicker$DatePicker$Disabled = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$EmptyString = {$: 1};
var $CurrySoftware$elm_datepicker$DatePicker$FailedInput = function (a) {
	return {$: 1, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$Invalid = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$None = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$Picked = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$update = F3(
	function (settings, msg, _v0) {
		var model = _v0;
		var forceOpen = model.N;
		var focused = model.u;
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							u: $elm$core$Maybe$Just(date),
							R: date
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 1:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							u: $elm$core$Maybe$Just(date)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 2:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{u: $elm$core$Maybe$Nothing, A: $elm$core$Maybe$Nothing, I: false}),
					$CurrySoftware$elm_datepicker$DatePicker$Picked(date));
			case 3:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							A: $elm$core$Maybe$Just(text)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 4:
				if (forceOpen) {
					return _Utils_Tuple2(model, $CurrySoftware$elm_datepicker$DatePicker$None);
				} else {
					var dateEvent = function () {
						var _v5 = model.A;
						if (_v5.$ === 1) {
							return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
						} else {
							if (_v5.a === '') {
								return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
							} else {
								var rawInput = _v5.a;
								var _v6 = settings.co(rawInput);
								if (!_v6.$) {
									var date = _v6.a;
									return settings.bl(date) ? $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Disabled(date)) : $CurrySoftware$elm_datepicker$DatePicker$Picked(date);
								} else {
									var e = _v6.a;
									return $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Invalid(e));
								}
							}
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								u: function () {
									if (dateEvent.$ === 2) {
										var date = dateEvent.a;
										return $elm$core$Maybe$Just(date);
									} else {
										return model.u;
									}
								}(),
								A: function () {
									if (dateEvent.$ === 2) {
										return $elm$core$Maybe$Nothing;
									} else {
										return model.A;
									}
								}()
							}),
						dateEvent);
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: false, I: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{I: forceOpen}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: false}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
		}
	});
var $author$project$UI$DatePicker$update = F3(
	function (_v0, msg, _v1) {
		var setter = _v0.gp;
		var wrapper = _v0.gY;
		var picker = _v1;
		if (!msg.$) {
			var datePickerMsg = msg.a;
			var _v3 = A3($CurrySoftware$elm_datepicker$DatePicker$update, $author$project$UI$DatePicker$settings, datePickerMsg, picker);
			var newPicker = _v3.a;
			var event = _v3.b;
			var newDateCmd = function () {
				if (event.$ === 2) {
					var date = event.a;
					return A2(
						$elm$core$Task$perform,
						setter,
						$elm$core$Task$succeed(
							$elm$core$Maybe$Just(
								$author$project$UI$DatePicker$toWorse(date))));
				} else {
					return $elm$core$Platform$Cmd$none;
				}
			}();
			return _Utils_Tuple2(newPicker, newDateCmd);
		} else {
			return _Utils_Tuple2(
				picker,
				A2(
					$elm$core$Task$perform,
					setter,
					$elm$core$Task$succeed($elm$core$Maybe$Nothing)));
		}
	});
var $author$project$OrderFilters$FinishedLoadingAccountManagers = function (a) {
	return {$: 3, a: a};
};
var $author$project$Data$AccountManager$listDecoder = $elm$json$Json$Decode$list($author$project$Data$AccountManager$decoder);
var $author$project$Request$AccountManager$findByName = F3(
	function (client, name, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$AccountManager$listDecoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$query,
				_List_fromArray(
					[
						_Utils_Tuple2('q[forename_or_surname_cont]', name)
					]),
				A2(
					$author$project$Carwow$Http$path,
					_List_fromArray(
						['api', 'v2', 'account-managers']),
					client)));
	});
var $author$project$OrderFilters$updateAccountManagerQuery = F3(
	function (model, query, otherCmd) {
		var newModel = _Utils_update(
			model,
			{aK: _List_Nil});
		var cmd = A3($author$project$Request$AccountManager$findByName, model.bU, query, $author$project$OrderFilters$FinishedLoadingAccountManagers);
		return _Utils_Tuple3(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, otherCmd])),
			false);
	});
var $author$project$UI$Autocomplete$updateCandidates = F2(
	function (_v0, candidates) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				bL: A2(model.bY, candidates, model.c)
			});
	});
var $author$project$OrderFilters$updateAutocompleteCandidatesForAccountManagers = function (model) {
	var newAccountManagerAutocomplete = A2($author$project$UI$Autocomplete$updateCandidates, model.T, model.aK);
	return _Utils_update(
		model,
		{T: newAccountManagerAutocomplete});
};
var $author$project$OrderFilters$updateAutocompleteCandidatesForDealershipGroups = function (model) {
	var newDealershipGroupAutocomplete = A2($author$project$UI$Autocomplete$updateCandidates, model.V, model.aN);
	return _Utils_update(
		model,
		{V: newDealershipGroupAutocomplete});
};
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$OrderFilters$updateAutocompleteCandidatesForDealerships = function (model) {
	var newDealershipAutocomplete = A2(
		$author$project$UI$Autocomplete$updateCandidates,
		model.U,
		A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, model.aO));
	return _Utils_update(
		model,
		{U: newDealershipAutocomplete});
};
var $author$project$OrderFilters$updateAutocompleteCandidatesForMake = function (model) {
	var newAutocomplete = A2($author$project$UI$Autocomplete$updateCandidates, model._, model.bn);
	return _Utils_update(
		model,
		{_: newAutocomplete});
};
var $author$project$OrderFilters$updateAutocompleteCandidatesForUsers = function (model) {
	var newUserAutocomplete = A2($author$project$UI$Autocomplete$updateCandidates, model.am, model.aH);
	return _Utils_update(
		model,
		{am: newUserAutocomplete});
};
var $author$project$OrderFilters$DealershipGroupsLoaded = function (a) {
	return {$: 11, a: a};
};
var $author$project$Data$DealershipGroup$listDecoder = $elm$json$Json$Decode$list($author$project$Data$DealershipGroup$decoder);
var $author$project$Request$DealershipGroup$search = function (config) {
	return A2(
		$author$project$Carwow$Http$query,
		_List_fromArray(
			[
				_Utils_Tuple2('q[name_cont]', config.fD)
			]),
		A2(
			$author$project$Carwow$Http$path,
			_List_fromArray(
				['api', 'v2', 'dealership_groups']),
			A2(
				$author$project$Carwow$Http$auth,
				'Bearer ' + config.H,
				$author$project$Carwow$Http$crossOrigin(config.L))));
};
var $author$project$OrderFilters$updateDealershipGroupQuery = F3(
	function (model, query, otherCmd) {
		var searchConfig = {L: model.L, H: model.H, fD: query};
		var cmd = A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$DealershipGroup$listDecoder,
			$author$project$OrderFilters$DealershipGroupsLoaded,
			$author$project$Request$DealershipGroup$search(searchConfig));
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, otherCmd])),
			false);
	});
var $author$project$OrderFilters$DealershipsLoaded = function (a) {
	return {$: 8, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $krisajenkins$remotedata$RemoteData$mapError = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var x = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(x);
			case 2:
				var e = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(
					f(e));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			default:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
		}
	});
var $author$project$Carwow$Http$RemoteData$doNotUseWebDataInMessages = F2(
	function (msg, data) {
		return msg(
			A2(
				$krisajenkins$remotedata$RemoteData$mapError,
				$author$project$Carwow$Http$toHttpError,
				A2(
					$krisajenkins$remotedata$RemoteData$map,
					function ($) {
						return $.cP;
					},
					$krisajenkins$remotedata$RemoteData$fromResult(data))));
	});
var $author$project$Data$Dealership$listDecoder = $elm$json$Json$Decode$list($author$project$Data$Dealership$decoder);
var $author$project$Request$Dealership$search = F2(
	function (toMsg, _v0) {
		var dealersSiteUrl = _v0.L;
		var jwt = _v0.H;
		var name = _v0.fD;
		var make = _v0.Z;
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$Dealership$listDecoder,
			$author$project$Carwow$Http$RemoteData$doNotUseWebDataInMessages(toMsg),
			$author$project$Carwow$Http$get(
				A3(
					$author$project$Carwow$Http$header,
					'Authorization',
					'Bearer ' + jwt,
					A2(
						$author$project$Carwow$Http$query,
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$elm$core$Maybe$Just(
									A2($elm$core$Tuple$pair, 'scope', 'all')),
									$elm$core$Maybe$Just(
									A2($elm$core$Tuple$pair, 'q[name_cont]', name)),
									A2(
									$elm$core$Maybe$map,
									function (makeSlug) {
										return A2($elm$core$Tuple$pair, 'q[make_eq]', makeSlug);
									},
									make)
								])),
						A2(
							$author$project$Carwow$Http$path,
							_List_fromArray(
								['api', 'v2', 'dealerships']),
							$author$project$Carwow$Http$crossOrigin(dealersSiteUrl))))));
	});
var $author$project$OrderFilters$updateDealershipQuery = F3(
	function (model, query, otherCmd) {
		var searchConfig = {L: model.L, H: model.H, Z: $elm$core$Maybe$Nothing, fD: query};
		var newModel = _Utils_update(
			model,
			{aO: $krisajenkins$remotedata$RemoteData$Loading});
		var cmd = A2($author$project$Request$Dealership$search, $author$project$OrderFilters$DealershipsLoaded, searchConfig);
		return _Utils_Tuple3(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, otherCmd])),
			false);
	});
var $author$project$OrderFilters$FinishedLoadingUsers = function (a) {
	return {$: 14, a: a};
};
var $author$project$Data$User$listDecoder = $elm$json$Json$Decode$list($author$project$Data$User$decoder);
var $author$project$Request$User$searchByEmail = F3(
	function (client, query, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$User$listDecoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$query,
				_List_fromArray(
					[
						_Utils_Tuple2('email', query)
					]),
				A2(
					$author$project$Carwow$Http$path,
					_List_fromArray(
						['api', 'v2', 'users']),
					client)));
	});
var $author$project$Request$User$searchByName = F3(
	function (client, query, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$User$listDecoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$query,
				_List_fromArray(
					[
						_Utils_Tuple2('name', query)
					]),
				A2(
					$author$project$Carwow$Http$path,
					_List_fromArray(
						['api', 'v2', 'users']),
					client)));
	});
var $author$project$OrderFilters$updateUserQuery = F3(
	function (model, query, otherCmd) {
		var fetchUsers = $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Request$User$searchByEmail, model.bw, query, $author$project$OrderFilters$FinishedLoadingUsers),
					A3($author$project$Request$User$searchByName, model.bw, query, $author$project$OrderFilters$FinishedLoadingUsers)
				]));
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{aH: _List_Nil}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[fetchUsers, otherCmd])),
			false);
	});
var $author$project$OrderFilters$update = F2(
	function (msg, model) {
		var query = model.c;
		switch (msg.$) {
			case 0:
				var newScope = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{aD: newScope})
						}),
					$elm$core$Platform$Cmd$none,
					true);
			case 1:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, true);
			case 2:
				var subMsg = msg.a;
				var _v1 = A2($author$project$UI$Autocomplete$update, subMsg, model.T);
				var newState = _v1.a;
				var rawCmd = _v1.b;
				var output = _v1.c;
				var newModel = _Utils_update(
					model,
					{T: newState});
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$OrderFilters$AccountManagerAutocomplete, rawCmd);
				switch (output.$) {
					case 0:
						var accountManager = output.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									c: _Utils_update(
										query,
										{ed: accountManager})
								}),
							cmd,
							true);
					case 1:
						var queryString = output.a;
						return A3($author$project$OrderFilters$updateAccountManagerQuery, newModel, queryString, cmd);
					default:
						return _Utils_Tuple3(newModel, cmd, false);
				}
			case 3:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					var newModel = $author$project$OrderFilters$updateAutocompleteCandidatesForAccountManagers(
						_Utils_update(
							model,
							{aK: response.cP}));
					return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingAccountManagers'),
						false);
				}
			case 4:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_eq(
						$elm$core$Maybe$Just(response.cP.e6),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.e6;
							},
							query.ed)) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									query,
									{
										ed: $elm$core$Maybe$Just(response.cP)
									})
							}),
						$elm$core$Platform$Cmd$none,
						false) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingAccountManager'),
						false);
				}
			case 5:
				var subMsg = msg.a;
				var _v5 = A2($author$project$UI$Autocomplete$update, subMsg, model._);
				var newState = _v5.a;
				var rawCmd = _v5.b;
				var output = _v5.c;
				var newModel = _Utils_update(
					model,
					{_: newState});
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$OrderFilters$MakeAutocomplete, rawCmd);
				switch (output.$) {
					case 0:
						var make = output.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									c: _Utils_update(
										query,
										{Z: make})
								}),
							cmd,
							true);
					case 1:
						return _Utils_Tuple3(
							$author$project$OrderFilters$updateAutocompleteCandidatesForMake(newModel),
							cmd,
							false);
					default:
						return _Utils_Tuple3(newModel, cmd, false);
				}
			case 6:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{bn: response.cP}),
						$elm$core$Platform$Cmd$none,
						false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingMakes'),
						false);
				}
			case 7:
				var subMsg = msg.a;
				var _v8 = A2($author$project$UI$Autocomplete$update, subMsg, model.U);
				var newState = _v8.a;
				var rawCmd = _v8.b;
				var output = _v8.c;
				var newModel = _Utils_update(
					model,
					{U: newState});
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$OrderFilters$DealershipAutocomplete, rawCmd);
				switch (output.$) {
					case 0:
						var dealership = output.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									c: _Utils_update(
										query,
										{y: dealership})
								}),
							cmd,
							true);
					case 1:
						var queryString = output.a;
						return A3($author$project$OrderFilters$updateDealershipQuery, newModel, queryString, cmd);
					default:
						return _Utils_Tuple3(newModel, cmd, false);
				}
			case 8:
				var result = msg.a;
				var newModel = $author$project$OrderFilters$updateAutocompleteCandidatesForDealerships(
					_Utils_update(
						model,
						{aO: result}));
				var cmd = A2($author$project$Toastr$fromRemoteData, 'There was an error loading dealerships', result);
				return _Utils_Tuple3(newModel, cmd, false);
			case 9:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_eq(
						$elm$core$Maybe$Just(response.cP.e6),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.e6;
							},
							query.y)) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									query,
									{
										y: $elm$core$Maybe$Just(response.cP)
									})
							}),
						$elm$core$Platform$Cmd$none,
						false) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingDealership'),
						false);
				}
			case 10:
				var subMsg = msg.a;
				var _v11 = A2($author$project$UI$Autocomplete$update, subMsg, model.V);
				var newState = _v11.a;
				var rawCmd = _v11.b;
				var output = _v11.c;
				var newModel = _Utils_update(
					model,
					{V: newState});
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$OrderFilters$DealershipGroupAutocomplete, rawCmd);
				switch (output.$) {
					case 0:
						var group = output.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									c: _Utils_update(
										query,
										{z: group})
								}),
							cmd,
							true);
					case 1:
						var queryString = output.a;
						return A3($author$project$OrderFilters$updateDealershipGroupQuery, newModel, queryString, cmd);
					default:
						return _Utils_Tuple3(newModel, cmd, false);
				}
			case 11:
				if (!msg.a.$) {
					var result = msg.a.a;
					var newModel = $author$project$OrderFilters$updateAutocompleteCandidatesForDealershipGroups(
						_Utils_update(
							model,
							{aN: result.cP}));
					return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, false);
				} else {
					var result = msg.a.a;
					var newModel = $author$project$OrderFilters$updateAutocompleteCandidatesForDealershipGroups(
						_Utils_update(
							model,
							{aN: _List_Nil}));
					var cmd = A2(
						$author$project$Toastr$detailedError,
						'There was an error loading dealership groups',
						$author$project$Carwow$Http$toHttpError(result));
					return _Utils_Tuple3(newModel, cmd, false);
				}
			case 12:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_eq(
						$elm$core$Maybe$Just(response.cP.e6),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.e6;
							},
							query.z)) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									query,
									{
										z: $elm$core$Maybe$Just(response.cP)
									})
							}),
						$elm$core$Platform$Cmd$none,
						false) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingDealershipGroup'),
						false);
				}
			case 13:
				var subMsg = msg.a;
				var _v14 = A2($author$project$UI$Autocomplete$update, subMsg, model.am);
				var newState = _v14.a;
				var rawCmd = _v14.b;
				var output = _v14.c;
				var newModel = _Utils_update(
					model,
					{am: newState});
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$OrderFilters$UserAutocomplete, rawCmd);
				switch (output.$) {
					case 0:
						var user = output.a;
						return _Utils_Tuple3(
							_Utils_update(
								newModel,
								{
									c: _Utils_update(
										query,
										{C: user})
								}),
							cmd,
							true);
					case 1:
						var queryString = output.a;
						return A3($author$project$OrderFilters$updateUserQuery, newModel, queryString, cmd);
					default:
						return _Utils_Tuple3(newModel, cmd, false);
				}
			case 14:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					var newModel = $author$project$OrderFilters$updateAutocompleteCandidatesForUsers(
						_Utils_update(
							model,
							{
								aH: A2(
									$elm_community$list_extra$List$Extra$uniqueBy,
									function ($) {
										return $.e6;
									},
									_Utils_ap(model.aH, response.cP))
							}));
					return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingUser'),
						false);
				}
			case 15:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_eq(
						$elm$core$Maybe$Just(response.cP.e6),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.e6;
							},
							query.C)) ? _Utils_Tuple3(
						_Utils_update(
							model,
							{
								c: _Utils_update(
									query,
									{
										C: $elm$core$Maybe$Just(response.cP)
									})
							}),
						$elm$core$Platform$Cmd$none,
						false) : _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, false);
				} else {
					var error = result.a;
					return _Utils_Tuple3(
						model,
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingUser'),
						false);
				}
			case 22:
				var newPurchaseType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{f9: newPurchaseType})
						}),
					$elm$core$Platform$Cmd$none,
					true);
			case 23:
				var newSalesmatchingType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{ai: newSalesmatchingType})
						}),
					$elm$core$Platform$Cmd$none,
					true);
			case 24:
				var newDealershipType = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{W: newDealershipType})
						}),
					$elm$core$Platform$Cmd$none,
					true);
			case 17:
				var str = msg.a;
				var _v18 = A3(
					$jinjor$elm_debounce$Debounce$push,
					$author$project$OrderFilters$debounceConfig($author$project$OrderFilters$OfferIdDebounce),
					str,
					model.aw);
				var newDebounce = _v18.a;
				var cmd = _v18.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: newDebounce}),
					cmd,
					false);
			case 16:
				var subMsg = msg.a;
				var _v19 = A4(
					$jinjor$elm_debounce$Debounce$update,
					$author$project$OrderFilters$debounceConfig($author$project$OrderFilters$OfferIdDebounce),
					$jinjor$elm_debounce$Debounce$takeLast(
						$author$project$OrderFilters$debouncedId($author$project$OrderFilters$SetOfferId)),
					subMsg,
					model.aw);
				var newDebounce = _v19.a;
				var cmd = _v19.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aw: newDebounce}),
					cmd,
					false);
			case 18:
				var offerId = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{fT: offerId})
						}),
					$elm$core$Platform$Cmd$none,
					!_Utils_eq(query.fT, offerId));
			case 20:
				var str = msg.a;
				var _v20 = A3(
					$jinjor$elm_debounce$Debounce$push,
					$author$project$OrderFilters$debounceConfig($author$project$OrderFilters$OrderIdDebounce),
					str,
					model.ay);
				var newDebounce = _v20.a;
				var cmd = _v20.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ay: newDebounce}),
					cmd,
					false);
			case 19:
				var subMsg = msg.a;
				var _v21 = A4(
					$jinjor$elm_debounce$Debounce$update,
					$author$project$OrderFilters$debounceConfig($author$project$OrderFilters$OrderIdDebounce),
					$jinjor$elm_debounce$Debounce$takeLast(
						$author$project$OrderFilters$debouncedId($author$project$OrderFilters$SetOrderId)),
					subMsg,
					model.ay);
				var newDebounce = _v21.a;
				var cmd = _v21.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{ay: newDebounce}),
					cmd,
					false);
			case 21:
				var orderId = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							c: _Utils_update(
								query,
								{ab: orderId})
						}),
					$elm$core$Platform$Cmd$none,
					!_Utils_eq(query.ab, orderId));
			case 25:
				var picker = msg.a;
				var subMsg = msg.b;
				var oldModel = function () {
					switch (picker) {
						case 0:
							return model.a1;
						case 1:
							return model.a0;
						case 2:
							return model.a3;
						default:
							return model.a2;
					}
				}();
				var _v22 = A3(
					$author$project$UI$DatePicker$update,
					$author$project$OrderFilters$datePickerConfig(picker),
					subMsg,
					oldModel);
				var updated = _v22.a;
				var cmd = _v22.b;
				var newModel = function () {
					switch (picker) {
						case 0:
							return _Utils_update(
								model,
								{a1: updated});
						case 1:
							return _Utils_update(
								model,
								{a0: updated});
						case 2:
							return _Utils_update(
								model,
								{a3: updated});
						default:
							return _Utils_update(
								model,
								{a2: updated});
					}
				}();
				return _Utils_Tuple3(newModel, cmd, false);
			default:
				var picker = msg.a;
				var maybeDate = msg.b;
				var newQuery = function () {
					switch (picker) {
						case 0:
							return _Utils_update(
								query,
								{ae: maybeDate});
						case 1:
							return _Utils_update(
								query,
								{ad: maybeDate});
						case 2:
							return _Utils_update(
								query,
								{ag: maybeDate});
						default:
							return _Utils_update(
								query,
								{af: maybeDate});
					}
				}();
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{c: newQuery}),
					$elm$core$Platform$Cmd$none,
					!_Utils_eq(query, newQuery));
		}
	});
var $author$project$OrdersTable$Adding = 1;
var $author$project$OrdersTable$FinishedCreatingNote = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$OrdersTable$Saving = 2;
var $author$project$Carwow$Http$body = F2(
	function (httpBody, _v0) {
		var client = _v0;
		return _Utils_update(
			client,
			{cP: httpBody});
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Carwow$Http$jsonBody = function (value) {
	return $author$project$Carwow$Http$body(
		$elm$http$Http$jsonBody(value));
};
var $author$project$Carwow$Http$Post = 1;
var $author$project$Carwow$Http$post = function (_v0) {
	var client = _v0;
	return _Utils_update(
		client,
		{fv: 1});
};
var $author$project$Request$Order$createNote = F4(
	function (client, id, note, toMsg) {
		return A3(
			$author$project$Carwow$Http$fetchJson,
			$author$project$Data$OrderNote$decoder,
			toMsg,
			A2(
				$author$project$Carwow$Http$jsonBody,
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'content',
							$elm$json$Json$Encode$string(note))
						])),
				A2(
					$author$project$Carwow$Http$path,
					_List_fromArray(
						[
							'orders',
							$elm$core$String$fromInt(id),
							'notes'
						]),
					$author$project$Carwow$Http$post(client))));
	});
var $isaacseymour$deprecated_time$Time$DateTime$dateTime = function (data) {
	return {
		d: A3($isaacseymour$deprecated_time$Time$Date$date, data.d9, data.dm, data.cW),
		b: $isaacseymour$deprecated_time$Time$Internal$offsetFromTimeData(data)
	};
};
var $isaacseymour$deprecated_time$Time$Internal$zero = {cW: 1, bj: 0, bp: 0, bq: 0, dm: 1, bA: 0, d9: 0};
var $isaacseymour$deprecated_time$Time$DateTime$zero = $isaacseymour$deprecated_time$Time$Internal$zero;
var $isaacseymour$deprecated_time$Time$DateTime$epoch = $isaacseymour$deprecated_time$Time$DateTime$dateTime(
	_Utils_update(
		$isaacseymour$deprecated_time$Time$DateTime$zero,
		{d9: 1970}));
var $isaacseymour$deprecated_time$Time$DateTime$fromPosix = function (posix) {
	return A2(
		$isaacseymour$deprecated_time$Time$DateTime$addMilliseconds,
		$elm$time$Time$posixToMillis(posix),
		$isaacseymour$deprecated_time$Time$DateTime$epoch);
};
var $author$project$OrdersTable$setNoteState = F2(
	function (state, row) {
		return _Utils_update(
			row,
			{aY: state});
	});
var $author$project$OrdersTable$Opened = 0;
var $author$project$OrdersTable$toggleDetail = function (row) {
	var newRowState = function () {
		var _v0 = row.aC;
		if (!_v0) {
			return 1;
		} else {
			return 0;
		}
	}();
	return _Utils_update(
		row,
		{aC: newRowState});
};
var $author$project$OrdersTable$updateRow = F2(
	function (fn, orderId) {
		return $elm$core$List$map(
			function (row) {
				return _Utils_eq(row.o.e6, orderId) ? fn(row) : row;
			});
	});
var $author$project$OrdersTable$update = F2(
	function (msg, model) {
		var orders = model.f_;
		switch (msg.$) {
			case 0:
				var posix = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bt: $elm$core$Maybe$Just(
								$isaacseymour$deprecated_time$Time$DateTime$fromPosix(posix))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var row = msg.a;
				var newOrders = A2(
					$krisajenkins$remotedata$RemoteData$map,
					A2($author$project$OrdersTable$updateRow, $author$project$OrdersTable$toggleDetail, row.o.e6),
					orders);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f_: newOrders}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var row = msg.a;
				var newOrders = A2(
					$krisajenkins$remotedata$RemoteData$map,
					A2(
						$author$project$OrdersTable$updateRow,
						$author$project$OrdersTable$setNoteState(1),
						row.o.e6),
					orders);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f_: newOrders}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var row = msg.a;
				var newNote = msg.b;
				var newOrders = A2(
					$krisajenkins$remotedata$RemoteData$map,
					A2(
						$author$project$OrdersTable$updateRow,
						function (order) {
							return _Utils_update(
								order,
								{aX: newNote});
						},
						row.o.e6),
					orders);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f_: newOrders}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var row = msg.a;
				var newOrders = A2(
					$krisajenkins$remotedata$RemoteData$map,
					A2(
						$author$project$OrdersTable$updateRow,
						$author$project$OrdersTable$setNoteState(2),
						row.o.e6),
					orders);
				var createNote = A4(
					$author$project$Request$Order$createNote,
					model.bK,
					row.o.e6,
					row.aX,
					$author$project$OrdersTable$FinishedCreatingNote(row));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f_: newOrders}),
					createNote);
			default:
				var row = msg.a;
				var result = msg.b;
				if (!result.$) {
					var response = result.a;
					var appendNote = function (order) {
						return _Utils_update(
							order,
							{
								dq: _Utils_ap(
									order.dq,
									_List_fromArray(
										[response.cP]))
							});
					};
					var resetNoteState = function (row_) {
						return _Utils_update(
							row_,
							{
								aX: '',
								aY: 0,
								o: appendNote(row_.o)
							});
					};
					var newOrders = A2(
						$krisajenkins$remotedata$RemoteData$map,
						A2($author$project$OrdersTable$updateRow, resetNoteState, row.o.e6),
						model.f_);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f_: newOrders}),
						$elm$core$Platform$Cmd$none);
				} else {
					var newOrders = A2(
						$krisajenkins$remotedata$RemoteData$map,
						A2(
							$author$project$OrdersTable$updateRow,
							$author$project$OrdersTable$setNoteState(1),
							row.o.e6),
						model.f_);
					var cmd = $author$project$Toastr$simpleError('There was an error saving a note');
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f_: newOrders}),
						cmd);
				}
		}
	});
var $author$project$Orders$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					var paginatedResponse = $krisajenkins$remotedata$RemoteData$Success(
						$author$project$PaginatedList$fromResponse(response));
					var newOrdersTable = A2($author$project$OrdersTable$setOrdersPage, paginatedResponse, model.x);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{az: paginatedResponse, x: newOrdersTable}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					var failedOrdersPage = $krisajenkins$remotedata$RemoteData$Failure(
						$author$project$Carwow$Http$toHttpError(error));
					var failedOrdersTable = A2($author$project$OrdersTable$setOrdersPage, failedOrdersPage, model.x);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{az: failedOrdersPage, x: failedOrdersTable}),
						A2($author$project$ErrorHandler$notify, error, 'FinishedLoadingOrders'));
				}
			case 0:
				var innerMsg = msg.a;
				var _v2 = A2($author$project$OrdersTable$update, innerMsg, model.x);
				var ordersTable = _v2.a;
				var cmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{x: ordersTable}),
					A2($elm$core$Platform$Cmd$map, $author$project$Orders$OrdersTableMsg, cmd));
			case 1:
				var innerMsg = msg.a;
				var ordersTable = model.x;
				var _v3 = A2($author$project$OrderFilters$update, innerMsg, model.ax);
				var newOrderFilters = _v3.a;
				var filtersCmd = _v3.b;
				var shouldRefresh = _v3.c;
				var wrappedFilterCmd = A2($elm$core$Platform$Cmd$map, $author$project$Orders$OrderFiltersMsg, filtersCmd);
				var urlChangeCmd = $author$project$Orders$pushQuery(
					$author$project$OrderFilters$toQuery(newOrderFilters));
				var newCurrentPage = shouldRefresh ? $elm$core$Maybe$Nothing : model.ar;
				var newOrdersTable = shouldRefresh ? _Utils_update(
					ordersTable,
					{f_: $krisajenkins$remotedata$RemoteData$Loading}) : ordersTable;
				var newModel = _Utils_update(
					model,
					{ar: newCurrentPage, ax: newOrderFilters, x: newOrdersTable});
				var newCmd = shouldRefresh ? $elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							wrappedFilterCmd,
							$author$project$Orders$fetchOrders(newModel)
						])) : wrappedFilterCmd;
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[newCmd, urlChangeCmd])));
			default:
				var newPage = msg.a;
				var newModel = _Utils_update(
					model,
					{
						ar: $elm$core$Maybe$Just(newPage)
					});
				return _Utils_Tuple2(
					newModel,
					$author$project$Orders$fetchOrders(newModel));
		}
	});
var $author$project$Orders$UpdatePage = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$UI$Basics$badge = function (message) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('badge badge-primary')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(message)
			]));
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$UI$Basics$boxContent = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ibox-content')
			]),
		content);
};
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$UI$Basics$boxTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ibox-title')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h5,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]))
			]));
};
var $author$project$UI$Basics$boxWithTitle = F2(
	function (title, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('ibox')
				]),
			_List_fromArray(
				[
					$author$project$UI$Basics$boxTitle(title),
					$author$project$UI$Basics$boxContent(content)
				]));
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$UI$Basics$headingAction = function (_v0) {
	var name = _v0.a;
	var url = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('title-action')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(url),
						$elm$html$Html$Attributes$class('btn btn-primary')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(name)
					]))
			]));
};
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$UI$Basics$headingText = F2(
	function (title, headingExtras) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]),
					headingExtras))
			]);
	});
var $author$project$UI$Basics$heading = F3(
	function (title, headingExtras, actions) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('row wrapper page-heading')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-lg-10')
						]),
					A2($author$project$UI$Basics$headingText, title, headingExtras)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-lg-2')
						]),
					A2($elm$core$List$map, $author$project$UI$Basics$headingAction, actions))
				]));
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$UI$Pagination$link = F3(
	function (dataToMsg, maybePageData, chevronClass) {
		var classes = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				['fa', chevronClass]));
		if (!maybePageData.$) {
			var pageData = maybePageData.a;
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-white'),
						$elm$html$Html$Events$onClick(
						dataToMsg(pageData))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(classes)
							]),
						_List_Nil)
					]));
		} else {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn btn-white disabled')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(classes)
							]),
						_List_Nil)
					]));
		}
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$UI$Pagination$basePager = F3(
	function (dataToMsg, maybePrevData, maybeNextData) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('centered')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-group pagination')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$UI$Pagination$link, dataToMsg, maybePrevData, 'fa-chevron-left')
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$UI$Pagination$link, dataToMsg, maybeNextData, 'fa-chevron-right')
								]))
						]))
				]));
	});
var $author$project$PaginatedList$nextPage = function (_v0) {
	var list = _v0;
	return list.$7;
};
var $author$project$PaginatedList$prevPage = function (_v0) {
	var list = _v0;
	return list.dy;
};
var $author$project$UI$Pagination$pager = F2(
	function (onPage, pagedList) {
		return A3(
			$author$project$UI$Pagination$basePager,
			onPage,
			$author$project$PaginatedList$prevPage(pagedList),
			$author$project$PaginatedList$nextPage(pagedList));
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $krisajenkins$remotedata$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map($elm$core$Maybe$Just),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$PaginatedList$totalCount = function (_v0) {
	var list = _v0;
	return list.d3;
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$UI$Form$controlLabel = function (labelText) {
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('control-label')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(labelText)
			]));
};
var $author$project$UI$Form$group = function (children) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		children);
};
var $author$project$UI$Autocomplete$SelectMsg = function (a) {
	return {$: 0, a: a};
};
var $sporto$elm_select$Select$Models$Single = function (a) {
	return {$: 0, a: a};
};
var $sporto$elm_select$Select$PrivateMsg = $elm$core$Basics$identity;
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $sporto$elm_select$Select$Messages$OnClear = {$: 3};
var $sporto$elm_select$Select$Messages$OnFocus = {$: 1};
var $sporto$elm_select$Select$Messages$OnQueryChange = function (a) {
	return {$: 8, a: a};
};
var $sporto$elm_select$Select$Messages$OnRemoveItem = function (a) {
	return {$: 4, a: a};
};
var $sporto$elm_select$Select$Utils$andThenSelected = F3(
	function (selected, fromSingle, fromMulti) {
		return A2(
			$elm$core$Maybe$andThen,
			function (value) {
				if (!value.$) {
					var item = value.a;
					return fromSingle(item);
				} else {
					var items = value.a;
					return fromMulti(items);
				}
			},
			selected);
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $sporto$elm_select$Select$Styles$clearClass = 'elm-select-clear ';
var $sporto$elm_select$Select$Styles$clearStyles = _List_fromArray(
	[
		_Utils_Tuple2('cursor', 'pointer'),
		_Utils_Tuple2('height', '1rem'),
		_Utils_Tuple2('line-height', '0rem'),
		_Utils_Tuple2('margin-top', '-0.5rem'),
		_Utils_Tuple2('position', 'absolute'),
		_Utils_Tuple2('right', '0.25rem'),
		_Utils_Tuple2('top', '50%')
	]);
var $sporto$elm_select$Select$Utils$difference = F2(
	function (listA, listB) {
		return A2(
			$elm$core$List$filter,
			function (x) {
				return !A2(
					$elm$core$List$any,
					function (y) {
						return _Utils_eq(x, y);
					},
					listB);
			},
			listA);
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $sporto$elm_select$Select$Styles$inputClass = 'elm-select-input';
var $sporto$elm_select$Select$Styles$inputControlClass = 'elm-select-input-control ';
var $sporto$elm_select$Select$Styles$inputControlStyles = _List_fromArray(
	[
		_Utils_Tuple2('position', 'relative'),
		_Utils_Tuple2('background', 'white')
	]);
var $sporto$elm_select$Select$Styles$inputStyles = _List_fromArray(
	[
		_Utils_Tuple2('flex', '1'),
		_Utils_Tuple2('border', 'none'),
		_Utils_Tuple2('outline', 'none'),
		_Utils_Tuple2('min-height', '1.3rem'),
		_Utils_Tuple2('font-size', '.75rem')
	]);
var $sporto$elm_select$Select$Styles$inputWrapperClass = 'elm-select-input-wrapper ';
var $sporto$elm_select$Select$Styles$inputWrapperStyles = _List_fromArray(
	[
		_Utils_Tuple2('display', 'flex'),
		_Utils_Tuple2('flex', '1'),
		_Utils_Tuple2('flex-direction', 'row'),
		_Utils_Tuple2('flex-wrap', 'wrap'),
		_Utils_Tuple2('overflow', 'hidden')
	]);
var $sporto$elm_select$Select$Search$ItemsFound = function (a) {
	return {$: 1, a: a};
};
var $sporto$elm_select$Select$Search$NotSearched = {$: 0};
var $tripokey$elm_fuzzy$Fuzzy$AddPenalty = function (a) {
	return {$: 0, a: a};
};
var $tripokey$elm_fuzzy$Fuzzy$addPenalty = function (penalty) {
	return $tripokey$elm_fuzzy$Fuzzy$AddPenalty(penalty);
};
var $sporto$elm_select$Select$Config$fuzzyAddPenalty = F2(
	function (config, options) {
		var _v0 = config.eX;
		if (!_v0.$) {
			var penalty = _v0.a;
			return _Utils_ap(
				options,
				_List_fromArray(
					[
						$tripokey$elm_fuzzy$Fuzzy$addPenalty(penalty)
					]));
		} else {
			return options;
		}
	});
var $tripokey$elm_fuzzy$Fuzzy$InsertPenalty = function (a) {
	return {$: 3, a: a};
};
var $tripokey$elm_fuzzy$Fuzzy$insertPenalty = function (penalty) {
	return $tripokey$elm_fuzzy$Fuzzy$InsertPenalty(penalty);
};
var $sporto$elm_select$Select$Config$fuzzyInsertPenalty = F2(
	function (config, options) {
		var _v0 = config.eY;
		if (!_v0.$) {
			var penalty = _v0.a;
			return _Utils_ap(
				options,
				_List_fromArray(
					[
						$tripokey$elm_fuzzy$Fuzzy$insertPenalty(penalty)
					]));
		} else {
			return options;
		}
	});
var $tripokey$elm_fuzzy$Fuzzy$MovePenalty = function (a) {
	return {$: 2, a: a};
};
var $tripokey$elm_fuzzy$Fuzzy$movePenalty = function (penalty) {
	return $tripokey$elm_fuzzy$Fuzzy$MovePenalty(penalty);
};
var $sporto$elm_select$Select$Config$fuzzyMovePenalty = F2(
	function (config, options) {
		var _v0 = config.eZ;
		if (!_v0.$) {
			var penalty = _v0.a;
			return _Utils_ap(
				options,
				_List_fromArray(
					[
						$tripokey$elm_fuzzy$Fuzzy$movePenalty(penalty)
					]));
		} else {
			return options;
		}
	});
var $tripokey$elm_fuzzy$Fuzzy$RemovePenalty = function (a) {
	return {$: 1, a: a};
};
var $tripokey$elm_fuzzy$Fuzzy$removePenalty = function (penalty) {
	return $tripokey$elm_fuzzy$Fuzzy$RemovePenalty(penalty);
};
var $sporto$elm_select$Select$Config$fuzzyRemovePenalty = F2(
	function (config, options) {
		var _v0 = config.e_;
		if (!_v0.$) {
			var penalty = _v0.a;
			return _Utils_ap(
				options,
				_List_fromArray(
					[
						$tripokey$elm_fuzzy$Fuzzy$removePenalty(penalty)
					]));
		} else {
			return options;
		}
	});
var $sporto$elm_select$Select$Config$fuzzyOptions = function (config) {
	return A2(
		$sporto$elm_select$Select$Config$fuzzyInsertPenalty,
		config,
		A2(
			$sporto$elm_select$Select$Config$fuzzyMovePenalty,
			config,
			A2(
				$sporto$elm_select$Select$Config$fuzzyRemovePenalty,
				config,
				A2($sporto$elm_select$Select$Config$fuzzyAddPenalty, config, _List_Nil))));
};
var $tripokey$elm_fuzzy$Fuzzy$Match = F4(
	function (score, offset, length, keys) {
		return {fn: keys, fq: length, b: offset, gk: score};
	});
var $tripokey$elm_fuzzy$Fuzzy$Result = F2(
	function (score, matches) {
		return {ca: matches, gk: score};
	});
var $tripokey$elm_fuzzy$Fuzzy$ConfigModel = F4(
	function (addPenalty, movePenalty, removePenalty, insertPenalty) {
		return {bb: addPenalty, bk: insertPenalty, bs: movePenalty, by: removePenalty};
	});
var $tripokey$elm_fuzzy$Fuzzy$defaultConfig = A4($tripokey$elm_fuzzy$Fuzzy$ConfigModel, 10, 1000, 10000, 1);
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $tripokey$elm_fuzzy$Fuzzy$dissect = F2(
	function (separators, strings) {
		dissect:
		while (true) {
			if (!separators.b) {
				return strings;
			} else {
				var head = separators.a;
				var tail = separators.b;
				var dissectEntry = function (entry) {
					var separatorLength = $elm$core$String$length(head);
					var slice = F2(
						function (index, _v1) {
							var prevIndex = _v1.a;
							var sum = _v1.b;
							var separatorSlice = _List_fromArray(
								[
									A3($elm$core$String$slice, index, index + separatorLength, entry)
								]);
							var precedingSlice = _Utils_eq(prevIndex, index) ? _List_Nil : _List_fromArray(
								[
									A3($elm$core$String$slice, prevIndex, index, entry)
								]);
							return _Utils_Tuple2(
								index + separatorLength,
								_Utils_ap(
									sum,
									_Utils_ap(precedingSlice, separatorSlice)));
						});
					var indexes = A2($elm$core$String$indexes, head, entry);
					var result = A3(
						$elm$core$List$foldl,
						slice,
						_Utils_Tuple2(0, _List_Nil),
						indexes);
					var lastIndex = result.a;
					var first = result.b;
					var entryLength = $elm$core$String$length(entry);
					var last = _Utils_eq(lastIndex, entryLength) ? _List_Nil : _List_fromArray(
						[
							A3($elm$core$String$slice, lastIndex, entryLength, entry)
						]);
					return _Utils_ap(first, last);
				};
				var dissected = A3(
					$elm$core$List$foldl,
					F2(
						function (e, s) {
							return _Utils_ap(
								s,
								dissectEntry(e));
						}),
					_List_Nil,
					strings);
				var $temp$separators = tail,
					$temp$strings = dissected;
				separators = $temp$separators;
				strings = $temp$strings;
				continue dissect;
			}
		}
	});
var $elm$core$String$foldl = _String_foldl;
var $tripokey$elm_fuzzy$Fuzzy$initialModel = _List_Nil;
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $tripokey$elm_fuzzy$Fuzzy$quickSort = function (entries) {
	if (!entries.b) {
		return _Utils_Tuple2(0, _List_Nil);
	} else {
		var head = entries.a;
		var tail = entries.b;
		var partition = A2(
			$elm$core$List$partition,
			function (e) {
				return _Utils_cmp(e, head) < 0;
			},
			tail);
		var smaller = $tripokey$elm_fuzzy$Fuzzy$quickSort(partition.a);
		var penalty = $elm$core$List$isEmpty(smaller.b) ? 0 : 1;
		var larger = $tripokey$elm_fuzzy$Fuzzy$quickSort(partition.b);
		return _Utils_Tuple2(
			(smaller.a + penalty) + larger.a,
			_Utils_ap(
				smaller.b,
				_Utils_ap(
					_List_fromArray(
						[head]),
					larger.b)));
	}
};
var $tripokey$elm_fuzzy$Fuzzy$distance = F3(
	function (config, needle, hay) {
		var accumulateInsertPenalty = F2(
			function (elem, result) {
				if (!result.a.$) {
					var prev = result.a.a;
					var score = result.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(elem),
						((elem - 1) - prev) + score);
				} else {
					var _v2 = result.a;
					var score = result.b;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(elem),
						score);
				}
			});
		var accumulate = F2(
			function (c, indexList) {
				var indexes = A2(
					$elm$core$String$indexes,
					$elm$core$String$fromChar(c),
					hay);
				var hayIndex = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (e) {
							return !A2($elm$core$List$member, e, indexList);
						},
						indexes));
				if (!hayIndex.$) {
					var v = hayIndex.a;
					return _Utils_ap(
						indexList,
						_List_fromArray(
							[v]));
				} else {
					return indexList;
				}
			});
		var accumulated = A3($elm$core$String$foldl, accumulate, $tripokey$elm_fuzzy$Fuzzy$initialModel, needle);
		var hPenalty = ($elm$core$String$length(hay) - $elm$core$List$length(accumulated)) * config.bb;
		var nPenalty = ($elm$core$String$length(needle) - $elm$core$List$length(accumulated)) * config.by;
		var sorted = $tripokey$elm_fuzzy$Fuzzy$quickSort(accumulated);
		var iPenalty = A3(
			$elm$core$List$foldl,
			accumulateInsertPenalty,
			_Utils_Tuple2($elm$core$Maybe$Nothing, 0),
			sorted.b).b * config.bk;
		var mPenalty = sorted.a * config.bs;
		return A4(
			$tripokey$elm_fuzzy$Fuzzy$Match,
			((mPenalty + hPenalty) + nPenalty) + iPenalty,
			0,
			$elm$core$String$length(hay),
			sorted.b);
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $tripokey$elm_fuzzy$Fuzzy$padHays = F2(
	function (ns, hs) {
		return _Utils_ap(
			hs,
			A2(
				$elm$core$List$repeat,
				ns - $elm$core$List$length(hs),
				''));
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $tripokey$elm_fuzzy$Fuzzy$reduceLeft = F3(
	function (ns, c, hs) {
		return _Utils_Tuple2(
			A3(
				$elm$core$List$foldl,
				F2(
					function (e, sum) {
						return $elm$core$String$length(e) + sum;
					}),
				0,
				A2($elm$core$List$take, c, hs)),
			A2($elm$core$List$drop, c, hs));
	});
var $tripokey$elm_fuzzy$Fuzzy$reduceRight = F3(
	function (ns, c, hs) {
		return A2(
			$elm$core$List$take,
			$elm$core$List$length(hs) - ((ns - c) - 1),
			hs);
	});
var $tripokey$elm_fuzzy$Fuzzy$match = F4(
	function (configs, separators, needle, hay) {
		var reduceHays = F3(
			function (ns, c, hs) {
				return A3(
					$tripokey$elm_fuzzy$Fuzzy$reduceLeft,
					ns,
					c,
					A3(
						$tripokey$elm_fuzzy$Fuzzy$reduceRight,
						ns,
						c,
						A2($tripokey$elm_fuzzy$Fuzzy$padHays, ns, hs)));
			});
		var needles = A2(
			$tripokey$elm_fuzzy$Fuzzy$dissect,
			separators,
			_List_fromArray(
				[needle]));
		var initialResult = A2($tripokey$elm_fuzzy$Fuzzy$Result, 0, _List_Nil);
		var hays = A2(
			$tripokey$elm_fuzzy$Fuzzy$dissect,
			separators,
			_List_fromArray(
				[hay]));
		var accumulateConfig = F2(
			function (c, sum) {
				switch (c.$) {
					case 0:
						var val = c.a;
						return _Utils_update(
							sum,
							{bb: val});
					case 1:
						var val = c.a;
						return _Utils_update(
							sum,
							{by: val});
					case 2:
						var val = c.a;
						return _Utils_update(
							sum,
							{bs: val});
					default:
						var val = c.a;
						return _Utils_update(
							sum,
							{bk: val});
				}
			});
		var config = A3($elm$core$List$foldl, accumulateConfig, $tripokey$elm_fuzzy$Fuzzy$defaultConfig, configs);
		var minScore = F2(
			function (n, _v2) {
				var offset = _v2.a;
				var hs = _v2.b;
				var initialPenalty = ((($elm$core$String$length(n) * config.by) + ($elm$core$String$length(n) * config.bs)) + ($elm$core$String$length(hay) * config.bb)) + (($elm$core$String$length(hay) * $elm$core$String$length(n)) * config.bk);
				var initialMatch = A4($tripokey$elm_fuzzy$Fuzzy$Match, initialPenalty, offset, 0, _List_Nil);
				var accumulateMatch = F2(
					function (e, _v1) {
						var prev = _v1.a;
						var prevOffset = _v1.b;
						var newOffset = prevOffset + $elm$core$String$length(e);
						var eDistance = A3($tripokey$elm_fuzzy$Fuzzy$distance, config, n, e);
						var newMatch = (_Utils_cmp(eDistance.gk, prev.gk) < 0) ? _Utils_update(
							eDistance,
							{b: prevOffset}) : prev;
						return _Utils_Tuple2(newMatch, newOffset);
					});
				return A3(
					$elm$core$List$foldl,
					accumulateMatch,
					_Utils_Tuple2(initialMatch, offset),
					hs).a;
			});
		var accumulateResult = F2(
			function (n, _v0) {
				var prev = _v0.a;
				var num = _v0.b;
				var matchResult = A2(
					minScore,
					n,
					A3(
						reduceHays,
						$elm$core$List$length(needles),
						num,
						hays));
				var newResult = _Utils_update(
					prev,
					{
						ca: _Utils_ap(
							prev.ca,
							_List_fromArray(
								[matchResult])),
						gk: matchResult.gk + prev.gk
					});
				return _Utils_Tuple2(newResult, num + 1);
			});
		return A3(
			$elm$core$List$foldl,
			accumulateResult,
			_Utils_Tuple2(initialResult, 0),
			needles).a;
	});
var $sporto$elm_select$Select$Search$scoreForItem = F3(
	function (config, query, item) {
		var options = $sporto$elm_select$Select$Config$fuzzyOptions(config);
		var lowerQuery = $elm$core$String$toLower(query);
		var lowerItem = $elm$core$String$toLower(
			config.d1(item));
		var fuzzySeparators = config.e$;
		return A4($tripokey$elm_fuzzy$Fuzzy$match, options, fuzzySeparators, lowerQuery, lowerItem).gk;
	});
var $elm$core$List$sortBy = _List_sortBy;
var $sporto$elm_select$Select$Search$matchedItems = F3(
	function (config, query, items) {
		var maybeQuery = A2($elm$core$Maybe$andThen, config.gG, query);
		if (maybeQuery.$ === 1) {
			return $sporto$elm_select$Select$Search$NotSearched;
		} else {
			var subQ = maybeQuery.a;
			var _v1 = config.eW;
			if (_v1) {
				var scoreFor = A2($sporto$elm_select$Select$Search$scoreForItem, config, subQ);
				return $sporto$elm_select$Select$Search$ItemsFound(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$second,
						A2(
							$elm$core$List$sortBy,
							$elm$core$Tuple$first,
							A2(
								$elm$core$List$filter,
								function (_v2) {
									var score = _v2.a;
									var item = _v2.b;
									return _Utils_cmp(score, config.gl) < 0;
								},
								A2(
									$elm$core$List$map,
									function (item) {
										return _Utils_Tuple2(
											scoreFor(item),
											item);
									},
									items)))));
			} else {
				return $sporto$elm_select$Select$Search$ItemsFound(items);
			}
		}
	});
var $sporto$elm_select$Select$Search$matchedItemsWithCutoff = F3(
	function (config, query, items) {
		var _v0 = A3($sporto$elm_select$Select$Search$matchedItems, config, query, items);
		if (!_v0.$) {
			return $sporto$elm_select$Select$Search$NotSearched;
		} else {
			var matching = _v0.a;
			var _v1 = config.ez;
			if (!_v1.$) {
				var n = _v1.a;
				return $sporto$elm_select$Select$Search$ItemsFound(
					A2($elm$core$List$take, n, matching));
			} else {
				return $sporto$elm_select$Select$Search$ItemsFound(matching);
			}
		}
	});
var $sporto$elm_select$Select$Styles$multiInputItemClass = 'elm-select-multi-input-item ';
var $sporto$elm_select$Select$Styles$multiInputItemContainerClass = 'elm-select-multi-input-item-container ';
var $sporto$elm_select$Select$Styles$multiInputItemContainerStyles = _List_fromArray(
	[
		_Utils_Tuple2('display', 'flex'),
		_Utils_Tuple2('flex-direction', 'row'),
		_Utils_Tuple2('align-items', 'center'),
		_Utils_Tuple2('justify-content', 'center')
	]);
var $sporto$elm_select$Select$Styles$multiInputItemStyles = _List_fromArray(
	[
		_Utils_Tuple2('display', 'flex'),
		_Utils_Tuple2('border-width', '0.1rem'),
		_Utils_Tuple2('border-radius', '0.2em'),
		_Utils_Tuple2('border-color', '#E3E5E8'),
		_Utils_Tuple2('background-color', '#E3E5E8'),
		_Utils_Tuple2('font-size', '.75rem'),
		_Utils_Tuple2('margin-right', '.2rem')
	]);
var $sporto$elm_select$Select$Styles$multiInputItemText = _List_fromArray(
	[
		_Utils_Tuple2('text-overflow', 'elpisis'),
		_Utils_Tuple2('padding-left', '.5rem'),
		_Utils_Tuple2('padding-right', '.3rem'),
		_Utils_Tuple2('padding-top', '.05rem'),
		_Utils_Tuple2('padding-bottom', '.05rem')
	]);
var $sporto$elm_select$Select$Styles$multiInputRemoveItem = _List_fromArray(
	[
		_Utils_Tuple2('display', 'flex'),
		_Utils_Tuple2('alignItems', 'center'),
		_Utils_Tuple2('justifyContent', 'center'),
		_Utils_Tuple2('padding-right', '.1rem')
	]);
var $sporto$elm_select$Select$Messages$NoOp = {$: 0};
var $sporto$elm_select$Select$Messages$OnBlur = {$: 2};
var $sporto$elm_select$Select$Utils$referenceDataName = 'data-select-id';
var $sporto$elm_select$Select$Events$onBlurAttribute = F2(
	function (config, state) {
		var dataDecoder = A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['relatedTarget', 'attributes', $sporto$elm_select$Select$Utils$referenceDataName, 'value']),
			$elm$json$Json$Decode$string);
		var attrToMsg = function (attr) {
			return _Utils_eq(attr, state.e6) ? $sporto$elm_select$Select$Messages$NoOp : $sporto$elm_select$Select$Messages$OnBlur;
		};
		var blur = A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault($sporto$elm_select$Select$Messages$OnBlur),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$map(attrToMsg),
				$elm$json$Json$Decode$maybe(dataDecoder)));
		return A2($elm$html$Html$Events$on, 'focusout', blur);
	});
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $sporto$elm_select$Select$Messages$OnSelect = function (a) {
	return {$: 9, a: a};
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $sporto$elm_select$Select$Select$Input$onKeyPressAttribute = function (maybeItem) {
	var fn = function (code) {
		switch (code) {
			case 9:
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Decode$fail('nothing selected'),
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $sporto$elm_select$Select$Messages$OnSelect),
						maybeItem));
			case 13:
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Decode$fail('nothing selected'),
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeL, $elm$json$Json$Decode$succeed, $sporto$elm_select$Select$Messages$OnSelect),
						maybeItem));
			default:
				return $elm$json$Json$Decode$fail('not TAB or ENTER');
		}
	};
	return A2(
		$elm$html$Html$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$andThen, fn, $elm$html$Html$Events$keyCode));
};
var $sporto$elm_select$Select$Messages$OnDownArrow = {$: 6};
var $sporto$elm_select$Select$Messages$OnEsc = {$: 5};
var $sporto$elm_select$Select$Messages$OnUpArrow = {$: 7};
var $sporto$elm_select$Select$Select$Input$onKeyUpAttribute = function (maybeItem) {
	var selectItem = function () {
		if (maybeItem.$ === 1) {
			return $elm$json$Json$Decode$fail('not Enter');
		} else {
			var item = maybeItem.a;
			return $elm$json$Json$Decode$succeed(
				$sporto$elm_select$Select$Messages$OnSelect(item));
		}
	}();
	var fn = function (code) {
		switch (code) {
			case 13:
				return selectItem;
			case 38:
				return $elm$json$Json$Decode$succeed($sporto$elm_select$Select$Messages$OnUpArrow);
			case 40:
				return $elm$json$Json$Decode$succeed($sporto$elm_select$Select$Messages$OnDownArrow);
			case 27:
				return $elm$json$Json$Decode$succeed($sporto$elm_select$Select$Messages$OnEsc);
			default:
				return $elm$json$Json$Decode$fail('not ENTER');
		}
	};
	return A2(
		$elm$html$Html$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$andThen, fn, $elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $sporto$elm_select$Select$Utils$referenceAttr = F2(
	function (config, model) {
		return A2($elm$html$Html$Attributes$attribute, $sporto$elm_select$Select$Utils$referenceDataName, model.e6);
	});
var $sporto$elm_select$Select$Styles$underlineClass = 'elm-select-underline ';
var $sporto$elm_select$Select$Styles$underlineStyles = _List_Nil;
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $sporto$elm_select$Select$Select$Clear$svgPath = 'M5,-2.5575245e-15 C3.66990575,-0.0132303807 2.39043461,0.509304222 1.44986941,1.44986941 C0.509304222,2.39043461 -0.0132303807,3.66990575 1.22125617e-14,5 C-0.0132303807,6.33009425 0.509304222,7.60956539 1.44986941,8.55013059 C2.39043461,9.49069578 3.66990575,10.0132304 5,10 C6.33009425,10.0132304 7.60956539,9.49069578 8.55013059,8.55013059 C9.49069578,7.60956539 10.0132304,6.33009425 10,5 C10.0132304,3.66990575 9.49069578,2.39043461 8.55013059,1.44986941 C7.60956539,0.509304222 6.33009425,-0.0132303807 5,-2.5575245e-15 Z M7,6.2 C7.20014558,6.42911206 7.20014558,6.77088794 7,7 C6.77088794,7.20014558 6.42911206,7.20014558 6.2,7 L5,5.8 L3.7,7 C3.60882286,7.09587507 3.48230759,7.15015018 3.35,7.15015018 C3.21769241,7.15015018 3.09117714,7.09587507 3,7 C2.90412493,6.90882286 2.84984982,6.78230759 2.84984982,6.65 C2.84984982,6.51769241 2.90412493,6.39117714 3,6.3 L4.2,5 L3,3.8 C2.79985442,3.57088794 2.79985442,3.22911206 3,3 C3.22776052,2.79628479 3.57223948,2.79628479 3.8,3 L5,4.2 L6.3,3 C6.39117714,2.90412493 6.51769241,2.84984982 6.65,2.84984982 C6.78230759,2.84984982 6.90882286,2.90412493 7,3 C7.09587507,3.09117714 7.15015018,3.21769241 7.15015018,3.35 C7.15015018,3.48230759 7.09587507,3.60882286 7,3.7 L5.8,5 L7,6.2 Z';
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $sporto$elm_select$Select$Select$Clear$view = function (config) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class(config.et),
				$elm$svg$Svg$Attributes$width('16'),
				$elm$svg$Svg$Attributes$height('16'),
				$elm$svg$Svg$Attributes$viewBox('0 0 16 16')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$transform('translate(3, 3)')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$d($sporto$elm_select$Select$Select$Clear$svgPath)
							]),
						_List_Nil)
					]))
			]));
};
var $sporto$elm_select$Select$Styles$removeItemSvgClass = 'elm-select-remove-item ';
var $sporto$elm_select$Select$Styles$removeItemSvgStyles = _List_fromArray(
	[
		_Utils_Tuple2('cursor', 'pointer')
	]);
var $sporto$elm_select$Select$Select$RemoveItem$svgPath = 'M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z';
var $sporto$elm_select$Select$Select$RemoveItem$view = function (config) {
	return A2(
		$elm$svg$Svg$svg,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class(
					_Utils_ap(config.dE, $sporto$elm_select$Select$Styles$removeItemSvgClass)),
					$elm$svg$Svg$Attributes$width('14'),
					$elm$svg$Svg$Attributes$height('14'),
					$elm$svg$Svg$Attributes$viewBox('0 0 20 20')
				]),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var f = _v0.a;
					var s = _v0.b;
					return A2($elm$html$Html$Attributes$style, f, s);
				},
				_Utils_ap(config.dF, $sporto$elm_select$Select$Styles$removeItemSvgStyles))),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d($sporto$elm_select$Select$Select$RemoveItem$svgPath)
					]),
				_List_Nil)
			]));
};
var $sporto$elm_select$Select$Select$Input$view = F4(
	function (config, model, items, selected) {
		var underlineStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$underlineStyles, config.gI);
		var underlineClasses = _Utils_ap($sporto$elm_select$Select$Styles$underlineClass, config.gH);
		var underline = A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class(underlineClasses),
				A2(
					$elm$core$List$map,
					function (_v22) {
						var f = _v22.a;
						var s = _v22.b;
						return A2($elm$html$Html$Attributes$style, f, s);
					},
					underlineStyles)),
			_List_Nil);
		var onClickWithoutPropagation = function (msg) {
			return A2(
				$elm$html$Html$Events$stopPropagationOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(msg, false)));
		};
		var multiInputItemStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$multiInputItemStyles, config.fB);
		var multiInputItemContainerStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$multiInputItemContainerStyles, config.fA);
		var multiInputItemContainerClasses = _Utils_ap($sporto$elm_select$Select$Styles$multiInputItemContainerClass, config.fz);
		var multiInputItemClasses = _Utils_ap($sporto$elm_select$Select$Styles$multiInputItemClass, config.fy);
		var viewMultiItems = function (subItems) {
			return A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class(multiInputItemContainerClasses),
					A2(
						$elm$core$List$map,
						function (_v17) {
							var f = _v17.a;
							var s = _v17.b;
							return A2($elm$html$Html$Attributes$style, f, s);
						},
						multiInputItemContainerStyles)),
				A2(
					$elm$core$List$map,
					function (item) {
						return A2(
							$elm$html$Html$div,
							A2(
								$elm$core$List$cons,
								$elm$html$Html$Attributes$class(multiInputItemClasses),
								A2(
									$elm$core$List$map,
									function (_v18) {
										var f = _v18.a;
										var s = _v18.b;
										return A2($elm$html$Html$Attributes$style, f, s);
									},
									multiInputItemStyles)),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									A2(
										$elm$core$List$map,
										function (_v19) {
											var f = _v19.a;
											var s = _v19.b;
											return A2($elm$html$Html$Attributes$style, f, s);
										},
										$sporto$elm_select$Select$Styles$multiInputItemText),
									_List_fromArray(
										[
											$elm$html$Html$text(
											config.d1(item))
										])),
									A2(
									$elm$core$Maybe$withDefault,
									A2($elm$html$Html$span, _List_Nil, _List_Nil),
									A2(
										$elm$core$Maybe$map,
										function (_v20) {
											return A2(
												$elm$html$Html$div,
												A2(
													$elm$core$List$cons,
													onClickWithoutPropagation(
														$sporto$elm_select$Select$Messages$OnRemoveItem(item)),
													A2(
														$elm$core$List$map,
														function (_v21) {
															var f = _v21.a;
															var s = _v21.b;
															return A2($elm$html$Html$Attributes$style, f, s);
														},
														$sporto$elm_select$Select$Styles$multiInputRemoveItem)),
												_List_fromArray(
													[
														$sporto$elm_select$Select$Select$RemoveItem$view(config)
													]));
										},
										config.fW))
								]));
					},
					subItems));
		};
		var inputWrapperStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$inputWrapperStyles, config.fh);
		var inputWrapperClass = _Utils_ap($sporto$elm_select$Select$Styles$inputWrapperClass, config.fg);
		var inputControlStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$inputControlStyles, config.fe);
		var inputControlClass = _Utils_ap($sporto$elm_select$Select$Styles$inputControlClass, config.fd);
		var filteredItems = A2(
			$elm$core$Maybe$withDefault,
			items,
			A3(
				$sporto$elm_select$Select$Utils$andThenSelected,
				selected,
				function (oneSelectedItem) {
					return $elm$core$Maybe$Nothing;
				},
				function (manySelectedItems) {
					return $elm$core$Maybe$Just(
						A2($sporto$elm_select$Select$Utils$difference, items, manySelectedItems));
				}));
		var matchedItems = A3($sporto$elm_select$Select$Search$matchedItemsWithCutoff, config, model.c, filteredItems);
		var preselectedItem = function () {
			if (!matchedItems.$) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!matchedItems.a.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!matchedItems.a.b.b) {
						var _v15 = matchedItems.a;
						var singleItem = _v15.a;
						return $elm$core$Maybe$Just(singleItem);
					} else {
						var found = matchedItems.a;
						var head = found.a;
						var rest = found.b;
						var _v16 = model.c9;
						if (_v16.$ === 1) {
							return $elm$core$Maybe$Just(head);
						} else {
							var n = _v16.a;
							return A2(
								$elm$core$Array$get,
								n % $elm$core$List$length(found),
								$elm$core$Array$fromList(found));
						}
					}
				}
			}
		}();
		var clearStyles = A2($elm$core$List$append, $sporto$elm_select$Select$Styles$clearStyles, config.es);
		var clearClasses = _Utils_ap($sporto$elm_select$Select$Styles$clearClass, config.er);
		var clear = function () {
			if (selected.$ === 1) {
				return $elm$html$Html$text('');
			} else {
				return A2(
					$elm$html$Html$div,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class(clearClasses),
								onClickWithoutPropagation($sporto$elm_select$Select$Messages$OnClear)
							]),
						A2(
							$elm$core$List$map,
							function (_v13) {
								var f = _v13.a;
								var s = _v13.b;
								return A2($elm$html$Html$Attributes$style, f, s);
							},
							clearStyles)),
					_List_fromArray(
						[
							$sporto$elm_select$Select$Select$Clear$view(config)
						]));
			}
		}();
		var _v0 = function () {
			if (selected.$ === 1) {
				return _Utils_Tuple2(config.f5, config.f6);
			} else {
				return _Utils_Tuple2('', _List_Nil);
			}
		}();
		var promptClass = _v0.a;
		var promptStyles = _v0.b;
		var inputClasses = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[$sporto$elm_select$Select$Styles$inputClass, config.fb, promptClass]));
		var inputStyles = $elm$core$List$concat(
			_List_fromArray(
				[$sporto$elm_select$Select$Styles$inputStyles, config.ff, promptStyles]));
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$autocomplete(false),
					A2($elm$html$Html$Attributes$attribute, 'autocorrect', 'off'),
					$elm$html$Html$Attributes$id(config.b8),
					A2($sporto$elm_select$Select$Events$onBlurAttribute, config, model),
					$sporto$elm_select$Select$Select$Input$onKeyUpAttribute(preselectedItem),
					$sporto$elm_select$Select$Select$Input$onKeyPressAttribute(preselectedItem),
					$elm$html$Html$Events$onInput($sporto$elm_select$Select$Messages$OnQueryChange),
					$elm$html$Html$Events$onFocus($sporto$elm_select$Select$Messages$OnFocus),
					A2($sporto$elm_select$Select$Utils$referenceAttr, config, model),
					$elm$html$Html$Attributes$class(inputClasses)
				]),
			A2(
				$elm$core$List$map,
				function (_v11) {
					var f = _v11.a;
					var s = _v11.b;
					return A2($elm$html$Html$Attributes$style, f, s);
				},
				inputStyles));
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class(inputControlClass),
				A2(
					$elm$core$List$map,
					function (_v2) {
						var f = _v2.a;
						var s = _v2.b;
						return A2($elm$html$Html$Attributes$style, f, s);
					},
					inputControlStyles)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class(inputWrapperClass),
						A2(
							$elm$core$List$map,
							function (_v3) {
								var f = _v3.a;
								var s = _v3.b;
								return A2($elm$html$Html$Attributes$style, f, s);
							},
							inputWrapperStyles)),
					function () {
						var _v4 = _Utils_Tuple2(selected, model.c);
						if (!_v4.a.$) {
							if (!_v4.b.$) {
								var selectedType = _v4.a.a;
								var queryValue = _v4.b.a;
								if (!selectedType.$) {
									var item = selectedType.a;
									return _List_fromArray(
										[
											A2($elm$html$Html$div, _List_Nil, _List_Nil),
											A2(
											$elm$html$Html$input,
											_Utils_ap(
												inputAttributes,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(queryValue)
													])),
											_List_Nil)
										]);
								} else {
									var subItems = selectedType.a;
									return _List_fromArray(
										[
											viewMultiItems(subItems),
											A2(
											$elm$html$Html$input,
											_Utils_ap(
												inputAttributes,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(queryValue)
													])),
											_List_Nil)
										]);
								}
							} else {
								var selectedType = _v4.a.a;
								var _v6 = _v4.b;
								if (!selectedType.$) {
									var item = selectedType.a;
									return _List_fromArray(
										[
											A2($elm$html$Html$div, _List_Nil, _List_Nil),
											A2(
											$elm$html$Html$input,
											_Utils_ap(
												inputAttributes,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(
														config.d1(item))
													])),
											_List_Nil)
										]);
								} else {
									var subItems = selectedType.a;
									return _List_fromArray(
										[
											viewMultiItems(subItems),
											A2(
											$elm$html$Html$input,
											_Utils_ap(
												inputAttributes,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value('')
													])),
											_List_Nil)
										]);
								}
							}
						} else {
							if (!_v4.b.$) {
								var _v8 = _v4.a;
								var queryValue = _v4.b.a;
								return _List_fromArray(
									[
										A2($elm$html$Html$div, _List_Nil, _List_Nil),
										A2(
										$elm$html$Html$input,
										_Utils_ap(
											inputAttributes,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(queryValue),
													$elm$html$Html$Attributes$placeholder(config.f4)
												])),
										_List_Nil)
									]);
							} else {
								var _v9 = _v4.a;
								var _v10 = _v4.b;
								return _List_fromArray(
									[
										A2($elm$html$Html$div, _List_Nil, _List_Nil),
										A2(
										$elm$html$Html$input,
										_Utils_ap(
											inputAttributes,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$value(''),
													$elm$html$Html$Attributes$placeholder(config.f4)
												])),
										_List_Nil)
									]);
							}
						}
					}()),
					underline,
					clear
				]));
	});
var $sporto$elm_select$Select$Styles$hiddenMenuStyles = _List_fromArray(
	[
		_Utils_Tuple2('display', 'none')
	]);
var $sporto$elm_select$Select$Styles$menuItemClass = 'elm-select-item ';
var $sporto$elm_select$Select$Select$Item$baseItemClasses = function (config) {
	return _Utils_ap($sporto$elm_select$Select$Styles$menuItemClass, config.fk);
};
var $sporto$elm_select$Select$Select$Item$baseItemStyles = function (config) {
	return config.fm;
};
var $sporto$elm_select$Select$Styles$menuItemStyles = _List_fromArray(
	[
		_Utils_Tuple2('cursor', 'pointer')
	]);
var $elm$html$Html$Events$onMouseDown = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mousedown',
		$elm$json$Json$Decode$succeed(msg));
};
var $sporto$elm_select$Select$Select$Item$view = F5(
	function (config, state, itemCount, index, item) {
		var itemHtml = function () {
			var _v3 = config.fl;
			if (_v3.$ === 1) {
				return $elm$html$Html$text(
					config.d1(item));
			} else {
				var fn = _v3.a;
				return A2(
					$elm$html$Html$map,
					function (_v4) {
						return $sporto$elm_select$Select$Messages$NoOp;
					},
					fn(item));
			}
		}();
		var _v0 = function () {
			var _v1 = state.c9;
			if (_v1.$ === 1) {
				return _Utils_Tuple2('', _List_Nil);
			} else {
				var highlighted = _v1.a;
				return _Utils_eq(highlighted % itemCount, index) ? _Utils_Tuple2(config.e3, config.e4) : _Utils_Tuple2('', _List_Nil);
			}
		}();
		var highlightedItemClass = _v0.a;
		var highlightedItemStyles = _v0.b;
		var classes = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					$sporto$elm_select$Select$Select$Item$baseItemClasses(config),
					highlightedItemClass
				]));
		var styles = $elm$core$List$concat(
			_List_fromArray(
				[
					$sporto$elm_select$Select$Styles$menuItemStyles,
					$sporto$elm_select$Select$Select$Item$baseItemStyles(config),
					highlightedItemStyles
				]));
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(classes),
						$elm$html$Html$Events$onMouseDown(
						$sporto$elm_select$Select$Messages$OnSelect(item)),
						A2($sporto$elm_select$Select$Utils$referenceAttr, config, state)
					]),
				A2(
					$elm$core$List$map,
					function (_v2) {
						var f = _v2.a;
						var s = _v2.b;
						return A2($elm$html$Html$Attributes$style, f, s);
					},
					styles)),
			_List_fromArray(
				[itemHtml]));
	});
var $sporto$elm_select$Select$Styles$menuClass = 'elm-select-menu ';
var $sporto$elm_select$Select$Select$Menu$viewClassAttr = function (config) {
	return $elm$html$Html$Attributes$class(
		_Utils_ap($sporto$elm_select$Select$Styles$menuClass, config.ft));
};
var $sporto$elm_select$Select$Select$Item$viewNotFound = function (config) {
	var styles = A2(
		$elm$core$List$append,
		$sporto$elm_select$Select$Select$Item$baseItemStyles(config),
		config.fR);
	var classes = A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$sporto$elm_select$Select$Select$Item$baseItemClasses(config),
				config.fP
			]));
	return (config.fO === '') ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class(classes),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var f = _v0.a;
					var s = _v0.b;
					return A2($elm$html$Html$Attributes$style, f, s);
				},
				styles)),
		_List_fromArray(
			[
				$elm$html$Html$text(config.fO)
			]));
};
var $sporto$elm_select$Select$Styles$visibleMenuStyles = _List_fromArray(
	[
		_Utils_Tuple2('position', 'absolute'),
		_Utils_Tuple2('z-index', '1')
	]);
var $sporto$elm_select$Select$Select$Menu$viewStyles = function (config) {
	return A2($elm$core$List$append, $sporto$elm_select$Select$Styles$visibleMenuStyles, config.fu);
};
var $sporto$elm_select$Select$Select$Menu$menu = F3(
	function (config, model, matchedItems) {
		var noResultElement = _Utils_eq(matchedItems, _List_Nil) ? $sporto$elm_select$Select$Select$Item$viewNotFound(config) : $elm$html$Html$text('');
		var itemCount = $elm$core$List$length(matchedItems);
		var hideWhenNotFound = (!config.fQ) && _Utils_eq(matchedItems, _List_Nil);
		var menuStyle = hideWhenNotFound ? A2(
			$elm$core$List$map,
			function (_v0) {
				var f = _v0.a;
				var s = _v0.b;
				return A2($elm$html$Html$Attributes$style, f, s);
			},
			$sporto$elm_select$Select$Styles$hiddenMenuStyles) : A2(
			$elm$core$List$map,
			function (_v1) {
				var f = _v1.a;
				var s = _v1.b;
				return A2($elm$html$Html$Attributes$style, f, s);
			},
			$sporto$elm_select$Select$Select$Menu$viewStyles(config));
		var elements = A2(
			$elm$core$List$indexedMap,
			A3($sporto$elm_select$Select$Select$Item$view, config, model, itemCount),
			matchedItems);
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$sporto$elm_select$Select$Select$Menu$viewClassAttr(config),
				menuStyle),
			A2($elm$core$List$cons, noResultElement, elements));
	});
var $sporto$elm_select$Select$Select$Menu$view = F4(
	function (config, model, items, selected) {
		var query = A2($elm$core$Maybe$withDefault, '', model.c);
		var filteredItems = A2(
			$elm$core$Maybe$withDefault,
			items,
			A3(
				$sporto$elm_select$Select$Utils$andThenSelected,
				selected,
				function (oneSelectedItem) {
					return $elm$core$Maybe$Nothing;
				},
				function (manySelectedItems) {
					return $elm$core$Maybe$Just(
						A2($sporto$elm_select$Select$Utils$difference, items, manySelectedItems));
				}));
		var searchResult = A3($sporto$elm_select$Select$Search$matchedItemsWithCutoff, config, model.c, filteredItems);
		if ((query === '') && (!config.eK)) {
			return $elm$html$Html$text('');
		} else {
			if (!searchResult.$) {
				return $elm$html$Html$text('');
			} else {
				var matchedItems = searchResult.a;
				return A3($sporto$elm_select$Select$Select$Menu$menu, config, model, matchedItems);
			}
		}
	});
var $sporto$elm_select$Select$Select$view = F4(
	function (config, model, items, selected) {
		var classes = 'elm-select';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(model.e6),
					$elm$html$Html$Attributes$class(classes),
					A2($elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A4($sporto$elm_select$Select$Select$Input$view, config, model, items, selected),
					A4($sporto$elm_select$Select$Select$Menu$view, config, model, items, selected)
				]));
	});
var $sporto$elm_select$Select$viewBase = F4(
	function (config, model, items, selected) {
		var model_ = $sporto$elm_select$Select$unwrapModel(model);
		var config_ = $sporto$elm_select$Select$unwrapConfig(config);
		return A2(
			$elm$html$Html$map,
			$elm$core$Basics$identity,
			A4($sporto$elm_select$Select$Select$view, config_, model_, items, selected));
	});
var $sporto$elm_select$Select$view = F4(
	function (config, model, items, selected) {
		return A4(
			$sporto$elm_select$Select$viewBase,
			config,
			model,
			items,
			A2($elm$core$Maybe$map, $sporto$elm_select$Select$Models$Single, selected));
	});
var $author$project$UI$Autocomplete$view = F2(
	function (_v0, selected) {
		var state = _v0;
		return A2(
			$elm$html$Html$map,
			$author$project$UI$Autocomplete$SelectMsg,
			A4($sporto$elm_select$Select$view, state.ct, state.bB, state.bL, selected));
	});
var $author$project$OrderFilters$accountManagersView = function (model) {
	var autoView = A2($author$project$UI$Autocomplete$view, model.T, model.c.ed);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-3')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Partnership Consultant'),
						A2($elm$html$Html$map, $author$project$OrderFilters$AccountManagerAutocomplete, autoView)
					]))
			]));
};
var $author$project$OrderFilters$Refresh = {$: 1};
var $author$project$UI$Button$simple = F3(
	function (extraClasses, content, clickMsg) {
		var classes = A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				_List_fromArray(
					['btn', 'btn-sm']),
				extraClasses));
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(classes),
					$elm$html$Html$Events$onClick(clickMsg)
				]),
			_List_fromArray(
				[content]));
	});
var $author$project$UI$Button$textSimple = F2(
	function (extraClasses, buttonText) {
		return A2(
			$author$project$UI$Button$simple,
			extraClasses,
			$elm$html$Html$text(buttonText));
	});
var $author$project$UI$Button$primary = $author$project$UI$Button$textSimple(
	_List_fromArray(
		['btn-primary']));
var $author$project$OrderFilters$buttonsView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('col-sm-3')
		]),
	_List_fromArray(
		[
			A2($author$project$UI$Button$primary, 'Refresh', $author$project$OrderFilters$Refresh)
		]));
var $author$project$UI$DatePicker$Clear = {$: 1};
var $author$project$UI$Icons$generic = function (name) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fa fa-' + name)
			]),
		_List_Nil);
};
var $author$project$UI$Icons$clear = $author$project$UI$Icons$generic('times-circle');
var $isaacseymour$deprecated_time$Time$Date$delta = F2(
	function (_v0, _v1) {
		var d1 = _v0;
		var d2 = _v1;
		return {
			eA: A3($isaacseymour$deprecated_time$Time$Date$daysFromYearMonthDay, d1.d9, d1.dm, d1.cW) - A3($isaacseymour$deprecated_time$Time$Date$daysFromYearMonthDay, d2.d9, d2.dm, d2.cW),
			dn: (($elm$core$Basics$abs(d1.d9) * 12) + $isaacseymour$deprecated_time$Time$Date$monthToInt(d1.dm)) - (($elm$core$Basics$abs(d2.d9) * 12) + $isaacseymour$deprecated_time$Time$Date$monthToInt(d2.dm)),
			ea: d1.d9 - d2.d9
		};
	});
var $justinmimbs$date$Date$fromOrdinalDate = F2(
	function (y, od) {
		var daysInY = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return $justinmimbs$date$Date$daysBeforeYear(y) + A3($elm$core$Basics$clamp, 1, daysInY, od);
	});
var $author$project$UI$DatePicker$toBetter = function (input) {
	var year = $isaacseymour$deprecated_time$Time$Date$year(input);
	var startOfYear = A3($isaacseymour$deprecated_time$Time$Date$date, year, 1, 1);
	var daysSinceStartOfYear = $elm$core$Basics$abs(
		A2($isaacseymour$deprecated_time$Time$Date$delta, input, startOfYear).eA);
	return A2($justinmimbs$date$Date$fromOrdinalDate, year, daysSinceStartOfYear + 1);
};
var $CurrySoftware$elm_datepicker$DatePicker$Blur = {$: 6};
var $CurrySoftware$elm_datepicker$DatePicker$Focus = {$: 5};
var $CurrySoftware$elm_datepicker$DatePicker$SubmitText = {$: 4};
var $CurrySoftware$elm_datepicker$DatePicker$Text = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $CurrySoftware$elm_datepicker$DatePicker$ChangeFocus = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Date$Months = 1;
var $CurrySoftware$elm_datepicker$DatePicker$MouseDown = {$: 7};
var $CurrySoftware$elm_datepicker$DatePicker$MouseUp = {$: 8};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.d9 - 1)) + ($justinmimbs$date$Date$monthToNumber(date.dm) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.cW,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $elm$html$Html$button = _VirtualDom_node('button');
var $CurrySoftware$elm_datepicker$DatePicker$Date$changeYear = F2(
	function (current, newYear) {
		var _v0 = $elm$core$String$toInt(newYear);
		if (!_v0.$) {
			var year = _v0.a;
			return A3(
				$justinmimbs$date$Date$fromCalendarDate,
				year,
				$justinmimbs$date$Date$month(current),
				$justinmimbs$date$Date$day(current));
		} else {
			return current;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$groupDates = function (dates) {
	var go = F4(
		function (i, xs, racc, acc) {
			go:
			while (true) {
				if (!xs.b) {
					return $elm$core$List$reverse(acc);
				} else {
					var x = xs.a;
					var xxs = xs.b;
					if (i === 6) {
						var $temp$i = 0,
							$temp$xs = xxs,
							$temp$racc = _List_Nil,
							$temp$acc = A2(
							$elm$core$List$cons,
							$elm$core$List$reverse(
								A2($elm$core$List$cons, x, racc)),
							acc);
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					} else {
						var $temp$i = i + 1,
							$temp$xs = xxs,
							$temp$racc = A2($elm$core$List$cons, x, racc),
							$temp$acc = acc;
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					}
				}
			}
		});
	return A4(go, 0, dates, _List_Nil, _List_Nil);
};
var $CurrySoftware$elm_datepicker$DatePicker$maybeOr = F2(
	function (lhs, rhs) {
		if (!rhs.$) {
			return rhs;
		} else {
			return lhs;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$mkClass = F2(
	function (_v0, c) {
		var classNamespace = _v0.aq;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(classNamespace, c));
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$option = _VirtualDom_node('option');
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval = function (weekday) {
	switch (weekday) {
		case 0:
			return 4;
		case 1:
			return 5;
		case 2:
			return 6;
		case 3:
			return 7;
		case 4:
			return 8;
		case 5:
			return 9;
		default:
			return 10;
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$prepareDates = F2(
	function (date, firstDayOfWeek) {
		var weekdayAsInterval = $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval(firstDayOfWeek);
		var start = A2(
			$justinmimbs$date$Date$floor,
			weekdayAsInterval,
			A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(date),
				$justinmimbs$date$Date$month(date),
				1));
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date),
			1);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayAsInterval,
			A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth));
		return {
			cU: A4($justinmimbs$date$Date$range, 11, 1, start, end),
			bQ: date
		};
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $CurrySoftware$elm_datepicker$DatePicker$Pick = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$mkClassList = F2(
	function (_v0, cs) {
		var classNamespace = _v0.aq;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var c = _v1.a;
					var b = _v1.b;
					return _Utils_Tuple2(
						_Utils_ap(classNamespace, c),
						b);
				},
				cs));
	});
var $CurrySoftware$elm_datepicker$DatePicker$viewDay = F5(
	function (settings, picked, isOtherMonth, isToday, d) {
		var disabled = settings.bl(d);
		var props = (!disabled) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$CurrySoftware$elm_datepicker$DatePicker$Pick(d))
			]) : _List_Nil;
		var classList = $CurrySoftware$elm_datepicker$DatePicker$mkClassList(settings);
		return A2(
			$elm$html$Html$td,
			_Utils_ap(
				_List_fromArray(
					[
						classList(
						_List_fromArray(
							[
								_Utils_Tuple2('day', true),
								_Utils_Tuple2('disabled', disabled),
								_Utils_Tuple2(
								'picked',
								picked(d)),
								_Utils_Tuple2(
								'today',
								isToday(d)),
								_Utils_Tuple2(
								'other-month',
								isOtherMonth(d))
							]))
					]),
				props),
			_List_fromArray(
				[
					settings.bM(
					$elm$core$String$fromInt(
						$justinmimbs$date$Date$day(d)))
				]));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$yearRange = F2(
	function (_v0, range) {
		var currentMonth = _v0.bQ;
		var today = _v0.R;
		switch (range.$) {
			case 1:
				var num = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth) - num,
					$justinmimbs$date$Date$year(currentMonth) + num);
			case 2:
				var start = range.a;
				var end = range.b;
				return A2($elm$core$List$range, start, end);
			case 3:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					year_,
					$justinmimbs$date$Date$year(today));
			case 4:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(today),
					year_);
			default:
				return _List_Nil;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$yearRangeActive = function (yearRange) {
	return !_Utils_eq(yearRange, $CurrySoftware$elm_datepicker$DatePicker$Date$Off);
};
var $CurrySoftware$elm_datepicker$DatePicker$datePicker = F3(
	function (pickedDate, settings, model) {
		var focused = model.u;
		var today = model.R;
		var picked = function (d) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (pdate) {
						return _Utils_eq(
							$justinmimbs$date$Date$toRataDie(pdate),
							$justinmimbs$date$Date$toRataDie(d));
					},
					pickedDate));
		};
		var onChange = function (handler) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, handler, $elm$html$Html$Events$targetValue));
		};
		var isToday = function (d) {
			return _Utils_eq(
				$justinmimbs$date$Date$toRataDie(d),
				$justinmimbs$date$Date$toRataDie(today));
		};
		var firstDayOffset = $justinmimbs$date$Date$weekdayToNumber(settings.bi) - 1;
		var dpClass = $CurrySoftware$elm_datepicker$DatePicker$mkClass(settings);
		var currentDate = A2(
			$elm$core$Maybe$withDefault,
			today,
			A2($CurrySoftware$elm_datepicker$DatePicker$maybeOr, pickedDate, focused));
		var isOtherMonth = function (d) {
			return !_Utils_eq(
				$justinmimbs$date$Date$month(currentDate),
				$justinmimbs$date$Date$month(d));
		};
		var arrow = F2(
			function (className, message) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							dpClass(className),
							$elm$html$Html$Events$onClick(message),
							$elm$html$Html$Attributes$tabindex(-1),
							$elm$html$Html$Attributes$type_('button')
						]),
					_List_Nil);
			});
		var _v0 = A2($CurrySoftware$elm_datepicker$DatePicker$prepareDates, currentDate, settings.bi);
		var currentMonth = _v0.bQ;
		var currentDates = _v0.cU;
		var dayList = A2(
			$elm$core$List$map,
			function (rowDays) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							dpClass('row')
						]),
					A2(
						$elm$core$List$map,
						A4($CurrySoftware$elm_datepicker$DatePicker$viewDay, settings, picked, isOtherMonth, isToday),
						rowDays));
			},
			$CurrySoftware$elm_datepicker$DatePicker$groupDates(currentDates));
		var isCurrentYear = function (selectedYear) {
			return _Utils_eq(
				$justinmimbs$date$Date$year(currentMonth),
				selectedYear);
		};
		var yearOption = F2(
			function (index, selectedYear) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(index),
					A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(selectedYear)),
								$elm$html$Html$Attributes$selected(
								isCurrentYear(selectedYear))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(selectedYear))
							])));
			});
		var _v1 = function () {
			var front = function (to_) {
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth),
					to_);
			};
			var back = function (from_) {
				return A2(
					$elm$core$List$range,
					from_,
					$justinmimbs$date$Date$year(currentMonth));
			};
			var _v2 = settings.aM;
			switch (_v2.$) {
				case 3:
					var from_ = _v2.a;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(
							$justinmimbs$date$Date$year(today) + 1));
				case 4:
					var to_ = _v2.a;
					return _Utils_Tuple2(
						front(
							$justinmimbs$date$Date$year(today) - 1),
						back(to_ + 1));
				case 2:
					var from_ = _v2.a;
					var to_ = _v2.b;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(to_ + 1));
				case 1:
					var y = _v2.a;
					return _Utils_Tuple2(_List_Nil, _List_Nil);
				default:
					return _Utils_Tuple2(_List_Nil, _List_Nil);
			}
		}();
		var addedYearsFront = _v1.a;
		var addedYearsBack = _v1.b;
		var dropdownYear = A3(
			$elm$html$Html$Keyed$node,
			'select',
			_List_fromArray(
				[
					onChange(
					A2(
						$elm$core$Basics$composeR,
						$CurrySoftware$elm_datepicker$DatePicker$Date$changeYear(currentDate),
						$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus)),
					dpClass('year-menu')
				]),
			A2(
				$elm$core$List$indexedMap,
				yearOption,
				$elm$core$List$concat(
					_List_fromArray(
						[
							addedYearsFront,
							A2(
							$CurrySoftware$elm_datepicker$DatePicker$Date$yearRange,
							{bQ: currentMonth, R: today},
							settings.aM),
							addedYearsBack
						]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					dpClass('picker'),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mousedown',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseDown, true))),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mouseup',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseUp, true)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							dpClass('picker-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('prev-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'prev',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, -1, currentDate)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('month-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('month')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											settings.cf(
												$justinmimbs$date$Date$month(currentMonth)))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('year')
										]),
									_List_fromArray(
										[
											(!$CurrySoftware$elm_datepicker$DatePicker$yearRangeActive(settings.aM)) ? $elm$html$Html$text(
											settings.cJ(
												$justinmimbs$date$Date$year(currentMonth))) : A3(
											$elm$html$Html$Keyed$node,
											'span',
											_List_Nil,
											_List_fromArray(
												[
													_Utils_Tuple2(
													$elm$core$String$fromInt(
														$justinmimbs$date$Date$year(currentMonth)),
													dropdownYear)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('next-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'next',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, 1, currentDate)))
								]))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							dpClass('table')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_fromArray(
								[
									dpClass('weekdays')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (d) {
											return A2(
												$elm$html$Html$td,
												_List_fromArray(
													[
														dpClass('dow')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														settings.bS(d))
													]));
										},
										A2(
											$elm$core$List$take,
											7,
											A2(
												$elm$core$List$drop,
												firstDayOffset,
												$elm$core$List$concat(
													A2(
														$elm$core$List$repeat,
														2,
														_List_fromArray(
															[0, 1, 2, 3, 4, 5, 6])))))))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_fromArray(
								[
									dpClass('days')
								]),
							dayList)
						]))
				]));
	});
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $CurrySoftware$elm_datepicker$DatePicker$view = F3(
	function (pickedDate, settings, _v0) {
		var datepicker = _v0;
		var model = datepicker;
		var potentialInputId = A3(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$core$List$filterMap($elm$core$Basics$identity),
			A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, settings.b8));
		var inputClasses = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(settings.aq + 'input', true)
				]),
			settings.fc);
		var inputCommon = function (xs) {
			return A2(
				$elm$html$Html$input,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(inputClasses),
							$elm$html$Html$Attributes$name(
							A2($elm$core$Maybe$withDefault, '', settings.b9)),
							$elm$html$Html$Attributes$type_('text'),
							A2(
							$elm$html$Html$Events$on,
							'change',
							$elm$json$Json$Decode$succeed($CurrySoftware$elm_datepicker$DatePicker$SubmitText)),
							$elm$html$Html$Events$onInput($CurrySoftware$elm_datepicker$DatePicker$Text),
							$elm$html$Html$Events$onBlur($CurrySoftware$elm_datepicker$DatePicker$Blur),
							$elm$html$Html$Events$onClick($CurrySoftware$elm_datepicker$DatePicker$Focus),
							$elm$html$Html$Events$onFocus($CurrySoftware$elm_datepicker$DatePicker$Focus)
						]),
					_Utils_ap(
						settings.b7,
						_Utils_ap(potentialInputId, xs))),
				_List_Nil);
		};
		var dateInput = inputCommon(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$placeholder(settings.cp),
					$elm$html$Html$Attributes$value(
					A2(
						$elm$core$Maybe$withDefault,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, settings.bR, pickedDate)),
						model.A))
				]));
		var containerClassList = A2(
			$elm$core$List$cons,
			_Utils_Tuple2(settings.aq + 'container', true),
			settings.bP);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(containerClassList)
				]),
			_List_fromArray(
				[
					dateInput,
					model.I ? A3($CurrySoftware$elm_datepicker$DatePicker$datePicker, pickedDate, settings, model) : $elm$html$Html$text('')
				]));
	});
var $author$project$UI$DatePicker$view = F3(
	function (_v0, maybeDate, _v1) {
		var wrapper = _v0.gY;
		var picker = _v1;
		var maybeBetterDate = A2($elm$core$Maybe$map, $author$project$UI$DatePicker$toBetter, maybeDate);
		var datePickerView = A2(
			$elm$html$Html$map,
			wrapper,
			A2(
				$elm$html$Html$map,
				$author$project$UI$DatePicker$DatePickerMsg,
				A3($CurrySoftware$elm_datepicker$DatePicker$view, maybeBetterDate, $author$project$UI$DatePicker$settings, picker)));
		var clearView = function () {
			if (maybeDate.$ === 1) {
				return $elm$html$Html$text('');
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('elm-datepicker--clear-container'),
							$elm$html$Html$Events$onClick(
							wrapper($author$project$UI$DatePicker$Clear))
						]),
					_List_fromArray(
						[$author$project$UI$Icons$clear]));
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('elm-datepicker--wrapper')
				]),
			_List_fromArray(
				[datePickerView, clearView]));
	});
var $author$project$OrderFilters$datePickerView = F2(
	function (picker, filters) {
		var config = $author$project$OrderFilters$datePickerConfig(picker);
		var _v0 = function () {
			switch (picker) {
				case 0:
					return _Utils_Tuple3(filters.a1, filters.c.ae, 'Was pending on or before');
				case 1:
					return _Utils_Tuple3(filters.a0, filters.c.ad, 'Was pending on or after');
				case 2:
					return _Utils_Tuple3(filters.a3, filters.c.ag, 'Was confirmed on or after');
				default:
					return _Utils_Tuple3(filters.a2, filters.c.af, 'Was confirmed on or before');
			}
		}();
		var model = _v0.a;
		var value = _v0.b;
		var label = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-sm-3')
				]),
			_List_fromArray(
				[
					$author$project$UI$Form$group(
					_List_fromArray(
						[
							$author$project$UI$Form$controlLabel(label),
							A3($author$project$UI$DatePicker$view, config, value, model)
						]))
				]));
	});
var $author$project$OrderFilters$dealershipGroupsView = function (model) {
	var autoView = A2($author$project$UI$Autocomplete$view, model.V, model.c.z);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-3')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Dealership Group'),
						A2($elm$html$Html$map, $author$project$OrderFilters$DealershipGroupAutocomplete, autoView)
					]))
			]));
};
var $author$project$OrderFilters$DealershipTypeChanged = function (a) {
	return {$: 24, a: a};
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$OrderFilters$pillButton = F3(
	function (label, value, selected) {
		return {
			fo: label,
			gm: _Utils_eq(value, selected),
			gT: value
		};
	});
var $author$project$UI$Button$group = function (children) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('btn-group')
			]),
		children);
};
var $author$project$UI$Button$white = $author$project$UI$Button$textSimple(
	_List_fromArray(
		['btn-white']));
var $author$project$UI$Form$pillButton = F2(
	function (msg, pill) {
		return pill.gm ? A2(
			$author$project$UI$Button$primary,
			pill.fo,
			msg(pill.gT)) : A2(
			$author$project$UI$Button$white,
			pill.fo,
			msg(pill.gT));
	});
var $author$project$UI$Form$pillsGroup = F2(
	function (msg, pills) {
		return $author$project$UI$Button$group(
			A2(
				$elm$core$List$map,
				$author$project$UI$Form$pillButton(msg),
				pills));
	});
var $author$project$OrderFilters$dealershipTypeView = function (selected) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-6')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Dealership Type'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$author$project$UI$Form$pillsGroup,
						$author$project$OrderFilters$DealershipTypeChanged,
						_List_fromArray(
							[
								A3($author$project$OrderFilters$pillButton, 'All', $elm$core$Maybe$Nothing, selected),
								A3(
								$author$project$OrderFilters$pillButton,
								'Retail',
								$elm$core$Maybe$Just(0),
								selected),
								A3(
								$author$project$OrderFilters$pillButton,
								'Leasing',
								$elm$core$Maybe$Just(1),
								selected)
							]))
					]))
			]));
};
var $author$project$OrderFilters$dealershipsView = function (model) {
	var autoView = A2($author$project$UI$Autocomplete$view, model.U, model.c.y);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-3')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Dealership'),
						A2($elm$html$Html$map, $author$project$OrderFilters$DealershipAutocomplete, autoView)
					]))
			]));
};
var $author$project$OrderFilters$makesView = function (model) {
	var autoView = A2($author$project$UI$Autocomplete$view, model._, model.c.Z);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-3')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Dealership Make'),
						A2($elm$html$Html$map, $author$project$OrderFilters$MakeAutocomplete, autoView)
					]))
			]));
};
var $author$project$OrderFilters$OfferIdInput = function (a) {
	return {$: 17, a: a};
};
var $author$project$UI$Form$textbox = function (msg) {
	return A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('text'),
				$elm$html$Html$Events$onInput(msg),
				$elm$html$Html$Attributes$class('form-control')
			]),
		_List_Nil);
};
var $author$project$OrderFilters$idFilterView = F3(
	function (label, msg, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('col-sm-3')
				]),
			_List_fromArray(
				[
					$author$project$UI$Form$group(
					_List_fromArray(
						[
							$author$project$UI$Form$controlLabel(label),
							$author$project$UI$Form$textbox(msg)
						]))
				]));
	});
var $author$project$OrderFilters$offerIdView = A2($author$project$OrderFilters$idFilterView, 'Offer ID', $author$project$OrderFilters$OfferIdInput);
var $author$project$OrderFilters$OrderIdInput = function (a) {
	return {$: 20, a: a};
};
var $author$project$OrderFilters$orderIdView = A2($author$project$OrderFilters$idFilterView, 'Order ID', $author$project$OrderFilters$OrderIdInput);
var $author$project$OrderFilters$PurchaseTypeChanged = function (a) {
	return {$: 22, a: a};
};
var $author$project$OrderFilters$purchaseTypeView = function (selectedPurchaseType) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-6')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Purchase Type'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$author$project$UI$Form$pillsGroup,
						$author$project$OrderFilters$PurchaseTypeChanged,
						_List_fromArray(
							[
								A3($author$project$OrderFilters$pillButton, 'All', $elm$core$Maybe$Nothing, selectedPurchaseType),
								A3(
								$author$project$OrderFilters$pillButton,
								'Stock',
								$elm$core$Maybe$Just(0),
								selectedPurchaseType),
								A3(
								$author$project$OrderFilters$pillButton,
								'Factory Order',
								$elm$core$Maybe$Just(1),
								selectedPurchaseType),
								A3(
								$author$project$OrderFilters$pillButton,
								'Demo',
								$elm$core$Maybe$Just(2),
								selectedPurchaseType),
								A3(
								$author$project$OrderFilters$pillButton,
								'Pre-reg',
								$elm$core$Maybe$Just(3),
								selectedPurchaseType),
								A3(
								$author$project$OrderFilters$pillButton,
								'Used',
								$elm$core$Maybe$Just(4),
								selectedPurchaseType)
							]))
					]))
			]));
};
var $author$project$UI$Form$row = function (children) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		children);
};
var $author$project$OrderFilters$SalesmatchingTypeChanged = function (a) {
	return {$: 23, a: a};
};
var $author$project$OrderFilters$salesmatchingTypeView = function (selected) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-6')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Salesmatching'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$author$project$UI$Form$pillsGroup,
						$author$project$OrderFilters$SalesmatchingTypeChanged,
						_List_fromArray(
							[
								A3($author$project$OrderFilters$pillButton, 'All', $elm$core$Maybe$Nothing, selected),
								A3(
								$author$project$OrderFilters$pillButton,
								'Exclude Salesmatching',
								$elm$core$Maybe$Just(1),
								selected),
								A3(
								$author$project$OrderFilters$pillButton,
								'Only Salesmatching',
								$elm$core$Maybe$Just(0),
								selected)
							]))
					]))
			]));
};
var $author$project$OrderFilters$ScopeChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$OrderFilters$scopesView = function (selectedScope) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-6')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('Scopes'),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
						$author$project$UI$Form$pillsGroup,
						$author$project$OrderFilters$ScopeChanged,
						_List_fromArray(
							[
								A3($author$project$OrderFilters$pillButton, 'All', $elm$core$Maybe$Nothing, selectedScope),
								A3(
								$author$project$OrderFilters$pillButton,
								'Pending',
								$elm$core$Maybe$Just(0),
								selectedScope),
								A3(
								$author$project$OrderFilters$pillButton,
								'Voided',
								$elm$core$Maybe$Just(1),
								selectedScope),
								A3(
								$author$project$OrderFilters$pillButton,
								'Confirmed',
								$elm$core$Maybe$Just(2),
								selectedScope),
								A3(
								$author$project$OrderFilters$pillButton,
								'Cancelled',
								$elm$core$Maybe$Just(3),
								selectedScope)
							]))
					]))
			]));
};
var $author$project$OrderFilters$usersView = function (model) {
	var autoView = A2($author$project$UI$Autocomplete$view, model.am, model.c.C);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('col-sm-3')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$group(
				_List_fromArray(
					[
						$author$project$UI$Form$controlLabel('User (search by name or email)'),
						A2($elm$html$Html$map, $author$project$OrderFilters$UserAutocomplete, autoView)
					]))
			]));
};
var $author$project$OrderFilters$view = function (filters) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('order-filters')
			]),
		_List_fromArray(
			[
				$author$project$UI$Form$row(
				_List_fromArray(
					[
						$author$project$OrderFilters$dealershipTypeView(filters.c.W),
						$author$project$OrderFilters$salesmatchingTypeView(filters.c.ai)
					])),
				$author$project$UI$Form$row(
				_List_fromArray(
					[
						$author$project$OrderFilters$accountManagersView(filters),
						$author$project$OrderFilters$makesView(filters),
						$author$project$OrderFilters$dealershipsView(filters),
						$author$project$OrderFilters$dealershipGroupsView(filters),
						$author$project$OrderFilters$usersView(filters),
						$author$project$OrderFilters$offerIdView(filters),
						$author$project$OrderFilters$orderIdView(filters)
					])),
				$author$project$UI$Form$row(
				_List_fromArray(
					[
						A2($author$project$OrderFilters$datePickerView, 1, filters),
						A2($author$project$OrderFilters$datePickerView, 0, filters),
						A2($author$project$OrderFilters$datePickerView, 2, filters),
						A2($author$project$OrderFilters$datePickerView, 3, filters)
					])),
				$author$project$UI$Form$row(
				_List_fromArray(
					[
						$author$project$OrderFilters$scopesView(filters.c.aD),
						$author$project$OrderFilters$purchaseTypeView(filters.c.f9)
					])),
				$author$project$UI$Form$row(
				_List_fromArray(
					[$author$project$OrderFilters$buttonsView]))
			]));
};
var $author$project$OrdersTable$container = function (inner) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('orders-table')
			]),
		_List_fromArray(
			[inner]));
};
var $author$project$UI$Basics$empty = A2($elm$html$Html$div, _List_Nil, _List_Nil);
var $author$project$UI$Icons$frown = $author$project$UI$Icons$generic('frown-o');
var $author$project$UI$Basics$error = F2(
	function (message, _v0) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('elm-error')
				]),
			_List_fromArray(
				[
					$author$project$UI$Icons$frown,
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(message)
						])),
					$author$project$UI$Icons$frown
				]));
	});
var $author$project$UI$Basics$loading = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('sk-spinner sk-spinner-rotating-plane')
		]),
	_List_Nil);
var $author$project$OrdersTable$headers = _List_fromArray(
	['', 'Offer Id', 'Dealership', 'Partnership consultant', 'Purchase type', 'Distance', 'Calls', 'User messages', 'Offer views', 'Hours pending', 'Last transition', 'History', 'Review', 'Voucher']);
var $author$project$OrdersTable$SaveNewNote = function (a) {
	return {$: 4, a: a};
};
var $author$project$OrdersTable$StartNewNote = function (a) {
	return {$: 2, a: a};
};
var $author$project$OrdersTable$UpdateNewNote = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$html$Html$Attributes$colspan = function (n) {
	return A2(
		_VirtualDom_attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$UI$DateFormat$monthName = function (num) {
	switch (num) {
		case 1:
			return 'Jan';
		case 2:
			return 'Feb';
		case 3:
			return 'Mar';
		case 4:
			return 'Apr';
		case 5:
			return 'May';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Aug';
		case 9:
			return 'Sep';
		case 10:
			return 'Oct';
		case 11:
			return 'Nov';
		default:
			return 'Dec';
	}
};
var $author$project$UI$DateFormat$padded = function (amount) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		A2($elm$core$String$padLeft, amount, '0'));
};
var $author$project$UI$DateFormat$date = function (input) {
	return A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			function (fn) {
				return fn(input);
			},
			_List_fromArray(
				[
					A2($elm$core$Basics$composeR, $isaacseymour$deprecated_time$Time$Date$month, $author$project$UI$DateFormat$monthName),
					$elm$core$Basics$always(' '),
					A2(
					$elm$core$Basics$composeR,
					$isaacseymour$deprecated_time$Time$Date$day,
					$author$project$UI$DateFormat$padded(2)),
					$elm$core$Basics$always(', '),
					A2($elm$core$Basics$composeR, $isaacseymour$deprecated_time$Time$Date$year, $elm$core$String$fromInt)
				])));
};
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $author$project$OrdersTable$detailsSection = F2(
	function (title, children) {
		var titleNode = A2(
			$elm$html$Html$h4,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(title + ':')
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-table__order-details-section')
				]),
			A2($elm$core$List$cons, titleNode, children));
	});
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $author$project$UI$Form$focussedTextbox = function (msg) {
	return A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('text'),
				$elm$html$Html$Events$onInput(msg),
				$elm$html$Html$Attributes$class('form-control'),
				$elm$html$Html$Attributes$autofocus(true)
			]),
		_List_Nil);
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $isaacseymour$deprecated_time$Time$ZonedDateTime$ZonedDateTime = $elm$core$Basics$identity;
var $isaacseymour$deprecated_time$Time$TimeZone$find = F2(
	function (time, spans) {
		var ms = $elm$time$Time$posixToMillis(time);
		var go = F2(
			function (prev, xs) {
				go:
				while (true) {
					if (!xs.$) {
						var span = xs.a;
						return span;
					} else {
						var span = xs.a;
						var other = xs.b;
						if ((_Utils_cmp(prev, ms) < 1) && (_Utils_cmp(ms, span.bH) < 0)) {
							return span;
						} else {
							var $temp$prev = span.bH,
								$temp$xs = other;
							prev = $temp$prev;
							xs = $temp$xs;
							continue go;
						}
					}
				}
			});
		return A2(go, (-1) / 0, spans);
	});
var $isaacseymour$deprecated_time$Time$TimeZone$offset = F2(
	function (time, _v0) {
		var spans = _v0.aj;
		return A2($isaacseymour$deprecated_time$Time$TimeZone$find, time, spans).b;
	});
var $isaacseymour$deprecated_time$Time$DateTime$delta = F2(
	function (_v0, _v1) {
		var t1 = _v0;
		var t2 = _v1;
		var _v2 = A2($isaacseymour$deprecated_time$Time$Date$delta, t1.d, t2.d);
		var years = _v2.ea;
		var months = _v2.dn;
		var days = _v2.eA;
		var milliseconds = (days * $isaacseymour$deprecated_time$Time$Internal$dayMs) + (t1.b - t2.b);
		var hours = (milliseconds / $isaacseymour$deprecated_time$Time$Internal$hourMs) | 0;
		var minutes = (milliseconds / $isaacseymour$deprecated_time$Time$Internal$minuteMs) | 0;
		var seconds = (milliseconds / $isaacseymour$deprecated_time$Time$Internal$secondMs) | 0;
		return {eA: days, e5: hours, ce: milliseconds, dl: minutes, dn: months, dP: seconds, ea: years};
	});
var $isaacseymour$deprecated_time$Time$DateTime$toPosix = function (time) {
	return $elm$time$Time$millisToPosix(
		A2($isaacseymour$deprecated_time$Time$DateTime$delta, time, $isaacseymour$deprecated_time$Time$DateTime$epoch).ce);
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$fromDateTime = F2(
	function (tz, dateTime) {
		var posix = $isaacseymour$deprecated_time$Time$DateTime$toPosix(dateTime);
		var offset = A2($isaacseymour$deprecated_time$Time$TimeZone$offset, posix, tz);
		return {
			m: A2($isaacseymour$deprecated_time$Time$DateTime$addMilliseconds, -offset, dateTime),
			ak: tz
		};
	});
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$OrdersTable$orderDetailItem = F2(
	function (label, child) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-table__order-detail-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('orders-table__order-detail-item-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label + ':')
						])),
					child
				]));
	});
var $author$project$OrdersTable$orderDetailItemText = F2(
	function (label, value) {
		return A2(
			$author$project$OrdersTable$orderDetailItem,
			label,
			$elm$html$Html$text(value));
	});
var $author$project$OrdersTable$orderDetailPlaceholder = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('orders-table__order-detail-item')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-table__order-detail-item-label')
				]),
			_List_Nil)
		]));
var $isaacseymour$deprecated_time$Time$DateTime$day = function (_v0) {
	var data = _v0;
	return $isaacseymour$deprecated_time$Time$Date$day(data.d);
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$day = function (_v0) {
	var dateTime = _v0.m;
	return $isaacseymour$deprecated_time$Time$DateTime$day(dateTime);
};
var $isaacseymour$deprecated_time$Time$DateTime$hour = function (_v0) {
	var offset = _v0.b;
	return (offset / $isaacseymour$deprecated_time$Time$Internal$hourMs) | 0;
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$hour = function (_v0) {
	var dateTime = _v0.m;
	return $isaacseymour$deprecated_time$Time$DateTime$hour(dateTime);
};
var $isaacseymour$deprecated_time$Time$DateTime$minute = function (_v0) {
	var offset = _v0.b;
	return (A2($elm$core$Basics$modBy, $isaacseymour$deprecated_time$Time$Internal$hourMs, offset) / $isaacseymour$deprecated_time$Time$Internal$minuteMs) | 0;
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$minute = function (_v0) {
	var dateTime = _v0.m;
	return $isaacseymour$deprecated_time$Time$DateTime$minute(dateTime);
};
var $isaacseymour$deprecated_time$Time$DateTime$month = function (_v0) {
	var data = _v0;
	return $isaacseymour$deprecated_time$Time$Date$month(data.d);
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$month = function (_v0) {
	var dateTime = _v0.m;
	return $isaacseymour$deprecated_time$Time$DateTime$month(dateTime);
};
var $isaacseymour$deprecated_time$Time$DateTime$year = function (_v0) {
	var data = _v0;
	return $isaacseymour$deprecated_time$Time$Date$year(data.d);
};
var $isaacseymour$deprecated_time$Time$ZonedDateTime$year = function (_v0) {
	var dateTime = _v0.m;
	return $isaacseymour$deprecated_time$Time$DateTime$year(dateTime);
};
var $author$project$UI$DateFormat$zonedLong = function (input) {
	return A2(
		$elm$core$String$join,
		'',
		A2(
			$elm$core$List$map,
			function (fn) {
				return fn(input);
			},
			_List_fromArray(
				[
					A2($elm$core$Basics$composeR, $isaacseymour$deprecated_time$Time$ZonedDateTime$month, $author$project$UI$DateFormat$monthName),
					$elm$core$Basics$always(' '),
					A2(
					$elm$core$Basics$composeR,
					$isaacseymour$deprecated_time$Time$ZonedDateTime$day,
					$author$project$UI$DateFormat$padded(2)),
					$elm$core$Basics$always(', '),
					A2($elm$core$Basics$composeR, $isaacseymour$deprecated_time$Time$ZonedDateTime$year, $elm$core$String$fromInt),
					$elm$core$Basics$always(' '),
					A2(
					$elm$core$Basics$composeR,
					$isaacseymour$deprecated_time$Time$ZonedDateTime$hour,
					$author$project$UI$DateFormat$padded(2)),
					$elm$core$Basics$always(':'),
					A2(
					$elm$core$Basics$composeR,
					$isaacseymour$deprecated_time$Time$ZonedDateTime$minute,
					$author$project$UI$DateFormat$padded(2))
				])));
};
var $author$project$OrdersTable$orderNoteRow = F2(
	function (timezone, orderNote) {
		var zonedCreatedAt = A2($isaacseymour$deprecated_time$Time$ZonedDateTime$fromDateTime, timezone, orderNote.D);
		var attributes = _List_fromArray(
			[
				$elm$html$Html$text(orderNote.ew),
				$elm$html$Html$text(orderNote.ej),
				$elm$html$Html$text(
				$author$project$UI$DateFormat$zonedLong(zonedCreatedAt))
			]);
		return A2(
			$elm$core$List$map,
			$elm$html$Html$td(_List_Nil),
			A2($elm$core$List$map, $elm$core$List$singleton, attributes));
	});
var $elm$html$Html$th = _VirtualDom_node('th');
var $author$project$OrdersTable$orderNotes = F2(
	function (timezone, order) {
		var header = A2(
			$elm$html$Html$thead,
			_List_Nil,
			$elm$core$List$singleton(
				A2(
					$elm$html$Html$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$elm$html$Html$th(_List_Nil),
						A2(
							$elm$core$List$map,
							$elm$core$List$singleton,
							A2(
								$elm$core$List$map,
								$elm$html$Html$text,
								_List_fromArray(
									['Content', 'Updated By', 'Timestamp'])))))));
		var body = A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$elm$html$Html$tr(_List_Nil),
				A2(
					$elm$core$List$map,
					$author$project$OrdersTable$orderNoteRow(timezone),
					order.dq)));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-table__notes')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table')
						]),
					_List_fromArray(
						[header, body]))
				]));
	});
var $author$project$OrdersTable$reviewsPath = function (order) {
	return A2(
		$elm$core$String$join,
		'/',
		_List_fromArray(
			[
				'dealerships',
				$elm$core$String$fromInt(order.eE),
				'reviews'
			]));
};
var $author$project$UI$Basics$linkToResource = F3(
	function (resource, id, child) {
		var url = '/' + A2(
			$elm$core$String$join,
			'/',
			_List_fromArray(
				[
					resource,
					$elm$core$String$fromInt(id)
				]));
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(url)
				]),
			_List_fromArray(
				[child]));
	});
var $author$project$UI$Basics$textLinkToResource = F3(
	function (resource, id, display) {
		return A3(
			$author$project$UI$Basics$linkToResource,
			resource,
			id,
			$elm$html$Html$text(display));
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$OrdersTable$transitionTds = F3(
	function (timezone, purchaseDate, transition) {
		var transitionDateText = $author$project$UI$DateFormat$zonedLong(
			A2($isaacseymour$deprecated_time$Time$ZonedDateTime$fromDateTime, timezone, transition.D));
		var purchaseOrTransitionDateElement = function () {
			var _v0 = A2($elm$core$Maybe$map, $author$project$UI$DateFormat$date, purchaseDate);
			if (!_v0.$) {
				var purchaseDateText = _v0.a;
				return A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$title('Received on: ' + transitionDateText),
							A2($elm$html$Html$Attributes$attribute, 'data-toggle', 'tooltip'),
							A2($elm$html$Html$Attributes$attribute, 'data-placement', 'bottom')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(purchaseDateText)
						]));
			} else {
				return $elm$html$Html$text(transitionDateText);
			}
		}();
		var dateElement = ($author$project$Data$Order$stateToString(transition.cF) === 'confirmed') ? purchaseOrTransitionDateElement : $elm$html$Html$text(transitionDateText);
		return A2(
			$elm$core$List$map,
			$elm$html$Html$td(_List_Nil),
			A2(
				$elm$core$List$map,
				$elm$core$List$singleton,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Data$Order$stateToString(transition.cF)),
						$elm$html$Html$text(transition.f1),
						$elm$html$Html$text(transition.gb),
						$elm$html$Html$text(transition.gc),
						$elm$html$Html$text(transition.dq),
						dateElement
					])));
	});
var $author$project$OrdersTable$transitionHistory = F2(
	function (timezone, order) {
		var header = A2(
			$elm$html$Html$thead,
			_List_Nil,
			$elm$core$List$singleton(
				A2(
					$elm$html$Html$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$elm$html$Html$th(_List_Nil),
						A2(
							$elm$core$List$map,
							$elm$core$List$singleton,
							A2(
								$elm$core$List$map,
								$elm$html$Html$text,
								_List_fromArray(
									['State', 'Updated By', 'Category', 'Reason', 'Notes', 'Timestamp'])))))));
		var body = A2(
			$elm$html$Html$tbody,
			_List_Nil,
			A2(
				$elm$core$List$map,
				$elm$html$Html$tr(_List_Nil),
				A2(
					$elm$core$List$map,
					A2($author$project$OrdersTable$transitionTds, timezone, order.f8),
					order.b$)));
		return A2(
			$author$project$OrdersTable$detailsSection,
			'History',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table')
						]),
					_List_fromArray(
						[header, body]))
				]));
	});
var $author$project$OrdersTable$orderDetail = F2(
	function (timezone, row) {
		var order = row.o;
		var yesNo = function (x) {
			return x ? 'Yes' : 'No';
		};
		var userReportedOn = A2(
			$elm$core$Maybe$withDefault,
			'n/a',
			A2(
				$elm$core$Maybe$map,
				$author$project$UI$DateFormat$zonedLong,
				A2(
					$elm$core$Maybe$map,
					$isaacseymour$deprecated_time$Time$ZonedDateTime$fromDateTime(timezone),
					order.gQ)));
		var reviewLinkToResource = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(
						'/' + ($author$project$OrdersTable$reviewsPath(order) + ('/new?order_id=' + $elm$core$String$fromInt(order.e6))))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Create review')
					])),
			A2(
				$elm$core$Maybe$map,
				function (id) {
					return A3(
						$author$project$UI$Basics$textLinkToResource,
						$author$project$OrdersTable$reviewsPath(order),
						id,
						'View review');
				},
				order.gi));
		var newNoteContent = function () {
			var _v1 = row.aY;
			switch (_v1) {
				case 0:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('orders-table__new-note')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$UI$Button$white,
								'Add note',
								$author$project$OrdersTable$StartNewNote(row))
							]));
				case 1:
					return A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('orders-table__new-note'),
								$elm$html$Html$Events$onSubmit(
								$author$project$OrdersTable$SaveNewNote(row))
							]),
						_List_fromArray(
							[
								$author$project$UI$Form$focussedTextbox(
								$author$project$OrdersTable$UpdateNewNote(row)),
								A2(
								$author$project$UI$Button$white,
								'Save note',
								$author$project$OrdersTable$SaveNewNote(row))
							]));
				default:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('orders-table__new-note')
							]),
						_List_fromArray(
							[$author$project$UI$Basics$loading]));
			}
		}();
		var notes = A2(
			$author$project$OrdersTable$detailsSection,
			'Notes',
			_List_fromArray(
				[
					A2($author$project$OrdersTable$orderNotes, timezone, order),
					newNoteContent
				]));
		var maybeLinkToResource = function () {
			var _v0 = order.ey;
			switch (_v0) {
				case 0:
					return $elm$core$Maybe$Just(
						A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary'),
									$elm$html$Html$Attributes$href(
									'/orders/' + ($elm$core$String$fromInt(order.e6) + '/void'))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Void')
								])));
				case 2:
					return $elm$core$Maybe$Just(
						A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn btn-primary'),
									$elm$html$Html$Attributes$href(
									'/orders/' + ($elm$core$String$fromInt(order.e6) + '/cancel'))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Cancel')
								])));
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		var estimatedDeliveryDate = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Maybe$map, $author$project$UI$DateFormat$date, order.eM));
		var editLink = A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn btn-primary'),
					$elm$html$Html$Attributes$href(
					'/orders/' + ($elm$core$String$fromInt(order.e6) + '/edit'))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Edit')
				]));
		var textLinkToResourceSection = A2(
			$author$project$OrdersTable$detailsSection,
			'Actions',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$core$Maybe$Just(editLink),
						maybeLinkToResource
					])));
		var details = A2(
			$author$project$OrdersTable$detailsSection,
			'Details',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('orders-table__order-properties')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$OrdersTable$orderDetailItem,
							'Offer',
							A3(
								$author$project$UI$Basics$textLinkToResource,
								'offers',
								order.fT,
								$elm$core$String$fromInt(order.fT))),
							A2(
							$author$project$OrdersTable$orderDetailItem,
							'User',
							A3(
								$author$project$UI$Basics$textLinkToResource,
								'users',
								order.gM,
								$elm$core$String$fromInt(order.gM))),
							A2(
							$author$project$OrdersTable$orderDetailItem,
							'Dealership',
							A3($author$project$UI$Basics$textLinkToResource, 'dealerships', order.eE, order.eF)),
							A2($author$project$OrdersTable$orderDetailItemText, 'Estimated delivery date', estimatedDeliveryDate),
							A2($author$project$OrdersTable$orderDetailItem, 'Review', reviewLinkToResource),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Voucher sent',
							yesNo(order.gV === 2)),
							A2($author$project$OrdersTable$orderDetailItemText, 'User reported on', userReportedOn),
							A2($author$project$OrdersTable$orderDetailItemText, 'Model', order.fx),
							A2($author$project$OrdersTable$orderDetailItemText, 'Payment', order.f0),
							A2($author$project$OrdersTable$orderDetailItemText, 'User reported owner', order.gR),
							A2($author$project$OrdersTable$orderDetailItemText, 'Dealer reported owner', order.eB),
							A2($author$project$OrdersTable$orderDetailItemText, 'Dealer reported salesperson', order.eC),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'User printed',
							yesNo(order.gP)),
							A2($author$project$OrdersTable$orderDetailItemText, 'Salesperson', order.gj),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Purchase type',
							A2(
								$elm$core$Maybe$withDefault,
								'',
								A2($elm$core$Maybe$map, $author$project$Data$Order$purchaseTypeToString, order.f9))),
							A2($author$project$OrdersTable$orderDetailItemText, 'Invoice reference', order.fj),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Distance',
							$elm$core$String$fromInt(order.eH)),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Offer viewed by dealer',
							$elm$core$String$fromInt(order.eD)),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'User messages',
							$elm$core$String$fromInt(order.gN + order.el)),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Calls',
							$elm$core$String$fromInt(order.gK)),
							A2($author$project$OrdersTable$orderDetailItemText, 'User postcode', order.gO),
							A2(
							$author$project$OrdersTable$orderDetailItemText,
							'Configured models',
							A2($elm$core$String$join, ',', order.eu)),
							A2($author$project$OrdersTable$orderDetailItemText, 'User reported Phone number', order.gS),
							$author$project$OrdersTable$orderDetailPlaceholder,
							$author$project$OrdersTable$orderDetailPlaceholder
						]))
				]));
		return A2(
			$elm$html$Html$tr,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('orders-table__order-details')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$colspan(
							$elm$core$List$length($author$project$OrdersTable$headers))
						]),
					_List_fromArray(
						[
							details,
							notes,
							A2($author$project$OrdersTable$transitionHistory, timezone, order),
							textLinkToResourceSection
						]))
				]));
	});
var $author$project$OrdersTable$ToggleDetail = function (a) {
	return {$: 1, a: a};
};
var $author$project$UI$Icons$downArrow = $author$project$UI$Icons$generic('angle-down');
var $author$project$UI$Icons$thumbsUp = $author$project$UI$Icons$generic('thumbs-o-up');
var $author$project$OrdersTable$hasReview = function (order) {
	var reviewLinkToResource = function (id) {
		return _List_fromArray(
			[
				A3(
				$author$project$UI$Basics$linkToResource,
				$author$project$OrdersTable$reviewsPath(order),
				id,
				$author$project$UI$Icons$thumbsUp)
			]);
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Maybe$map, reviewLinkToResource, order.gi)));
};
var $author$project$OrdersTable$historySummaryState = F4(
	function (state, tooltip, _class, active) {
		var classes = $elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('order-history-summary__state', true),
					_Utils_Tuple2('order-history-summary__state--' + _class, active)
				]));
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					classes,
					A2($elm$html$Html$Attributes$attribute, 'data-toggle', 'tooltip'),
					A2($elm$html$Html$Attributes$attribute, 'data-placement', 'top'),
					A2($elm$html$Html$Attributes$attribute, 'data-original-title', tooltip)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(state)
				]));
	});
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$OrdersTable$historySummary = function (order) {
	var isState = F2(
		function (state, transition) {
			return _Utils_eq(state, transition.cF);
		});
	var hasState = function (s) {
		return A2(
			$elm$core$List$any,
			isState(s),
			order.b$);
	};
	return A2(
		$elm$html$Html$ol,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('order-history-summary')
			]),
		_List_fromArray(
			[
				A4(
				$author$project$OrdersTable$historySummaryState,
				'P',
				'Pending',
				'pending',
				hasState(0)),
				A4(
				$author$project$OrdersTable$historySummaryState,
				'V',
				'Voided',
				'voided',
				hasState(1)),
				A4(
				$author$project$OrdersTable$historySummaryState,
				'C',
				'Confirmed',
				'confirmed',
				hasState(2)),
				A4(
				$author$project$OrdersTable$historySummaryState,
				'X',
				'Cancelled',
				'cancelled',
				hasState(3))
			]));
};
var $author$project$Data$Order$compareOrderTransition = F2(
	function (a, b) {
		return A2(
			$elm$core$Basics$compare,
			$elm$time$Time$posixToMillis(
				$isaacseymour$deprecated_time$Time$DateTime$toPosix(a.D)),
			$elm$time$Time$posixToMillis(
				$isaacseymour$deprecated_time$Time$DateTime$toPosix(b.D)));
	});
var $author$project$Data$Order$hoursBetween = function (a) {
	return A2(
		$elm$core$Basics$composeR,
		$isaacseymour$deprecated_time$Time$DateTime$delta(a),
		function ($) {
			return $.e5;
		});
};
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Data$Order$stoppedPendingTransition = function (transistions) {
	stoppedPendingTransition:
	while (true) {
		if (!transistions.b) {
			return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
		} else {
			if (!transistions.b.b) {
				var h = transistions.a;
				var _v1 = h.cF;
				if (!_v1) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(h),
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
				}
			} else {
				var h = transistions.a;
				var _v2 = transistions.b;
				var i = _v2.a;
				var l = _v2.b;
				var _v3 = h.cF;
				if (!_v3) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(h),
						$elm$core$Maybe$Just(i));
				} else {
					var $temp$transistions = A2($elm$core$List$cons, i, l);
					transistions = $temp$transistions;
					continue stoppedPendingTransition;
				}
			}
		}
	}
};
var $author$project$Data$Order$hoursPending = F2(
	function (now, order) {
		var transitions = A2($elm$core$List$sortWith, $author$project$Data$Order$compareOrderTransition, order.b$);
		var _v0 = $author$project$Data$Order$stoppedPendingTransition(transitions);
		if (!_v0.a.$) {
			if (_v0.b.$ === 1) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				return $elm$core$Maybe$Just(
					A2($author$project$Data$Order$hoursBetween, x.D, now));
			} else {
				var x = _v0.a.a;
				var y = _v0.b.a;
				return $elm$core$Maybe$Just(
					A2($author$project$Data$Order$hoursBetween, y.D, x.D));
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$OrdersTable$hoursPending = F2(
	function (maybeNow, order) {
		if (maybeNow.$ === 1) {
			return $elm$html$Html$text('');
		} else {
			var now = maybeNow.a;
			var state = function () {
				var _v1 = order.ey;
				switch (_v1) {
					case 0:
						return 'pending';
					case 1:
						return 'voided';
					case 2:
						return 'confirmed';
					default:
						return 'cancelled';
				}
			}();
			var hours = $elm$html$Html$text(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						A2($author$project$Data$Order$hoursPending, now, order))));
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hours-pending__state--' + state)
					]),
				_List_fromArray(
					[hours]));
		}
	});
var $author$project$Data$Order$lastTransitionAt = function (order) {
	var _default = order.D;
	return A2(
		$elm$core$Maybe$withDefault,
		_default,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.D;
			},
			$elm$core$List$head(
				$elm$core$List$reverse(order.b$))));
};
var $author$project$UI$Icons$clock = $author$project$UI$Icons$generic('clock-o');
var $author$project$OrdersTable$voucherStatus = function (order) {
	var _v0 = function () {
		var _v1 = order.gV;
		switch (_v1) {
			case 0:
				return _Utils_Tuple2(_List_Nil, '');
			case 2:
				return _Utils_Tuple2(
					_List_fromArray(
						[$author$project$UI$Icons$thumbsUp]),
					'Voucher sent');
			default:
				return _Utils_Tuple2(
					_List_fromArray(
						[$author$project$UI$Icons$clock]),
					'In voucher queue');
		}
	}();
	var child = _v0.a;
	var tooltip = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('orders-table__voucher-status'),
				A2($elm$html$Html$Attributes$attribute, 'data-toggle', 'tooltip'),
				A2($elm$html$Html$Attributes$attribute, 'data-placement', 'top'),
				A2($elm$html$Html$Attributes$attribute, 'data-original-title', tooltip)
			]),
		child);
};
var $author$project$OrdersTable$orderTds = F3(
	function (timezone, maybeNow, order) {
		return A2(
			$elm$core$List$map,
			$elm$html$Html$td(_List_Nil),
			A2(
				$elm$core$List$map,
				$elm$core$List$singleton,
				_List_fromArray(
					[
						A3(
						$author$project$UI$Basics$textLinkToResource,
						'offers',
						order.fT,
						$elm$core$String$fromInt(order.fT)),
						A3($author$project$UI$Basics$textLinkToResource, 'dealerships', order.eE, order.eF),
						A3(
						$author$project$UI$Basics$textLinkToResource,
						'account-managers',
						order.ed.e6,
						$author$project$Data$AccountManager$fullName(order.ed)),
						$elm$html$Html$text(
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, $author$project$Data$Order$purchaseTypeToString, order.f9))),
						$elm$html$Html$text(
						$elm$core$String$fromInt(order.eH)),
						$elm$html$Html$text(
						$elm$core$String$fromInt(order.gK)),
						$elm$html$Html$text(
						$elm$core$String$fromInt(order.gN + order.el)),
						$elm$html$Html$text(
						$elm$core$String$fromInt(order.eD)),
						A2($author$project$OrdersTable$hoursPending, maybeNow, order),
						$elm$html$Html$text(
						$author$project$UI$DateFormat$zonedLong(
							A2(
								$isaacseymour$deprecated_time$Time$ZonedDateTime$fromDateTime,
								timezone,
								$author$project$Data$Order$lastTransitionAt(order)))),
						$author$project$OrdersTable$historySummary(order),
						$author$project$OrdersTable$hasReview(order),
						$author$project$OrdersTable$voucherStatus(order)
					])));
	});
var $author$project$UI$Icons$rightArrow = $author$project$UI$Icons$generic('angle-right');
var $author$project$OrdersTable$orderSummary = F3(
	function (timezone, maybeNow, row) {
		var icon = function () {
			var _v0 = row.aC;
			if (!_v0) {
				return $author$project$UI$Icons$downArrow;
			} else {
				return $author$project$UI$Icons$rightArrow;
			}
		}();
		var toggleNode = A2(
			$elm$html$Html$td,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('orders-table__open-toggle'),
							$elm$html$Html$Events$onClick(
							$author$project$OrdersTable$ToggleDetail(row))
						]),
					_List_fromArray(
						[icon]))
				]));
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				toggleNode,
				A3($author$project$OrdersTable$orderTds, timezone, maybeNow, row.o)));
	});
var $author$project$OrdersTable$orderRow = F3(
	function (timezone, maybeNow, row) {
		var _v0 = row.aC;
		if (!_v0) {
			return _List_fromArray(
				[
					A3($author$project$OrdersTable$orderSummary, timezone, maybeNow, row),
					A2($author$project$OrdersTable$orderDetail, timezone, row)
				]);
		} else {
			return _List_fromArray(
				[
					A3($author$project$OrdersTable$orderSummary, timezone, maybeNow, row)
				]);
		}
	});
var $author$project$UI$Table$table = function (children) {
	var classes = $elm$html$Html$Attributes$classList(
		_List_fromArray(
			[
				_Utils_Tuple2('table', true),
				_Utils_Tuple2('table-striped', true)
			]));
	return A2(
		$elm$html$Html$table,
		_List_fromArray(
			[classes]),
		children);
};
var $author$project$OrdersTable$tableView = F3(
	function (timezone, maybeNow, orders) {
		var header = A2(
			$elm$html$Html$thead,
			_List_Nil,
			$elm$core$List$singleton(
				A2(
					$elm$html$Html$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$elm$html$Html$th(_List_Nil),
						A2(
							$elm$core$List$map,
							$elm$core$List$singleton,
							A2($elm$core$List$map, $elm$html$Html$text, $author$project$OrdersTable$headers))))));
		var body = A2(
			$elm$html$Html$tbody,
			_List_Nil,
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					A2($author$project$OrdersTable$orderRow, timezone, maybeNow),
					orders)));
		return $author$project$UI$Table$table(
			_List_fromArray(
				[header, body]));
	});
var $author$project$UI$RemoteDataView$simple = F2(
	function (config, remoteData) {
		switch (remoteData.$) {
			case 0:
				return config.fN;
			case 1:
				return config.fr;
			case 3:
				var data = remoteData.a;
				return config.gv(data);
			default:
				var error = remoteData.a;
				return config.eT(error);
		}
	});
var $author$project$UI$RemoteDataView$withContainer = F2(
	function (config, remoteData) {
		var inner = $author$project$UI$RemoteDataView$simple(
			{eT: config.eT, fr: config.fr, fN: config.fN, gv: config.gv});
		return config.ev(
			inner(remoteData));
	});
var $author$project$OrdersTable$view = function (model) {
	return A2(
		$author$project$UI$RemoteDataView$withContainer,
		{
			ev: $author$project$OrdersTable$container,
			eT: $author$project$UI$Basics$error('There was an error loading orders.'),
			fr: $author$project$UI$Basics$loading,
			fN: $author$project$UI$Basics$empty,
			gv: A2($author$project$OrdersTable$tableView, model.bF, model.bt)
		},
		model.f_);
};
var $author$project$UI$Basics$wrapper = function (contents) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('wrapper wrapper-content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('col-lg-12')
							]),
						contents)
					]))
			]));
};
var $author$project$Orders$view = function (model) {
	var totalCount = A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		'',
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			A2($elm$core$Basics$composeR, $author$project$PaginatedList$totalCount, $elm$core$String$fromInt),
			model.az));
	var pagination = A2(
		$elm$core$Maybe$map,
		$author$project$UI$Pagination$pager($author$project$Orders$UpdatePage),
		$krisajenkins$remotedata$RemoteData$toMaybe(model.az));
	var ordersTable = A2(
		$elm$html$Html$map,
		$author$project$Orders$OrdersTableMsg,
		$author$project$OrdersTable$view(model.x));
	var orderFilters = model.Y ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		A2(
			$elm$html$Html$map,
			$author$project$Orders$OrderFiltersMsg,
			$author$project$OrderFilters$view(model.ax)));
	var parts = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				orderFilters,
				$elm$core$Maybe$Just(ordersTable),
				pagination
			]));
	var headerActions = _List_fromArray(
		[
			_Utils_Tuple2('Report as user', '/orders/new')
		]);
	return model.Y ? A2($author$project$UI$Basics$boxWithTitle, 'Orders (' + (totalCount + ')'), parts) : A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$UI$Basics$heading,
				'Orders',
				_List_fromArray(
					[
						$author$project$UI$Basics$badge(totalCount)
					]),
				headerActions),
				$author$project$UI$Basics$wrapper(
				$elm$core$List$singleton(
					A2($author$project$UI$Basics$boxWithTitle, 'Orders', parts)))
			]));
};
var $author$project$Orders$main = $elm$browser$Browser$element(
	{
		e9: $author$project$Orders$init,
		gu: $elm$core$Basics$always($elm$core$Platform$Sub$none),
		gJ: $author$project$Orders$update,
		gU: $author$project$Orders$view
	});
_Platform_export({'Orders':{'init':$author$project$Orders$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (userId) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (timezone) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (researchSiteUrl) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (quotesSiteUrl) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (jwt) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (initialQuery) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (hideFilters) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (dealershipId) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (dealershipGroupId) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (dealersSiteUrl) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (carDataUrl) {
																							return $elm$json$Json$Decode$succeed(
																								{em: carDataUrl, L: dealersSiteUrl, cX: dealershipGroupId, eE: dealershipId, Y: hideFilters, fa: initialQuery, H: jwt, ga: quotesSiteUrl, dJ: researchSiteUrl, bF: timezone, gM: userId});
																						},
																						A2($elm$json$Json$Decode$field, 'carDataUrl', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'dealersSiteUrl', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'dealershipGroupId', $elm$json$Json$Decode$int));
																},
																A2($elm$json$Json$Decode$field, 'dealershipId', $elm$json$Json$Decode$int));
														},
														A2($elm$json$Json$Decode$field, 'hideFilters', $elm$json$Json$Decode$bool));
												},
												A2($elm$json$Json$Decode$field, 'initialQuery', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'jwt', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'quotesSiteUrl', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'researchSiteUrl', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'timezone', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'userId', $elm$json$Json$Decode$int)))(0)}});}(this));