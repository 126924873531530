import { Elm as SecondaryDealers } from 'SecondaryDealers.elm'
import { Elm as TeniosPurchaseForm } from 'TeniosPurchaseForm.elm'
import elmElement from '@carwow/carwow_theme/app/javascript/elm_element'
import toastrPorts from 'shared/elm_toastr'

elmElement('carwow-secondary-dealers', SecondaryDealers.SecondaryDealers, {
  mapFlags (flags) {
    return {
      jwt: window.CarwowJWT,
      dealersSiteUrl: Carwow.APIUrls.dealersSite,
      dealershipId: parseInt(flags.dealershipId, 10),
      leasingEnabled: flags.leasingEnabled === 'true',
      paymentsEnabled: flags.paymentsEnabled === 'true'
    }
  },
  setupPorts (ports) {
    toastrPorts(ports)
  }
})

elmElement('carwow-tenios-purchase-form', TeniosPurchaseForm.TeniosPurchaseForm)

/*
  Register volume errors for carwow-tenios-purchase-form & carwow-secondary-dealers.
  Occasional timeouts on load can be handled in Honeycomb instead.
*/
if (window.Carwow && window.Carwow.registerBugsnagVolumeError) {
  window.Carwow.registerBugsnagVolumeError((error) => {
    const secondaryDealersComponent = error.errorClass.includes('carwow-secondary-dealers')
    const messages = [
      'Http.NetworkError @',
      'Http.Timeout @'
    ]
    const validError = messages.some(message => error.errorMessage.includes(message))

    return secondaryDealersComponent && validError
  })
}
