$('.table-sortable').dataTable({
  paging: false,
  scrollY: 600,
  scrollX: true
})

$('a.navbar-minimalize').on('click', function (e) {
  const table = $('.table-sortable').DataTable()
  setTimeout(table.fixedHeader.adjust, 500)
  e.preventDefault()
})
