window.Carwow = window.Carwow || {}

window.Carwow.initAnalytics = function (dataLayer) {
  function set (data) {
    dataLayer.push(data)
  }

  function customEvent (name, info) {
    event('trackable event', { eventName: name, eventParams: info })
  }

  function event (name, info) {
    info.event = name
    set(info)
  }

  function track (path) {
    set({ page_virtualName: path, event: 'gtm.view' })
  }

  function trackPage (pageName, pageType) {
    set({ page_pageName: pageName, page_pageType: pageType })
  }

  return {
    event,
    customEvent,
    track,
    trackPage,
    set
  }
}
