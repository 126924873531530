export default function toastrPorts (ports) {
  if (ports.error) {
    ports.error.subscribe(error => {
      if (error.detail) {
        console.error(error.detail)
      }
      toastr.error(error.summary)
    })
  }

  if (ports.success) {
    ports.success.subscribe(message => toastr.success(message))
  }
}
