export default function setupPorts (ports) {
  ports.selectize.subscribe(({ inputClass, options }) => {
    requestAnimationFrame(() => {
      const $input = $(`input${inputClass}`)
      $input.selectize({
        options: options.map(slug => ({ slug })),
        maxItems: null,
        valueField: 'slug',
        labelField: 'slug',
        searchField: 'slug',
        persist: false,
        delimiter: ',',
        create: false
      })

      $input.on('change', function () {
        const field = $(this).data('field')
        const value = $(this).val()
        ports.input.send(JSON.stringify({ field, value }))
      })
    })
  })
}
