import { Elm as ActivityTimeline } from 'ActivityTimeline.elm'
import { Elm as CarConfigurationsForUser } from 'CarConfigurationsForUser.elm'
import { Elm as OffersForUser } from 'OffersForUser.elm'
import { Elm as SellingListingsForUser } from 'SellingListingsForUser.elm'
import toastrPorts from 'shared/elm_toastr'
import elmElement from '@carwow/carwow_theme/app/javascript/elm_element'

elmElement('carwow-activity-timeline', ActivityTimeline.ActivityTimeline, {
  setupPorts (ports) {
    toastrPorts(ports)
  },

  staticFlags: {
    jwt: window.CarwowJWT,
    locale: window.Carwow.LOCALE,
    timezone: window.Carwow.TIMEZONE,
    dealersSiteUrl: Carwow.APIUrls.dealersSite,
    quotesSiteUrl: Carwow.APIUrls.quotesSite
  },

  mapFlags (flags) {
    return Object.assign({}, flags, { targetId: parseInt(flags.targetId, 10) })
  }
})

elmElement('carwow-car-configurations-for-user', CarConfigurationsForUser.CarConfigurationsForUser, {
  toastrPorts,

  staticFlags: {
    jwt: window.CarwowJWT,
    locale: window.Carwow.LOCALE,
    researchSiteUrl: Carwow.APIUrls.researchSite,
    dealersSiteUrl: Carwow.APIUrls.dealersSite,
    quotesSiteUrl: Carwow.APIUrls.quotesSite
  },

  mapFlags (flags) {
    return Object.assign({}, flags, {
      userId: parseInt(flags.userId, 10),
      userSuspended: flags.userSuspended === 'true'
    })
  }
})

elmElement('carwow-offers-for-user', OffersForUser.OffersForUser, {
  toastrPorts,
  staticFlags: {
    jwt: window.CarwowJWT,
    quotesSiteUrl: Carwow.APIUrls.quotesSite
  },
  mapFlags (flags) {
    return Object.assign({}, flags, { userId: parseInt(flags.userId, 10) })
  }
})

elmElement('carwow-selling-listings-for-user', SellingListingsForUser.SellingListingsForUser, {
  toastrPorts,
  staticFlags: {
    jwt: window.CarwowJWT,
    quotesSiteUrl: Carwow.APIUrls.quotesSite,
    locale: window.Carwow.LOCALE
  },
  mapFlags (flags) {
    return Object.assign({}, flags, {
      userId: parseInt(flags.userId, 10),
      documentCollection: flags.documentCollection === 'true'
    })
  }
})

/*
  Register volume errors for various elm components. Occasional timeouts on load can
  be handled in Honeycomb instead.
*/
if (window.Carwow && window.Carwow.registerBugsnagVolumeError) {
  window.Carwow.registerBugsnagVolumeError((error) => {
    const activityTimelineComponent = error.errorClass.includes('carwow-activity-timeline')
    const messages = [
      'Http.NetworkError @',
      'Http.Timeout @',
      'Http.BadStatus: 504 @'
    ]
    const validError = messages.some(message => error.errorMessage.includes(message))

    return activityTimelineComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const carConfigurationsForUserComponent = error.errorClass.includes('carwow-car-configurations-for-user')
    const messages = [
      'Http.BadStatus: 401 @',
      'Http.Timeout @'
    ]
    const validError = messages.some(message => error.errorMessage.includes(message))

    return carConfigurationsForUserComponent && validError
  })

  window.Carwow.registerBugsnagVolumeError((error) => {
    const offersForUserComponent = error.errorClass.includes('carwow-offers-for-user')
    const validError = error.errorMessage.includes('Http.Timeout @')

    return offersForUserComponent && validError
  })
}
